export enum StudyStatus {
  WorkOrderCreated = 0,
  Started = 1,
  Counted = 2,
  CountCompleted = 3,
  Approved = 4,
  Warning = 5,
  Error = 6,
  NeedReview = 7,
}

export enum StudyType {
  AllStudies = 0,
  Volume = 1,
  TMC = 2,
  Speed = 4,
}
export enum StudyCategory {
  Volume = 1,
  Speed = 2,
  Classification = 4,
  ContinuousCount = 8,
}

export enum ReportingPeriodType {
  AM = 1,
  MidDay = 2,
  PM = 3,
  SpecialEvent = 4,
}

export enum AggregationInterval {
  five = 5,
  fifteen = 15,
  sixty = 60,
}
export enum AADTCalculationType {
  Calculate = 0,
  NotCalculate = 1,
  CalculatedFromRelatedStudy = 2,
}

export enum MovementType {
  // N : North
  // E : East
  // S : South
  // W : West
  // T : Through
  // R : Right
  // L : Left
  // U : U-Turn , Up
  // D : Down
  Unknown = 0,
  NT = 1,
  NL = 2,
  ER = 3,
  ET = 4,
  EL = 5,
  SR = 6,
  ST = 7,
  SL = 8,
  WR = 9,
  WT = 10,
  WL = 11,
  NR = 12,
  NRC = 13,
  NLC = 14,
  EUC = 15,
  EDC = 16,
  SRC = 17,
  SLC = 18,
  WUC = 19,
  WDC = 20,
  NU = 21,
  EU = 22,
  SU = 27,
  WU = 30,
}

export enum ApproachType {
  Unknown = 0,
  North = 1,
  East = 2,
  South = 4,
  West = 8,
  BothDirection = 16,
}

export enum BinMapType {
  TmcJCD = 1,
  ArmadilloSpeed = 2,
  PRNSpeed = 3,
  PRNVolume = 4,
  MetroCountInductionLoopsVolume = 5,
  OldArmadilloSpeed = 6,
  MiovisionVolume = 7,
  GretchTRF = 8,
  GoldenRiver = 9,
  MioVisionExcelTMC = 10,
  HoustonRadarSpeedClassCSV = 11,
}

export enum VehicleClassificationType {
  Unknown = 0,
  Car = 1,
  Truck = 2,
  HeavyTruck = 4,
  Cyclist = 8,
  Pedestrian = 16,
  AssistedPedestrian = 32,
  Bus = 64,
}

export enum DayDefinitionType {
  Noon2Noon = 1,
  MidNight2MidNight = 2,
}

export enum ApproachLaneNo {
  One = 1,
  TwoOrMore = 2,
}
export enum FlowConditionType {
  Restricted = 1,
  Free = 2,
}
export enum SignalWarrantAlgorithmType {
  OTM = 1,
}

export enum AllWayStopType {
  UrbanArterial = 1,
  CollectorRuralArterial = 2,
  Local = 3,
}

export enum AADTType {
  Intersection = 1,
  RoadSegment = 2,
}
export enum AADTSourceType {
  NotExist = -1,
  Unknown = 0,
  Count = 1,
  UserModified = 2,
  UserModifiedPermanent = 3,
  GrowthFromCount = 4,
  SingleIntersection = 5,
  IntersectionAverage = 6,
  MidblockLinearAverage = 7,
  TripGeneration = 8,
  TimeLinearAverage = 9,
  GrowthFromPrediction = 10,
  PredictedGrowthFromCount = 11,
  LinearRegression = 12,
  Corridor = 13,
  TesHistorical = 14,
}

export enum StudyValidationLogType {
  VolumeChnagedMoreThanThreshold = 1,
  DurationOfStudyisNotDefined = 2,
  RawDataDTOutOfWorkOrderDT = 3,
}

export enum TrafficMapMode {
  None = -1,
  postedSpeedByColor = 1,
  averageSpeedByColor = 2,
  intersectionAADT = 3,
  roadSegmentAADT = 4,
  compliance = 5,
}
export enum AADTPredictionProjectType {
  Intersection=1,
  RoadSegment=2,

}

