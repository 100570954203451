//#region impoprts
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { TesPut } from "../../../../../utils/rest";
import { Popup } from "devextreme-react/popup";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Button as DevExpressButton,
  Export,
  Selection,
  GroupPanel,
  Grouping,
  Search,
} from "devextreme-react/data-grid";
import {
  JobStatus,
  SupportStatus,
} from "../../../../../types/maintenance/maintenanceTypes";
import { Button } from "devextreme-react/button";
import CustomStore from "devextreme/data/custom_store";
import notify from "devextreme/ui/notify";
import { custom } from "devextreme/ui/dialog";
import { SelectBox, TextArea, ValidationGroup } from "devextreme-react";
import ScrollView from "devextreme-react/scroll-view";
import { Form, SimpleItem } from "devextreme-react/form";
import { VMWebSupport } from "../../../../../types/sign/dto/supportDto";
import Permission from "../../../../../components/permission/permision";
import { MaintenancePermissions } from "../../../../../constants/Permissions";
import { SignApiUrl } from "../../../../../environment/routeSettings";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";

//#endregion imports
//#region props
interface IPros {
  initDataJobStatuses: JobStatus[];
  initDataSupports: VMWebSupport[];
  generalSettings: any;
  supportStatus: SupportStatus;
  assignSupport: (lstSupports: VMWebSupport[]) => void;
  onDeleteSupport: (support: VMWebSupport) => void;
  assignSupportStatus: (supportId: string) => void;
  onValueChangeSupportStatus: (name: string, value: any) => void;
  addNewSupportStatus: (selectedSupport: VMWebSupport) => void;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroup>
}
//#endregion props
const Supports = (props: IPros) => {
  //#region  consts
  const [showModalAssignSupport, setShowModalAssignSupport] = useState(false);
  const [showModalStatus, setShowModalStatus] = useState(false);
  const [selectedSupport, setSelectedSupport] = useState<VMWebSupport>(new VMWebSupport());
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const dataGridRefAssignSupport = useRef<DataGrid<any, any>>(null);
  const { t } = useTranslation();
  const [totalCountValue, setTotalCountValue] = useState<number>(-1);
  const [totalCountAssignValue, setTotalCountAssignValue] = useState<number>(-1);
  //#endregion costs

  //#region functions
  const dataSourceAssignSupport = new CustomStore({
    load: async (loadOption) => {
      return await TesPut(
        `${SignApiUrl()}/api/Supports/LazyLoading/` +
        localStorage.getItem("selectedCustomerId"),
        JSON.stringify(loadOption),
        true
      );
    },
  });
  function onCloseModalAddSupport() {
    setShowModalAssignSupport(false);
    dataGridRefAssignSupport.current?.instance.deselectAll();
  }
  function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              props.onDeleteSupport(d);
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }
  function onAssignSupport() {
    var lstSupports =
      dataGridRefAssignSupport.current?.instance.getSelectedRowKeys() as VMWebSupport[];
    props.assignSupport(lstSupports);
    setShowModalAssignSupport(false);
    dataGridRefAssignSupport.current?.instance.deselectAll();
    props.setDataChanged(true)
  }
  function calculateStatus(d: any) {
    if (props.initDataJobStatuses) {
      const v = props.initDataJobStatuses.find((x) => x.id === d.statusId);
      if (v) {
        return v.name;
      }
    }
  }
  function calculateValuesListCell(d: any) {
    return (
      <Button
        className="tes-modal-btn-add"
        onClick={() => onOpenStatus(d.data)}
        text={t("status")}
      />
    );
  }
  function onOpenStatus(data: any) {
    props.assignSupportStatus(data.id);
    setSelectedSupport(data);
    setShowModalStatus(true);
  }
  function onCloseModalSupportStatus() {
    setShowModalStatus(false);
  }
  function onSaveStatus() {
    props.addNewSupportStatus(selectedSupport);
    dataGridRef.current?.instance.refresh();
    setShowModalStatus(false);
  }
  //#endregion functions
  return (
    <React.Fragment>
      <ValidationGroup
        ref={props.validationRef}
      >
        <div className={"content-block"}>
          <div className={" responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Button
                  style={{ marginLeft: 5 }}
                  onClick={() => setShowModalAssignSupport(true)}
                  icon="fa-solid fa-link"
                  text={t("assign")}
                  disabled={props.isLocked}
                />
              </div>
            </div>
            {/* Maintenance datagrid */}
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initDataSupports}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />

              <Export enabled={true} allowExportSelectedData={true} />

              <FilterRow visible={true} applyFilter="auto" />

              <HeaderFilter visible={true} />

              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column dataField="geoId" caption={t("geoId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                dataField="supportLocationTypeTitle"
                caption={t("locationType")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column alignment="left" dataField="xCoord" caption={t("xCoord")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column alignment="left" dataField="yCoord" caption={t("yCoord")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column alignment="left" dataField="supportId" caption={t("supportId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="supportCodeTitle"
                caption={t("code")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="supportDescriptionTitle"
                visible={false}
                caption={t("description")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="supportMaterialTitle"
                visible={false}
                caption={t("material")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="supportTypeTitle"
                visible={false}
                caption={t("type")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="height"
                visible={false}
                caption={t("height")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="supportPositionTitle"
                visible={false}
                caption={t("position")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="supportConditionTitle"
                visible={false}
                caption={t("condition")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="dateInstalled"
                dataType="date"
                format={props.generalSettings.dateFormat}
                visible={false}
                caption={t("installationDate")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="dateExpired"
                dataType="date"
                format={props.generalSettings.dateFormat}
                visible={false}
                caption={t("expirationDate")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="materialCost"
                visible={false}
                caption={t("materialCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="labourCost"
                visible={false}
                caption={t("labourCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="installationCost"
                visible={false}
                caption={t("installationCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column alignment="left" dataField="note" caption={t("comment")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                dataField="statusId"
                calculateDisplayValue={calculateStatus}
                caption={t("status")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column
                alignment="left"
                cellRender={calculateValuesListCell}
                //visible={!(this.props.isLocked)}
                caption={t("status")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              {/* {!this.props.isLocked && */}
              <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
              {/* } */}
            </DataGrid>
          </div>
        </div>

        {/* Assign Support Modal */}
        <Popup
          width={"65%"}
          visible={showModalAssignSupport}
          resizeEnabled={true}
          showTitle={true}
          title={t("assignSupport")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalAddSupport()}
        >
          <div style={{ height: "90%" }}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRefAssignSupport}
              dataSource={dataSourceAssignSupport}
              rowAlternationEnabled={true}
              showBorders={true}
              style={{ height: "100%" }}
              keyExpr="id"
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridRefAssignSupport?.current?.instance.deselectAll()}}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <Selection
                mode="multiple"
                selectAllMode={"allPages"}
                showCheckBoxesMode={"always"}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column dataField="geoId" caption={t("geoId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                dataField="supportLocationTypeTitle"
                caption={t("locationType")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column alignment="left" dataField="xCoord" caption={t("xCoord")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column alignment="left" dataField="yCoord" caption={t("yCoord")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column alignment="left" dataField="supportId" caption={t("supportId")}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="supportCodeTitle"
                caption={t("code")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="supportDescriptionTitle"
                visible={false}
                caption={t("description")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="supportMaterialTitle"
                visible={false}
                caption={t("material")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="supportTypeTitle"
                visible={false}
                caption={t("type")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="height"
                visible={false}
                caption={t("height")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="supportPositionTitle"
                visible={false}
                caption={t("position")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="supportConditionTitle"
                visible={false}
                caption={t("condition")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="dateInstalled"
                dataType="date"
                format={props.generalSettings.dateFormat}
                visible={false}
                caption={t("installationDate")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="dateExpired"
                dataType="date"
                format={props.generalSettings.dateFormat}
                visible={false}
                caption={t("expirationDate")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="materialCost"
                visible={false}
                caption={t("materialCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="labourCost"
                visible={false}
                caption={t("labourCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column
                alignment="left"
                dataField="installationCost"
                visible={false}
                caption={t("installationCost")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            </DataGrid>
          </div>
          {totalCountAssignValue !== -1 &&
            <div className="row">
              <div className="rightColumn">
                {totalCountAssignValue + " " + t('items')}
              </div>
            </div>
          }

          <div style={{ marginTop: 20 }}>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={onCloseModalAddSupport}
                text={t("cancel")}
              />
              <Permission
                allowed={[
                  MaintenancePermissions.Maintenance_D_Job,
                  MaintenancePermissions.Maintenance_E_Job,
                ]}
                hasFeedBackElement={false}
              >
                <Button
                  className="tes-modal-btn-add"
                  onClick={onAssignSupport}
                  text={t("add")}
                />
              </Permission>
            </div>
          </div>
        </Popup>

        {/* Status Modal */}
        <Popup
          width={"40%"}
          height="auto"
          visible={showModalStatus}
          resizeEnabled={true}
          showTitle={true}
          title={t("add")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModalSupportStatus()}
        >
          <ScrollView width="100%" height="100%">
            <Form colCount={2}>
              <SimpleItem colSpan={2}>
                <SelectBox
                  placeholder=""
                  style={{ marginBottom: -10 }}
                  label={t("statusId")}
                  labelMode="static"
                  valueExpr="id"
                  displayExpr="name"
                  onValueChange={(e) => props.onValueChangeSupportStatus("statusId", e)}
                  value={props.supportStatus.statusId}
                  items={SortObjectByPropName(props.initDataJobStatuses, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                />
              </SimpleItem>
              <SimpleItem colSpan={2}>
                <TextArea
                  style={{ marginBottom: 20 }}
                  label={t("note")}
                  height={50}
                  labelMode="static"
                  onValueChange={(e) =>
                    props.onValueChangeSupportStatus("note", e)
                  }
                  value={props.supportStatus.note}
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => setShowModalStatus(false)}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={onSaveStatus}
                  text={t("add")}
                />
              </div>
            </div>
          </ScrollView>
        </Popup>
      </ValidationGroup>
    </React.Fragment>
  );
};

export default Supports;
