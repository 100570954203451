import { useEffect, useRef } from 'react';
import ko from 'knockout';
import 'devexpress-reporting/dx-webdocumentviewer';
import { ClientReport } from '../../types/report/reportTypes';
import { ReportApiUrl } from '../../environment/routeSettings';
import { ajaxSetup } from "@devexpress/analytics-core/analytics-utils";
import { TesPost, TesPostReport } from '../../utils/rest';

interface IPros {
  selectedReport: ClientReport;
  lstSelectedIds: string[];
  payload?: string | null;
  isOpen: boolean;
  endPointURL?: string;
}
const ReportViewer = (props: IPros) => {
  //const [reportUrl, setReportUrl] = useState(null);

  const viewer = useRef(null);

  useEffect(() => {
    ajaxSetup.ajaxSettings = {
      headers: {
        'X-CSRF': '1'
      }
    };
    // console.log("REPORT " + JSON.stringify(props.selectedReport))
    // console.log("REPORT ID" + JSON.stringify(props.selectedReport.id))
    // console.log("props.payload" + props.payload)
    // console.log("props.lstSelectedIds" + props.lstSelectedIds)
    if (props.isOpen === true && props.selectedReport.id !== undefined && (props.lstSelectedIds.length > 0 || props.payload !== undefined)) {
      var requestOptions = {
        host: ReportApiUrl(),
        // host: "https://localhost:15101",

        invokeAction: "/DXXRDV"
      };

      ko.applyBindings({

        reportUrl: getReportURL(),
        requestOptions: requestOptions,
        headers: {
          'X-CSRF': '1'
        },
        callbacks: {
          OnExport: function (src: any, event: any) {
            // console.log('export', src, event);
            onExport(event)
            event.stopPropagation();
            event.cancelBubble = true;
          }
        }

      }, viewer.current);
    }
    return () => {
      if (viewer.current != null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ko.cleanNode(viewer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen, props.selectedReport, props.lstSelectedIds, props.payload]);

  useEffect(() => {
    return () => {
      if (viewer.current != null)
        // eslint-disable-next-line react-hooks/exhaustive-deps
        ko.cleanNode(viewer.current);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.selectedReport, props.lstSelectedIds]);

  function getReportURL() {
    const obj = {
      "ReportName": props.selectedReport.name,
      "ReportId": props.selectedReport.id,
      "CustomerId": props.selectedReport.customerId,
      "Token": `Bearer ${localStorage.getItem('tesToken')}`,
      "ListIds": props.lstSelectedIds,
      "Payload": props.payload,
      "IsDesigner": false,
      "EndPointURL": props.endPointURL
    }
    return JSON.stringify(obj);
  }

  function onExport(event: any) {
    let formData = "";
    Object.keys(event.FormData).forEach(key => {
      if (formData == "") {
        formData += (key + "=" + encodeURIComponent(event.FormData[key]));
      }
      else {
        formData += ("&" + key + "=" + encodeURIComponent(event.FormData[key]));
      }
    });

    //TODO:Prevent default export
    TesPostReport(ReportApiUrl() + "/DXXRDV", formData, true).then(x => {
      //console.log("RESSDS" + x)
      saveAs(x);
    });
  }

  function findMimeType(type: any) {
    if (type.indexOf("vnd.ms-excel") > -1) {
      return "xls";
    }
    else if (type.indexOf("vnd.openxmlformats-officedocument.spreadsheetml.sheet") > -1) {
      return "xlsx";
    }
    else if (type.indexOf("rtf") > -1) {
      return "rtf";
    }
    else if (type.indexOf("vnd.openxmlformats-officedocument.wordprocessingml.document") > -1) {
      return "docx";
    }
    else if (type.indexOf("message/rfc822") > -1) {
      return "rfc822";
    }
    else if (type.indexOf("text/html") > -1) {
      return "html";
    }
    else if (type.indexOf("text/plain") > -1) {
      return "txt";
    }
    return "";
  }

  function saveAs(blob: any) {
    var link = window.document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = link.href.split('/').pop() + '.' + findMimeType(blob.type);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }



  return (
    <div ref={viewer} data-bind="dxReportViewer: $data"></div>
  )
}

export default ReportViewer