import React, { useState, useRef, useEffect, useCallback } from "react";
import { TesGet } from '../../../../../utils/rest';
import { TesField } from '../../../../../types/field/fieldType';
import { Button } from 'devextreme-react/button';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Pager, Paging, Selection, SortByGroupSummaryInfo, GroupItem, Summary, StateStoring, Export, Search,
} from 'devextreme-react/data-grid';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../../contexts/auth";
import notify from "devextreme/ui/notify";
import { SignApiUrl } from "../../../../../environment/routeSettings";
import ValidationGroup from "devextreme-react/validation-group";
import { GridType } from "../../../../../types/general/enums/generalEnums";
import { OnExporting } from "../../../../../utils/dataGridTools";

// props
interface IPros {
    intersectionId: string
    isLocked: boolean;
    validationRef: React.RefObject<ValidationGroup>
}

const Sign = (props: IPros) => {
    const [initDataFields, setInitDataFields] = useState<TesField[]>();
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const history = useNavigate();
    const { t } = useTranslation();
    const { activeLoading } = useAuth();
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                if (props.intersectionId !== "AddNew") {
                    await getInitialDataFields();
                };
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    async function getInitialDataFields() {
        setInitDataFields(await TesGet(SignApiUrl() + "/api/Supports/GetSupportOfLocation/" + props.intersectionId, true));
    }

    const navigateToSupportDetails = useCallback(() => {
        history('/sign/supportDetails/AddNew');
    }, [history]);

    return (
        <div className={`intersectionDetails-supports ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <ValidationGroup
                    ref={props.validationRef}
                >
                    <div className={'content-block'}>
                        <div className={'dx-card responsive-paddings'}>
                            <div className="row">
                                <div className='leftColumn'>
                                    {/* <CollisionDiagram
                                getCollisionDiagramData={this.getCollisionDiagramData.bind(this)}
                                lstCollisionIds={this.state.lstCollisionIds}
                            /> */}
                                </div>
                                <div className='rightColumn'>
                                    <Button
                                        onClick={navigateToSupportDetails}
                                        icon="fa-solid fa-circle-plus"
                                        text={t('add')}
                                        disabled={props.isLocked}
                                    />
                                </div>
                            </div>

                            <DataGrid id="gridContainer"
                                ref={dataGridRef}
                                key="id"
                                dataSource={initDataFields}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                //onRowClick={onRowClicked}
                                hoverStateEnabled={true}
                                remoteOperations={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                columnAutoWidth={true}
                                style={{ margin: "0 1rem" }}
                                onExporting={OnExporting}
                                //onContentReady={e => {e.component.deselectAll()}}
                                //onFilterValueChange={e => {dataGridRef?.current?.instance.deselectAll()}}
                            >
                                <Export enabled={true} allowExportSelectedData={true} />
                                <StateStoring
                                    enabled={true}
                                    type="localStorage"
                                    storageKey={GridType.SupportsInIntersectionDetails.toString()}
                                    savingTimeout={500}
                                />


                                <SortByGroupSummaryInfo
                                    summaryItem="Total Count"
                                    sortOrder="desc"
                                />
                                <Summary>
                                    <GroupItem
                                        summaryType="count"
                                        alignByColumn
                                        name="Total Count"
                                    />
                                </Summary>
                                <Paging enabled={true} defaultPageSize={100} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[100, 200, 300, 400, 500]}
                                    showNavigationButtons={true}
                                    showInfo={true}
                                    visible={true}
                                />

                                <Selection
                                    mode="multiple"
                                    selectAllMode={'allPages'}
                                    showCheckBoxesMode={'always'}
                                    deferred={true}
                    // @ts-ignore
                    maxFilterLengthInRequest={10000}
                                />

                                <FilterRow visible={true}
                                    applyFilter="auto" />

                                <HeaderFilter visible={true} />


                                <SearchPanel visible={true}
                                    width={285}
                                    placeholder={t("search...")} />
                                <ColumnChooser width={350} height={400}
                                    enabled={true}
                                    mode="select"
                                    sortOrder="asc"
                                >
                                    <Search enabled />
                                </ColumnChooser>

                                <Column alignment="left" dataField="ChangeStatus"
                                    //cellRender={calculateImageTypeCell.bind(this)}
                                    caption={t("symbol")}
                                    width={100}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="geoId"
                                    caption={t("geoId")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="signLocationTypeTitle"
                                    caption={t("locationType")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="signId"
                                    caption={t("signId")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="supportTitle"
                                    caption={t("supportId")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="signCodeTitle"
                                    caption={t("code")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="signDimensionTitle"
                                    caption={t("dimension")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="height"
                                    caption={t("height")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="signFacingDirectionTitle"
                                    caption={t("facingDirection")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="signFaceMaterialTitle"
                                    caption={t("facingMaterial")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="signReflectiveCoatingTitle"
                                    caption={t("reflectiveCoating")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="signReflectiveRatingTitle"
                                    caption={t("reflectiveRating")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="signConditionTitle"
                                    caption={t("condition")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                {/* <Column alignment="left" dataField="dateInstalled" dataType="date" format={this.props.clientGeneralSettings ? this.props.clientGeneralSettings["dateFormat"] : "MM/dd/yyyy"}
                            caption={t("installationDate")}>
                            <HeaderFilter>
                                    <Search enabled/>
                                </HeaderFilter>
                        </Column>

                        <Column alignment="left" dataField="dateExpired" dataType="date" format={this.props.clientGeneralSettings ? this.props.clientGeneralSettings["dateFormat"] : "MM/dd/yyyy"}
                            caption={t("expirationDate")}>
                            <HeaderFilter>
                                    <Search enabled/>
                                </HeaderFilter>
                        </Column> */}


                                <Column alignment="left" dataField="materialCost"
                                    caption={t("materialCost")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="labourCost"
                                    caption={t("labourCost")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="installationCost"
                                    caption={t("installationCost")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="latitude" dataType="number"
                                    caption={t("latitude")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="longitude" dataType="number"
                                    caption={t("longitude")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="xCoord" dataType="number"
                                    caption={t("xCoord")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column alignment="left" dataField="yCoord" dataType="number"
                                    caption={t("yCoord")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                            </DataGrid>
                        </div>
                    </div>
                </ValidationGroup>
            </React.Fragment>
        </div>
    );
}
export default Sign;