//#region imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Summary,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  GroupItem,
  Export,
  Search
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import {
  TesGet,
} from "../../../utils/rest";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import "./modules.scss";
import { useNavigate } from "react-router-dom";
import { ClientModule } from "../../../types/moduleOfModules/moduleOfModulesTypes";
import { ModuleOfModulePermissions } from "../../../constants/Permissions";
import Permission from "../../../components/permission/permision";
import notify from "devextreme/ui/notify";
import { ModuleOfModuleApiUrl } from "../../../environment/routeSettings";
import { OnExporting } from "../../../utils/dataGridTools";
import { ContextMenu, Lookup } from "devextreme-react";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { useClientSetting } from "../../../contexts/clientSetting";

//#endregion imports
const Modules = () => {
  //#region consts
  const [initData, setInitData] = useState<ClientModule[]>([new ClientModule()]);
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const { activeLoading } = useAuth();
  const { user } = useAuth();
  const { t } = useTranslation();
  const history = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { customerDivisions } = useClientSetting();

  //#endregion consts

  //#region functions
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(
      await TesGet(
        ModuleOfModuleApiUrl() +
        "/api/ClientModule/GetCustomerClientModule/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  const navigateToModuleDetails = useCallback(() => {
    history("/moduleOfModules/ModulesDetails/AddNew");
  }, [history]);

  //#endregion functions

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/moduleOfModules/ModulesDetails/${e.data.id}`, "_blank");
      } else {
        history(`/moduleOfModules/ModulesDetails/${e.data.id}`);
      }
    }
  }

  function calculateValuesListCell(d: any) {
    return (
      <Button
        onClick={() => history('/moduleOfModules/data/' + d.data.id)}
        icon="fa-solid fa-circle-plus"
        text={t('data')}
      />
    );
  }
  return (
    <Permission
      allowed={[ModuleOfModulePermissions.ModuleOfModule_D_Data, ModuleOfModulePermissions.ModuleOfModule_D_Module, ModuleOfModulePermissions.ModuleOfModule_E_Data, ModuleOfModulePermissions.ModuleOfModule_V_Data]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, `/moduleOfModules/ModulesDetails/`)}
          />
          <h2 className={"content-block"}>{t("moduleOfModules")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={navigateToModuleDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("module")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                onRowClick={onRowClicked}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Summary>
                  <GroupItem summaryType="count" alignByColumn name="Total Count" />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column alignment="left"
                  cellRender={calculateValuesListCell}
                  caption={t("data")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                    dataField="DivisionId"
                    allowSorting={true}
                    visible={false}
                    caption={t("division")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(customerDivisions!, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Modules;
