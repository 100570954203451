import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContextMenu, DataGrid } from "devextreme-react";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { AADTSourceType, AADTType, StudyType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { Column, ColumnChooser, Export, FilterBuilderPopup, FilterPanel, FilterRow, Grouping, GroupPanel, HeaderFilter, Pager, Paging, SearchPanel, Lookup, SortByGroupSummaryInfo, Summary, GroupItem, Search } from "devextreme-react/data-grid";
import { AADTDetail, StudyAADTCalculationResult } from "../../../../../../types/trafficStudy/dtos/studyDto";
import { useClientSetting } from "../../../../../../contexts/clientSetting";
import { useNavigate } from "react-router-dom";
import { OnExporting } from "../../../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";
import { RightClickMenu } from "../../../../../../utils/rightClickMenu";

// props
interface IPros {
  initData: AADTDetail,
  aadtType: string,
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
}

const StudiesAADT = (props: IPros) => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const { generalSetting } = useClientSetting();
  const [lstSourceType, setLstSourceType] = useState<NameValue[]>([]);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    //   { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const history = useNavigate();
  const compactViewModel: boolean =(JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  useEffect(() => {
    setLstSourceType(Enum2Array(AADTSourceType));
  }, []);

  function onRowClick(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

    if (isCtrlKeyPressed) {
      window.open(`/trafficStudy/studies/studyDetails/${e.data.studyType}/${e.data.studyId}`, "_blank");
    } else {
      history(`/trafficStudy/studies/studyDetails/${e.data.studyType}/${e.data.studyId}`);
    }
  }

  return (
    <div className={`aadtDetails-studies ${compactViewModel ? "compactStyle" : ""}`}>
    <React.Fragment>
      <ContextMenu
        dataSource={items}
        width={100}
        target=".dx-data-row"
        onItemClick={e => RightClickMenu(e, selectedRowData.row.data.studyId, `/trafficStudy/studies/studyDetails/${selectedRowData.row.data.studyType}/`)}
      />
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <DataGrid
            id="studiesGridContainer"
            ref={dataGridRef}
            dataSource={props.aadtType.toString() === AADTType.Intersection.toString() ? props.initData?.intersectionAADTs : props.initData?.roadSegmentAADTs}
            rowAlternationEnabled={true}
            showBorders={true}
            hoverStateEnabled={true}
            remoteOperations={true}
            allowColumnReordering={true}
            allowColumnResizing={true}
            columnAutoWidth={true}
            style={{ margin: "0 1rem" }}
            onRowClick={onRowClick}
            onExporting={OnExporting}
            onContextMenuPreparing={e => { setSelectedRowData(e) }}
          >
            <Export enabled={true} allowExportSelectedData={true} />
            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
            <GroupPanel visible={true} /> {/* or "auto" */}
            <FilterPanel visible={true} />
            <FilterBuilderPopup position={"top"} />
            <Paging enabled={true} defaultPageSize={100} />
            <Pager
              showPageSizeSelector={true}
              allowedPageSizes={[100, 200, 300, 400, 500]}
              showNavigationButtons={true}
              showInfo={true}
              visible={true}
            />
            <FilterRow visible={true} applyFilter="auto" />
            <HeaderFilter visible={true} />
            <SearchPanel visible={true} width={285} placeholder={t("search...")} />
            <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
              <Search enabled />
            </ColumnChooser>
            <SortByGroupSummaryInfo
              summaryItem="Total Count"
              sortOrder="desc"
            />
            <Summary>
              <GroupItem
                summaryType="count"
                alignByColumn
                name="Total Count"
              />
            </Summary>
            <Column dataField="id" caption={t("Id")} visible={false}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="aadtRawDataDT" caption={t("date")} visible={true}
              dataType="datetime"
              format={generalSetting?.dateFormat}
            >
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            {props.aadtType.toString() === AADTType.Intersection.toString() &&
              <Column dataField="majorVolume" caption={t("majorVolumeAadt")} visible={true} format="#,###">
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            }
            {props.aadtType.toString() === AADTType.Intersection.toString() &&
              <Column dataField="minorVolume" caption={t("minorVolumeAadt")} visible={true} format="#,###">
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            }
            {props.aadtType.toString() === AADTType.Intersection.toString() &&
              <Column dataField="northApproachAADT" caption={t("northApproachAADT")} visible={true} format="#,###">
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            }
            {props.aadtType.toString() === AADTType.Intersection.toString() &&
              <Column dataField="southApproachAADT" caption={t("southApproachAADT")} visible={true} format="#,###">
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            }
            {props.aadtType.toString() === AADTType.Intersection.toString() &&
              <Column dataField="eastApproachAADT" caption={t("eastApproachAADT")} visible={true} format="#,###">
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            }
            {props.aadtType.toString() === AADTType.Intersection.toString() &&
              <Column dataField="westApproachAADT" caption={t("westApproachAADT")} visible={true} format="#,###">
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            }
            {/* {props.aadtType.toString() === AADTType.RoadSegment.toString() &&
              <Column dataField="segmentVolume" caption={t("segmentVolume")} visible={true} format="#,###">
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
            } */}
            <Column dataField="totalVolume" caption={t("totalVolumeAadt")} visible={true} format="#,###">
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
            </Column>
            <Column dataField="sourceType" caption={t("sourceType")}>
              <HeaderFilter>
                <Search enabled />
              </HeaderFilter>
              <Lookup
                dataSource={SortObjectByPropName(lstSourceType, "name")}
                valueExpr="value"
                displayExpr="name"
              />
            </Column>
          </DataGrid>
        </div>
      </div>
    </React.Fragment>
    </div>
  );
};
export default StudiesAADT;
