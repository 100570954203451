import React, { useEffect, useState } from 'react';
import { Attachment, TesSign } from '../../../../../types/sign/signTypes';
import { useTranslation } from 'react-i18next';
import { TesPost } from '../../../../../utils/rest';
import { Button, ValidationGroup } from 'devextreme-react';
import FileUploader from 'devextreme-react/file-uploader';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { AttachmentDownloadURL } from '../../../../../types/general/generalTypes';
import { AttachmentType, NewAttachmentType } from '../../../../../types/sign/enums/signEnums';
import { custom } from "devextreme/ui/dialog";
import { SignApiUrl } from '../../../../../environment/routeSettings';
import { AttachmentTypeFinder, FileKeyCutterWithExtension, FileType2AttachmentType, IsImage } from '../../../../../utils/fileTools';
import { t } from 'i18next';
import { Tooltip } from 'devextreme-react/tooltip';


// props
interface IPros {
    files: any[],
    isLocked: boolean;
    onDeleteNewFile: (name: string) => void;
    onDeleteExistingFile: (id: string) => void;
    handleChangeFile: (files: any[] | undefined) => void;
    validationRef: React.RefObject<ValidationGroup>
    initDataSign: TesSign
}


const Attachments = (props: IPros) => {
    const [selectedFileName, setSelectedFileName] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [showImageModal, setShowImageModal] = useState<boolean>(false);
    const [showURLModal, setShowURLModal] = useState<boolean>(false);
    const [showUploadModal, setShowUploadModal] = useState<boolean>(false);
    const [currentFileSelected, setCurrentFileSelected] = useState<any[] | undefined>([]);
    const [downloadIconColor, setDownloadIconColor] = useState<string>("#F0B70D");
    const [trashIconColor, setTrashIconColor] = useState<string>("#F0B70D");
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    const iconMap: Record<NewAttachmentType, string> = {
        [NewAttachmentType.compressed]: 'fa-file-zipper',
        [NewAttachmentType.video]: 'fa-youtube',
        [NewAttachmentType.location]: 'fa-location-dot',
        [NewAttachmentType.clone]: 'fa-compact-disk',
        [NewAttachmentType.image]: 'fa-image',
        [NewAttachmentType.word]: 'fa-file-word',
        [NewAttachmentType.html]: 'fa-file-code',
        [NewAttachmentType.note]: 'fa-file-lines',
        [NewAttachmentType.pdf]: 'fa-file-pdf',
        [NewAttachmentType.music]: 'fa-headphones',
        [NewAttachmentType.powerpoint]: 'fa-file-powerpoint',
        [NewAttachmentType.excel]: 'fa-file-excel',
        [NewAttachmentType.unknown]: 'fa-file-circle-question'
    };

    const onMouseIn = (event: any, key: string) => {
        setSelectedFileName(key)
    };

    const onMouseOutDelete = (event: any, key: string) => {
        setSelectedFileName("")
    };

    async function showAttachment(fileKey: string) {
        var res = await TesPost(SignApiUrl() + "/api/Attachments/DownloadAttachment", { key: fileKey }, true) as AttachmentDownloadURL
        setUrl(res.downloadUrl);
        IsImage(fileKey) ? setShowImageModal(true) : setShowURLModal(true)
    }
    
    async function showDocAttachment(fileKey: string) {
        var res = await TesPost(SignApiUrl() + "/api/Attachments/DownloadAttachment", { key: fileKey }, true) as AttachmentDownloadURL
        setUrl(res.downloadUrl);
        setShowURLModal(true)
    }

    async function onDelete(key: string, mode: number) {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: t("deleteWarningMessage"),
            buttons: [
                {
                    text: t("yes"),
                    onClick: async (e) => {
                        if (mode === 1) {
                            props.onDeleteExistingFile(key)
                        }
                        if (mode === 2) {
                            props.onDeleteNewFile(key)
                        }

                        return { buttonText: e.component.option("text") };
                    },
                },
                {
                    text: t("no"),
                    onClick: (e) => {
                        return { buttonText: e.component.option("text") };
                    },
                },
            ],
        });
        myDialog.show();
    }

    return (
        <div className={`collision-attachments ${compactViewModel ? "compactStyle" : ""}`}>

            <React.Fragment>
                <ValidationGroup
                    ref={props.validationRef}
                >
                    {props.initDataSign?.attachments === null ?
                        <div className='row' style={{ marginTop: 20, padding: 10 }}>
                            <div className='halfColumn' >
                                <p style={{ fontWeight: 'bold' }}>{t("thereIsNoDataInThisTab")}</p>
                            </div>
                        </div>
                        :
                        <div className={"content-block"}>
                            <div className={" responsive-paddings"}>
                                <div className="row">
                                    <div className="rightColumn">
                                        <Button
                                            onClick={() => setShowUploadModal(true)}
                                            icon="fa-solid fa-circle-plus"
                                            text={t("add")}
                                            disabled={props.isLocked}
                                        />

                                    </div>
                                </div>
                                <div className='row' style={{ marginTop: "-4.75rem" }}>
                                    {(props.initDataSign?.attachments !== null) &&
                                        <h5 style={{ marginBottom: "1rem" }}>{t("previousAttachments")}</h5>
                                    }
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {props.initDataSign?.attachments && props.initDataSign?.attachments?.map((f, index) => (
                                            <div style={{ width: '12%', justifyContent: 'center', alignItems: 'center', margin: "0 1.5rem -1rem 0" }} onMouseLeave={e => onMouseOutDelete(e, f.key)} onMouseOver={e => onMouseIn(e, f.key)} >
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid ${iconMap[AttachmentTypeFinder(f.key)]} ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.key &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>
                                                                {!props.isLocked &&
                                                                    <i
                                                                        className={`fa-solid fa-trash-can ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                        onClick={() => onDelete(f.key, 1)}
                                                                        style={{ display: "block", margin: "1rem 0 0 0", cursor: "pointer", color: trashIconColor }}
                                                                        onMouseOver={e => setTrashIconColor("#212121")}
                                                                        onMouseLeave={e => setTrashIconColor("#F0B70D")}
                                                                    >
                                                                    </i>}
                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>

                                    {props.files?.length !== 0 && <h5 style={{ margin: "1rem 0" }}>{t("recentAttachments")}</h5>}
                                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                                        {props.files?.map((f, index) => (
                                            <div style={{ width: '12%', justifyContent: 'center', alignItems: 'center', margin: "0 1.5rem -1rem 0" }} onMouseLeave={e => onMouseOutDelete(e, f.name)} onMouseOver={e => onMouseIn(e, f.name)}>
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid ${iconMap[AttachmentTypeFinder(f.name)]} ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.name &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showAttachment(f.name)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>
                                                                {!props.isLocked &&
                                                                    <i
                                                                        className={`fa-solid fa-trash-can ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                        onClick={() => onDelete(f.name, 2)}
                                                                        style={{ display: "block", margin: "1rem 0 0 0", cursor: "pointer", color: trashIconColor }}
                                                                        onMouseOver={e => setTrashIconColor("#212121")}
                                                                        onMouseLeave={e => setTrashIconColor("#F0B70D")}
                                                                    >
                                                                    </i>}
                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`recentFile${index}`}>{FileKeyCutterWithExtension(f.name, 15)}</p>
                                                    {f.name.length > 15 &&
                                                        <Tooltip
                                                            target={`#recentFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.name}</div>
                                                        </Tooltip>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </ValidationGroup>
            </React.Fragment>
            {/* Image modal */}
            <Popup
                                    width={"50%"}
                                    height={"auto"}
                                    visible={showImageModal}
                                    resizeEnabled={true}
                                    showTitle={true}
                                    title={t('image')}
                                    hideOnOutsideClick={false}
                                    showCloseButton={true}
                                    onHiding={() => setShowImageModal(false)}
                                >
                                    <ScrollView width='100%' height='100%'>
                                        <div style={{ height: "90%" }}>

                                            <div className="row">
                                                <div className="col-md-12">
                                                    {/* eslint-disable-next-line jsx-a11y/alt-text */}
                                                    <img src={url} id="pic" style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'contain' }} />
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='rightColumn' style={{ marginTop: 10 }}>
                                                <Button
                                                    className='tes-modal-btn-add'
                                                    style={{ marginRight: 20 }}
                                                    onClick={() => window.open(url, "_blank")}
                                                    text={t('download')}
                                                />
                                                <Button
                                                    className='tes-modal-btn-add'
                                                    onClick={() => { navigator.clipboard.writeText(url); notify(t("linkCopied"), "success", 5000); }}
                                                    text={t('copyLink')}
                                                />

                                            </div>
                                        </div>
                                    </ScrollView>
                                </Popup>


                                {/* URL modal */}
                                <Popup
                                    width={"50%"}
                                    height={"auto"}
                                    visible={showURLModal}
                                    resizeEnabled={true}
                                    showTitle={true}
                                    title={t('url')}
                                    hideOnOutsideClick={false}
                                    showCloseButton={true}
                                    onHiding={() => setShowURLModal(false)}
                                >
                                    <ScrollView width='100%' height='100%'>
                                        <div style={{ height: "90%" }}>

                                            <div className="row">
                                                <div className="col-md-6">
                                                    <span style={{ color: "#F0B70D", fontWeight: 'bold' }}>{t("link")}: </span>
                                                    <a href={url} style={{ textDecoration: 'underline', color: '#404040' }}>{url}</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div className='rightColumn' style={{ marginTop: 10 }}>
                                                <Button
                                                    className='tes-modal-btn-add'
                                                    style={{ marginRight: 20 }}
                                                    onClick={() => window.open(url, "_blank")}
                                                    text={t('download')}
                                                />
                                                <Button
                                                    className='tes-modal-btn-add'
                                                    onClick={() => { navigator.clipboard.writeText(url); notify(t("linkCopied"), "success", 5000); }}
                                                    text={t('copyLink')}
                                                />

                                            </div>
                                        </div>
                                    </ScrollView>
                                </Popup>


                                {/* Upload modal */}
                                <Popup
                                    width={"50%"}
                                    height={"auto"}
                                    visible={showUploadModal}
                                    resizeEnabled={true}
                                    showTitle={true}
                                    title={t('upload')}
                                    hideOnOutsideClick={false}
                                    showCloseButton={true}
                                    onHiding={() => setShowUploadModal(false)}
                                >
                                    <ScrollView width='100%' height='100%'>
                                        <div style={{ height: "90%" }}>

                                            <div className="row">
                                                <FileUploader selectButtonText={t("selectFile")} labelText="" multiple={true}
                                                    uploadMode="useForm"
                                                    value={currentFileSelected}
                                                    onValueChanged={e => setCurrentFileSelected(e.value)}
                                                />
                                            </div>
                                        </div>
                                        <div>
                                            <div className='rightColumn' style={{ marginTop: 10 }}>
                                                <Button
                                                    className='tes-modal-btn-add'
                                                    style={{ marginRight: 20 }}
                                                    onClick={() => { props.handleChangeFile(currentFileSelected); setShowUploadModal(false); setCurrentFileSelected([]) }}
                                                    text={t('add')}
                                                />
                                            </div>
                                        </div>
                                    </ScrollView>
                                </Popup>
        </div >
    )
}
export default Attachments;