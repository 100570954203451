import { DateTime } from "luxon";
import { IGeoJson } from "../../arcGis/arcGisTypes";
import { NameValue } from "../../general/generalTypes";
import { ToMapRequest } from "../../map/mapTypes";
import { CollisionExportType, CollisionMapMode, CollisionMergeAction, CollisionStatus, CollisionTransferAction, CollisionTransferMatchBy, GeoCodeStatus, ImportFieldCategory, XMLImportAction } from "../enums/collisionEnums";
import {
  ILocationCollisionSummery,
  ISeverityResult,
  SimplifiedCollision,
} from "./../collisionTypes";
export class VMMapLocation {
  latitude!: number;
  longitude!: number;
  xCoord!: number;
  yCoord!: number;
}
export class VMSimplifiedVehicleDriverCombined {
  index!: string;
  vehicleType!: string;
  driverAction!: string;
  driverCondition!: string;
  driverAgeGroup!: string;
  isDriverCyclist!: boolean;
  parent!: SimplifiedCollision;
  accidentNum!: number;
}

export class FromMapRequestCollisionDTO {
  customerId!: string;
  locationId!: string;
  fromDate!: Date;
  toDate!: Date;
}

export class FromMapResponseCollisionDTO {
  severity!: ISeverityResult[];
  impactType!: NameValue[];
  impactTypeClient!: NameValue[];
  roadSurfaceCondition!: NameValue[];
  lightingCondition!: NameValue[];
}

export class ToMapRequestCollisionDTO extends ToMapRequest {
  constructor() {
    super();
  }
  showCollisionLocations!: boolean;
  showCollisions!: boolean;
  showClusterData!: boolean;
  mapMode!: CollisionMapMode;
}

export class ToMapResponseCollisionDTO {
  geoJsonData!: IGeoJson;
  collisionLocations!: ILocationCollisionSummery[];
}

export class GetCollisionDatePeriodRequestDTO {
  customerId?: string;
  startDT?: Date;
  endDT?: Date;
}
export class XmlImportSettingDTO {
  title!: string;
  description!: string;
  customerId!: string;
  rootTagName!: string;
  generalTagName!: string;
  driverTagName!: string;
  personTagName!: string;
  vehicleTagName!: string;
  locationTagName!: string;
  roadTagName!: string;
  driverIdentifier!: string;
  enabled?: boolean;
  isDeleted?: boolean;
}

export class GeoCodeGroupDTO {
  id!: string;
  customerId!: string;
  title!: string;
}

export class UpdateGeoCodeStatusDTO {
  geoCodeStatus!: GeoCodeStatus;
  collisionIds!: string[]
}

export class VMCollisionStatusUpdate {
  status!: CollisionStatus;
  level!: number;
  collisionIds!: string[]
}



export class TransferCollisionTask {
  collisionIds!: string[];
  collisionTransferType?: CollisionExportType;
  toCustomerId?: string;
  emails?: string[];
  matchBy?: CollisionTransferMatchBy;
  collisionTransferAction?: CollisionTransferAction
}

export class CollisionMergeUserActionDTO {
  collisionMergeAction!: CollisionMergeAction;
  collisionId!: string;
}
export class CollisionMergeDecisionDTO {
  constructor() {
    this.collisionMergeUserActions = []
  }
  collisionMergeUserActions!: CollisionMergeUserActionDTO[];
  collisionId2MoveFile2!: string;
}

export class XMLImportLogDTO {
  id!: string;
  customerId!: string;
  userId!: string;
  startDT!: DateTime;
  endDT!: DateTime;
  xmlImportAction!:XMLImportAction;
  importSettingId!: string;
}
