import { MatchByType, MMSResStatus } from "./enums/infrastructureEnums";
import { INameId } from "./../general/generalTypes";
import {
  CastType,
  DataSourceType,
  LocationType,
  StreetDirection,
} from "../infrastructure/enums/infrastructureEnums";
import { FieldType } from "../field/enums/fieldEnums";
import { VMLocation } from "./dto/locationdDto";

export interface IInfrastructureSetting {
  id: string;
  customerId: string;
  intersectionFileAddress: string;
  midblockFileAddress: string;
  intersectionGeoIdType: FieldType;
  midblockGeoIdType: FieldType;
  latitude: number;
  longitude: number;
  arcGisApiKey: string;
  gisLayers: IInfrastructureGisLayer[];
  baseMapLayers: BaseMapLayer[];
  wkidNumber: number;
}

export interface IInfrastructureGisLayer {
  id: string;
  name: string;
  url: string;
  midblockFileAddress: string;
  settingId: string;
}

export interface IIntersectionParams {
  [key: string]: any;
  intersectionId?: string;
}

export interface IMMSParams {
  [key: string]: any;
  mmsId?: string;
}

export class IntersectionDescription {
  [key: string]: any;
  public street1Id!: string;
  public street2Id!: string;
}

//area
export interface IAreaParams {
  [key: string]: any;
  areaId: string;
}
export class Area {
  constructor() {
    this.locations = [];
    this.sharedAreas = [];
  }
  id!: string;
  customerId!: string;
  name!: string;
  userId!: string;
  locations!: AreaXLocation[];
  sharedAreas!: SharedArea[];
  locationCount!: number;
}

export class UpdateArea {
  [key: string]: any;
  customerId!: string;
  name!: string;
  lstLocationIds?: string[];
  listUserIds?: string[];
  listGroupIds?: string[];
  divisionId!: string;
}

export class AreaXLocation {
  id!: string;
  locationId!: string;
  order!: number;
  areaId!: IAreaParams;
}
export class SharedArea {
  id!: string;
  fromUserId!: string;
  toUserId!: string;
  toGroupId!: string;
  area!: Area;
  areaId!: IAreaParams;
}

export class MapSetting {
  constructor() {
    this.baseMapLayers = [];
  }
  [key: string]: any;
  id!: string;
  customerId!: string;
  intersectionFileAddress!: string;
  midblockFileAddress!: string;
  intersectionGeoIdName!: string;
  midblockGeoIdName!: string;
  intersectionGeoIdType!: FieldType;
  midblockGeoIdType!: FieldType;
  latitude!: number;
  longitude!: number;
  arcGisApiKey!: string;
  apiKeyExpiryDT!: Date;
  expiryEmailNotification!: string[];
  gisLayers!: GISLayerSetting[];
  baseMapLayers!: BaseMapLayer[];
  wkidNumber!: number;
}


//GIS Layer
export class GISLayerSetting {
  id!: string;
  name!: string;
  url!: string;
  setting!: MapSetting;
  settingId!: string;
}

//street
export class Street {
  [key: string]: any;
  id!: string;
  Name!: string;
  general: any;
  municiplaity!: string;
  inactive!: boolean;
}

export class Location {
  [key: string]: any;
  id!: string;
  tesId!: string;
  description!: string;
  geoId!: string;
  customerId!: string;
  divisionId!: string;
  siteSubTypeId!: string;
  locationType!: LocationType;
  streetId!: string;
  limit1IntersectionId!: string;
  limit2IntersectionId!: string;
  limit1StreetId!: string;
  limit2StreetId!: string;
  latitude!: number;
  longitude!: number;
  xCoord!: number;
  yCoord!: number;
  street1Id!: string;
  street2Id!: string;
  streetDirection1!: StreetDirection;
  streetDirection2!: StreetDirection;
  intersectionNorthApproachId!: string;
  intersectionSouthApproachId!: string;
  intersectionEastApproachId!: string;
  intersectionWestApproachId!: string;
  general: any;
  isDeleted!: boolean;
  inactive!: boolean;
  overwriteAutoGenerateDescription!: boolean;
  signalWarrantsPredictionSettings!: SignalWarrantsPredictionSettings;
}

export class RoadSegmentDescription {
  streetId!: string;
  limit1StreetId!: string;
  limit2StreetId!: string;
}

export interface IRoadSegmentParams {
  [key: string]: any;
  roadSegmentId: string;
}
export class TesCodeValue {
  municipalities!: INameId[];
  jurisdictions!: INameId[];
}

export class MunicipalityPopulation {
  id!: string;
  customerId!: string;
  municipalityId!: string;
  population!: number;
}

export class FieldDataSource {
  constructor() {
    this.lstColumnMap = [];
  }
  id!: string;
  customerId!: string;
  dataSourceType!: DataSourceType;
  dataSourceName!: string;
  infrastructureType!: LocationType;
  lstColumnMap!: ColumnMap[];
  isApproachLayer!: boolean;
}

export class ColumnMap {
  id!: string;
  clientFieldName!: string;
  clientFieldIndex!: number;
  isForeignKey!: boolean;
  castType!: CastType;
  fieldId!: string;
  constantField!: string;
  dateTimeFormat!: string;
  matchByType!: MatchByType;
}

export interface IFiledDataSourceParams {
  [key: string]: any;
  fieldDataSourceId: string;
}

export class InfrastructureLoadDataReq {
  id?: string;
  customerId!: string;
  startDt?: string;
  note?: string;
  importReqStatus?: ImportReqStatus;
  lstFieldDataSourceAddress!: FieldDataSourceAddress[];
}

export class FieldDataSourceAddress {
  [key: string]: any;
  id?: string;
  fieldDataSourceId?: string;
  fieldDataSourceType?: DataSourceType;
  fileAddress?: string;
  note?: string;
  importReqStatus?: ImportReqStatus;
}

export class ImportReqStatus {
  Processing!: 1;
  Complete!: 2;
  Failed!: 3;
}

export class PermanentCountStation {
  id!: string;
  customerId!: string;
  locationId!: string;
  name!: string;
  description!: string;
  installationDate!: Date;
}

export interface IPermanentCountStationParams {
  [key: string]: any;
  stationId?: string;
}

export interface ITrafficSectionParams {
  [key: string]: any;
  areaId: string;
}

export class TrafficSection {
  constructor() {
    this.locations = [];
  }
  [key: string]: any;
  id?: string;
  customerId!: string;
  divisionId!: string;
  name!: string;
  locations!: VMLocation[];
}

export class SignalWarrantsPredictionSettings {
  northUserDefinedGrowth!: number;
  eastUserDefinedGrowth!: number;
  southUserDefinedGrowth!: number;
  westUserDefinedGrowth!: number;
}

export class MmsRequest {
  id!: string;
  customerId!: string;
  divisionId?: string;
  name!: string;
  description!: string;
  year!: number;
}

export class MmsRes {
  id!: string;
  mmsRequestId!: string;
  customerId!: string;
  divisionId?: string;
  locationId!: string;
  locationDescription!: string;
  geoId!: string;
  postedSpeed!: number;
  aadt!: number;
  mms!: number;
  status!: MMSResStatus;
  rin!: string;
  smoothMMS!: number;
  mmsSmoothingStatus!: MMSSmoothingStatus;
}

export class BaseMapLayer {
  id!: string;
  url!: string;
  title!: string;
}

export enum MMSSmoothingStatus
{
    None = 0,
    Manually = 1 ,
    CalculatedByMinAlgorithm = 2,
}