//#region imports
import React, { useEffect, useRef, useState } from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser,
    Pager, Paging, TotalItem, Summary, Grouping, GroupPanel, FilterBuilderPopup,
    FilterPanel, StateStoring, GroupItem, Button as DevExpressButton, Export, Lookup, Search
} from 'devextreme-react/data-grid';
import TextBox from 'devextreme-react/text-box';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { TesPut, TesGet, TesPost, TesDelete } from '../../../../utils/rest';
import { GridType, ModalMode } from '../../../../types/general/enums/generalEnums';
import './codeGroups.scss';
import { useTranslation } from "react-i18next";
import ScrollView from 'devextreme-react/scroll-view';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { useAuth } from '../../../../contexts/auth';
import { Form, SimpleItem } from 'devextreme-react/form';
import { FieldServiceType } from '../../../../types/field/enums/fieldEnums';
import { CodeGroup, CodeValue } from '../../../../types/field/fieldType';
import { NumberBox, SelectBox } from 'devextreme-react';
import { ClientModule } from '../../../../types/moduleOfModules/moduleOfModulesTypes';
import Permission from '../../../../components/permission/permision';
import { ModuleOfModulePermissions } from '../../../../constants/Permissions';
import { FieldApiUrl, ModuleOfModuleApiUrl } from '../../../../environment/routeSettings';
import { useScreenSize } from "../../../../utils/media-query";
import { OnExporting } from '../../../../utils/dataGridTools';
//#endregion imports

const ModuleCodeGroups = () => {
    //#region consts
    const [initData, setInitData] = useState<CodeGroup[]>([]);
    const [initDataCodeValues, setInitDataCodeValues] = useState<CodeValue[]>([new CodeValue()]);
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const dataGridCodeValueRef = useRef<DataGrid<any, any>>(null);
    const [showModal, setShowModal] = useState(false);
    const [showModalCodeValues, setShowModalCodeValues] = useState(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const [modalModeCodeValue, setModalModeCodeValue] = useState<ModalMode>(ModalMode.Add);
    const [showValues, setShowValues] = useState(false);
    const [selectedData, setSelectedData] = useState<CodeGroup>(new CodeGroup());
    const [selectedDataCodeValue, setSelectedDataCodeValue] = useState<CodeValue>(new CodeValue());
    const [lstModule, setLstModule] = useState<ClientModule[]>();
    const [codeGroupId, setCodeGroupId] = useState<string>();
    const { activeLoading } = useAuth();
    const { t } = useTranslation();
    const { isXLarge, is2xLarge } = useScreenSize();
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    //#endregion consts


    //#region functions
    const [gridHeight, setGridHeight] = useState<number>(400)
    const [popupHeight, setPopupHeight] = useState<string>("85%")


    useEffect(() => {
        if (isXLarge) { setPopupHeight("auto"); setGridHeight(530) }
        else if (is2xLarge) { setPopupHeight("auto"); setGridHeight(900) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitData();
                await getInitDataModuleTabs();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    //Get InitData
    async function getInitData() {
        const postObj = {
            customerId: localStorage.getItem('selectedCustomerId'),
            fieldServiceType: FieldServiceType.ModuleOfModule,
        }
        setInitData(await TesPost(FieldApiUrl() + "/api/codeGroups/GetCodeGroups", postObj, true));
    }

    async function getInitDataModuleTabs() {
        setLstModule(await TesGet(ModuleOfModuleApiUrl() + "/api/ClientModule/GetCustomerClientModule/" + localStorage.getItem("selectedCustomerId"), true));
    }

    // Code Group Functions
    function onEdit(d: any) {
        setModalMode(ModalMode.Edit);
        setSelectedData(d.row.data);
        setShowModal(true);
    }
    function onNew() {
        setSelectedData(new CodeGroup());
        setModalMode(ModalMode.Add);
        setShowModal(true);
    }
    const onValueChanged = (key: string, value: any) => {
        setSelectedData({ ...selectedData, [key]: value });
    }
    function onCloseModal() {
        if (modalMode === ModalMode.Add) {
            setShowModal(false);
        } else {
            setSelectedData(new CodeGroup());
            setShowModal(false);
        }
    }
    //add, update and delete
    async function onAdd() {
        const postObj = {
            ...selectedData,
            customerId: localStorage.getItem('selectedCustomerId'),
            fieldServiceType: FieldServiceType.ModuleOfModule
        }
        try {
            if (activeLoading) activeLoading(true);
            await TesPost(FieldApiUrl() + "/api/codeGroups", postObj, true);
            await getInitData();
            onCloseModal();
            if (activeLoading) activeLoading(false);
            notify(t("dataSuccessfullyAdded"), "success", 5000);

        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }
    async function onUpdate() {
        try {
            const postObj = {
                ...selectedData,
                FieldServiceType: FieldServiceType.ModuleOfModule
            }
            if (activeLoading) activeLoading(true);
            await TesPut(FieldApiUrl() + "/api/codeGroups/" + postObj.id, postObj, true);
            await getInitData();
            onCloseModal();
            notify(t("dataSuccessfullyUpdated"), "success", 5000);
            if (activeLoading) activeLoading(false);

        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }
    async function onDelete(d: any) {
        let myDialog = custom({
            title: t("deleteData"),
            messageHtml: t("areYouSureWantToDelete?"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {

                    try {
                        await TesDelete(FieldApiUrl() + "/api/codeGroups/" + d.row.data.id, true);
                        await getInitData();
                        notify(t("dataSuccessfullyDeleted"), "success", 5000);

                    } catch {
                        notify(t("someErrorOccurred"), "error", 5000);
                    }


                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }

    //Code Value functions
    function onEditCodeValues(d: any) {
        let newData = new CodeValue();
        Object.assign(newData, d.row.data);
        setModalModeCodeValue(ModalMode.Edit);
        setSelectedDataCodeValue(newData);
        setShowModalCodeValues(true);
    }
    function onNewCodeValues() {
        setModalModeCodeValue(ModalMode.Add);
        setShowModalCodeValues(true);
        setSelectedDataCodeValue(new CodeValue())
    }
    function onCloseModalCodeValue() {
        if (modalMode === ModalMode.Add) {
            setShowModalCodeValues(false);
        } else {
            setSelectedData(new CodeGroup());
            setShowModalCodeValues(false);
        }
    }
    const onValueChangedCodeValue = (key: string, value: any) => {
        setSelectedDataCodeValue({ ...selectedDataCodeValue, [key]: value });
    }
    async function onAddCodeValue() {
        try {
            const postObj: CodeValue = {
                ...selectedDataCodeValue,
                codeGroupId: codeGroupId!
            }
            if (activeLoading) activeLoading(true);
            await TesPost(FieldApiUrl() + "/api/codeValues", postObj, true);
            setInitDataCodeValues(await TesGet(FieldApiUrl() + "/api/codeValues/" + codeGroupId, true));
            onCloseModalCodeValue();
            if (activeLoading) activeLoading(false);
            notify(t("dataSuccessfullyAdded"), "success", 5000);
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }
    async function onUpdateCodeValue() {
        try {
            if (activeLoading) activeLoading(true);
            await TesPut(FieldApiUrl() + "/api/codeValues/" + selectedDataCodeValue.id, selectedDataCodeValue, true);
            var index = initDataCodeValues.findIndex(x => x?.id === selectedDataCodeValue.id);
            let items = [...initDataCodeValues];
            items[index] = selectedDataCodeValue;
            setInitDataCodeValues(items);
            onCloseModalCodeValue();
            notify(t("dataSuccessfullyUpdated"), "success", 5000);
            if (activeLoading) activeLoading(false);

        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }
    async function onOpenValues(id: string, name: string) {
        setCodeGroupId(id)
        setInitDataCodeValues(await TesGet(FieldApiUrl() + "/api/codeValues/" + id, true));
        setShowValues(true)
    }
    function calculateValuesListCell(d: any) {
        return (
            <Button
                onClick={() => onOpenValues(d.data.id, d.data.name)}
                icon="fa-solid fa-circle-plus"
                text={t('values')}
            />
        );
    }
    async function onDeleteCodeValue(d: any) {
        let myDialog = custom({
            title: t("deleteData"),
            messageHtml: t("areYouSureWantToDelete?"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {

                    try {
                        await TesDelete(FieldApiUrl() + "/api/codeValues/" + d.row.data.id, true);
                        await getInitData();
                        notify(t("dataSuccessfullyDeleted"), "success", 5000);

                    } catch {
                        notify(t("someErrorOccurred"), "error", 5000);
                    }


                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }
    //#endregion functions

    return (
        <Permission
            allowed={[ModuleOfModulePermissions.ModuleOfModule_D_Setups]}
            hasFeedBackElement={true}
        >
            <div className={compactViewModel ? "compactStyle" : ""}>
                <React.Fragment>
                    <h2 className={'content-block'}>{t('codeGroups')}</h2>
                    <div className={'content-block'}>
                        <div className={'dx-card responsive-paddings'}>
                            <div className="row">

                                <div className='rightColumn'>
                                    <Button
                                        onClick={onNew}
                                        icon="fa-solid fa-circle-plus"
                                        text={t('add')}
                                    />
                                </div>
                            </div>
                            <DataGrid id="gridContainer"
                                ref={dataGridRef}
                                dataSource={initData}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                hoverStateEnabled={true}
                                remoteOperations={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                columnAutoWidth={true}
                                style={{ margin: "0 1rem" }}
                                onExporting={OnExporting}
                            >
                                <Export enabled={true}
                                    allowExportSelectedData={true}
                                />
                                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                                <GroupPanel visible={true} /> {/* or "auto" */}
                                <FilterPanel visible={true} />
                                <FilterBuilderPopup position={'top'} />

                                <StateStoring enabled={true} type="localStorage" storageKey={GridType.CollisionCodeGroup.toString()} savingTimeout={500} />

                                <Summary>
                                    <TotalItem
                                        column="GeoId"
                                        summaryType="count"
                                    />
                                    <GroupItem
                                        summaryType="count"
                                        alignByColumn
                                        name="Total Count"
                                    />
                                </Summary>


                                <Paging enabled={true} defaultPageSize={100} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[100, 200, 300, 400, 500]}
                                    showNavigationButtons={true}
                                    showInfo={true}
                                    visible={true}
                                />

                                <FilterRow visible={true}
                                    applyFilter="auto" />

                                <HeaderFilter visible={true} />


                                <SearchPanel visible={true}
                                    width={285}
                                    placeholder={t("search...")} />
                                <ColumnChooser width={350} height={400}
                                    enabled={true}
                                    mode="select"
                                    sortOrder="asc"
                                >
                                    <Search enabled />
                                </ColumnChooser>

                                <Column dataField="name"
                                    caption={t("name")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="codeNum"
                                    caption={t("number")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="ncdbCodeNum"
                                    caption={t("ncdbNumber")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="moduleOfModuleId" caption={t("moduleOfModule")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup dataSource={lstModule} displayExpr={'name'} valueExpr={'id'} />
                                </Column>

                                <Column alignment="left"
                                    cellRender={calculateValuesListCell}
                                    caption={t("valueList")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column type="buttons"
                                    caption={("actions")} fixed={true} fixedPosition="right" width={100}>

                                    <DevExpressButton hint={t('edit')} onClick={onEdit} icon="fa-solid fa-pencil">

                                    </DevExpressButton>
                                    <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                                    </DevExpressButton>
                                </Column>

                            </DataGrid>
                        </div>
                    </div>
                    {/* Modal for adding/editing Code Group */}
                    <Popup
                        width={"50%"}
                        height={"auto"}
                        visible={showModal}
                        resizeEnabled={true}
                        showTitle={true}
                        title={modalMode === ModalMode.Add ? t('add') : t('update')}
                        hideOnOutsideClick={false}
                        showCloseButton={true}
                        onHiding={() => onCloseModal()}
                    >
                        <ScrollView width='100%' height='100%'>
                            <Form colCount={2} className='popupFields'>
                                <SimpleItem colSpan={1} >
                                    <TextBox
                                        label={t('name')}
                                        labelMode='static'
                                        value={selectedData.name}
                                        onValueChange={e => onValueChanged("name", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1} >
                                    <NumberBox
                                        step={0}
                                        label={t('ncdbCodeNum')}
                                        labelMode='static'
                                        value={selectedData.ncdbCodeNum}
                                        onValueChange={e => onValueChanged("ncdbCodeNum", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1} >
                                    <NumberBox
                                        step={0}
                                        label={t('codeNum')}
                                        labelMode='static'
                                        value={selectedData.codeNum}
                                        onValueChange={e => onValueChanged("codeNum", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>

                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                        placeholder=""
                                        label={t("moduleTab")}
                                        labelMode="static"
                                        value={selectedData.moduleOfModuleId}
                                        onValueChange={(e) => onValueChanged("moduleOfModuleId", e)}
                                        className="modalInput"
                                        valueExpr="id"
                                        displayExpr="name"
                                        items={(lstModule)}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    />
                                </SimpleItem>

                            </Form>
                            <div>
                                <div className='rightColumn'>
                                    <Button
                                        className='tes-modal-btn-cancel'
                                        style={{ marginRight: 20 }}
                                        onClick={() => onCloseModal()}
                                        text={t('cancel')}
                                    />
                                    {modalMode === ModalMode.Add ?
                                        <Button
                                            className='tes-modal-btn-add'
                                            onClick={onAdd}
                                            text={t('add')}
                                        />
                                        :
                                        <Button
                                            className='tes-modal-btn-add'
                                            onClick={onUpdate}
                                            text={t('update')}
                                        />
                                    }
                                </div>
                            </div>

                        </ScrollView>
                    </Popup>

                    {/* Modal for showing/choosing Code Values */}
                    <Popup
                        width={"70%"}
                        height={popupHeight}
                        visible={showValues}
                        resizeEnabled={true}
                        showTitle={true}
                        title={t("values")}
                        hideOnOutsideClick={false}
                        showCloseButton={true}
                        onHiding={() => setShowValues(false)}
                    >
                        <div style={{ height: "80%" }}>
                            <div className="row">
                                <div className='rightColumn'>
                                    <Button
                                        onClick={() => onNewCodeValues()}
                                        icon="fa-solid fa-circle-plus"
                                        text={t('add')}
                                    />
                                </div>
                            </div>
                            <DataGrid id="gridContainer"
                                ref={dataGridCodeValueRef}
                                dataSource={initDataCodeValues}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                hoverStateEnabled={true}
                                remoteOperations={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                columnAutoWidth={true}
                                height={gridHeight}
                                onExporting={OnExporting}
                            >
                                <Export enabled={true}
                                    allowExportSelectedData={true}
                                />
                                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                                <GroupPanel visible={true} /> {/* or "auto" */}
                                <FilterPanel visible={true} />
                                <FilterBuilderPopup position={'top'} />

                                <StateStoring enabled={true} type="localStorage" storageKey={GridType.CollisionCodeValues.toString()} savingTimeout={500} />

                                <Summary>
                                    <TotalItem
                                        column="GeoId"
                                        summaryType="count"
                                    />
                                    <GroupItem
                                        summaryType="count"
                                        alignByColumn
                                        name="Total Count"
                                    />
                                </Summary>


                                <Paging enabled={true} defaultPageSize={100} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[100, 200, 300, 400, 500]}
                                    showNavigationButtons={true}
                                    showInfo={true}
                                    visible={true}
                                />

                                <FilterRow visible={true}
                                    applyFilter="auto" />

                                <HeaderFilter visible={true} />


                                <SearchPanel visible={true}
                                    width={285}
                                    placeholder={t("search...")} />
                                <ColumnChooser width={350} height={400}
                                    enabled={true}
                                    mode="select"
                                    sortOrder="asc"
                                >
                                    <Search enabled />
                                </ColumnChooser>

                                <Column dataField="name"
                                    caption={t("name")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="code"
                                    caption={t("code")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="index"
                                    caption={t("index")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="ncdbCodeNum"
                                    caption={t("ncdbNumber")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>


                                <Column dataField="ncdbCodeDescription"
                                    caption={t("ncdbDescription")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="ncdbName"
                                    caption={t("ncdbName")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="value1"
                                    caption={t("value1")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="value2"
                                    caption={t("value2")}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">

                                    <DevExpressButton hint={t('edit')} onClick={onEditCodeValues} icon="fa-solid fa-pencil">

                                    </DevExpressButton>
                                    <DevExpressButton hint={t('delete')} onClick={onDeleteCodeValue} icon="fa-solid fa-trash-can">

                                    </DevExpressButton>
                                </Column>
                            </DataGrid>

                            <div>
                                <div className='rightColumn' style={{ marginTop: 10 }}>
                                    <Button
                                        className='tes-modal-btn-cancel'
                                        onClick={() => setShowValues(false)}
                                        text={t('cancel')}
                                    />
                                </div>
                            </div>

                        </div>
                    </Popup>

                    {/* Modal for adding/editing Code Values */}
                    <Popup
                        width={"50%"}
                        height={"auto"}
                        visible={showModalCodeValues}
                        resizeEnabled={true}
                        showTitle={true}
                        title={modalModeCodeValue === ModalMode.Add ? t('add') : t('update')}
                        hideOnOutsideClick={false}
                        showCloseButton={true}
                        onHiding={() => onCloseModalCodeValue()}
                    >
                        <ScrollView width='100%' height='100%'>
                            <Form colCount={2} className="popupFields">
                                <SimpleItem colSpan={1} >
                                    <TextBox
                                        label={t('name')}
                                        labelMode='static'
                                        value={selectedDataCodeValue.name}
                                        onValueChange={e => onValueChangedCodeValue("name", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>


                                <SimpleItem colSpan={1} >
                                    <TextBox
                                        label={t('code')}
                                        labelMode='static'
                                        value={selectedDataCodeValue.code}
                                        onValueChange={e => onValueChangedCodeValue("code", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>

                                <SimpleItem colSpan={1} >
                                    <NumberBox
                                        step={0}
                                        label={t('index')}
                                        labelMode='static'
                                        value={selectedDataCodeValue.index}
                                        onValueChange={e => onValueChangedCodeValue("index", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>

                                <SimpleItem colSpan={1} >
                                    <NumberBox
                                        step={0}
                                        label={t('ncdbCodeNum')}
                                        labelMode='static'
                                        value={selectedDataCodeValue.ncdbCodeNum}
                                        onValueChange={e => onValueChangedCodeValue("ncdbCodeNum", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>

                                <SimpleItem colSpan={1} >
                                    <TextBox
                                        label={t('ncdbDescription')}
                                        labelMode='static'
                                        value={selectedDataCodeValue.ncdbDescription}
                                        onValueChange={e => onValueChangedCodeValue("ncdbDescription", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>


                                <SimpleItem colSpan={1} >
                                    <TextBox
                                        label={t('ncdbName')}
                                        labelMode='static'
                                        value={selectedDataCodeValue.ncdbName}
                                        onValueChange={e => onValueChangedCodeValue("ncdbName", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>



                                <SimpleItem colSpan={1} >
                                    <TextBox
                                        label={t('value1')}
                                        labelMode='static'
                                        value={selectedDataCodeValue.value1}
                                        onValueChange={e => onValueChangedCodeValue("value1", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>

                                <SimpleItem colSpan={1} >
                                    <TextBox
                                        label={t('value2')}
                                        labelMode='static'
                                        value={selectedDataCodeValue.value2}
                                        onValueChange={e => onValueChangedCodeValue("value2", e)}
                                        className="modalInput"
                                    />
                                </SimpleItem>

                            </Form>
                            <div>
                                <div className='rightColumn'>
                                    <Button
                                        className='tes-modal-btn-cancel'
                                        style={{ marginRight: 20 }}
                                        onClick={() => setShowModalCodeValues(false)}
                                        text={t('cancel')}
                                    />
                                    {modalModeCodeValue === ModalMode.Add ?
                                        <Button
                                            className='tes-modal-btn-add'
                                            onClick={onAddCodeValue}
                                            text={t('add')}
                                        />
                                        :
                                        <Button
                                            className='tes-modal-btn-add'
                                            onClick={onUpdateCodeValue}
                                            text={t('update')}
                                        />
                                    }
                                </div>
                            </div>
                        </ScrollView>
                    </Popup>
                </React.Fragment>
            </div>
        </Permission>
    );
}
export default ModuleCodeGroups;