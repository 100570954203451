import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import tabTitles from "./data/index";
import Details from "./components/details/details";
import BinMaps from "./components/binMaps/binMaps";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { Button, ValidationGroup } from "devextreme-react";
import { custom } from "devextreme/ui/dialog";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import Permission from "../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import "./trafficCountersDetails.scss";
import { ITrafficCounterParams, TrafficCounter } from "../../../../types/trafficStudy/trafficStudyTypes";
import { StudyType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { EnumFlag2Array } from "../../../../utils/enumTools";
import VehicleClassifications from "./components/vehicleClassification/vehicleClassification";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import getTitle from "../../../../components/title/getTitle";
import ShareURL from "../../../../components/shareURL/shareURL";

const TrafficCounterDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [initData, setInitData] = useState<TrafficCounter>(
    new TrafficCounter()
  );

  const params = useParams<ITrafficCounterParams>();
  const [backPermission, setBackPermission] = useState(false);
  const validationRef = useRef<ValidationGroup>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [isLocked, setIsLocked] = useState(true);
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  const title = getTitle('/trafficStudy/setups/trafficCountersDetails/', initData?.name);
  useEffect(() => {
    document.title = title;
  }, [title]);
  
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (params.trafficCounterId !== "AddNew") {
          await getInitData(params.trafficCounterId!);
        } else {
          setIsLocked(false)
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.trafficCounterId]);
  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  //General Functions
  async function getInitData(id: string) {
    try {
      let res = await TesGet(
        TrafficApiUrl() + "/api/Setups/GetTrafficCounterDetails/" + id,
        true
      ) as RequestResponseResult<TrafficCounter>;
      if (res.responseCode === ResponseCode.OK) {
        res.results.selectedStudyTypes = EnumFlag2Array(StudyType, res.results.studyTypes)
        setInitData(res.results)
      }
      else if (res.responseCode === ResponseCode.IdNotExists) {
        notify(t("idNotExists"), "error", 5000)
        history(-1);
      }
      else {
        await RequestErrorHandling(res);;
      };
    } catch (ex) {
      notify(t("errorInFetchData") + ex, "error", 5000);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.trafficCounterId !== "AddNew") {
                  updateTrafficCounter();
                  setDataChanged(false)
                } else {
                  addTrafficCounter();
                  setDataChanged(false)
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function onLockHandler() {
    setIsLocked(!isLocked);
  }

  async function addTrafficCounter() {
    try {
      const validationRes = validationRef.current?.instance.validate();
      let studyTypesFlag = 0
      initData.selectedStudyTypes.forEach(x => studyTypesFlag += x)
      if (validationRes?.isValid) {
        const postObj = {
          ...initData,
          customerId: localStorage.getItem("selectedCustomerId"),
          studyTypes: studyTypesFlag
        };
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          TrafficApiUrl() + "/api/Setups/AddTrafficCounter",
          postObj,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          history(-1);
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyAdded"), "success", 5000);
        } else {
          await RequestErrorHandling(res);;
        }
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function updateTrafficCounter() {
    try {
      const validationRes = validationRef.current?.instance.validate();
      let studyTypesFlag = 0
      initData.selectedStudyTypes.forEach(x => studyTypesFlag += x)
      if (validationRes?.isValid) {
        const postObj = {
          ...initData,
          customerId: localStorage.getItem("selectedCustomerId"),
          studyTypes: studyTypesFlag
        };
        if (activeLoading) activeLoading(true);
        let res = (await TesPost(
          TrafficApiUrl() + "/api/Setups/UpdateTrafficCounter",
          postObj,
          true
        )) as RequestResponseResult<null>;
        if (res.responseCode === ResponseCode.OK) {
          if (backPermission === true) {
            history(-1);
          }
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyUpdated"), "success", 5000);
        } else {
          await RequestErrorHandling(res);;
        }
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false)
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[TrafficStudyPermissions.TrafficStudy_D_Setups]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
          {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem"}}>
                  <p ref={headingParagraphRef} className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t('name')}: {initData?.name}</span>
                  </p>
                  <Button
                    onClick={() => copyTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                  <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
                </div>
              )}
            <div className="row">
              <div className="leftColumn">
                <Button
                  onClick={() => goBackPermission()}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">
                {params.trafficCounterId !== "AddNew" && (
                  <ShareURL
                    url={url}
                  />
                )}
                <Button
                  onClick={onLockHandler}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                />
              </div>
              <div className="rightColumn">
                {params.trafficCounterId === "AddNew" && (
                  <Button
                    onClick={addTrafficCounter}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("save")}
                  />
                )}
                {params.trafficCounterId !== "AddNew" && isLocked === false && (
                  <Button
                    onClick={updateTrafficCounter}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("update")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={"dx-card"}>
            <Tabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onOptionChanged={onTabsSelectionChanged}
            />
            {selectedIndex === 0 && (
              <Details
                initData={initData}
                setDataChanged={setDataChanged}
                setInitData={setInitData}
                isLocked={isLocked}
                validationRef={validationRef}
              />
            )}
            {selectedIndex === 1 && (
              <VehicleClassifications
                initData={initData}
                setDataChanged={setDataChanged}
                setInitData={setInitData}
                isLocked={isLocked}
                validationRef={validationRef}
                />
                )}
            {selectedIndex === 2 && (
              <BinMaps
                initData={initData}
                setDataChanged={setDataChanged}
                setInitData={setInitData}
                isLocked={isLocked}
                validationRef={validationRef}
              />
            )}
          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default TrafficCounterDetails;
