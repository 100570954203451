import React from "react";
import { useTranslation } from "react-i18next";
import { SimpleItem } from 'devextreme-react/form';
import { Form, NumberBox, SelectBox, Switch, TextArea ,TextBox} from 'devextreme-react';
import { DataDetailsDTO } from "../../../../../types/moduleOfModules/dtos/moduleOfModuleDto";
import { VMField } from "../../../../../types/field/dto/fieldDTO";
import { Validator, RequiredRule } from 'devextreme-react/validator';
import DateBox from 'devextreme-react/date-box';
import { GeneralSetting } from "../../../../../contexts/clientSetting";
import { LowerCaseFirstLetter } from "../../../../../utils/stringTools";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";

// props
interface IPros {
  order: number,
  generalSettings: GeneralSetting | null;
  initData: DataDetailsDTO,
  setInitData: React.Dispatch<React.SetStateAction<any>>;
}

const Details = (props: IPros) => {
  const { t } = useTranslation();


  function onChangeField(name: string, value: any, isUpperCase: boolean) {
    props.setInitData({ ...props.initData, detailsData: { ...props.initData.detailsData, [name]: isUpperCase ? value.toString().toUpperCase() : value } })
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2}>
          {/* eslint-disable-next-line array-callback-return */}
          {props.initData?.tabs?.find(x => x.order === props.order)?.fields?.map((f: VMField, i: number) => {
            if (f.fieldType === 1) {
              return (
                <SimpleItem colSpan={1} >
                  <TextBox
                    label={f.labelText}
                    labelMode='static'
                    key={i}
                    value={props.initData.detailsData && props.initData.detailsData[LowerCaseFirstLetter(f.name)]}
                    onValueChange={e => onChangeField(LowerCaseFirstLetter(f.name), e, f.isUpperCase)}
                  >
                    {f.isRequired &&
                      <Validator>
                        <RequiredRule message={t('fieldIsRequired')} />
                      </Validator>
                    }
                  </TextBox>
                </SimpleItem>
              );
            }
            if (f.fieldType === 2) {
              return (
                <SimpleItem colSpan={1} >
                  <NumberBox
                    step={0}
                    label={f.labelText}
                    labelMode='static'
                    key={i}
                    value={props.initData.detailsData && props.initData.detailsData[LowerCaseFirstLetter(f.name)]}
                    onValueChange={e => onChangeField(f.name, e, f.isUpperCase)}
                  >
                    {f.isRequired &&
                      <Validator>
                        <RequiredRule message={t('fieldIsRequired')} />
                      </Validator>
                    }
                  </NumberBox>
                </SimpleItem>
              );
            }
            if (f.fieldType === 3) {
              return (
                <SimpleItem colSpan={1} >
                  <div >{f.labelText}</div>
                  <Switch
                    style={{ marginTop: 5, fontWeight: 'bold' }}
                    value={props.initData.detailsData && props.initData.detailsData[LowerCaseFirstLetter(f.name)]}
                    onValueChange={e => onChangeField(LowerCaseFirstLetter(f.name), e, false)}
                    hint={f.labelText}
                  />
                </SimpleItem>
              );
            }
            if (f.fieldType === 4) {
              return (
                <SimpleItem colSpan={1}>
                  <DateBox
                    type="date"
                    pickerType="calendar"
                    label={f.labelText}
                    displayFormat={props.generalSettings?.dateFormat}
                    defaultValue={props.initData.detailsData && props.initData.detailsData[LowerCaseFirstLetter(f.name)]}
                    onValueChange={e => onChangeField(LowerCaseFirstLetter(f.name), e, false)}
                    showClearButton={true}
                  >
                    {f.isRequired &&

                      <Validator>
                        <RequiredRule message={t('fieldIsRequired')} />
                      </Validator>
                    }
                  </DateBox>
                </SimpleItem>
              );
            }
            if (f.fieldType === 5) {
              return (
                <SimpleItem colSpan={1}>
                  <DateBox
                    type="time"
                    label={f.labelText}
                    pickerType='rollers'
                    useMaskBehavior={true}
                    displayFormat={props.generalSettings?.isAmPm ? 'shortTime' : 'HH:mm'}
                    defaultValue={props.initData.detailsData && props.initData.detailsData[LowerCaseFirstLetter(f.name)]}
                    onValueChange={e => onChangeField(LowerCaseFirstLetter(f.name), e, false)}
                    showClearButton={true}
                  >
                    {f.isRequired &&
                      <Validator>
                        <RequiredRule message={t('fieldIsRequired')} />
                      </Validator>
                    }
                  </DateBox>
                </SimpleItem>
              );
            }
            if (f.fieldType === 6) {
              return (
                <SimpleItem colSpan={1}>
                  <DateBox
                    type="datetime"
                    label={f.labelText}
                    pickerType='calendar'
                    useMaskBehavior={true}
                    displayFormat={props.generalSettings?.dateTimeFormat}
                    defaultValue={props.initData.detailsData && props.initData.detailsData[LowerCaseFirstLetter(f.name)]}
                    onValueChange={e => onChangeField(LowerCaseFirstLetter(f.name), e, false)}
                    showClearButton={true}
                  >
                    {f.isRequired &&
                      <Validator>
                        <RequiredRule message={t('fieldIsRequired')} />
                      </Validator>
                    }
                  </DateBox>
                </SimpleItem>
              );
            }
            if (f.fieldType === 7) {
              return (
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={f.labelText}
                    valueExpr='id'
                    displayExpr='name'
                    value={props.initData.detailsData && props.initData.detailsData[LowerCaseFirstLetter(f.name)]}
                    onValueChange={e => onChangeField(LowerCaseFirstLetter(f.name), e, f.isUpperCase)}
                    items={SortObjectByPropName(f.fieldValues, "name")}
                    showClearButton={true}
                    searchEnabled={true}
                  >
                    {f.isRequired &&
                      <Validator>
                        <RequiredRule message={t('fieldIsRequired')} />
                      </Validator>
                    }
                  </SelectBox>
                </SimpleItem>
              );
            }
            if (f.fieldType === 8) {
              return (
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={f.labelText}
                    disabled={true}
                    defaultValue={props.initData.detailsData && props.initData.detailsData[LowerCaseFirstLetter(f.name)]}
                  />
                </SimpleItem>
              );
            }
            if (f.fieldType === 9) {
              return (
                <SimpleItem colSpan={1}>
                  <TextArea
                    label={f.labelText}
                    value={props.initData.detailsData && props.initData.detailsData[LowerCaseFirstLetter(f.name)]}
                    onValueChange={e => onChangeField(LowerCaseFirstLetter(f.name), e, f.isUpperCase)}
                  >
                    {f.isRequired &&
                      <Validator>
                        <RequiredRule message={t('fieldIsRequired')} />
                      </Validator>
                    }
                  </TextArea>
                </SimpleItem>
              );
            }
          })
          }
        </Form>
      </div>
    </React.Fragment>
  );
};
export default Details;
