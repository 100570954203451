import { Button, DataGrid, Form, NumberBox, Popup, ScrollView, ValidationGroup } from "devextreme-react";
import { Column, ColumnChooser, Button as DevExpressButton, Export, FilterBuilderPopup, FilterPanel, FilterRow, Grouping, GroupItem, GroupPanel, HeaderFilter, Pager, Paging, Search, SearchPanel, SortByGroupSummaryInfo, StateStoring, Summary } from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { PdfRedactionSetting, XMLRedaction } from "../../../../../types/collision/collisionTypes";
import { GridType, ModalMode } from "../../../../../types/general/enums/generalEnums";
import { v4 as uuidv4 } from 'uuid';

interface IPros {
  isLocked: boolean;
  initData: XMLRedaction;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroup>;
}

const PDFRedaction = (props: IPros) => {
    const [dataGridData, setDataGridData] = useState<PdfRedactionSetting[]>([]);
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const [selectedData, setSelectedData] = useState<PdfRedactionSetting>(new PdfRedactionSetting)
    const dataGridRef = useRef<DataGrid<any, any>>(null);
  
    useEffect(() => {
      setDataGridData(props.initData.pdfRedactionSettings || []);
    }, [props.initData]);
  
    function onNew() {
      setModalMode(ModalMode.Add);
      setShowModal(true);
    }
  
    function onCloseModal() {
      setSelectedData(new PdfRedactionSetting)
      setShowModal(false);
    }
  
    async function onAdd() {
        try {
          props.setInitData({
            ...props.initData,
            pdfRedactionSettings: [...props.initData.pdfRedactionSettings || [], {...selectedData, id: uuidv4()}]
          });
      
          dataGridRef.current?.instance.refresh();
          setShowModal(false);
          setSelectedData(new PdfRedactionSetting());
      
        } catch (er) {
          notify(t("someErrorOccurred"), "error", 5000);
        }
      }
  
      async function onUpdate() {
        try {
          const updatedSettings = props.initData.pdfRedactionSettings.map(x =>
            x.id === selectedData.id ? { ...selectedData } : x
          );

          props.setInitData({
            ...props.initData,
            pdfRedactionSettings: updatedSettings,
          });
      
          dataGridRef.current?.instance.refresh();
      
          setShowModal(false);
        } catch (error) {
          notify(t("someErrorOccurred"), "error", 5000);
        }
      }
      
      
      
    function onDelete(e: any): void {
        const newSettings = props.initData.pdfRedactionSettings.filter(x => x.id !== e.row.data.id);
        props.setInitData({ 
          ...props.initData, 
          pdfRedactionSettings: newSettings 
        });
        dataGridRef.current?.instance.refresh();
      }

      console.log(props.initData)
      
  
    return (
      <React.Fragment>
        <ValidationGroup ref={props.validationRef}>
          <div className='row' style={{ marginTop: 20, padding: 10 }}>
            <div className='rightColumn'>
              <Button
                onClick={onNew}
                icon="fa-solid fa-circle-plus"
                text={t('add')}
                disabled={props.isLocked}
                style={{ marginBottom: "0.5rem" }}
              />
            </div>
            <ScrollView width="100%" height="100%">
              <Form colCount={3}>
                <SimpleItem colSpan={3}>
                  <DataGrid
                    id="gridContainer"
                    ref={dataGridRef}
                    dataSource={dataGridData}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    remoteOperations={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    disabled={props.isLocked}
                    onRowClick={e => {
                      setSelectedData(e.data)
                      setModalMode(ModalMode.Edit)
                      setShowModal(true)
                    }
                    }
                  >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                    <GroupPanel visible={true} />
                    <FilterPanel visible={true} />
                    <FilterBuilderPopup position={"top"} />
                    <StateStoring
                      enabled={true}
                      type="localStorage"
                      storageKey={GridType.CollisionXMLRedactionDetails.toString()}
                      savingTimeout={500}
                    />
                    <SortByGroupSummaryInfo
                      summaryItem="Total Count"
                      sortOrder="desc"
                    />
                    <Summary>
                      <GroupItem
                        summaryType="count"
                        alignByColumn
                        name="Total Count"
                      />
                    </Summary>
                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                      showPageSizeSelector={true}
                      allowedPageSizes={[100, 200, 300, 400, 500]}
                      showNavigationButtons={true}
                      showInfo={true}
                      visible={true}
                    />
                    <FilterRow visible={true} applyFilter="auto" />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                    <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                      <Search enabled />
                    </ColumnChooser>
                    <Column dataField="x" caption={t("X")}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="y" caption={t("Y")}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="width" caption={t("width")}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="height" caption={t("height")}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="pageNumberFromFirst" caption={t("pageNumberFromFirst")}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="pageNumberFromLast" caption={t("pageNumberFromLast")}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column dataField="priority" caption={t("priority")}>
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column
                      dataField="id"
                      allowSorting={true}
                      visible={false}
                      caption={t("id")}
                    >
                      <HeaderFilter>
                        <Search enabled />
                      </HeaderFilter>
                    </Column>
                    <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                      <DevExpressButton
                        hint={t("delete")}
                        onClick={e => onDelete(e)}
                        icon="fa-solid fa-trash-can"
                      />
                    </Column>
                  </DataGrid>
                </SimpleItem>
              </Form>
            </ScrollView>
          </div>
  
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={12}>
                <SimpleItem colSpan={4}>
                    <NumberBox
                        label={t('X')}
                        labelMode='static'
                        value={selectedData.x}
                        onValueChange={e => setSelectedData({...selectedData, x: e})}
                        className="modalInput"
                        style={{margin:" -0.25rem 0"}}
                    />
                </SimpleItem>
                <SimpleItem colSpan={4}>
                    <NumberBox
                        label={t('Y')}
                        labelMode='static'
                        value={selectedData.y}
                        onValueChange={e => setSelectedData({...selectedData, y: e})}
                        className="modalInput"
                        style={{margin:" -0.25rem 0"}}
                    />
                </SimpleItem>
                <SimpleItem colSpan={4}>
                    <NumberBox
                        label={t('width')}
                        labelMode='static'
                        value={selectedData.width}
                        onValueChange={e => setSelectedData({...selectedData, width: e})}
                        className="modalInput"
                        style={{margin:" -0.25rem 0"}}
                    />
                </SimpleItem>
                <SimpleItem colSpan={4}>
                    <NumberBox
                        label={t('height')}
                        labelMode='static'
                        value={selectedData.height}
                        onValueChange={e => setSelectedData({...selectedData, height: e})}
                        className="modalInput"
                        style={{margin:" -0.25rem 0"}}
                    />
                </SimpleItem>
                <SimpleItem colSpan={4}>
                    <NumberBox
                        label={t('pageNumberFromFirst')}
                        labelMode='static'
                        value={selectedData.pageNumberFromFirst}
                        onValueChange={e => setSelectedData({...selectedData, pageNumberFromFirst: e})}
                        className="modalInput"
                        style={{margin:" -0.25rem 0"}}
                    />
                </SimpleItem>
                <SimpleItem colSpan={4}>
                    <NumberBox
                        label={t('pageNumberFromLast')}
                        labelMode='static'
                        value={selectedData.pageNumberFromLast}
                        onValueChange={e => setSelectedData({...selectedData, pageNumberFromLast: e})}
                        className="modalInput"
                        style={{margin:" -0.25rem 0"}}
                    />
                </SimpleItem>
                <SimpleItem colSpan={4}>
                    <NumberBox
                        label={t('priority')}
                        labelMode='static'
                        value={selectedData.priority}
                        onValueChange={e => setSelectedData({...selectedData, priority: e})}
                        className="modalInput"
                        style={{margin:" -0.25rem 0"}}
                    />
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
  
        </ValidationGroup>
      </React.Fragment>
    );
}

export default PDFRedaction;

