import { useEffect, useRef, useState } from 'react';
import { useAuth } from '../../contexts/auth';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { TesPost } from '../../utils/rest';
import { CollisionApiUrl } from '../../environment/routeSettings';
import DataGrid, {
   Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser,
   Pager, Paging, Grouping, GroupPanel, FilterBuilderPopup, FilterPanel, Export, Search,
} from "devextreme-react/data-grid";
import { CollisionValidationReport } from '../../types/report/reportTypes';
import { Popup } from "devextreme-react/popup";
import { useScreenSize } from "../../utils/media-query";
import { OnExporting } from '../../utils/dataGridTools';



interface IProps {
   collisionIds: string[],
   showValidationReport: boolean,
   setShowValidationReport: React.Dispatch<React.SetStateAction<boolean>>
}

const ValidationReport = (props: IProps) => {
   const [initData, setInitData] = useState<CollisionValidationReport[]>([]);
   const { t } = useTranslation();
   const { activeLoading } = useAuth();
   const dataGridRef = useRef<DataGrid<any, any>>(null);
   const { isXLarge, is2xLarge } = useScreenSize();
   const [gridHeight, setGridHeight] = useState<number | string>(630)

   useEffect(() => {
      if (isXLarge || is2xLarge) { setGridHeight("auto") }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      async function fetchMyAPI() {
         try {
            if (activeLoading) activeLoading(true);
            await getInitialData();
            if (activeLoading) activeLoading(false);
         } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred") + ex, "error", 5000);
         }
      }
      if (props.showValidationReport) {
         fetchMyAPI();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [props.showValidationReport, props.collisionIds]);


   async function getInitialData() {
      if (props.collisionIds.length > 0) {
         try {
            const res = await TesPost(CollisionApiUrl() + "/api/reports/validationReport", props.collisionIds, true)
            setInitData(res);
         } catch (ex) {
            notify(t("someErrorOccurred") + ex, "error", 5000);
         }
      }
   }

   return (
      <div>
         <Popup
            visible={props.showValidationReport}
            width={'80%'}
            height={gridHeight}
            resizeEnabled={true}
            showTitle={true}
            title={t("validationReport")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => props.setShowValidationReport(false)}
         >
            <DataGrid
               id="gridContainer"
               ref={dataGridRef}
               dataSource={initData}
               rowAlternationEnabled={true}
               showBorders={true}
               hoverStateEnabled={true}
               allowColumnReordering={true}
               allowColumnResizing={true}
               columnAutoWidth={true}
               onExporting={OnExporting}
               height={"100%"}
            >
               <Export enabled={true} allowExportSelectedData={true} />
               <Grouping contextMenuEnabled={true} autoExpandAll={false} />
               <GroupPanel visible={true} /> {/* or "auto" */}
               <FilterPanel visible={true} />
               <FilterBuilderPopup position={"top"} />
               <Paging enabled={true} defaultPageSize={100} />
               <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
               />

               <FilterRow visible={true} applyFilter="auto" />
               <HeaderFilter visible={true} />
               <SearchPanel visible={true} width={285} placeholder={t("search...")} />
               <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
               </ColumnChooser>
               <Column dataField="collisionIdentifier"
                  caption={t('collision')}
               //  groupIndex={0}
               >
                  <HeaderFilter>
                     <Search enabled />
                  </HeaderFilter>
               </Column>
               <Column dataField="level"
                  caption={t('validationStatus')}
               // groupIndex={1}
               >
                  <HeaderFilter>
                     <Search enabled />
                  </HeaderFilter>
               </Column>
               <Column dataField="message"
                  caption={t("message")} >
                  <HeaderFilter>
                     <Search enabled />
                  </HeaderFilter>
               </Column>
            </DataGrid>
         </Popup>
      </div>
   )
}

export default ValidationReport;