//#region imports
import React, { useEffect, useRef, useState, useCallback } from 'react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Selection,
    Pager, Paging, Grouping, GroupPanel, FilterBuilderPopup,
    FilterPanel, StateStoring, Export, Lookup, SortByGroupSummaryInfo, Summary, GroupItem, Search
} from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import { Popup } from 'devextreme-react/popup';
import { RequestErrorHandling, TesGet, TesPost, TesPut } from '../../../../utils/rest';
import { GridType, ResponseCode, TesMapModule } from '../../../../types/general/enums/generalEnums';
import { GridSetting, LazyLoadingRequest, LazyLoadingRequestBase, NameValue, RequestResponseResult } from '../../../../types/general/generalTypes';
import { useClientSetting } from '../../../../contexts/clientSetting';
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import DropDownButton from 'devextreme-react/drop-down-button';
import { ClientReport, UiReportRequest } from '../../../../types/report/reportTypes';
import './allStudies.scss';
import notify from 'devextreme/ui/notify';
import ReportViewer from '../../../../components/reportViewer/ReportViewer';
import { ReportLocationType } from '../../../../types/report/enums/reportEnums';
import GeneralGisMapManager from '../../../../components/gisMap/generalGisMapManager';
import GridProfilePicker from '../../../../components/gridProfilePicker/gridProfilePicker';
import { Enum2Array, EnumFlag2Array } from '../../../../utils/enumTools';
import { ApproachType, StudyCategory, StudyStatus, StudyType } from '../../../../types/trafficStudy/enums/trafficStudyEnums';
import Permission from '../../../../components/permission/permision';
import { TrafficStudyPermissions } from '../../../../constants/Permissions';
import { useAuth } from '../../../../contexts/auth';
import { FieldApiUrl, ReportApiUrl, TrafficApiUrl } from '../../../../environment/routeSettings';
import { Button } from "devextreme-react/button";
import StatusChanger from '../../../../components/statusChanger/statusChanger';
import RecalculateStudy from '../../../../components/recalculateStudy/recalculateStudy';
import GridSettings from '../../../../components/gridSettings/gridSettings';
import { ExtraPercentile } from '../../../../types/trafficStudy/dtos/studyDto';
import { TesCodeValue } from '../../../../types/infrastructure/infrastructureTypes';
import { OnExporting } from '../../../../utils/dataGridTools';
import { SortObjectByPropName } from '../../../../utils/arrayTools';
import { SimpleTrafficCounterDTO } from '../../../../types/trafficStudy/dtos/TrafficCounterDTO';
import reportLocations from '../../../../constants/reportLocations';
import GridFilter from '../../../../components/gridFilter/gridFilter';
import { MapUpdateStatus, SectionDetailType } from '../../../../types/infrastructure/enums/infrastructureEnums';
import { ContextMenu } from 'devextreme-react';
import { RightClickMenu } from '../../../../utils/rightClickMenu';
import getTitle from '../../../../components/title/getTitle';
import MaxDropdownItemWidthCalculator from '../../../../utils/dropDownWidthCalculator';
//#endregion imports

const dataSource = new CustomStore({
    load: async (loadOption) => {
        return await TesPost(
            `${TrafficApiUrl()
            }/api/Study/GetCustomerStudyByTypeLazyLoading`,
            {
                customerId: localStorage.getItem("selectedCustomerId"),
                divisionId: localStorage.getItem("selectedDivisionId"),
                loadOptions: loadOption,
                filter: { type: StudyType.Speed }
            } as LazyLoadingRequest,
            true
        );
    },
});

const Speed = () => {
    //#region consts 
    const [showMap, setShowMap] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [initDataReports, setInitDataReports] = useState<ClientReport[]>([]);
    const [selectedReport, setSelectedReport] = useState<ClientReport>(new ClientReport());
    const [showReportModal, setShowReportModal] = useState<boolean>(false);
    const [lstSelectedIds, setLstSelectedIds] = useState<string[]>([]);
    const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
    const [lstStudyStatus, setLstStudyStatus] = useState<NameValue[]>([]);
    const [lstApproachType, setLstApproachType] = useState<NameValue[]>();
    const [initDataTesCodeValues, setInitDataTesCodeValues] = useState<TesCodeValue>(new TesCodeValue());
    const [lstTrafficCounter, setLstTrafficCounter] = useState<SimpleTrafficCounterDTO[]>();
    const [initDataReportsForIndex, setInitDataReportsForIndex] = useState<ClientReport[]>();
    const [lstMapUpdateStatus, setLstMapUpdateStatus] = useState<NameValue[]>([]);

    const [lstSectionType, setLstSectionType] = useState<NameValue[]>([]);
    const history = useNavigate();
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const { t } = useTranslation();
    const dropDownOptions = { width: 215 };
    const { activeLoading } = useAuth();
    const [selectedRowData, setSelectedRowData] = useState<any>(null);
    const items = [
        { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
        { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
    ];
    const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const { generalSetting, customerDivisions } = useClientSetting();
    //#endregion consts

    //#region functions

    const title = getTitle('/trafficStudy/studies/speed', '');
    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInfrastructureTesCodeValues();
                await getInitialDataReports();
                await getLstTrafficCounter();
                setLstStudyType(Enum2Array(StudyType));
                setLstMapUpdateStatus(Enum2Array(MapUpdateStatus));

                setLstStudyStatus(Enum2Array(StudyStatus));
                setLstApproachType(Enum2Array(ApproachType));
                setLstSectionType(Enum2Array(SectionDetailType));
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const navigateToStudyDetails = useCallback((d: any) => {
        history(`/trafficStudy/studies/studyDetails/4/AddNew`);
    }, [history])

    async function getInitialDataReports() {
        try {
            var postObj: UiReportRequest = {
                customerId: localStorage.getItem("selectedCustomerId")!,
                lstLocations: [
                    reportLocations.TrafficStudy_StudyDetails_Speed,
                ]
            }
            var resultReportData = await TesPost(
                ReportApiUrl() +
                "/api/ClientReports/GetPageReports",
                postObj,
                true
            );
            setInitDataReports(resultReportData);
            setInitDataReportsForIndex(resultReportData);



            console.log(resultReportData);

        } catch {

        }
    }

    async function getInfrastructureTesCodeValues() {
        setInitDataTesCodeValues(await TesGet(FieldApiUrl() + "/api/codeValues/infrastructureTesCodeValues/" + localStorage.getItem('selectedCustomerId'), true));
    }

    async function getLstTrafficCounter() {
        const postObj: LazyLoadingRequestBase = {
            customerId: localStorage.getItem("selectedCustomerId") as string,
            divisionId: null,
            filter: { returnAll: true }
        }
        const res = await TesPost(
            TrafficApiUrl() +
            "/api/Import/GetCustomerSimplifiedTrafficCounter",
            postObj,
            true
        ) as RequestResponseResult<SimpleTrafficCounterDTO[]>
        if (res.responseCode === ResponseCode.OK) {
            setLstTrafficCounter(res.results);
        } else {
            await RequestErrorHandling(res);
        }
    }


    function onRowClicked(e: any) {
        const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
        if (e.data.id !== undefined) {

            if (isCtrlKeyPressed) {
                window.open(`/trafficStudy/studies/studyDetails/${e.data.studyType}/${e.data.id}`, "_blank");
            } else {
                history(`/trafficStudy/studies/studyDetails/${e.data.studyType}/${e.data.id}`);
            }
        }
    }

    function onOpenReport(d: any) {
        if (dataGridRef.current?.instance.getSelectedRowKeys().length === 0) {
            notify(t('signReportDropDownMenuWarning'), 'warning', 5000);
            return;
        }
        if (dataGridRef.current?.instance.getSelectedRowKeys() !== undefined && dataGridRef.current?.instance.getSelectedRowKeys().length > 10000) {
            notify(t('signReportDropDownMenuWarning'), 'warning', 5000);
            return;
        }
        setSelectedReport(d.itemData);
        setLstSelectedIds(dataGridRef.current?.instance.getSelectedRowKeys().map(x => x.id) as string[]);
        setShowReportModal(true);
    }

    function onCloseReportModal() {
        setShowReportModal(false);
        dataGridRef.current?.instance.deselectAll();
    }

    const headerFilterData = [
        {
            text: t("speed"),
            value: ['StudyCategory', '=', 2]
        },
        {
            text: t("speed") + "," + t("classification"),
            value: ['StudyCategory', '=', 6]
        }
        , {
            text: t("speed") + "," + t("continuousCount"),
            value: ['StudyCategory', '=', 10]
        }
        , {
            text: t("speed") + "," + t("classification") + "," + t("continuousCount"),
            value: ['StudyCategory', '=', 14]
        }
    ];


    const headerLookup = [
        {
            name: t("speed"),
            value: 2
        },
        {
            name: t("speed") + "," + t("classification"),
            value: 6
        }
        , {
            name: t("speed") + "," + t("continuousCount"),
            value: 10
        }
        , {
            name: t("speed") + "," + t("classification") + "," + t("continuousCount"),
            value: 14
        }
    ];

    //#endregion functions
    return (
        <Permission
            allowed={[TrafficStudyPermissions.TrafficStudy_D_Study, TrafficStudyPermissions.TrafficStudy_E_Study, TrafficStudyPermissions.TrafficStudy_V_Study]}
            hasFeedBackElement={true}
        >
            <div className={compactViewModel ? "compactStyle" : ""}>
                <React.Fragment>
                    <ContextMenu
                        dataSource={items}
                        width={100}
                        target=".dx-data-row"
                        onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, `/trafficStudy/studies/studyDetails/${selectedRowData.row.data.studyType}/`, selectedRowData.row.data.aadt.locationType, selectedRowData.row.data.locationId)}
                    />
                    <h2 className={'content-block'}>{t('speed')}</h2>
                    <div className={'content-block'}>
                        <div className={'dx-card responsive-paddings'}>
                            <div className="row">
                                <div className='leftColumn' style={{ display: "flex" }}>
                                    <Button onClick={() => setShowMap(true)}
                                        icon="fa-solid fa-map-location-dot"
                                    />
                                    <GridProfilePicker
                                        customerId={localStorage.getItem("selectedCustomerId")!}
                                        gridType={GridType.SpeedStudies}
                                        dataGridRef={dataGridRef}
                                        setGridSetting={setGridSetting}
                                    />
                                    <Button
                                        style={{ marginLeft: 10 }}
                                        onClick={() => setShowFilter(true)}
                                        icon="fa-solid fa-filter"
                                        hint={t("filter")}
                                    />
                                    <DropDownButton
                                        style={{ marginLeft: 10 }}
                                        icon="fa-solid fa-chart-line"
                                        hint={t('report')}
                                        items={SortObjectByPropName(initDataReports?.filter((x: ClientReport) => x.reportLocation === ReportLocationType.TrafficStudy && x.isShownOnMulti === true), "name")}
                                        dropDownOptions={{ width: MaxDropdownItemWidthCalculator(SortObjectByPropName(initDataReports?.filter((x: ClientReport) => x.reportLocation === ReportLocationType.TrafficStudy && x.isShownOnMulti === true), "name")) }}
                                        displayExpr="name"
                                        onItemClick={(d) => onOpenReport(d)}
                                    />

                                    <StatusChanger
                                        datagridRef={dataGridRef}
                                    />
                                    <RecalculateStudy
                                        datagridRef={dataGridRef}
                                    />
                                </div>
                                <Permission
                                    allowed={[TrafficStudyPermissions.TrafficStudy_D_Study, TrafficStudyPermissions.TrafficStudy_E_Study]}
                                    hasFeedBackElement={false}
                                >
                                    <div className='rightColumn'>
                                        <Button
                                            style={{ marginLeft: 10 }}
                                            onClick={navigateToStudyDetails}
                                            icon="fa-solid fa-circle-plus"
                                            text={t("speed")}
                                        />
                                    </div>
                                </Permission>
                            </div>
                            <DataGrid id="gridContainer"
                                ref={dataGridRef}
                                dataSource={dataSource}
                                rowAlternationEnabled={true}
                                showBorders={true}
                                onRowClick={onRowClicked}
                                hoverStateEnabled={true}
                                remoteOperations={true}
                                allowColumnReordering={true}
                                allowColumnResizing={true}
                                style={{ margin: "0 1rem" }}
                                onExporting={OnExporting}
                                onContextMenuPreparing={e => { setSelectedRowData(e) }}
                                columnAutoWidth={gridSetting.horizontalScrolling}
                            //onContentReady={e => {e.component.deselectAll()}}
                            //onFilterValueChange={e => {dataGridRef?.current?.instance.deselectAll()}}
                            >
                                <Export enabled={true}
                                    allowExportSelectedData={true}
                                />
                                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                                <GroupPanel visible={true} /> {/* or "auto" */}
                                <FilterPanel visible={true} />
                                <FilterBuilderPopup position={'top'} />

                                <StateStoring enabled={true} type="localStorage" storageKey={GridType.SpeedStudies.toString()} savingTimeout={500} />

                                <SortByGroupSummaryInfo
                                    summaryItem="Total Count"
                                    sortOrder="desc"
                                />
                                <Summary>
                                    <GroupItem
                                        summaryType="count"
                                        alignByColumn
                                        name="Total Count"
                                    />
                                </Summary>
                                <Paging enabled={true} defaultPageSize={100} />
                                <Pager
                                    showPageSizeSelector={true}
                                    allowedPageSizes={[100, 200, 300, 400, 500]}
                                    showNavigationButtons={true}
                                    showInfo={true}
                                    visible={true}
                                />
                                <Selection
                                    mode="multiple"
                                    selectAllMode={'allPages'}
                                    showCheckBoxesMode={'always'}
                                />
                                <FilterRow visible={true}
                                    applyFilter="auto" />

                                <HeaderFilter visible={true} />


                                <SearchPanel visible={true}
                                    width={285}
                                    placeholder={t("search...")} />
                                <ColumnChooser width={350} height={400}
                                    enabled={true}
                                    mode="select"
                                    sortOrder="asc"
                                >
                                    <Search enabled />
                                </ColumnChooser>

                                <Column alignment="left" dataField="id" visible={false}
                                    caption={t('id')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="parentId" visible={false}
                                    caption={t('parentId')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="customerId" visible={false}
                                    caption={t('customerId')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="studyNo"
                                    caption={t('studyNo')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="workOrder.no"
                                    caption={t('workOrderNo')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="geoId"
                                    caption={t('geoId')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="locationDescription"
                                    caption={t('locationDescription')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="sectionType"
                                    caption={t('sectionType')} visible={false}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup dataSource={SortObjectByPropName(lstSectionType, "name")} valueExpr="value" displayExpr="name" />
                                </Column>
                                <Column
                                    dataField="municipalityId"
                                    visible={initDataTesCodeValues.municipalities?.length !== 0 && initDataTesCodeValues.municipalities !== undefined}
                                    allowHeaderFiltering={true}
                                    caption={t("municipality")}
                                >
                                    <Search enabled />
                                    <Lookup
                                        dataSource={SortObjectByPropName(initDataTesCodeValues?.municipalities, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                    />
                                    <HeaderFilter dataSource={initDataTesCodeValues?.municipalities?.map(x => ({ text: x.name, value: x.id }))}>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="MapUpdateStatus" caption={t("mapUpdateStatus")} visible={false}>
                                    <Lookup
                                        dataSource={SortObjectByPropName(lstMapUpdateStatus, "name")}
                                        valueExpr="value"
                                        displayExpr="name"
                                    />
                                </Column>
                                <Column
                                    dataField="jurisdictionId"
                                    visible={initDataTesCodeValues.jurisdictions?.length !== 0 && initDataTesCodeValues.jurisdictions !== undefined}
                                    allowHeaderFiltering={true}
                                    caption={t("jurisdiction")}

                                >
                                    <Search enabled />
                                    <Lookup
                                        dataSource={SortObjectByPropName(initDataTesCodeValues?.jurisdictions, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                    />
                                    <HeaderFilter dataSource={initDataTesCodeValues?.jurisdictions?.map(x => ({ text: x.name, value: x.id }))}>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="studyType"
                                    caption={t('studyType')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup dataSource={SortObjectByPropName(lstStudyType, "name")} valueExpr="value" displayExpr="name" />
                                </Column>

                                <Column dataField="studyCategory"
                                    caption={t('studyCategory')}

                                >
                                    <HeaderFilter dataSource={headerFilterData} width={350} >
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup dataSource={headerLookup} valueExpr="value" displayExpr="name" />
                                </Column>

                                <Column dataField="status"
                                    caption={t('status')}>
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup dataSource={SortObjectByPropName(lstStudyStatus, "name")} valueExpr="value" displayExpr="name" />
                                </Column>

                                <Column alignment="left" dataField="duration"
                                    caption={t('duration')}
                                    visible={false}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="rawDataStartDT"
                                    caption={t('startDT')}
                                    dataType="datetime"
                                    format={generalSetting?.dateTimeFormat}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column alignment="left" dataField="rawDataEndDT"
                                    caption={t('endDT')}
                                    dataType="datetime"
                                    format={generalSetting?.dateTimeFormat}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column caption={t('speedStatisticsSummary')} visible={true}>
                                    <Column alignment="left" dataField="speedStatisticsSummary.approachType" caption={t("speedStatisticsSummary.approachType")} visible={false}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                        {lstApproachType !== undefined &&
                                            < Lookup
                                                dataSource={lstApproachType}
                                                valueExpr="value"
                                                displayExpr="name"
                                            />
                                        }
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.average" visible={true}
                                        format="#.00"
                                        caption={t('speedStatisticsSummary.average')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column alignment="left" dataField="speedStatisticsSummary.median" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummary.median')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.speed85thPercentile" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummary.speed85thPercentile')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.speed95thPercentile" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummary.speed95thPercentile')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.compliance" visible={false}
                                        format="percent"
                                        caption={t('speedStatisticsSummary.compliance')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.standardDeviation" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummary.standardDeviation')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.postedSpeed" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummary.postedSpeed')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.maximumSpeed" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummary.maximumSpeed')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.minimumSpeed" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummary.minimumSpeed')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.minPace" visible={false}
                                        caption={t('speedStatisticsSummary.minPace')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>


                                    <Column alignment="left" dataField="speedStatisticsSummary.maxPace" visible={false}
                                        caption={t('speedStatisticsSummary.maxPace')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.pace" visible={false}
                                        caption={t('speedStatisticsSummary.pace')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.pacePercent" visible={false}
                                        format="percent"
                                        caption={t('speedStatisticsSummary.pacePercent')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummary.total" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummary.total')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                </Column>

                                <Column caption={t('speedStatisticsSummary1')} visible={false}>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.approachType" caption={t("speedStatisticsSummaryDirection1.approachType")} visible={false}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                        {lstApproachType !== undefined &&
                                            < Lookup
                                                dataSource={lstApproachType}
                                                valueExpr="value"
                                                displayExpr="name"
                                            />
                                        }
                                    </Column>
                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.average" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection1.average')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.median" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection1.median')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.speed85thPercentile" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection1.speed85thPercentile')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.speed95thPercentile" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection1.speed95thPercentile')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.compliance" visible={false}
                                        format="percent"
                                        caption={t('speedStatisticsSummaryDirection1.compliance')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.standardDeviation" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection1.standardDeviation')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.postedSpeed" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection1.postedSpeed')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.maximumSpeed" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection1.maximumSpeed')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.minimumSpeed" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection1.minimumSpeed')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>


                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.minPace" visible={false}
                                        caption={t('speedStatisticsSummary.minPace')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>


                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.maxPace" visible={false}
                                        caption={t('speedStatisticsSummary.maxPace')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.pace" visible={false}
                                        caption={t('speedStatisticsSummary.pace')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.pacePercent" visible={false}
                                        format="percent"
                                        caption={t('speedStatisticsSummary.pacePercent')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>



                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection1.total" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection1.total')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                </Column>

                                <Column caption={t('speedStatisticsSummary2')} visible={false}>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.approachType" caption={t("speedStatisticsSummaryDirection2.approachType")} visible={false}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                        {lstApproachType !== undefined &&
                                            < Lookup
                                                dataSource={lstApproachType}
                                                valueExpr="value"
                                                displayExpr="name"
                                            />
                                        }
                                    </Column>
                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.average" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection2.average')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.median" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection2.median')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.speed85thPercentile" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection2.speed85thPercentile')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.speed95thPercentile" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection2.speed95thPercentile')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.compliance" visible={false}
                                        format="percent"
                                        caption={t('speedStatisticsSummaryDirection2.compliance')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.standardDeviation" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection2.standardDeviation')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.postedSpeed" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection2.postedSpeed')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>


                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.maximumSpeed" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection2.maximumSpeed')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>


                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.minimumSpeed" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection2.minimumSpeed')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>



                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.minPace" visible={false}
                                        caption={t('speedStatisticsSummary.minPace')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>


                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.maxPace" visible={false}
                                        caption={t('speedStatisticsSummary.maxPace')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.pace" visible={false}
                                        caption={t('speedStatisticsSummary.pace')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.pacePercent" visible={false}
                                        format="percent"
                                        caption={t('speedStatisticsSummary.pacePercent')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>


                                    <Column alignment="left" dataField="speedStatisticsSummaryDirection2.total" visible={false}
                                        format="#.00"
                                        caption={t('speedStatisticsSummaryDirection2.total')}>
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                </Column>

                                <Column
                                    visible={false}
                                    caption={t("trafficCounter")}
                                >

                                    <Column
                                        dataField="trafficCounterId"
                                        visible={false}
                                        allowHeaderFiltering={true}
                                        caption={t("trafficCounter")}
                                    >
                                        <Search enabled />
                                        <Lookup
                                            dataSource={SortObjectByPropName(lstTrafficCounter, "name")}
                                            valueExpr="id"
                                            displayExpr="name"
                                        />
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column
                                        dataField="binMapId"
                                        visible={false}
                                        allowHeaderFiltering={true}
                                        caption={t("binMaps")}
                                    >
                                        <Search enabled />
                                        <Lookup
                                            dataSource={SortObjectByPropName(lstTrafficCounter?.flatMap(x => x.binMaps), "name")}
                                            valueExpr="id"
                                            displayExpr="name"
                                        />
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                </Column>


                                <Column caption={t("aadt")} visible={true}>

                                    <Column dataField="aadt.totalVolume" caption={t("totalVolumeAadt")} visible={true} allowHeaderFiltering={false}>
                                    </Column>
                                    <Column dataField="aadt.percentSummary" caption={t("directionSplit")} visible={false} format="#.00">
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="aadt.northPercent" caption={t("northPercent")} visible={false} format="#.00">
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="aadt.eastPercent" caption={t("eastPercent")} visible={false} format="#.00">
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="aadt.westPercent" caption={t("westPercent")} visible={false} format="#.00">
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="aadt.southPercent" caption={t("southPercent")} visible={false} format="#.00">
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                </Column>

                                <Column caption={t("volumeSummary")} visible={false}>
                                    <Column dataField="volumeSummary.northApproachVolume" caption={t("northApproachVolume")} visible={false} >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="volumeSummary.eastApproachVolume" caption={t("eastApproachVolume")} visible={false} >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="volumeSummary.westApproachVolume" caption={t("westApproachVolume")} visible={false} >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="volumeSummary.southApproachVolume" caption={t("southApproachVolume")} visible={false} >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="volumeSummary.totalVolume" caption={t("totalVolume")} visible={false} >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="volumeSummary.pedestrianVolume" caption={t("pedestrianVolume")} visible={false} >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="volumeSummary.carVolume" caption={t("carVolume")} visible={false} >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                    <Column dataField="volumeSummary.truckVolume" caption={t("truckVolume")} visible={false} >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="volumeSummary.heavyTruckVolume" caption={t("heavyTruckVolume")} visible={false} >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                    <Column dataField="volumeSummary.cyclistVolume" caption={t("cyclistVolume")} visible={false} >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                </Column>
                                <Column dataField="processDT"
                                    visible={false}
                                    caption={t('submissionDT')}
                                    dataType="datetime"
                                    format={generalSetting?.dateTimeFormat}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>

                                <Column dataField="createdDT"
                                    visible={false}
                                    caption={t('createdDT')}
                                    dataType="datetime"
                                    format={generalSetting?.dateTimeFormat}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column dataField="lastModifiedDT"
                                    visible={false}
                                    caption={t('lastModifiedDT')}
                                    dataType="datetime"
                                    format={generalSetting?.dateTimeFormat}

                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                </Column>
                                <Column
                                    dataField="divisionId"
                                    allowSorting={true}
                                    visible={false}
                                    caption={t("division")}
                                >
                                    <HeaderFilter>
                                        <Search enabled />
                                    </HeaderFilter>
                                    <Lookup
                                        dataSource={SortObjectByPropName(customerDivisions!, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                    />
                                </Column>
                            </DataGrid>
                        </div>
                    </div>

                    {/* Map Popup */}
                    <Popup
                        width={"50%"}
                        height={"auto"}
                        visible={showMap}
                        resizeEnabled={true}
                        showTitle={true}
                        title={t("map")}
                        hideOnOutsideClick={false}
                        showCloseButton={true}
                        onHiding={() => setShowMap(false)}
                    >
                        <GeneralGisMapManager
                            tesModule={TesMapModule.Study}
                            dataGridRef={dataGridRef}
                            showGis={showMap}
                            setShowMap={setShowMap}
                            locId="locationId"
                        />

                    </Popup>

                    {/* Report Popup */}
                    <Popup
                        visible={showReportModal}
                        width={"60%"}
                        height={"75%"}
                        resizeEnabled={true}
                        showTitle={true}
                        title={t("reportViewer")}
                        hideOnOutsideClick={false}
                        showCloseButton={true}
                        onHiding={() => onCloseReportModal()}
                    >
                        <ReportViewer
                            selectedReport={selectedReport}
                            lstSelectedIds={lstSelectedIds}
                            isOpen={showReportModal}
                        />
                    </Popup>

                    {/*Grid Filter*/}
                    <GridFilter
                        dataGridRef={dataGridRef}
                        setShowModal={setShowFilter}
                        showModal={showFilter}
                        tesModule={TesMapModule.Study}
                    />
                </React.Fragment>
            </div>
        </Permission>
    );
}
export default Speed;

function setInitData(results: SimpleTrafficCounterDTO[]) {
    throw new Error('Function not implemented.');
}
