//imports
import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  Export,
  Button as DevExpressButton,
  Search,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import {
  ModalMode, ResponseCode,
} from "../../../../types/general/enums/generalEnums";
import "./siteTypes.scss";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import Permission from "../../../components/permission/permision";
import { useAuth } from "../../../../contexts/auth";
import notify from "devextreme/ui/notify";
import Form, { SimpleItem } from "devextreme-react/form";
import { ContextMenu, ScrollView, TextBox } from "devextreme-react";
import { SiteType } from "../../../../types/safety/safetyTypes";
import { InfrastructureApiUrl } from "../../../../environment/routeSettings";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import { LazyLoadingRequestBase, RequestResponseResult } from "../../../../types/general/generalTypes";
import Permission from "../../../../components/permission/permision";
import SafetyPermissions from "../../../../constants/Permissions/SafetyPermissions";
import { OnExporting } from "../../../../utils/dataGridTools";
import { RightClickMenu } from "../../../../utils/rightClickMenu";

const SiteTypes = () => {
  const [selectedData, setSelectedData] = useState<SiteType>(new SiteType());
  const [initData, setInitData] = useState<SiteType[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const history = useNavigate();
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  async function getInitialData() {
    try {
      var res = await TesPost(
        InfrastructureApiUrl() +
        "/api/siteTypes/getCustomerSiteTypes",
        {
          customerId: localStorage.getItem("selectedCustomerId")
        } as LazyLoadingRequestBase,
        true
      ) as RequestResponseResult<SiteType[]>
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }



  // async function getInitialData() {
  //   const res = await TesGet(InfrastructureApiUrl() + "/api/siteTypes/getCustomerSiteTypes/" + localStorage.getItem('selectedCustomerId'), true) as RequestResponseResult<SiteType[]>
  //   if (res.responseCode === ResponseCode.OK) {
  //     setInitData(res.results)
  //    } else {
  //       await RequestErrorHandling(res);
  //   }
  // }

  function onEdit(d: any) {
    let newData = new SiteType();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }

  async function onDelete(d: any) {
    //setInitData(initData.filter(x => x.name !== d.row.data.name))
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
    } else {
      setSelectedData(new SiteType());
      setShowModal(false);
    }
  }

  async function onAdd() {
    try {
      const postObj = {
        ...selectedData,
        customerId: localStorage.getItem("selectedCustomerId"),
      };
      if (activeLoading) activeLoading(true);
      let res = await TesPost(InfrastructureApiUrl() + "/api/siteTypes/addSiteType", postObj, true) as RequestResponseResult<SiteType>;
      if (res.responseCode === ResponseCode.OK) {
        await getInitialData()
      }

      else {
        await RequestErrorHandling(res);;
      };
      onCloseModal();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function onValueChange(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value })
  }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/safety/setups/siteTypeDetails/${e.data.id}`, "_blank");
      } else {
        history(`/safety/setups/siteTypeDetails/${e.data.id}`);
      }
    }
  }

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.data.id, "/safety/setups/siteTypeDetails/")}
          />
          <h2 className={"content-block"}>{t("siteTypes")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  {/* <Permission
                  allowed={[
                    InfrastructurePermissions.Infrastructure_D_Site,
                    InfrastructurePermissions.Infrastructure_R_Site,
                  ]}
                  hasFeedBackElement={false}
                > */}
                  <Button
                    onClick={onNew}
                    icon="fa-solid fa-circle-plus"
                    text={t("siteType")}
                  />
                  {/* </Permission> */}
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                onRowClick={onRowClicked}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                {/* <StateStoring enabled={true} type="localStorage" storageKey={GridType.Intersection.toString()} savingTimeout={500} /> */}
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>

                <Column
                  dataField="name"
                  allowSorting={false}
                  caption={t("name")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>

          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2}>
                <SimpleItem colSpan={2}>
                  <TextBox
                    label={t("name")}
                    labelMode="static"
                    value={selectedData.name}
                    onValueChange={(e) => onValueChange("name", e)}
                    className="modalInput"
                  />
                </SimpleItem>

              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onAdd}
                    text={t("add")}
                  />


                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default SiteTypes;
