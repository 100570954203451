import { Button, DateBox, Form, SelectBox, TagBox, TextBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import CollapsibleCard from "../../../../components/collapsibleCard/collapsibleCard";
import { NameValue, RequestResponseResult } from "../../../../types/general/generalTypes";
import { LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { Enum2Array } from "../../../../utils/enumTools";
import { useClientSetting } from "../../../../contexts/clientSetting";
import { AggregationInterval, StudyCategory, StudyStatus, StudyType } from "../../../../types/trafficStudy/enums/trafficStudyEnums";
import { TesCodeValue } from "../../../../types/infrastructure/infrastructureTypes";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import { FieldApiUrl, TrafficApiUrl } from "../../../../environment/routeSettings";
import { TrafficIntegrationRequestExport } from "../../../../types/trafficStudy/trafficStudyTypes";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";

const Export = () => {
    
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    const [exportMovementData, setExportMovementData] = useState<TrafficIntegrationRequestExport>({...new TrafficIntegrationRequestExport, fromDate: new Date, toDate: new Date})
    const [exportApproachData, setExportApproachData] = useState<TrafficIntegrationRequestExport>({...new TrafficIntegrationRequestExport, fromDate: new Date, toDate: new Date})
    const { generalSetting } = useClientSetting();
    const [lstLocationType, setLstLocationType] = useState<NameValue[]>([])
    const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
    const [lstStudyCategory, setLstStudyCategory] = useState<NameValue[]>([]);
    const [lstStudyStatus, setLstStudyStatus] = useState<NameValue[]>([]);
    const [lstIntervals, setLstIntervals] = useState<NameValue[]>([]);
    const { customerDivisions } = useClientSetting();
    const [initDataTesCodeValues, setInitDataTesCodeValues] = useState<TesCodeValue>(new TesCodeValue());

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                getInfrastructureTesCodeValues()
                setLstLocationType(Enum2Array(LocationType));
                setLstStudyType(Enum2Array(StudyType));
                setLstStudyCategory(Enum2Array(StudyCategory));
                setLstStudyStatus(Enum2Array(StudyStatus));
                setLstIntervals(Enum2Array(AggregationInterval))
            } catch (ex) {
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getInfrastructureTesCodeValues() {
        setInitDataTesCodeValues(await TesGet(FieldApiUrl() + "/api/codeValues/infrastructureTesCodeValues/" + localStorage.getItem('selectedCustomerId'), true));
    }
    
    async function onExport(type: string) {
        const exportData = type === "VolumeDataByMovement" ? exportMovementData : exportApproachData;
    
        if (!exportData.fromDate || !exportData.toDate) {
            notify(t("dateFieldsCantBeEmpty"), "error", 5000);
            return;
        }
        if (exportData.fromDate >= exportData.toDate) {
            notify(t("fromDateMustBeLessThanToDate"), "error", 5000);
            return;
        }
    
        const res = await TesPost(TrafficApiUrl() + "/api/ExportIntegratedDatas/" + type, exportData, true);
        if (res.responseCode === ResponseCode.OK) {
            notify(t("exportResMessage"), "success", 5000);
            if (type === "VolumeDataByMovement") {
                setExportMovementData({...new TrafficIntegrationRequestExport, fromDate: new Date(), toDate: new Date()});
            } else {
                setExportApproachData({...new TrafficIntegrationRequestExport, fromDate: new Date(), toDate: new Date()});
            }
        } else {
            await RequestErrorHandling(res);
        }
    }    

    return (
        <div className={`copySetting ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <h2 className={'content-block'}>{t('exportTrafficIntegration')}</h2>
                <div className={'content-block'}>
                    <div className={'dx-card'} style={{ padding: "0.5rem 1rem"}}>
                        <CollapsibleCard title={t('volumeDataByMovement')} isOpen={true}>
                            <Form colCount={3} className='popupFields'>
                                {/* <SimpleItem colSpan={1}>
                                    StudyIdsPicker
                                </SimpleItem> */}
                                {/* <SimpleItem colSpan={1}>
                                    GeoIdsPicker
                                </SimpleItem> */}
                                <SimpleItem colSpan={1}>
                                    <TagBox
                                        label={t("divisions")}
                                        items={SortObjectByPropName(customerDivisions!, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                        value={exportMovementData.divisionIds}
                                        onValueChange={e => {setExportMovementData({...exportMovementData, divisionIds: e})}}
                                        searchEnabled={true}
                                        style={{ marginBottom: '5px' }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <TagBox
                                        label={t("municipalities")}
                                        dataSource={SortObjectByPropName(initDataTesCodeValues?.municipalities, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                        value={exportMovementData.municipalityIds}
                                        onValueChange={e => {setExportMovementData({...exportMovementData, municipalityIds: e})}}
                                        searchEnabled={true}
                                        style={{ marginBottom: '5px' }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <TagBox
                                        label={t("jurisdictions")}
                                        dataSource={SortObjectByPropName(initDataTesCodeValues?.jurisdictions, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                        value={exportMovementData.jurisdictionIds}
                                        onValueChange={e => {setExportMovementData({...exportMovementData, jurisdictionIds: e})}}
                                        searchEnabled={true}
                                        style={{ marginBottom: '5px' }}
                                    />
                                </SimpleItem>
                                {/* <SimpleItem colSpan={1}>
                                    LocationIdsPicker
                                </SimpleItem> */}
                                <SimpleItem colSpan={1}>
                                    <DateBox
                                       label={t("fromDate")}
                                       type="date"
                                       value={exportMovementData.fromDate}
                                       onValueChange={e => {setExportMovementData({...exportMovementData, fromDate: e})}}
                                       pickerType="calendar"
                                       placeholder={generalSetting?.dateFormat}
                                       displayFormat={generalSetting?.dateFormat}
                                       useMaskBehavior={true}
                                       openOnFieldClick={true}
                                       showClearButton={true}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <DateBox
                                       label={t("toDate")}
                                       type="date"
                                       value={exportMovementData.toDate}
                                       onValueChange={e => {setExportMovementData({...exportMovementData, toDate: e})}}
                                       pickerType="calendar"
                                       placeholder={generalSetting?.dateFormat}
                                       displayFormat={generalSetting?.dateFormat}
                                       useMaskBehavior={true}
                                       openOnFieldClick={true}
                                       showClearButton={true}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                        placeholder=""
                                        label={t('locationType')}
                                        valueExpr="value"
                                        displayExpr="name"
                                        labelMode='floating'
                                        value={exportMovementData.locationType}
                                        onValueChange={e => {setExportMovementData({...exportMovementData, locationType: e})}}
                                        items={SortObjectByPropName(lstLocationType, "name")}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                      placeholder=""
                                      label={t("studyType")}
                                      labelMode="floating"
                                      value={exportMovementData.studyType}
                                      onValueChange={e => {setExportMovementData({...exportMovementData, studyType: e})}}
                                      items={SortObjectByPropName(lstStudyType, "name")}
                                      valueExpr="value"
                                      displayExpr="name"
                                      showClearButton={true}
                                      searchEnabled={true}
                                    >
                                    </SelectBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                      placeholder=""
                                      label={t("category")}
                                      labelMode="floating"
                                      value={exportMovementData.category}
                                      onValueChange={e => {setExportMovementData({...exportMovementData, category: e})}}
                                      items={SortObjectByPropName(lstStudyCategory, "name")}
                                      valueExpr="value"
                                      displayExpr="name"
                                      showClearButton={true}
                                      searchEnabled={true}
                                    >
                                    </SelectBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                      placeholder=""
                                      label={t("status")}
                                      labelMode="floating"
                                      value={exportMovementData.status}
                                      onValueChange={e => {setExportMovementData({...exportMovementData, status: e})}}
                                      items={SortObjectByPropName(lstStudyStatus, "name")}
                                      valueExpr="value"
                                      displayExpr="name"
                                      showClearButton={true}
                                      searchEnabled={true}
                                    >
                                    </SelectBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                        placeholder=""
                                        label={t('interval')}
                                        valueExpr="value"
                                        displayExpr="name"
                                        labelMode='floating'
                                        value={exportMovementData.interval}
                                        onValueChange={e => {setExportMovementData({...exportMovementData, interval: e})}}
                                        items={SortObjectByPropName(lstIntervals, "name")}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    />
                                </SimpleItem>
                            </Form>
                            <Button
                            style={{marginTop:"0.5rem"}}
                              className='tes-modal-btn-add rightColumn'
                              onClick={e => onExport("VolumeDataByApproach")}
                              text={t('export')}
                            />
                        </CollapsibleCard>
                        <CollapsibleCard title={t('volumeDataByApproach')} isOpen={true}>
                            <Form colCount={3} className='popupFields'>
                                {/* <SimpleItem colSpan={1}>
                                    StudyIdsPicker
                                </SimpleItem> */}
                                {/* <SimpleItem colSpan={1}>
                                    GeoIdsPicker
                                </SimpleItem> */}
                                <SimpleItem colSpan={1}>
                                    <TagBox
                                        label={t("divisions")}
                                        items={SortObjectByPropName(customerDivisions!, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                        value={exportApproachData.divisionIds}
                                        onValueChange={e => {setExportApproachData({...exportApproachData, divisionIds: e})}}
                                        searchEnabled={true}
                                        style={{ marginBottom: '5px' }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <TagBox
                                        label={t("municipalities")}
                                        dataSource={SortObjectByPropName(initDataTesCodeValues?.municipalities, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                        value={exportApproachData.municipalityIds}
                                        onValueChange={e => {setExportApproachData({...exportApproachData, municipalityIds: e})}}
                                        searchEnabled={true}
                                        style={{ marginBottom: '5px' }}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <TagBox
                                        label={t("jurisdictions")}
                                        dataSource={SortObjectByPropName(initDataTesCodeValues?.jurisdictions, "name")}
                                        valueExpr="id"
                                        displayExpr="name"
                                        value={exportApproachData.jurisdictionIds}
                                        onValueChange={e => {setExportApproachData({...exportApproachData, jurisdictionIds: e})}}
                                        searchEnabled={true}
                                        style={{ marginBottom: '5px' }}
                                    />
                                </SimpleItem>
                                {/* <SimpleItem colSpan={1}>
                                    LocationIdsPicker
                                </SimpleItem> */}
                                <SimpleItem colSpan={1}>
                                    <DateBox
                                       label={t("fromDate")}
                                       type="date"
                                       value={exportApproachData.fromDate}
                                       onValueChange={e => {setExportApproachData({...exportApproachData, fromDate: e})}}
                                       pickerType="calendar"
                                       placeholder={generalSetting?.dateFormat}
                                       displayFormat={generalSetting?.dateFormat}
                                       useMaskBehavior={true}
                                       openOnFieldClick={true}
                                       showClearButton={true}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <DateBox
                                       label={t("toDate")}
                                       type="date"
                                       value={exportApproachData.toDate}
                                       onValueChange={e => {setExportApproachData({...exportApproachData, toDate: e})}}
                                       pickerType="calendar"
                                       placeholder={generalSetting?.dateFormat}
                                       displayFormat={generalSetting?.dateFormat}
                                       useMaskBehavior={true}
                                       openOnFieldClick={true}
                                       showClearButton={true}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                        placeholder=""
                                        label={t('locationType')}
                                        valueExpr="value"
                                        displayExpr="name"
                                        labelMode='floating'
                                        value={exportApproachData.locationType}
                                        onValueChange={e => {setExportApproachData({...exportApproachData, locationType: e})}}
                                        items={SortObjectByPropName(lstLocationType, "name")}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                      placeholder=""
                                      label={t("studyType")}
                                      labelMode="floating"
                                      value={exportApproachData.studyType}
                                      onValueChange={e => {setExportApproachData({...exportApproachData, studyType: e})}}
                                      items={SortObjectByPropName(lstStudyType, "name")}
                                      valueExpr="value"
                                      displayExpr="name"
                                      showClearButton={true}
                                      searchEnabled={true}
                                    >
                                    </SelectBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                      placeholder=""
                                      label={t("category")}
                                      labelMode="floating"
                                      value={exportApproachData.category}
                                      onValueChange={e => {setExportApproachData({...exportApproachData, category: e})}}
                                      items={SortObjectByPropName(lstStudyCategory, "name")}
                                      valueExpr="value"
                                      displayExpr="name"
                                      showClearButton={true}
                                      searchEnabled={true}
                                    >
                                    </SelectBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                      placeholder=""
                                      label={t("status")}
                                      labelMode="floating"
                                      value={exportApproachData.status}
                                      onValueChange={e => {setExportApproachData({...exportApproachData, status: e})}}
                                      items={SortObjectByPropName(lstStudyStatus, "name")}
                                      valueExpr="value"
                                      displayExpr="name"
                                      showClearButton={true}
                                      searchEnabled={true}
                                    >
                                    </SelectBox>
                                </SimpleItem>
                                <SimpleItem colSpan={1}>
                                    <SelectBox
                                        placeholder=""
                                        label={t('interval')}
                                        valueExpr="value"
                                        displayExpr="name"
                                        labelMode='floating'
                                        value={exportApproachData.interval}
                                        onValueChange={e => {setExportApproachData({...exportApproachData, interval: e})}}
                                        items={SortObjectByPropName(lstIntervals, "name")}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    />
                                </SimpleItem>
                            </Form>
                            <Button
                            style={{marginTop:"0.5rem"}}
                              className='tes-modal-btn-add rightColumn'
                              onClick={e => onExport("VolumeDataByApproach")}
                              text={t('export')}
                            />
                        </CollapsibleCard>
                    </div>
                </div>
            </React.Fragment>
        </div>
    );
};

export default Export;
