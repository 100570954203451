//#region imports
import React, { useState, useRef } from "react";
import { Button } from 'devextreme-react/button';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Pager, Paging, Button as DevExpressButton, Search
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { Form, ScrollView, TextBox } from 'devextreme-react';
import { ModalMode } from '../../../../../types/general/enums/generalEnums';
import { useTranslation } from "react-i18next";
import { CustomerCollision, NetworkDriveFiles } from "../../../../../types/collision/collisionTypes";
import { GeneralSetting, useClientSetting } from "../../../../../contexts/clientSetting";
import { SimpleItem } from "devextreme-react/form";
import { v4 as uuid } from 'uuid';
import { custom } from "devextreme/ui/dialog";

//#endregion imports

//#region props
interface IPros {
    initDataCollision: CustomerCollision;
    generalSettings: GeneralSetting | null;
    setInitDataCollision: React.Dispatch<React.SetStateAction<any>>;
    title: string;
}

//#endregion props
const GridsObject = (props: IPros) => {
    //#region consts
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const [selectedData, setSelectedData] = useState<NetworkDriveFiles>(new NetworkDriveFiles());
    const { t } = useTranslation();
    const { generalSetting } = useClientSetting();

    //#endregion consts
    //#region functions
    function onChangeField(name: string, value: any) {
            const updatedValue =   value !== null ? "file:///" + value : "";
            const fileName = value ? value.split('/').pop() : "";
            setSelectedData({
                ...selectedData,
                "fileAddress": updatedValue,
                "fileName": fileName
            })
    }


    function onCloseModal() {
        setSelectedData(new NetworkDriveFiles());
        setShowModal(false);
    }
    function onNew() {
        setModalMode(ModalMode.Add);
        setShowModal(true);
    }

    function onEdit(d: any) {
        let newData = new NetworkDriveFiles();
        Object.assign(newData, d.row.data);
        setModalMode(ModalMode.Edit);
        setSelectedData(newData);
        setShowModal(true);
    }

    function onAdd() {
        props.setInitDataCollision({
            ...props.initDataCollision,
            networkDriveFiles:
                [
                    ...props.initDataCollision?.networkDriveFiles,
                    { ...selectedData, id: uuid() }
                ]
        });
        dataGridRef.current?.instance.refresh();
        onCloseModal();
    }


    function onUpdate() {
        const data = props.initDataCollision.networkDriveFiles;
        const selectedDataIndex: number = data.findIndex((x: NetworkDriveFiles) => x.id === selectedData.id);
        data[selectedDataIndex] = selectedData;
        props.setInitDataCollision({
            ...props.initDataCollision,
            networkDriveFiles: data
        });
        dataGridRef.current?.instance.refresh();
        onCloseModal();
    }

    async function onDelete(d: any) {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: t("deleteWarningMessage"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {
                    props.setInitDataCollision({
                        ...props.initDataCollision,
                        NetworkDriveFiles: [
                            ...props.initDataCollision.networkDriveFiles.filter((x: any) => x.Id !== d.row.data.Id)
                        ]
                    });
                    dataGridRef.current?.instance.refresh();
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }


    //#endregion functions
    return (
        <React.Fragment>
            <div className="row" style={{ marginTop: 20, padding: 10 }}>
                <div className="row ">
                    <Button
                        className="tes-modal-btn-add"
                        onClick={onNew}
                        icon="fa-solid fa-circle-plus"
                        text={props.title}
                        style={{ marginBottom: "-7rem", zIndex: "1000" }}
                    />
                </div>

                <DataGrid id="gridContainer"
                    ref={dataGridRef}
                    dataSource={props.initDataCollision?.networkDriveFiles}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    remoteOperations={false}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                >
                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                    />

                    <FilterRow visible={true}
                        applyFilter="auto" />

                    <HeaderFilter visible={true} />


                    <SearchPanel visible={true}
                        width={285}
                        placeholder={t("search...")} />
                    <ColumnChooser width={350} height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                    >
                        <Search enabled />
                    </ColumnChooser>



                    <Column dataField="fileName"
                        caption={t('fileName')}
                        alignment='left'
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="fileAddress"
                        caption={t('fileAddress')}
                        alignment='left'
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>

                    <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                        <DevExpressButton hint={t('edit')} onClick={onEdit} icon="fa-solid fa-pencil">

                        </DevExpressButton>
                        <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                        </DevExpressButton>
                    </Column>
                </DataGrid>


                <Popup
                    width={"50%"}
                    height={"auto"}
                    visible={showModal}
                    resizeEnabled={true}
                    showTitle={true}
                    title={modalMode === ModalMode.Add ? t('add') : t('update')}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    onHiding={() => onCloseModal()}
                >
                    <ScrollView width='100%' height='100%'>
                        <Form
                            colCount={2}>
                            <SimpleItem colSpan={1} >
                                <TextBox
                                    placeholder=""
                                    label={t('fileAddress')}
                                    value={selectedData?.fileAddress}
                                    onValueChange={e => onChangeField("fileAddress", e)}
                                />
                            </SimpleItem>
                            {/* <SimpleItem colSpan={1} >
                                <TextBox
                                    placeholder=""
                                    label={t('fileName')}
                                    value={selectedData?.fileName}
                                    onValueChange={e => onChangeField("fileName", e)}
                                />
                            </SimpleItem> */}

                        </Form>
                        {/* </ValidationGroup> */}
                        <div>
                            <div className='rightColumn'>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20, marginTop: '10px' }}
                                    onClick={() => onCloseModal()}
                                    text={t('cancel')}
                                />
                                {modalMode === ModalMode.Add ?
                                    <Button
                                        style={{ marginTop: '10px' }}
                                        className='tes-modal-btn-add'
                                        onClick={onAdd}
                                        text={t('add')}
                                    />
                                    :
                                    <Button
                                        style={{ marginTop: '10px' }}

                                        className='tes-modal-btn-add'
                                        onClick={onUpdate}
                                        text={t('update')}
                                    />
                                }
                            </div>
                        </div>

                    </ScrollView>
                </Popup>
            </div>

        </React.Fragment>
    );
}
export default GridsObject;