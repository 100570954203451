//#region imports
import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  Button as DevExpressButton,
  Export,
  Search,
} from "devextreme-react/data-grid";
import TextBox from "devextreme-react/text-box";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import { TesPut, TesGet, TesPost, TesDelete, RequestErrorHandling } from "../../../utils/rest";
import { GridType, ModalMode, ResponseCode } from "../../../types/general/enums/generalEnums";
import "./personnels.scss";
import { useTranslation } from "react-i18next";
import ScrollView from "devextreme-react/scroll-view";
import notify from "devextreme/ui/notify";
import { custom } from "devextreme/ui/dialog";
import { useAuth } from "../../../contexts/auth";
import { Form, SimpleItem } from "devextreme-react/form";
import { Lookup, SelectBox } from "devextreme-react";
import { DateBox } from "devextreme-react/date-box";
import {
  NumberBox,
  Button as NumberBoxButton,
} from "devextreme-react/number-box";
import { VMFindUser } from "../../../types/identity/dto/identityDTO";
import {
  Cost,
  Personnel as VMPersonnel,
} from "../../../types/maintenance/maintenanceTypes";
import { useClientSetting } from "../../../contexts/clientSetting";
import Permission from "../../../components/permission/permision";
import { MaintenancePermissions } from "../../../constants/Permissions";
import { AuthApiUrl, MaintenanceApiUrl } from "../../../environment/routeSettings";
import { GridSetting, RequestResponseResult } from "../../../types/general/generalTypes";
import GridSettings from "../../../components/gridSettings/gridSettings";
import GridProfilePicker from "../../../components/gridProfilePicker/gridProfilePicker";
import { dxButtonOptions } from "devextreme/ui/button";
import { useScreenSize } from "../../../utils/media-query";
import { OnExporting } from "../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../utils/arrayTools";

//#endregion imports
const Personnel = () => {
  //#region consts
  const [initDataPersonnels, setInitDataPersonnels] = useState<VMPersonnel[]>([]);
  const [selectedPersonnel, setSelectedPersonnel] = useState<VMPersonnel>(new VMPersonnel());
  const [initDataCosts, setInitDataCosts] = useState<Cost[]>([new Cost()]);
  const [selectedCost, setSelectedCost] = useState<Cost>(new Cost());
  const [initDataUsers, setInitDataUsers] = useState<VMFindUser[]>([
    new VMFindUser(),
  ]);
  const dataGridPersonnelRef = useRef<DataGrid<any, any>>(null);
  const dataGridCostRef = useRef<DataGrid<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [showModalCosts, setShowModalCosts] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [modalModeCost, setModalModeCost] = useState<ModalMode>(ModalMode.Add);
  const [showValues, setShowCosts] = useState(false);
  const [personnelId, setPersonnelId] = useState<string>();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const { isXLarge, is2xLarge } = useScreenSize();
  const [gridSetting, setGridSetting] = useState<GridSetting>(JSON.parse((localStorage.getItem("GridSetting")!)) || new GridSetting)
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { generalSetting, customerDivisions } = useClientSetting();
  //#endregion consts

  const [popupHeight, setPopupHeight] = useState<string>("80%")
  const [gridHeight, setGridHeight] = useState<number>(360)

  useEffect(() => {
    if (isXLarge) { setGridHeight(530) }
    else if (is2xLarge) { setPopupHeight("50%"); setGridHeight(850) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitDataPersonnels();
        await getInitDataUsers();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //#region functions
  //Get InitData
  async function getInitDataPersonnels() {
    setInitDataPersonnels(
      await TesGet(
        MaintenanceApiUrl() +
        "/api/personnels/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  async function getInitDataUsers() {
    setInitDataUsers(
      await TesGet(
        AuthApiUrl() +
        "/api/User/GetUsersOfClient/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  // Personnel Functions
  function onEdit(d: any) {
    var selectedData: VMPersonnel = d.row.data
    setModalMode(ModalMode.Edit);
    setSelectedPersonnel(selectedData);
    setShowModal(true);
  }

  function onNew() {
    var selectedData = new VMPersonnel()
    setSelectedPersonnel(selectedData);
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  const onValueChanged = (key: string, value: any) => {
    setSelectedPersonnel({ ...selectedPersonnel, [key]: value });
  };
  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
    } else {
      setSelectedPersonnel(new VMPersonnel());
      setShowModal(false);
    }
  }
  async function onAdd() {
    const postObj = {
      ...selectedPersonnel,
      customerId: localStorage.getItem("selectedCustomerId"),
    };
    try {
      if (activeLoading) activeLoading(true);
      await TesPost(MaintenanceApiUrl() + "/api/personnels", postObj, true);
      await getInitDataPersonnels();
      onCloseModal();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }
  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      await TesPut(
        MaintenanceApiUrl() + "/api/personnels/" + selectedPersonnel.id,
        selectedPersonnel,
        true
      );
      await getInitDataPersonnels();
      onCloseModal();
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }
  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              await TesDelete(
                MaintenanceApiUrl() + "/api/personnels/" + d.row.data.id,
                true
              );
              await getInitDataPersonnels();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }
  //Costs functions
  function onEditCost(d: any) {
    // let newData = new Cost();
    //Object.assign(newData, d.row.data);
    setModalModeCost(ModalMode.Edit);
    setSelectedCost(d.row.data);
    console.log(JSON.stringify(d.row.data))
    setShowModalCosts(true);
  }

  function onNewCost() {
    setSelectedCost(new Cost());
    setModalModeCost(ModalMode.Add);
    setShowModalCosts(true);
  }

  function onCloseModalCost() {
    setShowModalCosts(false);
    setSelectedCost(new Cost());
  }

  const onValueChangedCost = (key: string, value: any) => {
    setSelectedCost({ ...selectedCost, [key]: value });
  };

  // function dateRangeOverlaps(a_start: Date | undefined, a_end: Date | undefined, b_start: Date | undefined, b_end: Date | undefined) {
  //   return (
  //     ((a_start == null || b_end == null || a_start <= b_end)
  //       && (b_start == null || a_end == null || b_start <= a_end)
  //       && (a_start == null || a_end == null || a_start <= a_end)
  //       && (b_start == null || b_end == null || b_start <= b_end))
  //   );
  // }



  async function onAddCost() {
    try {
      const postObj: Cost = {
        ...selectedCost,
        personnelId: personnelId as string,
      };

      if (postObj.fromDate && postObj.toDate && postObj.fromDate.getTime() > postObj.toDate.getTime()) {
        notify(t("validDataRange"), "error", 5000);
        return;
      }

      if (activeLoading) activeLoading(true);
      const res = await TesPost(MaintenanceApiUrl() + "/api/costs", postObj, true) as RequestResponseResult<Cost>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitDataCosts([...initDataCosts, res.results]);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        onCloseModalCost();
      } else if (res.responseCode === ResponseCode.DateHasOverlap) {
        notify(t("dateHasOverlap"), "error", 5000);
      } else {
        await RequestErrorHandling(res);;
      }
      dataGridCostRef.current?.instance.refresh();
      //onOpenCosts(selectedCost);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }




  async function onUpdateCost() {
    if (
      selectedCost.fromDate &&
      selectedCost.toDate &&
      new Date(selectedCost.fromDate).getTime() >
      new Date(selectedCost.toDate).getTime()
    ) {
      notify(t("validDataRange"), "error", 5000);
      return;
    }

    try {
      if (activeLoading) activeLoading(true);
      const postObj: Cost = {
        ...selectedCost,
        personnelId: personnelId as string,
      };
      var res = await TesPut(MaintenanceApiUrl() + "/api/costs/" + selectedCost.id, postObj, true) as RequestResponseResult<Cost>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        //update the cost
        var index = initDataCosts.findIndex((x) => x?.id === selectedCost.id);
        let items = [...initDataCosts];
        items[index] = selectedCost;

        var personnelIndex = initDataPersonnels.findIndex((x) => x?.id === personnelId);
        let personnelItems = [...initDataPersonnels];
        personnelItems[personnelIndex].costs = items;


        setInitDataCosts(items);
        setInitDataPersonnels(personnelItems);
        onCloseModalCost();
        dataGridCostRef.current?.instance.refresh();
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.DateHasOverlap) {
        notify(t("dateHasOverlap"), "error", 5000);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }


  async function onOpenCosts(data: any) {
    setPersonnelId(data.data.id);
    //setSelectedCost(data.data);
    // setInitDataCosts(
    //   await TesGet(
    //     MaintenanceApiUrl() + "/api/costs/personnelCosts/" + data.data.id,
    //     true
    //   )
    // );
    setInitDataCosts(data.data.costs);
    setShowCosts(true);
  }

  function calculateCostsListCell(d: any) {
    return (
      <Button
        onClick={() => onOpenCosts(d)}
        icon="fa-solid fa-circle-plus"
        text={t("costs")}
      />
    );
  }
  const numberBoxButton: dxButtonOptions = {
    icon: "fa-solid fa-dollar-sign",
    type: "default",
  };

  //#endregion functions

  return (
    <Permission
      allowed={[MaintenancePermissions.Maintenance_D_Personnel]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("personnel")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="leftColumn" style={{ display: "flex" }}>
                  <GridProfilePicker
                    customerId={localStorage.getItem("selectedCustomerId")!}
                    gridType={GridType.Personnel}
                    dataGridRef={dataGridPersonnelRef}
                    setGridSetting={setGridSetting}
                  />
                </div>
                <div className="rightColumn">
                  <Button onClick={onNew} icon="fa-solid fa-circle-plus" text={t("add")} />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridPersonnelRef}
                dataSource={initDataPersonnels}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                columnAutoWidth={gridSetting.horizontalScrolling}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />

                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="firstname" caption={t("firstname")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="lastname" caption={t("lastname")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  cellRender={calculateCostsListCell}
                  caption={t("costs")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  fixed={true}
                  fixedPosition="right"
                  width={110}
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>
          {/* Modal for adding/editing Personnel */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2}>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("userId")}
                    labelMode="static"
                    valueExpr="id"
                    displayExpr="userName"
                    value={selectedPersonnel?.userId?.toLowerCase()}
                    onValueChange={(e) => onValueChanged("userId", e)}
                    className="modalInput"
                    items={SortObjectByPropName(initDataUsers, "userName")}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("note")}
                    labelMode="static"
                    value={selectedPersonnel.note}
                    onValueChange={(e) => onValueChanged("note", e)}
                    className="modalInput"
                  />
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>

          {/* Modal for showing/choosing Costs */}
          <Popup
            width={"65%"}
            height={popupHeight}
            visible={showValues}
            resizeEnabled={true}
            showTitle={true}
            title={t("costs")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowCosts(false)}
          >
            <div style={{ height: "80%" }}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={() => onNewCost()}
                    icon="fa-solid fa-circle-plus"
                    text={t("add")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                style={{ height: "100%" }}
                ref={dataGridCostRef}
                dataSource={initDataCosts}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                height={gridHeight}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column alignment="left" dataField="price" caption={t("cost")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="fromDate"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  caption={t("from")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="toDate"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  caption={t("to")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                    dataField="DivisionId"
                    allowSorting={true}
                    visible={false}
                    caption={t("division")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(customerDivisions!, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
                <Column
                  type="buttons"
                  caption={t("actions")}
                  fixed={true}
                  fixedPosition="right"
                  width={110}
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEditCost}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>

              <div>
                <div className="rightColumn" style={{ marginTop: 10 }}>
                  <Button
                    className="tes-modal-btn-cancel"
                    onClick={() => setShowCosts(false)}
                    text={t("close")}
                  />

                </div>
              </div>
            </div>
          </Popup>

          {/* Modal for adding/editing Costs */}
          <Popup
            width={"50%"}
            height="auto"
            visible={showModalCosts}
            resizeEnabled={true}
            showTitle={true}
            title={modalModeCost === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModalCost()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2} className="popupFields">
                <SimpleItem colSpan={1}>
                  <NumberBox
                    step={0}
                    label={t("cost")}
                    labelMode="static"
                    value={selectedCost.price}
                    onValueChange={(e) => onValueChangedCost("price", e)}
                    className="modalInput"
                  >
                    <NumberBoxButton
                      name="clock"
                      location="after"
                      options={numberBoxButton}
                    />
                  </NumberBox>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <DateBox
                    label={t('from')}
                    value={selectedCost?.fromDate}
                    type="date"
                    onValueChange={(e) => onValueChangedCost("fromDate", e)}
                    pickerType="calendar"
                    placeholder={generalSetting?.dateFormat}
                    displayFormat={generalSetting?.dateFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    showClearButton={true}
                  >
                  </DateBox>
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <DateBox
                    label={t('to')}
                    value={selectedCost.toDate}
                    type="date"
                    onValueChange={(e) => onValueChangedCost("toDate", e)}
                    pickerType="calendar"
                    placeholder={generalSetting?.dateFormat}
                    displayFormat={generalSetting?.dateFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    showClearButton={true}
                  >
                  </DateBox>
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModalCost()}
                    text={t("cancel")}
                  />
                  {modalModeCost === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAddCost}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdateCost}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Personnel;
