import { ScrollView } from 'devextreme-react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Selection,
    Pager, Paging, Grouping, GroupPanel, Button as DevExpressButton, Export, Editing, Search
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { VMAssignUser2Divisions, VMDivisionXUser, VMUserProfileDetails } from '../../../../../types/identity/dto/identityDTO';
import { RequestErrorHandling, TesGet, TesPost } from '../../../../../utils/rest';
import { ResponseCode } from '../../../../../types/general/enums/generalEnums';
import { useAuth } from '../../../../../contexts/auth';
import { RequestResponseResult } from '../../../../../types/general/generalTypes';
import { Enum2Array } from '../../../../../utils/enumTools';
import { DivisionsPermissionTypes } from '../../../../../types/identity/enums/identityEnums';
import { Division } from '../../../../../types/identity/identityTypes';
import { AuthApiUrl } from '../../../../../environment/routeSettings';
import { useScreenSize } from "../../../../../utils/media-query";
import { OnExporting } from '../../../../../utils/dataGridTools';


// props
interface IPros {
    initData: VMUserProfileDetails,
    setInitData: React.Dispatch<React.SetStateAction<any>>;
}

const Divisions = (props: IPros) => {
    const [showModal, setShowModal] = useState(false);
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const dataGridModalRef = useRef<DataGrid<any, any>>(null);
    const [initDataModal, setInitDataModal] = useState<Division[]>();
    const { activeLoading } = useAuth();
    const lstDivisionsPermissionTypes = Enum2Array(DivisionsPermissionTypes)
    const { isXLarge, is2xLarge } = useScreenSize();
    const [gridHeight, setGridHeight] = useState<number>(420)
    const [popupHeight, setPopupHeight] = useState<string>("85%")

    useEffect(() => {
        if (isXLarge) { setPopupHeight("80%"); setGridHeight(530) }
        else if (is2xLarge) { setPopupHeight("60%"); setGridHeight(1020) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {

    }, [props.initData]);

    //modal functions 
    function onCloseModal() {
        setShowModal(false);
    }
    //modal mode functions 
    async function onNew() {
        var getRes = await TesGet(AuthApiUrl() + "/api/divisions/" + props.initData.defaultCustomerId, true) as Division[];
        setInitDataModal(
            getRes.filter(x => !props.initData.divisions.map(a => a.divisionId).includes(x.id!)
            ));
        setShowModal(true);
    }

    async function onAdd() {
        try {
            var selectedData = initDataModal?.filter(x => dataGridModalRef.current?.instance.getSelectedRowKeys().map(a => a.id).includes(x.id)) as Division[];
            if (props.initData !== undefined && selectedData !== undefined) {
                var res = selectedData.map(x => {
                    var d = new VMDivisionXUser();
                    d.divisionId = x.id!;
                    d.city = x.city!;
                    d.name = x.name!;
                    d.rolesArray = x["rolesArray"];
                    return d;
                })
                props.setInitData({ ...props.initData, divisions: [...props.initData.divisions, ...res] });
            }
            onCloseModal();
            dataGridRef.current?.instance.refresh();
        } catch (ex) {
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }
    //delete functions 
    async function onDelete(d: any) {
        let myDialog = custom({
            title: t("deleteData"),
            messageHtml: t("areYouSureWantToDelete?"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {
                    try {
                        props.setInitData({ ...props.initData, divisions: props.initData.divisions.filter(x => x.divisionId !== d.row.data.divisionId) });
                        dataGridRef.current?.instance.refresh();
                    } catch {
                        notify(t("someErrorOccurred"), "error", 5000);
                    }
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }

    async function onSave() {
        try {
            if (activeLoading) activeLoading(true);

            const postObj: VMAssignUser2Divisions = {
                userId: props.initData.id,
                divisions: props.initData.divisions
            }
            const res = (await (TesPost(AuthApiUrl() + "/api/Divisions/AssignUser2Divisions", postObj, true))) as RequestResponseResult<null>
            if (res.responseCode === ResponseCode.OK) {
                if (activeLoading) activeLoading(false);
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
            } else if (res.responseCode === ResponseCode.UserNotPermitted) {
                notify(t("userNotPermitted"), "error", 5000);
            } else if (res.responseCode === ResponseCode.NotPermitted) {
                notify(t("notPermitted"), "error", 5000);
            } else if (res.responseCode === ResponseCode.UpdatingError) {
                notify(t("updatingError"), "error", 5000);
            }
            if (activeLoading) activeLoading(false);
            await RequestErrorHandling(res);;

        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }


    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <div className="row">
                    <div className='rightColumn'>
                        <Button
                            text={t('add')}
                            onClick={onNew}
                            icon="fa-solid fa-circle-plus"
                        />
                    </div>
                </div>
                <DataGrid id="gridContainer"
                    ref={dataGridRef}
                    dataSource={props.initData.divisions}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    style={{ margin: "0 1rem" }}
                    onExporting={OnExporting}
                    onEditorPreparing={e => {
                        if ((e.parentType === "dataRow" || e.parentType === "filterRow") && e.dataField === "rolesArray") {
                            e.editorName = "dxTagBox"
                            e.editorOptions.dataSource = lstDivisionsPermissionTypes;
                            e.editorOptions.showSelectionControls = true;
                            e.editorOptions.displayExpr = "name";
                            e.editorOptions.valueExpr = "value";
                            e.editorOptions.value = e.value || [];
                            e.editorOptions.onValueChanged = function (args: any) {
                                e.setValue(args.value);
                            }
                        }
                    }}
                    //onContentReady={e => {e.component.deselectAll()}}
                    //onFilterValueChange={e => {dataGridRef?.current?.instance.deselectAll()}}
                >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <SearchPanel visible={true}
                        width={285}
                        placeholder={t("search...")} />


                    <ColumnChooser width={350} height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                    >
                        <Search enabled />
                    </ColumnChooser>
                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 20, 50, 100]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                    />
                    <Editing
                        mode="cell"
                        allowUpdating={true}
                        allowAdding={false}
                        allowDeleting={false} />
                    <Selection
                        mode="multiple"
                        selectAllMode={'allPages'}
                        showCheckBoxesMode={'always'}
                    />

                    <FilterRow visible={true}
                        applyFilter="auto" />

                    <HeaderFilter visible={true} />
                    <Column dataField="id"
                        caption={t('id')}
                        visible={false}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="name"
                        caption={t('name')}
                        defaultSortOrder="asc"
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="city"
                        caption={t('city')}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="rolesArray" caption={t('permisions')}
                        calculateDisplayValue={function (rowData: any) {
                            var values = rowData.rolesArray;
                            var res = [];
                            if (values !== undefined) {
                                for (let i = 0; i < values.length; i++) {
                                    res.push(DivisionsPermissionTypes[values[i]]);
                                }
                            }
                            return res.join(",");
                        }}
                    ></Column>


                    <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">

                        <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                        </DevExpressButton>
                    </Column>
                </DataGrid>

                <div className='rightColumn'>
                    <Button
                        className='tes-modal-btn-add saveButton'
                        onClick={onSave}
                        text={t('save')}
                    />

                </div>
            </div>
            <Popup
                width={"50%"}
                height={popupHeight}
                visible={showModal}
                resizeEnabled={true}
                showTitle={true}
                title={t('add')}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onCloseModal()}
            >
                <div style={{ height: "90%" }}>
                    <ScrollView width='100%' height='100%'>
                        <DataGrid id="gridContainer"
                            ref={dataGridModalRef}
                            dataSource={initDataModal}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            style={{ height: '100%' }}
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            onExporting={OnExporting}
                            onEditorPreparing={e => {
                                if ((e.parentType === "dataRow" || e.parentType === "filterRow") && e.dataField === "rolesArray") {
                                    e.editorName = "dxTagBox"
                                    e.editorOptions.dataSource = lstDivisionsPermissionTypes;
                                    e.editorOptions.showSelectionControls = true;
                                    e.editorOptions.displayExpr = "name";
                                    e.editorOptions.valueExpr = "value";
                                    e.editorOptions.value = e.value || [];
                                    e.editorOptions.onValueChanged = function (args: any) {
                                        e.setValue(args.value);
                                    }
                                }
                            }}
                            //onContentReady={e => {e.component.deselectAll()}}
                            //onFilterValueChange={e => {dataGridModalRef?.current?.instance.deselectAll()}}
                            height={gridHeight}
                        >
                            <Editing
                                mode="cell"
                                allowUpdating={true}
                                allowAdding={false}
                                allowDeleting={false} />

                            <Export enabled={true}
                                allowExportSelectedData={true}
                            />
                            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                            <GroupPanel visible={true} /> {/* or "auto" */}

                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[10, 20, 50, 100]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />

                            <Selection
                                mode="multiple"
                                selectAllMode={'allPages'}
                                showCheckBoxesMode={'always'}
                            />
                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />


                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />
                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>

                            <Column dataField="id"
                                caption={t('id')}
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="name"
                                caption={t('name')}
                                defaultSortOrder="asc"
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="city"
                                caption={t('city')}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column
                                dataField="rolesArray" caption={t('permisions')}
                                calculateDisplayValue={function (rowData: any) {
                                    var values = rowData.rolesArray;
                                    var res = [];
                                    if (values !== undefined) {
                                        for (let i = 0; i < values.length; i++) {
                                            res.push(DivisionsPermissionTypes[values[i]]);
                                        }
                                    }
                                    return res.join(",");
                                }}
                            ></Column>
                        </DataGrid>
                    </ScrollView>
                </div>
                <div className='rightColumn' style={{ marginTop: 10 }}>
                    <Button
                        className='tes-modal-btn-cancel'
                        style={{ marginRight: 20 }}
                        onClick={() => onCloseModal()}
                        text={t('cancel')}
                    />
                    <Button
                        className='tes-modal-btn-add'
                        onClick={onAdd}
                        text={t('add')}
                    />

                </div>
            </Popup>
        </React.Fragment>
    );


}
export default Divisions;