import { Button, Form, NumberBox, Popup, ScrollView, SelectBox, TextBox } from 'devextreme-react';
import DataGrid, {
  Column,
  ColumnChooser,
  Button as DevExpressButton,
  Export,
  FilterBuilderPopup,
  FilterPanel,
  FilterRow,
  Grouping,
  GroupItem,
  GroupPanel,
  HeaderFilter,
  Lookup,
  MasterDetail,
  Pager, Paging,
  Search,
  SearchPanel,
  SortByGroupSummaryInfo, StateStoring, Summary
} from 'devextreme-react/data-grid';
import { SimpleItem } from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';
import { t } from 'i18next';
import React, { useState } from 'react';
import { useAuth } from '../../../../../../contexts/auth';
import { TrafficApiUrl } from '../../../../../../environment/routeSettings';
import { GridType } from '../../../../../../types/general/enums/generalEnums';
import { NameValue } from '../../../../../../types/general/generalTypes';
import { MmsRes, MMSSmoothingStatus, TesCodeValue } from '../../../../../../types/infrastructure/infrastructureTypes';
import { OnExporting } from '../../../../../../utils/dataGridTools';
import { Enum2Array } from '../../../../../../utils/enumTools';
import { TesPost } from '../../../../../../utils/rest';
import DetailTemplate from './detailsTmeplate';

interface IProps {
  smoothingGridData: MmsRes[]
  GetInitDataSetting: () => void
  GetInitDataResult: () => void
  dataGridResRef: React.RefObject<DataGrid<any, any>>
  dataGridSmoothingRef: React.RefObject<DataGrid<any, any>>
  // initDataTesCodeValues: TesCodeValue
}

class DetailedMMSRes { 
  rin!: string; 
  smoothMMS!: number; 
  mmsSmoothingStatus!: number; 
  details!: Omit<MmsRes, 'rin' | 'smoothMMS' | 'mmsSmoothingStatus'>[]; 
}

const Smoothing = (props: IProps) => {
    
  const lstMmsSmoothingStatus: NameValue[] = Enum2Array(MMSSmoothingStatus)
  const [showModal, setShowModal] = useState<boolean>(false);
  const [editingRow, setEditingRow] = useState<DetailedMMSRes>({...new DetailedMMSRes});
  const { activeLoading } = useAuth();

    function transformArray(mmsResArray: MmsRes[]): DetailedMMSRes[] {
      return mmsResArray.reduce((acc, curr) => {
        const rinValue = curr.rin || "-1";
    
        let rinGroup = acc.find(group => group.rin === rinValue);
    
        if (!rinGroup) {
          rinGroup = { 
            rin: rinValue, 
            smoothMMS: curr.smoothMMS, 
            mmsSmoothingStatus: curr.mmsSmoothingStatus, 
            details: [] 
          };
          acc.push(rinGroup);
        }
    
        const { rin, smoothMMS, mmsSmoothingStatus, ...details } = curr;
        rinGroup.details.push(details);
    
        return acc;
      }, [] as DetailedMMSRes[]);
    }
    
    function onEdit(e: any) {
      setEditingRow({ ...e.row.data }); 
      setShowModal(true);
    }
  
    function onCloseModal() {
      setShowModal(false);
      setEditingRow({ ...new DetailedMMSRes() });
    }

    async function onUpdate() {
      try {
          if (activeLoading) activeLoading(true);
          await TesPost(TrafficApiUrl() + "/api/Mms/CustomizeSmoothing", {
            id: editingRow.details[0].id,
            customMmsSmooth : editingRow.smoothMMS
          }, true);
          await props.GetInitDataSetting();
          await props.GetInitDataResult();
          props.dataGridSmoothingRef.current?.instance.refresh()
          props.dataGridResRef.current?.instance.refresh()
          onCloseModal();
          if (activeLoading) activeLoading(false);
          notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } catch (ex) {
          if (activeLoading) activeLoading(false);
          notify(t("someErrorOccurred") + ex, "error", 5000);
      }
  }

    return (
      <React.Fragment>
      <div className='row' style={{ marginTop: 20, padding: 10 }}>
        <DataGrid id="gridContainer DetailsGrid"
        ref={props.dataGridSmoothingRef}
        dataSource={transformArray(props.smoothingGridData)}
        rowAlternationEnabled={true}
        showBorders={true}
        hoverStateEnabled={true}
        remoteOperations={true}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnAutoWidth={true}
        onExporting={OnExporting}
      >
        <Export enabled={true} allowExportSelectedData={true} />
        <Grouping contextMenuEnabled={true} autoExpandAll={false} />
        <GroupPanel visible={true} /> {/* or "auto" */}
        <FilterPanel visible={true} />
        <FilterBuilderPopup position={"top"} />
        <StateStoring
          enabled={true}
          type="localStorage"
          storageKey={GridType.MMSDetailsSmoothing.toString()}
          savingTimeout={500}
        />
        <SortByGroupSummaryInfo
          summaryItem="Total Count"
          sortOrder="desc"
        />
        <Summary>
          <GroupItem
            summaryType="count"
            alignByColumn
            name="Total Count"
          />
        </Summary>
        <Paging enabled={true} defaultPageSize={100} />
        <Pager
          showPageSizeSelector={true}
          allowedPageSizes={[100, 200, 300, 400, 500]}
          showNavigationButtons={true}
          showInfo={true}
          visible={true}
        />
        <FilterRow visible={true} applyFilter="auto" />
        <HeaderFilter visible={true} />
        <SearchPanel visible={true} width={285} placeholder={t("search...")} />
        <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
          <Search enabled />
        </ColumnChooser>

        <Column dataField="id" caption={t("id")} visible={false}>
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column dataField="rin" caption={t("RIN")}width={60}>
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column dataField="smoothMMS" caption={t("smoothMMS")}width={100}>
          <HeaderFilter>
            <Search enabled />
          </HeaderFilter>
        </Column>
        <Column dataField="mmsSmoothingStatus" caption={t('mmsSmoothingStatus')}>
            <HeaderFilter>
                <Search enabled />
            </HeaderFilter>
            <Lookup dataSource={lstMmsSmoothingStatus} valueExpr="value" displayExpr="name" />
        </Column>
        <Column
          type="buttons"
          caption={t("actions")}
          width={"6rem"}
          fixed={true}
          fixedPosition="right"
        >
          <DevExpressButton
            hint={t("edit")}
            onClick={e => onEdit(e)}
            icon="fa-solid fa-pencil"
          ></DevExpressButton>
        </Column>
        <MasterDetail
          enabled={true}
          component={DetailTemplate}
        />
      </DataGrid>
      <Popup
        width={"50%"}
        height={"auto"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={t("edit")}
        hideOnOutsideClick={true}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
      <ScrollView width="100%" height="100%">
        <Form colCount={2}>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("rin")}
              labelMode="floating"
              value={editingRow.rin}
              className="modalInput"
              disabled={true}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <NumberBox
              label={t("smoothMMS")}
              labelMode="floating"
              value={editingRow.smoothMMS}
              onValueChange={(e) => setEditingRow({...editingRow, smoothMMS: e})}
              className="modalInput"
              />
          </SimpleItem>
          <SimpleItem>
            <SelectBox
              disabled={true}
              placeholder=""
              label={t("mmsSmoothingStatus")}
              labelMode="static"
              items={lstMmsSmoothingStatus}
              displayExpr="name"
              valueExpr="value"
              value={editingRow.mmsSmoothingStatus}
              showClearButton={true}
              searchEnabled={true}
              style={{marginTop: "-1.5rem"}}
            />
          </SimpleItem>
        </Form>
        <div>
          <div className="rightColumn">
            <Button
              className="tes-modal-btn-cancel"
              style={{ marginRight: 20 }}
              onClick={() =>onCloseModal()}
              text={t("cancel")}
            />
            <Button
              className="tes-modal-btn-add"
              onClick={() => onUpdate()}
              text={t("update")}
            />
          </div>
        </div>
      </ScrollView>
    </Popup>
</div>
</React.Fragment>   
      );
}

export default Smoothing