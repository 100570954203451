import React, { useEffect, useRef, useState } from 'react';

import PivotGridDataSource from 'devextreme/ui/pivot_grid/data_source';

import Chart, {
  AdaptiveLayout,
  CommonSeriesSettings,
  Export,
  Size,
  Tooltip,
} from 'devextreme-react/chart';

import PivotGrid, {
  FieldChooser,
} from 'devextreme-react/pivot-grid';

import { FlatRawDataDTO, FlatRawDataReq, StudySpeedWebDetailsDTO, StudyTmcWebDetailsDTO } from '../../../../../../types/trafficStudy/dtos/studyDto';
import { GeneralSetting } from '../../../../../../types/setting/gridSettingTypes';
import { ApproachType, MovementType } from '../../../../../../types/trafficStudy/enums/trafficStudyEnums';
import { useTranslation } from 'react-i18next';
import { RequestErrorHandling, TesPost } from '../../../../../../utils/rest';
import { TrafficApiUrl } from '../../../../../../environment/routeSettings';
import { RequestResponseResult } from '../../../../../../types/general/generalTypes';
import { ResponseCode } from '../../../../../../types/general/enums/generalEnums';
import { Button, DateBox, ValidationGroup } from 'devextreme-react';
import { AddDays2Date, CombineDateAndTime, CreateDateAsUTC, GetHourDiff } from '../../../../../../utils/dateTimeTools';
import { useAuth } from '../../../../../../contexts/auth';
import Form, { SimpleItem } from 'devextreme-react/form';
import { DateTime } from "luxon";
import { onExportingPivotGrid } from '../../../../../../utils/dataGridTools';
import RowDataManager from '../../../../../../components/rowDataManager/rowDataManager';
import Permission from '../../../../../../components/permission/permision';
import { TrafficStudyPermissions } from '../../../../../../constants/Permissions';
//import  saveAs  from 'file-saver';
interface IPros {
  initDataSpeed: StudySpeedWebDetailsDTO,
  generalSettings: GeneralSetting | null;
  isLocked: boolean;
  validationRef: React.RefObject<ValidationGroup>;
}

const SpeedRawData = (props: IPros) => {
  const { activeLoading } = useAuth();
  let chartRef = useRef<Chart>(null)
  let pivotGridRef = useRef<PivotGrid>(null)
  const [flatRawData, setFlatRawData] = useState<FlatRawDataDTO[]>([]);

  const [maxDate, setMaxDate] = useState<Date>(new Date(2012, 0, 1))
  const [minDate, setMinDate] = useState<Date>(new Date(2011, 0, 1))

  const [dateRange, setDateRange] = useState<[Date, Date]>();
  const [timeOfStartDate, setTimeOfStartDate] = useState<Date>()
  const [timeOfEndDate, setTimeOfEndDate] = useState<Date>()
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const [showDateRangePicker, setShowDateRangePicker] = useState<boolean>(false)
  const [ds, setDs] = useState<PivotGridDataSource>()


  const { t } = useTranslation();


  // const Sampledata = {
  //   "id": "00000000-0000-0000-0000-000000000000",
  //   "errorMessages": [],
  //   "responseCode": 200,
  //   "results": [
  //     {
  //       "id": "00000000-0000-0000-0000-000000000000",
  //       "startDT": "2018-05-02T00:00:00",
  //       "movement": 4,
  //       "vehicleClassName": "",
  //       "approach": 2,
  //       "vehicleClassificationType": 0,
  //       "upperSpeed": 45,
  //       "lowerSpeed": 41,
  //       "speedBin": "41 - 45",
  //       "lane": 0,
  //       "count": 1,
  //       "speedBinFormated": "041 - 045"
  //     }

  //   ]
  // }

  useEffect(() => {
    // console.log("DATA " + JSON.stringify(props.initDataBase))
    var startDT = new Date(props.initDataSpeed.rawDataStartDT!);
    var endDT = new Date(props.initDataSpeed.rawDataEndDT!);
    var duration = GetHourDiff(startDT, endDT);
    if (duration > 24) {
      setShowDateRangePicker(true)
    }
    if (duration === 0) {
      endDT = new Date()
    }
    var startYear = startDT.getFullYear() ?? new Date().getFullYear();
    var startMonth = startDT.getMonth() ?? new Date().getMonth();
    var startDay = startDT.getDate() ?? new Date().getDate();

    var endYear = endDT.getFullYear() ?? new Date().getFullYear();
    var endMonth = endDT.getMonth() ?? new Date().getDate();
    var endDay = endDT.getDate() ?? new Date().getFullYear();
    //if (startYear === endYear) endYear++;

    // console.log("TIME " + endDT)

    setMinDate(new Date(startYear, startMonth, startDay))
    setMaxDate(new Date(endYear, endMonth, endDay))

    setTimeOfStartDate(startDT)
    if (duration > 72) {
      endDT = AddDays2Date(startDT, 1);
      setTimeOfEndDate(endDT)
    } else {
      setTimeOfEndDate(endDT)
    }
    setDateRange([startDT, endDT]);
  }, [props.initDataSpeed.rawDataStartDT, props.initDataSpeed.rawDataEndDT]);



  useEffect(() => {
    if (dataLoaded === false && dateRange !== undefined) {
      applyPeriodFilter();
      setDataLoaded(true);
    }
  }, [dataLoaded, dateRange]);


  useEffect(() => {
    const dataSource = new PivotGridDataSource({
      fields: [
        {
          caption: t('speedBin'),
          dataField: 'speedBinFormated',
          width: 25,
          area: 'column',
          sortBy: "value",
          customizeText: function (cellInfo) {
            var vals = cellInfo.value?.toString().split(" - ");
            var sp1 = parseInt(vals![0],10);
            var sp2 = parseInt(vals![1],10);
            return sp1 + "-" + sp2
          },
        },
        {
          caption: t('approach'),
          dataField: 'approach',
          width: 150,
          area: 'column',
          customizeText: function (cellInfo) {
            return (t("approach-" + ApproachType[parseInt(cellInfo.value?.toString()!)]))
          },
        },
        {
          caption: t('movement'),
          width: 120,
          dataField: 'movement',
          area: 'column',
          sortBySummaryField: 'Total',
          customizeText: function (cellInfo) {
            return (t("movement-" + MovementType[parseInt(cellInfo.value?.toString()!)]))
          },
          sortBy: "value"
        },
        {
          caption: t('vehicleClassName'),
          dataField: 'vehicleClassName',
          width: 150,
          area: 'column',
        },
        {
          caption: t('lane'),
          width: 120,
          dataField: 'lane',
          area: 'column',
        },
        {
          dataField: 'startDT',
          dataType: 'date',
          area: 'row',
          format: props.generalSettings?.dateTimeFormat
        },

        {
          caption: 'Total',
          dataField: 'count',
          dataType: 'number',
          summaryType: 'sum',
          area: 'data',
        }],
      store: flatRawData,
      retrieveFields: false,
    });
    setDs(dataSource);
    pivotGridRef.current?.instance.bindChart(chartRef.current?.instance, {
      dataFieldsDisplayMode: 'splitPanes',
      alternateDataFields: false,
    })
  }, [flatRawData, props.generalSettings?.dateTimeFormat, t]);

  function isValidDate(dateObject: Date) {
    return new Date(dateObject).toString() !== 'Invalid Date';
  }

  async function applyPeriodFilter() {
    if (!isValidDate(dateRange![1])) {
      return
    }
    if (activeLoading) activeLoading(true);
    var postObj: FlatRawDataReq = {
      endDT: CreateDateAsUTC(dateRange![1]),
      startDT: CreateDateAsUTC(dateRange![0]),
      studyId: props.initDataSpeed.id
    }

    const res = (await TesPost(
      TrafficApiUrl() + "/api/Study/GetFlatRawData", postObj,
      true
    )) as RequestResponseResult<FlatRawDataDTO[]>;
    if (res.responseCode === ResponseCode.OK) {
      setFlatRawData(res.results);
    } else {
      await RequestErrorHandling(res);;
    }
    if (activeLoading) activeLoading(false);

  }

  return (
    <React.Fragment>
      <ValidationGroup
        ref={props.validationRef}
      >
        {dateRange !== undefined && isValidDate(dateRange![1]) && showDateRangePicker &&
          <div>
            <Form colCount={3} style={{ marginBottom: 20, marginLeft: '2.5rem' }}>
              <SimpleItem colSpan={3}>
                <p style={{ marginTop: 5, fontSize: 16 }}> {t("theRawDataIsAccessibleForTheDurationSpanningFrom")} <span style={{ fontWeight: "bold" }}>{props.initDataSpeed.rawDataStartDT !== undefined ? DateTime.fromISO(props.initDataSpeed.rawDataStartDT.toString(), { zone: 'utc' }).toFormat(props.generalSettings?.dateTimeFormat!) : ""}</span> {t("to_lowercase")} <span style={{ fontWeight: "bold" }}>{props.initDataSpeed.rawDataEndDT !== undefined ? DateTime.fromISO(props.initDataSpeed.rawDataEndDT?.toString(), { zone: 'utc' }).toFormat(props.generalSettings?.dateTimeFormat!) : ""}</span>. {t("theReportBelowHasBeenGeneratedForTheSpecificDatesSpecifiedInTheFields.")}</p>
              </SimpleItem>
              {dateRange !== undefined &&
                <SimpleItem colSpan={1}>
                  <DateBox
                    label={t("from")}
                    //defaultValue={reportDate.beginDateTo}
                    type="datetime"
                    onValueChange={e => setDateRange([e, dateRange![1]])}
                    pickerType="calendar"
                    placeholder={props.generalSettings?.dateTimeFormat}
                    displayFormat={props.generalSettings?.dateTimeFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    showClearButton={true}
                    value={dateRange![0]}
                    min={props.initDataSpeed.rawDataStartDT}
                    max={props.initDataSpeed.rawDataEndDT}
                  />
                </SimpleItem>
              }
              {dateRange !== undefined &&
                <SimpleItem colSpan={1}>
                  <DateBox
                    label={t("to")}
                    //defaultValue={reportDate.beginDateTo}
                    type="datetime"
                    onValueChange={e => setDateRange([dateRange[0]!, e])}
                    placeholder={props.generalSettings?.dateTimeFormat}
                    displayFormat={props.generalSettings?.dateTimeFormat}
                    useMaskBehavior={true}
                    openOnFieldClick={true}
                    showClearButton={true}
                    value={dateRange![1]}
                    min={dateRange![0]}
                    max={new Date(dateRange![0].getFullYear(), dateRange![0].getMonth(), dateRange![0].getDate() + 3)}
                  />
                </SimpleItem>
              }
              <SimpleItem colSpan={1}>
                <Button
                  onClick={() => applyPeriodFilter()}
                  className='tes-modal-btn-add'
                  text={t('apply')}
                  style={{ marginTop: 10 }}
                />
              </SimpleItem>
            </Form>


            {/* <DateRangeSlider
            maxDate={maxDate}
            minDate={minDate}
            minDurationInDays={minDurationInDays}
            maxDurationInDays={maxDurationInDays}
            dateRange={dateRange}
            setDateRange={setDateRange}
            hasTime={hasTime}
            timeOfStartDate={timeOfStartDate}
            setTimeOfStartDate={setTimeOfStartDate}
            timeOfEndDate={timeOfEndDate}
            setTimeOfEndDate={setTimeOfEndDate}
          /> */}

          </div>
        }
         <Permission
          allowed={[TrafficStudyPermissions.TrafficStudy_E_Study]}
          hasFeedBackElement={false}
        >
        <RowDataManager arrangement={['0.25rem', 'auto', 'auto', '2.5rem']} studyId={props.initDataSpeed.id!} />
        </Permission>
        <Chart ref={chartRef}>
          <Size height={200} />
          <Tooltip enabled={true}
          />
          <CommonSeriesSettings type="bar" />
          <AdaptiveLayout width={450} />
          <Export enabled={true} printingEnabled={false} />
        </Chart>

        <PivotGrid
          id="pivotgrid"
          dataSource={ds}
          allowSortingBySummary={true}
          allowFiltering={true}
          showBorders={true}
          showColumnTotals={false}
          showColumnGrandTotals={true}
          showRowTotals={false}
          showRowGrandTotals={true}
          onExporting={onExportingPivotGrid}
          fieldPanel={{
            showColumnFields: true,
            showDataFields: true,
            showFilterFields: true,
            showRowFields: true,
            allowFieldDragging: true,
            visible: true
          }}
          allowSorting={true}
          allowExpandAll={true}
          //  onExporting={onExporting}
          ref={pivotGridRef}
        >
          <Export enabled={true} printingEnabled={false} />

          <FieldChooser enabled={true} height={400} />
        </PivotGrid>
      </ValidationGroup>
    </React.Fragment>
  )
}


export default SpeedRawData;