import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import { RequestErrorHandling, TesGet } from "../../../../utils/rest";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import tabTitles from "./data/index";
import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import "./xmlImportLogDetails.scss";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import Permission from "../../../../components/permission/permision";
import { CollisionPermissions, SignPermissions, TMSPermissions, } from "../../../../constants/Permissions";
import { IXMLImportLogParam, XmlImportLog } from "../../../../types/collision/collisionTypes";
import Logs from "./components/logs/logs";
import { CollisionApiUrl } from "../../../../environment/routeSettings";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import getTitle from "../../../../components/title/getTitle";
import ShareURL from "../../../../components/shareURL/shareURL";

const XMLImportLogDetails = () => {
  const history = useNavigate();
  const params = useParams<IXMLImportLogParam>();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [initData, setInitData] = useState<XmlImportLog>(new XmlImportLog());
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  const title = getTitle('/collision/import/xml/xmlImportLogDetails', `${initData?.startDT?.toString().split('T')[0]}`);
  useEffect(() => {
    document.title = title;
  }, [title]);
  
  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData(params.xmlLogId!)
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.xmlLogId]);


  async function getInitData(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        CollisionApiUrl() + "/api/XMLImport/GetXMLImportLogDetails/" + id,
        true
      )) as RequestResponseResult<XmlImportLog>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results)
      } else {
        await RequestErrorHandling(res);;
      }
    }
    catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }







  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }





  function LockHandler() {
    setIsLocked(!isLocked);
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  //Detail Functions

  return (
    <Permission
      allowed={[CollisionPermissions.Collision_GeoCode, TMSPermissions.TMS_Admin]}
      hasFeedBackElement={true}
    >
      <div className={`xmlImportLogDetails ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
            {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem"}}>
                  <p ref={headingParagraphRef} className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t('date')}: {initData?.startDT?.toString().split('T')[0]}</span>
                  </p>
                  <Button
                    onClick={() => copyTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                  <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => history(-1)}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />

                </div>
                <div className="rightColumn">
                  <Permission
                    allowed={[SignPermissions.Sign_E]}
                    hasFeedBackElement={false}
                  >
                    {params.xmlLogId !== "AddNew" && (
                      <ShareURL
                        url={url}
                      />
                    )}
                    <Button
                      onClick={() => LockHandler()}
                      icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                      hint={isLocked ? t("unlock") : t("lock")}
                    />
                  </Permission>
                </div>
              </div>

            </div>
            <div className={"dx-card "}>
              <Tabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onOptionChanged={onTabsSelectionChanged}
              />

              {selectedIndex === 0 && (
                <Logs
                  initData={initData}
                />
              )}

            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default XMLImportLogDetails;
