import { Button } from 'devextreme-react/button';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import notify from 'devextreme/ui/notify';
import { VMRoleStatus, VMUpdateUserPermissions, VMUserProfileDetails } from '../../../../../types/identity/dto/identityDTO';
import { RequestErrorHandling, TesGet, TesPost, TesPut } from '../../../../../utils/rest';
import { ResponseCode } from '../../../../../types/general/enums/generalEnums';
import { useAuth } from '../../../../../contexts/auth';
import { RequestResponseResult } from '../../../../../types/general/generalTypes';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard';
import { Switch } from 'devextreme-react/switch';
import { SelectBox } from 'devextreme-react';

import { Form, SimpleItem, GroupItem as GroupItemForm } from 'devextreme-react/form';
import { ApprovalLevel, ApprovalLevelXUser } from '../../../../../types/collision/collisionTypes';
import { custom } from 'devextreme/ui/dialog';

import design from "./permissions.module.scss"
import { AuthApiUrl, CollisionApiUrl } from '../../../../../environment/routeSettings';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';


// props
interface IPros {
    initData: VMUserProfileDetails,
    initDataLevel: ApprovalLevel[],
    initDataUserLevel: Partial<ApprovalLevelXUser>,
    setInitData: React.Dispatch<React.SetStateAction<any>>,
    setInitDataUserLevel: React.Dispatch<React.SetStateAction<any>>,
    getInitialData: () => void,
    getInitialUserLevels: () => void

}

const Permissions = (props: IPros) => {
    const [prmsTMS, setPrmsTMS] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsFMS, setPrmsFMS] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsTMC, setPrmsTMC] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsSafety, setPrmsSafety] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsTCBOffline, setPrmsTCBOffline] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsTCBOnline, setPrmsTCBOnline] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsSign, setPrmsSign] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsMaintenance, setPrmsMaintenance] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsCollision, setPrmsCollision] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsTrafficStudy, setPrmsTrafficStudy] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsModuleOfModule, setPrmsModuleOfModule] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsInfrastructure, setPrmsInfrastructure] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsDashboard, setPrmsDashboard] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsCustomerGeneral, setPrmsCustomerGeneral] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsUser, setPrmsUser] = useState<VMRoleStatus[]>([new VMRoleStatus()]);
    const [prmsSystematicScreening, setPrmsSystematicScreening] = useState<VMRoleStatus[]>([new VMRoleStatus()]);

    const { activeLoading, user } = useAuth();

    const switchCols = window.innerWidth < 1200 ? 2 : 3;

    useEffect(() => {
        generateRoles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);


    function generateRoles() {
        if (props.initData.allPermissions.tms) {
            setPrmsTMS(props.initData.allPermissions.tms.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.fms) {
            setPrmsFMS(props.initData.allPermissions.fms.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }
        if (props.initData.allPermissions.moduleOfModule) {
            setPrmsModuleOfModule(props.initData.allPermissions.moduleOfModule.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }
        if (props.initData.allPermissions.trafficStudy) {
            setPrmsTrafficStudy(props.initData.allPermissions.trafficStudy.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.tmc) {
            setPrmsTMC(props.initData.allPermissions.tmc.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.safety) {
            setPrmsSafety(props.initData.allPermissions.safety.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.tcbOffline) {
            setPrmsTCBOffline(props.initData.allPermissions.tcbOffline.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.tcbOnline) {
            setPrmsTCBOnline(props.initData.allPermissions.tcbOnline.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.sign) {
            setPrmsSign(props.initData.allPermissions.sign.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.maintenance) {
            setPrmsMaintenance(props.initData.allPermissions.maintenance.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.collision) {
            setPrmsCollision(props.initData.allPermissions.collision.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.dashboard) {
            setPrmsDashboard(props.initData.allPermissions.dashboard.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.infrastructure) {
            setPrmsInfrastructure(props.initData.allPermissions.infrastructure.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.customerGeneral) {
            setPrmsCustomerGeneral(props.initData.allPermissions.customerGeneral.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.user) {
            setPrmsUser(props.initData.allPermissions.user.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

        if (props.initData.allPermissions.systematicScreening) {
            setPrmsSystematicScreening(props.initData.allPermissions.systematicScreening.filter(x => user?.permissions?.includes(x)).map(prm => {
                var res = new VMRoleStatus();
                res.active = props.initData.userPermission.includes(prm)
                res.name = prm;
                return res;
            }))
        }

    }

    async function onSave() {
        try {
            if (activeLoading) activeLoading(true);

            const postObj = new VMUpdateUserPermissions();

            postObj.id = props.initData.id;
            postObj.role = [];

            // eslint-disable-next-line array-callback-return
            prmsTMS.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsUser.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsTrafficStudy.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsModuleOfModule.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsMaintenance.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsTMC.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsSafety.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsFMS.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsTCBOffline.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsTCBOnline.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsSign.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsCollision.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsDashboard.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsInfrastructure.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsCustomerGeneral.map(p => {
                if (p.active) postObj.role.push(p.name);
            });
            // eslint-disable-next-line array-callback-return
            prmsSystematicScreening.map(p => {
                if (p.active) postObj.role.push(p.name);
            });

            if (props.initData.allPermissions.collision && props.initDataUserLevel) {
                await updateLevels();
            }

            const res = (await (TesPost(AuthApiUrl() + "/api/User/UpdateUserPermissions", postObj, true))) as RequestResponseResult<null>
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
            } else if (res.responseCode === ResponseCode.UserNotPermitted) {
                notify(t("userNotPermitted"), "error", 5000);
            } else if (res.responseCode === ResponseCode.UpdatingError) {
                notify(t("updatingError"), "error", 5000);
            }
            if (activeLoading) activeLoading(false);
            await RequestErrorHandling(res);;
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    async function updateLevels() {
        try {
            var postObj = props.initDataUserLevel;
            postObj.userId = props.initData.id;
            const res = (await (TesPut(CollisionApiUrl() + "/api/ApprovalLevels/updateUserLevel/" + props.initData.defaultCustomerId, postObj, true))) as RequestResponseResult<null>
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
            } else if (res.responseCode === ResponseCode.UserNotPermitted) {
                notify(t("userNotPermitted"), "error", 5000);
            } else if (res.responseCode === ResponseCode.UpdatingError) {
                notify(t("updatingError"), "error", 5000);
            }
            await RequestErrorHandling(res);;
        } catch (ex) {
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    function onToggleChanged(prmList: VMRoleStatus[], setPrm: React.Dispatch<React.SetStateAction<any>>, name: string, value: boolean) {
        var index = prmList.findIndex(x => x.name === name);
        let items = [...prmList];
        items[index].active = value
        setPrm(items);
    }

    function setPrmListValue(prmList: VMRoleStatus[], setPrm: React.Dispatch<React.SetStateAction<any>>, value: boolean) {
        setPrm([])
        prmList.forEach(element => {
            element.active = value;
        });
        setPrm(prmList);
    }
    function setAllPrmValues(value: boolean) {
        if (props.initData.allPermissions.tms) setPrmListValue(prmsTMS, setPrmsTMS, value);
        if (props.initData.allPermissions.fms) setPrmListValue(prmsFMS, setPrmsFMS, value);
        if (props.initData.allPermissions.tmc) setPrmListValue(prmsTMC, setPrmsTMC, value);
        if (props.initData.allPermissions.safety) setPrmListValue(prmsSafety, setPrmsSafety, value);
        if (props.initData.allPermissions.tcbOffline) setPrmListValue(prmsTCBOffline, setPrmsTCBOffline, value);
        if (props.initData.allPermissions.tcbOnline) setPrmListValue(prmsTCBOnline, setPrmsTCBOnline, value);
        if (props.initData.allPermissions.sign) setPrmListValue(prmsSign, setPrmsSign, value);
        if (props.initData.allPermissions.moduleOfModule) setPrmListValue(prmsModuleOfModule, setPrmsModuleOfModule, value);
        if (props.initData.allPermissions.trafficStudy) setPrmListValue(prmsTrafficStudy, setPrmsTrafficStudy, value);
        if (props.initData.allPermissions.maintenance) setPrmListValue(prmsMaintenance, setPrmsMaintenance, value);
        if (props.initData.allPermissions.collision) setPrmListValue(prmsCollision, setPrmsCollision, value);
        if (props.initData.allPermissions.infrastructure) setPrmListValue(prmsInfrastructure, setPrmsInfrastructure, value);
        if (props.initData.allPermissions.dashboard) setPrmListValue(prmsDashboard, setPrmsDashboard, value);
        if (props.initData.allPermissions.customerGeneral) setPrmListValue(prmsCustomerGeneral, setPrmsCustomerGeneral, value);
        if (props.initData.allPermissions.user) setPrmListValue(prmsUser, setPrmsUser, value);
        if (props.initData.allPermissions.systematicScreening) setPrmListValue(prmsSystematicScreening, setPrmsSystematicScreening, value);
        if (value === true) {
            notify(t('accessToAllPermissions'), 'success', 3000);
        }
        if (value === false) {
            notify(t('denyAllPermissions'), 'warning', 3000);
        }
    }

    function onLevelValueChange(name: string, value: string) {
        props.setInitDataUserLevel({ ...props.initDataUserLevel, [name]: value });
    }

    async function onToggleInheritChanged(value: any) {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: props.initData.isInheritFromGroup ? t("yourPermissionsWillNotBeInheritedFromRuleAnymoreAndTheyWillBeSelectedManually") : t("allYourPermissionsWillBeDeletedAndThenInheritedFromRule"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {
                    try {
                        if (activeLoading) activeLoading(true);
                        const res = (await (TesGet(AuthApiUrl() + "/api/groups/ChangeUserRoleInheritMode/" + props.initData.id, true))) as RequestResponseResult<null>
                        if (activeLoading) activeLoading(false);
                        if (res.responseCode === ResponseCode.OK) {
                            if (activeLoading) activeLoading(true);
                            props.getInitialData();
                            props.getInitialUserLevels();
                            if (activeLoading) activeLoading(false);
                            props.setInitData({ ...props.initData, isInheritFromGroup: value })
                            notify(t("dataSuccessfullyUpdated"), "success", 5000);
                        } else if (res.responseCode === ResponseCode.UserNotPermitted) {
                            notify(t("userNotPermitted"), "error", 5000);
                        } else if (res.responseCode === ResponseCode.UpdatingError) {
                            notify(t("updatingError"), "error", 5000);
                        }
                        await RequestErrorHandling(res);;
                    } catch (ex) {
                        if (activeLoading) activeLoading(false);
                        notify(t("someErrorOccurred" + ex), "error", 5000);
                    }
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();



    }
    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>

                <Form
                    colCount={4}>
                    <GroupItemForm caption={t("permissionMode")}
                        name={t("fieldServiceType")}
                        visible={true}
                        colSpan={4}
                    >
                        <Form
                            colCount={4}>
                            <SimpleItem >
                                <div>{t("inheritFromRoles")}</div>
                                <Switch
                                    style={{ marginTop: 5 }}
                                    hint={t("inactive")}
                                    value={props.initData.isInheritFromGroup}
                                    onValueChange={e => onToggleInheritChanged(e)}
                                />
                            </SimpleItem>

                        </Form>
                    </GroupItemForm>

                </Form>
                {!props.initData.isInheritFromGroup &&

                    <div className='row'>
                        <div className='rightColumn' style={{ marginTop: 15 }}>
                            <Button
                                className='tes-modal-btn-cancel'
                                style={{ marginRight: 20 }}
                                onClick={() => setAllPrmValues(false)}
                                text={t('deny')}
                            />
                            <Button
                                className='tes-modal-btn-add'
                                onClick={() => setAllPrmValues(true)}
                                text={t('allow')}
                            />
                        </div>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.tms &&
                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmTMS')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsTMS, setPrmsTMS, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsTMS, setPrmsTMS, true)}
                                    text={t('allow')}
                                />

                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsTMS && prmsTMS.map((p) => {
                                    return (
                                        <SimpleItem  >
                                            <div>{t(`permissions.${p.name}`)}</div>
                                            <Switch

                                                style={{ marginTop: 5 }}
                                                hint={t("inactive")}
                                                value={p.active}
                                                onValueChange={e => onToggleChanged(prmsTMS, setPrmsTMS, p.name, e)}
                                            />
                                        </SimpleItem>
                                    )
                                })}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.fms &&

                    <div className={design.column2}>

                        <CollapsibleCard title={t('prmFMS')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsFMS, setPrmsFMS, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsFMS, setPrmsFMS, true)}
                                    text={t('allow')}
                                />

                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsFMS && prmsFMS.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsFMS, setPrmsFMS, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.tmc &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmTMC')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsTMC, setPrmsTMC, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsTMC, setPrmsTMC, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsTMC && prmsTMC.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsTMC, setPrmsTMC, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.tcbOffline &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmTCBOffline')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsTCBOffline, setPrmsTCBOffline, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsTCBOffline, setPrmsTCBOffline, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsTCBOffline && prmsTCBOffline.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsTCBOffline, setPrmsTCBOffline, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.tcbOnline &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmTCBOnline')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsTCBOnline, setPrmsTCBOnline, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsTCBOnline, setPrmsTCBOnline, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsTCBOnline && prmsTCBOnline.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsTCBOnline, setPrmsTCBOnline, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.sign &&


                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmSign')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsSign, setPrmsSign, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsSign, setPrmsSign, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsSign && prmsSign.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsSign, setPrmsSign, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.moduleOfModule &&
                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmModuleOfModule')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsModuleOfModule, setPrmsModuleOfModule, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsModuleOfModule, setPrmsModuleOfModule, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsModuleOfModule && prmsModuleOfModule.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsModuleOfModule, setPrmsModuleOfModule, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }

                {!props.initData.isInheritFromGroup && props.initData.allPermissions.trafficStudy &&
                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmTrafficStudy')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsTrafficStudy, setPrmsTrafficStudy, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsTrafficStudy, setPrmsTrafficStudy, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsTrafficStudy && prmsTrafficStudy.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsTrafficStudy, setPrmsTrafficStudy, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.safety &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmSafety')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsSafety, setPrmsSafety, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsSafety, setPrmsSafety, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsSafety && prmsSafety.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsSafety, setPrmsSafety, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }

                {!props.initData.isInheritFromGroup && props.initData.allPermissions.maintenance &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmMaintenance')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsMaintenance, setPrmsMaintenance, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsMaintenance, setPrmsMaintenance, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsMaintenance && prmsMaintenance.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsMaintenance, setPrmsMaintenance, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.collision &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmCollision')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsCollision, setPrmsCollision, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsCollision, setPrmsCollision, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                <SimpleItem colSpan={3} >
                                    <SelectBox
                                        placeholder=""
                                        label={t('fromLevel')}
                                        valueExpr="id"
                                        displayExpr="name"
                                        labelMode='static'
                                        value={props.initDataUserLevel?.fromLevelId}
                                        onValueChange={(e) => onLevelValueChange("fromLevelId", e)}
                                        className="modalInput"
                                        items={SortObjectByPropName(props.initDataLevel, "name")}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    />
                                </SimpleItem>
                                <SimpleItem colSpan={3} >
                                    <SelectBox
                                        placeholder=""
                                        label={t('toLevel')}
                                        valueExpr="id"
                                        displayExpr="name"
                                        labelMode='static'
                                        value={props.initDataUserLevel?.toLevelId}
                                        onValueChange={(e) => onLevelValueChange("toLevelId", e)}
                                        className="modalInput"
                                        items={SortObjectByPropName(props.initDataLevel, "name")}
                                        showClearButton={true}
                                        searchEnabled={true}
                                    />
                                </SimpleItem>
                                {prmsCollision && prmsCollision.map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsCollision, setPrmsCollision, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}


                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.infrastructure &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmInfrastructure')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsInfrastructure, setPrmsInfrastructure, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsInfrastructure, setPrmsInfrastructure, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsInfrastructure && prmsInfrastructure.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsInfrastructure, setPrmsInfrastructure, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.customerGeneral &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmCustomerGeneral')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsCustomerGeneral, setPrmsCustomerGeneral, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsCustomerGeneral, setPrmsCustomerGeneral, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsCustomerGeneral && prmsCustomerGeneral.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsCustomerGeneral, setPrmsCustomerGeneral, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }
                {!props.initData.isInheritFromGroup && props.initData.allPermissions.user &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmUser')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsUser, setPrmsUser, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsUser, setPrmsUser, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsUser && prmsUser.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsUser, setPrmsUser, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }

                {!props.initData.isInheritFromGroup && props.initData.allPermissions.dashboard &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmDashboard')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsDashboard, setPrmsDashboard, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsDashboard, setPrmsDashboard, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsDashboard && prmsDashboard.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsDashboard, setPrmsDashboard, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }

                {!props.initData.isInheritFromGroup && props.initData.allPermissions.systematicScreening &&

                    <div className={design.column2}>
                        <CollapsibleCard title={t('prmSystematicScreening')} isOpen={false}>
                            <div className='rightColumn' style={{ marginTop: 15 }}>
                                <Button
                                    className='tes-modal-btn-cancel'
                                    style={{ marginRight: 20 }}
                                    onClick={() => setPrmListValue(prmsSystematicScreening, setPrmsSystematicScreening, false)}
                                    text={t('deny')}
                                />
                                <Button
                                    className='tes-modal-btn-add'
                                    onClick={() => setPrmListValue(prmsSystematicScreening, setPrmsSystematicScreening, true)}
                                    text={t('allow')}
                                />
                            </div>
                            <Form
                                colCount={switchCols}>
                                {prmsSystematicScreening && prmsSystematicScreening.map((p, i) => (
                                    <SimpleItem >
                                        <div>{t(`permissions.${p.name}`)}</div>
                                        <Switch
                                            style={{ marginTop: 5 }}
                                            hint={t("inactive")}
                                            value={p.active}
                                            onValueChange={e => onToggleChanged(prmsSystematicScreening, setPrmsSystematicScreening, p.name, e)}
                                        />
                                    </SimpleItem>
                                ))}
                            </Form>
                        </CollapsibleCard>
                    </div>
                }

            </div>
            {!props.initData.isInheritFromGroup &&
                <div className='row' style={{ marginTop: 20, padding: 10 }}>
                    <div className='rightColumn'>
                        <Button
                            className='tes-modal-btn-add saveButton'
                            onClick={onSave}
                            text={t('save')}
                        />

                    </div>
                </div>
            }
        </React.Fragment>
    );


}
export default Permissions;