import React, { useEffect, useRef, useState } from "react";
import Permission from "../../../../../components/permission/permision";
import { TMSPermissions } from "../../../../../constants/Permissions";
import { Button, DataGrid, Form, Popup, SelectBox, TextBox } from "devextreme-react";
import { Column, ColumnChooser, Export, FilterBuilderPopup, FilterPanel, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Paging, Search, SearchPanel, Button as DevExpressButton, Lookup } from "devextreme-react/data-grid";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { t } from "i18next";
import { AddNewTranslationToLanguagesDto, EditTranslationDto, GetTranslationDto } from "../../../../../types/translation/dtos/translationDtos";
import { SimpleItem } from "devextreme-react/form";
import { useParams } from "react-router-dom";
import { ILanguageParams } from "../../../../../types/translation/traslationTypes";
import notify from "devextreme/ui/notify";
import { ModalMode, ResponseCode } from "../../../../../types/general/enums/generalEnums";
import { RequestErrorHandling, TesPost, TesPut } from "../../../../../utils/rest";
import { TranslationApiUrl } from "../../../../../environment/routeSettings";
import { INameId, NameValue, RequestResponseResult } from "../../../../../types/general/generalTypes";
import { LanguageType, TranslationValueStatus } from "../../../../../types/translation/enums/translationEnums";
import { Enum2Array } from "../../../../../utils/enumTools";

interface IProps {
  isLocked: boolean;
  translations: GetTranslationDto[];
  getTranslations: () => Promise<void>;
  setDataChanged:React.Dispatch<React.SetStateAction<any>>
}

const Tags = (props: IProps) => {
  
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const [currentTag, setCurrentTag] = useState<AddNewTranslationToLanguagesDto>(new AddNewTranslationToLanguagesDto);
  const [statusList, setStatusList] = useState<NameValue[]>([]);
  const params = useParams<ILanguageParams>();

  useEffect(()=>{
    setStatusList(Enum2Array(TranslationValueStatus));
  },[])

    function onAdd() {
      setModalMode(ModalMode.Add)
      setShowModal(true)
    }
  
    function onCloseModal() {
      setShowModal(false)
      setCurrentTag(new AddNewTranslationToLanguagesDto);
    }

    function onPopupValueChange(variable:string, value: any) {
      if (variable === "key") {
        setCurrentTag({...currentTag, key: value})
      } else if (variable === "value") {
        setCurrentTag({...currentTag, translations:[{languageId: params?.languageId!, value: value, status: TranslationValueStatus.OK}]})
      } else {
        setCurrentTag({...currentTag, translations:[{languageId: params?.languageId!, value: currentTag.translations[0].value, status: value}]})
      }
    }
    
    async function onPopupAdd(newOption: boolean) {
      try {
        const res = (await (TesPost(TranslationApiUrl() + "/api/ManageTranslations/AddTranslationToBaseLanguages", currentTag, true))) as RequestResponseResult<null>
        if (res.responseCode === ResponseCode.OK) {
          props.getTranslations()
          props.setDataChanged(true)
          dataGridRef.current?.instance.refresh()
          notify(t("dataSuccessfullyAdded"), "success", 5000);
          !newOption && onCloseModal()
          newOption && setCurrentTag(new AddNewTranslationToLanguagesDto)
        } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
          notify(t("error"), "error", 5000);
        }
        await RequestErrorHandling(res);;
      } catch (ex) {
        notify(t("someErrorOccurred" + ex), "error", 5000);
      }
    }
    
    async function onPopupUpdate() {
      try {
        const putObj: EditTranslationDto = {
          languageId: params?.languageId!,
          languageType: LanguageType.Groupe,
          translations: [
            { key: selectedRowData.key,
              value: currentTag.translations[0]?.value,
              keyId: selectedRowData.keyId,
              valueId: selectedRowData.valueId,
              status: currentTag.translations[0]?.status
            }
          ]
        }
        const res = (await (TesPut(TranslationApiUrl() + "/api/ManageTranslations/EditTranslation", putObj, true))) as RequestResponseResult<null>
        if (res.responseCode === ResponseCode.OK) {
          props.getTranslations()
          props.setDataChanged(true)
          dataGridRef.current?.instance.refresh()
          notify(t("dataSuccessfullyUpdated"), "success", 5000);
          onCloseModal()
        } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
          notify(t("error"), "error", 5000);
        }
        await RequestErrorHandling(res);;
      } catch (ex) {
        notify(t("someErrorOccurred" + ex), "error", 5000);
      }
    }

    function onRowClicked(r: any) {
      setSelectedRowData(r.row.data)
      setCurrentTag({key: r.row.data.key, translations:[{languageId: params?.languageId!, value: r.row.data.value, status: r.row.data.status}]})
      setModalMode(ModalMode.Edit)
      setShowModal(true)
    }

    return (
        <Permission
        allowed={[TMSPermissions.TMS_Admin]}
          hasFeedBackElement={true}
        >
          <React.Fragment>
            <div className={"content-block"}>
              <div className={"dx-card responsive-paddings"}>
                <div className="row">
                  <div className="rightColumn" style={{margin:"-1.5rem 1rem -0.5rem 0"}}>
                    <Button
                      onClick={onAdd}
                      icon="fa-solid fa-circle-plus"
                      text={t("tag")}
                      visible={!props.isLocked}
                    />
                  </div>
                </div>
                <DataGrid
                  id="gridContainer"
                  ref={dataGridRef}
                  dataSource={props.translations}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onExporting={OnExporting}
                  // onRowClick={e => onRowClicked(e)}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} />
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <Column
                    dataField="key"
                    allowSorting={false}
                    caption={t("key")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="value"
                    allowSorting={false}
                    caption={t("value")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="status"
                    allowSorting={false}
                    caption={t("status")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup dataSource={statusList} valueExpr="value" displayExpr="name" />
                  </Column>
                  <Column
                  type="buttons"
                  caption={t("actions")}
                  width={90}
                  fixed={true}
                  fixedPosition="right"
                  visible={!props.isLocked}
                >
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={e => onRowClicked(e)}
                    icon="fa-solid fa-pencil"
                  />
                </Column>
                </DataGrid>
              </div>
            </div>

            <Popup
                width={"60%"}
                height="auto"
                visible={showModal}
                resizeEnabled={true}
                showTitle={true}
                title={modalMode === ModalMode.Add ? t("add") : t("update")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onCloseModal()}
            >
                <div>
                    <Form colCount={2}>
                        {modalMode === ModalMode.Add &&
                          <SimpleItem colSpan={1}>
                          <TextBox
                            placeholder=""
                            label={t('key')}
                            labelMode='static'
                            value={currentTag.key}
                            onValueChange={(e) => onPopupValueChange("key", e)}
                            showClearButton={true}
                          />
                        </SimpleItem>}
                        <SimpleItem colSpan={1}>
                          <TextBox
                            placeholder=""
                            label={t('value')}
                            labelMode='static'
                            value={currentTag.translations[0].value}
                            onValueChange={(e) => onPopupValueChange("value", e)}
                            showClearButton={true}
                          />
                        </SimpleItem>
                        <SimpleItem colSpan={1}>
                        <SelectBox
                          placeholder=""
                          label={t('status')}
                          valueExpr="value"
                          displayExpr="name"
                          labelMode='static'
                          value={currentTag.translations[0].status}
                          onValueChange={(e) => onPopupValueChange("status", e)}
                          items={statusList}
                          showClearButton={true}
                          searchEnabled={true}
                        >
                      </SelectBox>
                      </SimpleItem>
                    </Form>
                </div>
                <div className='rightColumn' style={{margin:"2rem -0.5rem 0 0"}}>
                    <Button
                        className='tes-modal-btn-cancel'
                        style={{ marginRight: 20 }}
                        onClick={() => onCloseModal()}
                        text={t('cancel')}
                    />
                    {modalMode === ModalMode.Add ? 
                    <Button
                      className='tes-modal-btn-add'
                      style={{ marginRight: 20 }}
                      onClick={() => onPopupAdd(false)}
                      text={t('add')}
                    />
                    :
                    <Button
                      className='tes-modal-btn-add'
                      style={{ marginRight: 20 }}
                      onClick={() => onPopupUpdate()}
                      text={t('update')}
                    />
                    }
                    {modalMode === ModalMode.Add &&
                    <Button
                        className='tes-modal-btn-add'
                        style={{ marginRight: 20 }}
                        onClick={() => onPopupAdd(true)}
                        text={t('addAndNew')}
                    />}
                </div>
            </Popup>
          </React.Fragment>
        </Permission>
      )
}

export default Tags;