import React from "react";
import { Form, RequiredRule, SimpleItem } from "devextreme-react/form";
import { useTranslation } from "react-i18next";
import { SelectBox, TextBox, ValidationGroup, Validator } from "devextreme-react";
import { ClientReport, TesReportType } from "../../../../../types/report/reportTypes";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";

// props
interface IPros {
  initData: ClientReport,
  initDataReportType: TesReportType[]
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroup>
}

const Details = (props: IPros) => {
  const { t } = useTranslation();

  function onValueChange(name: string, value: any) {
    props.setInitData({ ...props.initData, [name]: value })
    props.setDataChanged(true)
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("name")}
                labelMode="static"
                value={props.initData.name}
                onValueChange={(e) => onValueChange("name", e)}
                disabled={props.isLocked}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </TextBox>
            </SimpleItem>
            <SimpleItem colSpan={1} >
              <SelectBox
                placeholder=""
                label={t('emailTemplateType')}
                labelMode='static'
                value={props.initData.tesReportTypeId}
                onValueChange={(e) => onValueChange("tesReportTypeId", e)}
                className="modalInput"
                valueExpr={'id'}
                displayExpr={'name'}
                items={SortObjectByPropName(props.initDataReportType, "name")}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              >
                <Validator>
                  <RequiredRule message={t('fieldIsRequired')} />
                </Validator>
              </SelectBox>

            </SimpleItem>

          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};
export default Details;
