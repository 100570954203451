import { t } from "i18next";
import RangeValidator from "../../components/rangeValidator/rangeValidator";
import { useEffect, useState } from "react";
import notify from "devextreme/ui/notify";
import { TranslationApiUrl } from "../../environment/routeSettings";
import { ResponseCode } from "../../types/general/enums/generalEnums";
import { RequestErrorHandling, TesGet } from "../../utils/rest";

const Test = () => {

    const [initData, setInitData] = useState({});

    useEffect(() => {
      async function fetchMyAPI() {
        try {
          await getInitData();
          // setLstLanguageType(Enum2Array(LanguageType))
        } catch (ex) {
          notify(t("someErrorOccurred") + ex, "error", 5000);
        }
      }
      fetchMyAPI();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    
    async function getInitData() {
      try {
        const res = await TesGet(
          TranslationApiUrl() + "/api/ManageTranslations/GetTranslationOfLanguage" + "?languageId=en&languageType=0",
          true
        );
        if (res.responseCode === ResponseCode.OK) {
          setInitData(res.results);
        } else {
          await RequestErrorHandling(res);;
        }
      } catch (error) {
        console.error("Error fetching initial data:", error);
      }
    }
    
    {console.log(initData)}

    return (
      <div style={{ margin: "10rem" }}>
        TEST
      </div>
    );
};

export default Test;
