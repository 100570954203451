import React, { useEffect, useRef, useState } from "react";
import { t } from "i18next"
import { Button, Form, Popup, ScrollView } from "devextreme-react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Selection,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  Export,
  Search,
} from "devextreme-react/data-grid";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { RequestErrorHandling, TesGet, TesPost } from "../../utils/rest";
import { useAuth } from "../../contexts/auth";
import { AreaDTO } from "../../types/infrastructure/dto/locationdDto";
import { Area } from "../../types/infrastructure/infrastructureTypes";
import { InfrastructureApiUrl } from "../../environment/routeSettings";
import { useScreenSize } from "../../utils/media-query";
import { OnExporting } from "../../utils/dataGridTools";
import { RequestResponseResult } from "../../types/general/generalTypes";
import { ResponseCode } from "../../types/general/enums/generalEnums";

interface IProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<any>>
  addFromAreaPicker: (Data: AreaDTO[]) => void
}

function AreaPicker(props: IProps) {
  const [lstData, setLstData] = useState<AreaDTO[]>([]);
  const [selectedArea, setSelectedArea] = useState<AreaDTO[]>([]);
  const areaDataGridRef = useRef<DataGrid<any, any>>(null);
  const { activeLoading } = useAuth();
  const { isXLarge, is2xLarge } = useScreenSize();
  const [gridHeight, setGridHeight] = useState<number>(400)
  const [popupHeight, setPopupHeight] = useState<string>("auto")

  useEffect(() => {
    if (isXLarge) { setPopupHeight("auto"); setGridHeight(530) }
    else if (is2xLarge) { setPopupHeight("auto"); setGridHeight(900) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    props.showModal && fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.showModal]);

  async function getInitialData() {
    const postObj = {
        customerId: localStorage.getItem("selectedCustomerId"),
        divisionId: localStorage.getItem("selectedDivisionId"),
    }
    const res = await TesPost(InfrastructureApiUrl() + "/api/areas",
    postObj
    ,true) as RequestResponseResult<Area[]>
    if (res.responseCode === ResponseCode.OK) {
      setLstData(res.results)
    }  else {
        await RequestErrorHandling(res);
    }
  }

  function onAdd() {
    props.setShowModal(false)
    props.addFromAreaPicker(selectedArea)
  }

  function Area2AreaDTO(e: Area[]) {
    const newArr = e.map((x) => (
      {
        id: x.id,
        customerId: x.customerId,
        name: x.name,
        userId: x.userId,
        locations: x.locations,
        locationCount:x.locationCount
      }
    ))
    setSelectedArea(newArr)
  }

  return (
    // <Permission
    //   allowed={[
    //     InfrastructurePermissions.Infrastructure_D_Site,
    //     InfrastructurePermissions.Infrastructure_R_Site,
    //     InfrastructurePermissions.Infrastructure_V_Site,
    //   ]}
    //   hasFeedBackElement={true}
    // >
    <React.Fragment>
      {props.showModal && <Popup
        width={"80%"}
        height={popupHeight}
        visible={props.showModal}
        resizeEnabled={true}
        showTitle={true}
        title={t("assign")}
        hideOnOutsideClick={false}
        showCloseButton={true}
        onHiding={() => props.setShowModal(false)}
      >
        <ScrollView width="100%" height="100%">
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <DataGrid
                id="gridContainer"
                ref={areaDataGridRef}
                dataSource={lstData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onSelectedRowKeysChange={e => Area2AreaDTO(e)}
                height={gridHeight}
                onExporting={OnExporting}
                //onContentReady={e => {e.component.deselectAll()}}
                //onFilterValueChange={e => {areaDataGridRef?.current?.instance.deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={'allPages'}
                  showCheckBoxesMode={'always'}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="name" allowSorting={false} caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </SimpleItem>
          </Form>
          <div>
            <div className="rightColumn" style={{ margin: "0.5rem 0.5rem 0 0" }}>
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => props.setShowModal(false)}
                text={t("cancel")}
              />
              <Button
                className="tes-modal-btn-add"
                onClick={onAdd}
                text={t("assign")}
              />
            </div>
          </div>
        </ScrollView>
      </Popup>}
    </React.Fragment>
    // </Permission>
  )
}

export default AreaPicker;
