//imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  TotalItem,
  Summary,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  StateStoring,
  GroupItem,
  //Button as DevExpressButton,
  Export,
  SortByGroupSummaryInfo,
  Search,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import { TesGet } from "../../../utils/rest";
import { GridType } from "../../../types/general/enums/generalEnums";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import { useNavigate } from "react-router-dom";
import Permission from "../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { InfrastructureApiUrl } from "../../../environment/routeSettings";
// import "./area.scss";
//import { Area } from "../../../types/infrastructure/infrastructureTypes";
import notify from "devextreme/ui/notify";
import { OnExporting } from "../../../utils/dataGridTools";
import { ContextMenu, Lookup } from "devextreme-react";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import getTitle from "../../../components/title/getTitle";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import { useClientSetting } from "../../../contexts/clientSetting";

const TrafficSection = () => {
  const [initData, setInitData] = useState({});
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const { generalSetting, customerDivisions } = useClientSetting();

  const title = getTitle('/infrastructure/trafficSection', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //Get InitData
  async function getInitData() {
    setInitData(
      await TesGet(
        InfrastructureApiUrl() +
        "/api/TrafficSections/GetCustomerTrafficSections/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  const navigateToTrafficSectionDetails = useCallback(() => {
    history("/infrastructure/trafficSectionDetails/AddNew");
  }, [history]);

  // function onEdit(d: any) {
  //   let newData = new Area();
  //   Object.assign(newData, d.row.data);
  // }

  // async function onDelete(d: any) {
  //   let myDialog = custom({
  //     title: t("deleteData"),
  //     messageHtml: t("areYouSureWantToDelete?"),
  //     buttons: [
  //       {
  //         text: t("yes"),
  //         onClick: async (e) => {
  //           try {
  //             await TesDelete(
  //               AuthApiUrl() + "/api/Customers/" + d.row.data.id,
  //               true
  //             );
  //             await getInitData();
  //             notify(t("dataSuccessfullyDeleted"), "success", 5000);
  //           } catch {
  //             notify(t("someErrorOccurred"), "error", 5000);
  //           }

  //           return { buttonText: e.component.option("text") };
  //         },
  //       },
  //       {
  //         text: t("no"),
  //         onClick: (e) => {
  //           return { buttonText: e.component.option("text") };
  //         },
  //       },
  //     ],
  //   });
  //   myDialog.show();
  // }

  function onRowClick(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;

    if (isCtrlKeyPressed) {
      window.open(`/infrastructure/trafficSectionDetails/${e.data.id}`, "_blank");
    } else {
      history(`/infrastructure/trafficSectionDetails/${e.data.id}`);
    }
  }

  return (
    <Permission allowed={[InfrastructurePermissions.Infrastructure_D_Area]} hasFeedBackElement={true}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, "/infrastructure/trafficSectionDetails/")}
          />
          <h2 className={"content-block"}>{t("trafficSection")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={navigateToTrafficSectionDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("add")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={onRowClick}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <StateStoring
                  enabled={true}
                  type="localStorage"
                  savingTimeout={500}
                />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                    dataField="DivisionId"
                    allowSorting={true}
                    visible={false}
                    caption={t("division")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(customerDivisions!, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
    </Permission>
  );
};
export default TrafficSection;
