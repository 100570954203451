import React, { useEffect, useRef, useState } from "react";
import { Form, RequiredRule, SimpleItem } from "devextreme-react/form";
import { useTranslation } from "react-i18next";
import { Button, DataGrid, ValidationGroup, Validator, ScrollView, Popup, SelectBox } from "devextreme-react";
import { NameValue } from "../../../../../types/general/generalTypes";
import { Column, ColumnChooser, Export, FilterBuilderPopup, FilterPanel, FilterRow, Grouping, GroupPanel, HeaderFilter, Pager, Paging, SearchPanel, Button as DevExpressButton, Search } from "devextreme-react/data-grid";
import { ModalMode } from "../../../../../types/general/enums/generalEnums";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../../../../contexts/auth";
import { ClientReport, ReportLocation } from "../../../../../types/report/reportTypes";
import reportLocations from "../../../../../constants/reportLocations";
import { v4 as uuid } from 'uuid';
import { custom } from "devextreme/ui/dialog";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";

// props
interface IPros {
  initData: ClientReport,
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroup>
}

const ReportLocations = (props: IPros) => {
  const { t } = useTranslation();
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [selectedData, setSelectedData] = useState<ReportLocation>(new ReportLocation());
  const [lstTesLocation, setTesLocation] = useState<NameValue[]>([])
  const validationRef = useRef<ValidationGroup>(null);
  const { activeLoading } = useAuth();

  useEffect(() => {
    var lstData = Object.keys(reportLocations)
    var res: NameValue[] = [];
    lstData.forEach(element => {
      res.push({
        name: element,
        value: element
      })
    });
    setTesLocation(res);
  }, []);

  function onValueChange(name: string, value: any) {
    setSelectedData({ ...selectedData, [name]: value })
    props.setDataChanged(true)
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new ReportLocation());
    } else {
      setSelectedData(new ReportLocation());
      setShowModal(false);
    }
  }

  function onAdd() {
    try {
      const validationRes = validationRef.current?.instance.validate();
      if (validationRes?.isValid) {
        if (props.initData.reportLocations.some(x => x.name === selectedData.name)) {
          notify(t("dataAlreadyExist"), "error", 5000); return
        }
        selectedData.id = uuid();
        selectedData.clientReportId = props.initData.id;
        props.setInitData({ ...props.initData, reportLocations: [...props.initData.reportLocations, selectedData] })
        dataGridRef.current?.instance.refresh()
        onCloseModal()
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }

  }

  function onDelete(d: any) {

    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [{
        text: t("yes"),
        onClick: async (e) => {
          props.setInitData({ ...props.initData, reportLocations: [...props.initData.reportLocations.splice(d.row.dataIndex, 1)] })
          props.setInitData(props.initData)
          return { buttonText: e.component.option("text") }
        }
      },
      {
        text: t("no"),
        onClick: (e) => {
          return { buttonText: e.component.option("text") }
        }
      },]
    });
    myDialog.show();

  }

  return (
    <React.Fragment>
      <h2 className={"content-block"}>{t("pages")}</h2>
      <div className={"content-block"}>
        <div className={"dx-card responsive-paddings"}>
          <div className="row">
            <div className="rightColumn">
              <Button
                onClick={onNew}
                icon="fa-solid fa-circle-plus"
                text={t("location")}
                disabled={props.isLocked}
              />
            </div>
          </div>
          <ValidationGroup ref={props.validationRef}>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initData.reportLocations}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              style={{ margin: "0 1rem" }}
              onExporting={OnExporting}
              disabled={props.isLocked}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} /> {/* or "auto" */}
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column dataField="id" caption={t("id")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="name" caption={t("name")} visible={true}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column dataField="clientReportId" caption={t("reportId")} visible={false}>
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>

              <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </ValidationGroup>
        </div>
      </div>

      <Popup
        width={"50%"}
        height={"auto"}
        visible={showModal}
        resizeEnabled={true}
        showTitle={true}
        title={modalMode === ModalMode.Add ? t("add") : t("update")}
        showCloseButton={true}
        onHiding={() => onCloseModal()}
      >
        <ScrollView width="100%" height="100%">
          <ValidationGroup ref={validationRef}>
            <Form colCount={2}>

              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("name")}
                  labelMode="static"
                  value={selectedData.name}
                  onValueChange={(e) => onValueChange("name", e)}
                  className="modalInput"
                  valueExpr="value"
                  displayExpr="name"
                  items={SortObjectByPropName(lstTesLocation, "name")}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>

            </Form>
          </ValidationGroup>
          <div>
            <div className="rightColumn">
              <Button
                className="tes-modal-btn-cancel"
                style={{ marginRight: 20 }}
                onClick={() => onCloseModal()}
                text={t("cancel")}
              />
              <Button
                className="tes-modal-btn-add"
                onClick={onAdd}
                text={t("add")}
              />
            </div>
          </div>
        </ScrollView>
      </Popup>
    </React.Fragment>
  );
};
export default ReportLocations;
