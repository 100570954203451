import { ScrollView } from 'devextreme-react';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Selection,
    Pager, Paging, Grouping, GroupPanel, Button as DevExpressButton, Export, Search
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { Button } from 'devextreme-react/button';
import { t } from 'i18next';
import React, { useEffect, useRef, useState } from 'react';
import notify from 'devextreme/ui/notify';
import { custom } from 'devextreme/ui/dialog';
import { VMFindUser } from '../../../../../types/identity/dto/identityDTO';
import { TesGet } from '../../../../../utils/rest';
import { AuthApiUrl } from '../../../../../environment/routeSettings';
import { useScreenSize } from "../../../../../utils/media-query";
import { OnExporting } from '../../../../../utils/dataGridTools';


// props
interface IPros {
    initData: VMFindUser[] | undefined,
    addUsers: (data: VMFindUser[]) => void;
    removeUser: (userId: string) => void;
    // updateGisLayer: (selectedData: Partial<GISLayerSetting>) => void;
}

const Users = (props: IPros) => {
    const [showModal, setShowModal] = useState(false);
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const dataGridModalRef = useRef<DataGrid<any, any>>(null);
    const [initDataModal, setInitDataModal] = useState<VMFindUser[]>();
    const { isXLarge, is2xLarge } = useScreenSize();
    const [gridHeight, setGridHeight] = useState<number>(420)
    const [popupHeight, setPopupHeight] = useState<string>("85%")

    useEffect(() => {
        if (isXLarge) { setPopupHeight("80%"); setGridHeight(530) }
        else if (is2xLarge) { setPopupHeight("60%"); setGridHeight(1020) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    //modal functions 
    function onCloseModal() {
        setShowModal(false);
    }
    //modal mode functions 
    async function onNew() {
        var getRes = await TesGet(AuthApiUrl() + "/api/user/finduser", true) as VMFindUser[];
        setInitDataModal(
            getRes.filter(x =>
                x.defaultCustomerId === localStorage.getItem('selectedCustomerId')
                && !props.initData?.map(a => a.id).includes(x.id)
            ));
        setShowModal(true);
    }




    async function onAdd() {
        try {
            var selectedData = initDataModal?.filter(x => dataGridModalRef.current?.instance.getSelectedRowKeys().map(a => a.id).includes(x.id));

            if (props.initData !== undefined && selectedData !== undefined) {
                var res = [...props.initData, ...selectedData] as VMFindUser[]
                await props.addUsers(res);
            }
            onCloseModal();
            dataGridRef.current?.instance.refresh();
            notify(t("dataSuccessfullyAdded"), "success", 5000);
        } catch {
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }

    //delete functions 
    async function onDelete(d: any) {
        let myDialog = custom({
            title: t("deleteData"),
            messageHtml: t("areYouSureWantToDelete?"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {
                    try {
                        props.removeUser(d.row.data.id)
                        dataGridRef.current?.instance.refresh();

                        notify(t("dataSuccessfullyDeleted"), "success", 5000);

                    } catch {
                        notify(t("someErrorOccurred"), "error", 5000);
                    }

                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }

    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <div className="row">
                    <div className='rightColumn'>
                        <Button
                            text={t('add')}
                            onClick={onNew}
                            icon="fa-solid fa-circle-plus"
                        />
                    </div>
                </div>
                <DataGrid id="gridContainer"
                    ref={dataGridRef}
                    dataSource={props.initData}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    style={{ height: '100%', margin: "0 1rem" }}
                    hoverStateEnabled={true}
                    remoteOperations={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onExporting={OnExporting}
                    //onContentReady={e => {e.component.deselectAll()}}
                    //onFilterValueChange={e => {dataGridRef?.current?.instance.deselectAll()}}
                >
                    <Export enabled={true}
                        allowExportSelectedData={true}
                    />
                    <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                    <GroupPanel visible={true} /> {/* or "auto" */}

                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                    />

                    <Selection
                        mode="multiple"
                        selectAllMode={'allPages'}
                        showCheckBoxesMode={'always'}
                    />
                    <FilterRow visible={true}
                        applyFilter="auto" />

                    <HeaderFilter visible={true} />


                    <SearchPanel visible={true}
                        width={285}
                        placeholder={t("search...")} />
                    <ColumnChooser width={350} height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                    >
                        <Search enabled />
                    </ColumnChooser>
                    <Column dataField="userName"
                        caption={t('username')}
                        defaultSortOrder="asc"
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="email"
                        caption={t('email')}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="firstName"
                        caption={t('firstName')}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="lastName"
                        caption={t('lastName')}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="defaultCustomerName"
                        caption={t('client')}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>

                    <Column dataType="boolean" dataField="isActive"
                        caption={t('isActive')}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">

                        <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                        </DevExpressButton>
                    </Column>
                </DataGrid>
            </div>

            <Popup
                width={"70%"}
                height={popupHeight}
                visible={showModal}
                resizeEnabled={true}
                showTitle={true}
                title={t('add')}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onCloseModal()}
            >
                <div style={{ height: "90%" }}>
                    <ScrollView width='100%' height='100%'>
                        <DataGrid id="gridContainer"
                            ref={dataGridModalRef}
                            dataSource={initDataModal}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            style={{ height: '100%' }}
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            height={gridHeight}
                            onExporting={OnExporting}
                            //onContentReady={e => {e.component.deselectAll()}}
                            //onFilterValueChange={e => {dataGridModalRef?.current?.instance.deselectAll()}}
                        >
                            <Export enabled={true}
                                allowExportSelectedData={true}
                            />
                            <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                            <GroupPanel visible={true} /> {/* or "auto" */}

                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />

                            <Selection
                                mode="multiple"
                                selectAllMode={'allPages'}
                                showCheckBoxesMode={'always'}
                            />
                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />


                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />
                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>
                            <Column dataField="userName"
                                caption={t('username')}
                                defaultSortOrder="asc"
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="email"
                                caption={t('email')}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="firstName"
                                caption={t('firstName')}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="lastName"
                                caption={t('lastName')}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="defaultCustomerName"
                                caption={t('client')}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column dataType="boolean" dataField="isActive"
                                caption={t('isActive')}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">

                                <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                                </DevExpressButton>
                            </Column>
                        </DataGrid>

                    </ScrollView>
                </div>
                <div className='rightColumn'>
                    <Button
                        className='tes-modal-btn-cancel'
                        style={{ marginRight: 20 }}
                        onClick={() => onCloseModal()}
                        text={t('cancel')}
                    />
                    <Button
                        className='tes-modal-btn-add'
                        onClick={onAdd}
                        text={t('add')}
                    />
                </div>
            </Popup>
        </React.Fragment>
    );


}
export default Users;