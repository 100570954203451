import { Form, SelectBox, Switch, TextBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import { t } from "i18next";
import React from "react";
import Permission from "../../../../../components/permission/permision";
import { TMSPermissions } from "../../../../../constants/Permissions";
import { LanguageDetailsDto } from "../../../../../types/translation/dtos/translationDtos";
import { INameId, NameValue } from "../../../../../types/general/generalTypes";
import { ILanguageParams } from "../../../../../types/translation/traslationTypes";

interface IProps {
    isLocked: boolean;
    selectedLang: LanguageDetailsDto;
    setSelectedLang: React.Dispatch<React.SetStateAction<any>>
    setDataChanged: React.Dispatch<React.SetStateAction<any>>
    baseLanguagesList: NameValue[]
    params: Readonly<Partial<ILanguageParams>>
    initDataClient: INameId[]
    // selectedCustomerId: string;
    // setSelectedCustomerId: React.Dispatch<React.SetStateAction<any>>
}

const Details = (props: IProps) => {
  
  function onValueChanged(key: string, value: any) {
    props.setSelectedLang({...props.selectedLang, [key]:value})
    props.setDataChanged(true)
  }
  
  console.log(props.baseLanguagesList)
  return (
        <Permission
        allowed={[TMSPermissions.TMS_Admin]}
          hasFeedBackElement={true}
        >
          <React.Fragment>
            <div className="row">
                <Form colCount={2}>
                    <SimpleItem colSpan={1}>
                        <TextBox
                          label={t("name")}
                          labelMode="static"
                          value={props.selectedLang?.languageName}
                          onValueChange={(e) => onValueChanged("languageName", e)}
                          className="modalInput"
                          disabled={props.isLocked}
                        />
                     </SimpleItem>
                     <SimpleItem colSpan={1}>
                      <TextBox
                        label={t("languageCode")}
                        labelMode="static"
                        value={props.selectedLang?.languageCode}
                        onValueChange={(e) => onValueChanged("languageCode", e)}
                        className="modalInput"
                        disabled={props.isLocked}
                      />
                     </SimpleItem>
                     <SimpleItem colSpan={1}>
                      {props.params.languageId === "AddNew" ?
                      <SelectBox
                        placeholder=""
                        label={t("parentLanguage")}
                        labelMode="static"
                        onValueChange={(e) => onValueChanged("parentLanguageId", e)}
                        items={props.baseLanguagesList!}
                        valueExpr="value"
                        displayExpr="name"
                        showClearButton={true}
                        searchEnabled={true}
                        style={{margin:"-2rem 0 1rem 0"}}
                      />
                      :
                      <SelectBox
                        placeholder={props.selectedLang.parentLanguageName}
                        label={t("parentLanguage")}
                        labelMode="static"
                        style={{margin:"-2rem 0 1rem 0"}}
                        disabled={true}
                      />
                      }
                    </SimpleItem>
                     <SimpleItem colSpan={1}>
                         <TextBox
                          label={t("imageUrl")}
                          labelMode="static"
                          value={props.selectedLang?.imageAddress}
                          onValueChange={(e) => onValueChanged("imageAddress", e)}
                          className="modalInput"
                          disabled={props.isLocked}
                          style={{margin:"-2rem 0 1rem 0"}}
                        />
                    </SimpleItem> 
                     <SimpleItem colSpan={1}>
                         <TextBox
                          label={t("description")}
                          labelMode="static"
                          value={props.selectedLang?.description}
                          onValueChange={(e) => onValueChanged("description", e)}
                          className="modalInput"
                          disabled={props.isLocked}
                          style={{margin:"-2rem 0 1rem 0"}}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                      <SelectBox
                          style={{marginTop:"-2rem"}}
                          placeholder=""
                          label={t('customer')}
                          valueExpr="id"
                          displayExpr="name"
                          labelMode='static'
                          value={props.selectedLang?.customerId}
                          onValueChange={(e) => onValueChanged("customerId", e)}
                          items={props.initDataClient}
                          showClearButton={true}
                          searchEnabled={true}
                          disabled={props.isLocked}
                      >
                      </SelectBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1} >
                      <div style={{margin:"-1.5rem 0 1rem 0"}}>{t("isDefault")}</div>
                      <Switch
                          style={{marginTop:"-1.5rem"}}
                          value={props.selectedLang?.isDefault}
                          onValueChange={e => onValueChanged("isDefault", e)}
                          disabled={props.isLocked}
                      />
                    </SimpleItem> 
                </Form>
            </div>
          </React.Fragment>
        </Permission>
      )
}

export default Details;