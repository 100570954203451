import React, { useEffect, useRef, useState } from "react"
import Permission from "../../../../../components/permission/permision"
import { TMSPermissions } from "../../../../../constants/Permissions"
import { Button, DataGrid, Form, Popup, SelectBox, Switch, TextBox } from "devextreme-react"
import { Column, Export, FilterBuilderPopup, FilterPanel, FilterRow, GroupPanel, Grouping, HeaderFilter, Lookup, Paging, Search, SearchPanel, Button as DevExpressButton } from "devextreme-react/data-grid"
import { Pager } from "devextreme-react/tree-list"
import { t } from "i18next"
import { INameId } from "../../../../../types/general/generalTypes"
import { SimpleItem } from "devextreme-react/form"
import { ModalMode, ResponseCode } from "../../../../../types/general/enums/generalEnums"
import { AddExistsLanguageToCustomerDto, CustomerLanguageDto } from "../../../../../types/translation/dtos/translationDtos"
import { ILanguageParams } from "../../../../../types/translation/traslationTypes"
import { LanguageType } from "../../../../../types/translation/enums/translationEnums"
import { TranslationApiUrl } from "../../../../../environment/routeSettings"
import { RequestErrorHandling, TesPut } from "../../../../../utils/rest"
import notify from "devextreme/ui/notify"

interface IProps {
  isLocked: boolean;
  customerIdList: CustomerLanguageDto[];
  initDataClient: INameId[];
  params: Readonly<Partial<ILanguageParams>>
  getCustomersIdList: () => Promise<void>
}


const Customers = (props: IProps) => {
  
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [currentCustomer, setCurrentCustomer] = useState<AddExistsLanguageToCustomerDto>({...new AddExistsLanguageToCustomerDto, languageId: props.params?.languageId!, languageType: LanguageType.Groupe});

  function onAdd() {
    setModalMode(ModalMode.Add)
    setShowModal(true)
  }
  
  function onUpdate(e: any) {
    setShowModal(true)
    setModalMode(ModalMode.Edit)
    setCurrentCustomer({...currentCustomer, customerId: e.row.data.customerId, isDefault: e.row.data.isDefault})
  }
  
  function onCloseModal() {
    setShowModal(false)
    setCurrentCustomer({...new AddExistsLanguageToCustomerDto, languageId: props.params?.languageId!, languageType: LanguageType.Groupe});
  }

  function onValueChanged(key: string, value: any) {
    setCurrentCustomer({...currentCustomer, [key]:value})
  }

  async function onPopupAdd() {
    try {
      const res = (await (TesPut(TranslationApiUrl() + "/api/ManageCustomerLanguages/AddLanguageToCustomer", currentCustomer, true)))
      if (res.responseCode === ResponseCode.OK) {
        props.getCustomersIdList()
        dataGridRef.current?.instance.refresh()
        onCloseModal()
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
        notify(t("customerNotPermitted"), "error", 5000);
      }
      await RequestErrorHandling(res);;
    } catch (ex) {
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }
  
  async function onPopupUpdate() {
    try {
      const res = (await (TesPut(TranslationApiUrl() + "/api/ManageCustomerLanguages/SetDefaultLanguageForCustomer",
            {
              customerId: currentCustomer.customerId,
              isDefault: currentCustomer.isDefault,
              languageType: LanguageType.Groupe,
              languageId: currentCustomer.languageId
            },
              true)))
        if (res.responseCode === ResponseCode.OK) {
        props.getCustomersIdList()
        dataGridRef.current?.instance.refresh()
        onCloseModal()
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
        notify(t("customerNotPermitted"), "error", 5000);
      }
      await RequestErrorHandling(res);;
    } catch (ex) {
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

    return (
        <Permission
        allowed={[TMSPermissions.TMS_Admin]}
          hasFeedBackElement={true}
        >
          <React.Fragment>
            <div className={"content-block"}>
              <div className={"dx-card responsive-paddings"}>
              {!props.isLocked &&
              <div className="row">
                <div className="rightColumn" style={{margin:"-1.5rem 1rem -0.5rem 0"}}>
                  <Button
                    onClick={onAdd}
                    icon="fa-solid fa-circle-plus"
                    text={t("customer")}
                  />
                </div>
              </div>}
                <DataGrid
                  id="gridContainer"
                  ref={dataGridRef}
                  dataSource={props.customerIdList}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  //onExporting={OnExporting}
                  // onRowClick={e => onUpdate(e)}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} />
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                  <Column dataField="customerId"
                    caption={t('customer')}
                  >
                    <HeaderFilter>
                        <Search enabled />
                    </HeaderFilter>
                    <Lookup dataSource={props.initDataClient} valueExpr="id" displayExpr="name" />
                  </Column>
                  <Column dataField="isDefault" caption={t("isDefault")}>
                    <HeaderFilter>
                        <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                      type="buttons"
                      caption={t("actions")}
                      width={90}
                      fixed={true}
                      fixedPosition="right"
                      visible={!props.isLocked}
                      >
                        <DevExpressButton
                          hint={t("edit")}
                          onClick={e => onUpdate(e)}
                          icon="fa-solid fa-pencil"
                        />
                      </Column>
                </DataGrid>
              </div>
            </div>

            <Popup
                width={"60%"}
                height="auto"
                visible={showModal}
                resizeEnabled={true}
                showTitle={true}
                title={modalMode === ModalMode.Add ? t("add") : t("update")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onCloseModal()}
            >
                <div>
                  <Form colCount={2}>
                      <SimpleItem colSpan={1}>
                        <SelectBox
                          style={{marginTop:"-1rem"}}
                          placeholder=""
                          label={t('customer')}
                          valueExpr="id"
                          displayExpr="name"
                          labelMode='static'
                          value={currentCustomer?.customerId}
                          onValueChange={e => onValueChanged("customerId", e)}
                          items={props.initDataClient}
                          showClearButton={true}
                          searchEnabled={true}
                          disabled={modalMode === ModalMode.Edit ? true : false}
                        >
                      </SelectBox>
                      </SimpleItem>
                      <SimpleItem colSpan={1} >
                      <div style={{margin:"-0.5rem 0 1rem 0"}}>{t("isDefault")}</div>
                        <Switch
                          style={{marginTop:"-1.5rem"}}
                          value={currentCustomer?.isDefault}
                          onValueChange={e => onValueChanged("isDefault", e)}
                        />
                    </SimpleItem> 
                  </Form>
                </div>
                <div className='rightColumn' style={{margin:"2rem -0.5rem 0 0"}}>
                    <Button
                        className='tes-modal-btn-cancel'
                        style={{ marginRight: 20 }}
                        onClick={() => onCloseModal()}
                        text={t('cancel')}
                    />
                    {modalMode === ModalMode.Add ? 
                    <Button
                      className='tes-modal-btn-add'
                      style={{ marginRight: 20 }}
                      onClick={() => onPopupAdd()}
                      text={t('add')}
                    />
                    :
                    <Button
                      className='tes-modal-btn-add'
                      style={{ marginRight: 20 }}
                      onClick={() => onPopupUpdate()}
                      text={t('update')}
                    />
                    }
                </div>
            </Popup>
            
          </React.Fragment>
        </Permission>
      )
}

export default Customers