import { withNavigationWatcher } from "./contexts/navigation";
import {
  AADTDetailsPage,
  AADTPatternDetailsPage,
  AADTPatternsPage,
  AADTPredictionDetailsPage,
  AADTPredictionGeneralSettingPage,
  AADTPredictionPage,
  AddUserPage,
  AllStudiesPage,
  AllWayStopWarrantSettingsPage,
  AppVersionsPage,
  ApplicationVersionsPage,
  BaseLanguageDetailsPage,
  BaseLanguagesPage,
  ClientDetailsPage,
  ClientEnvironmentsPage,
  ClientReportDetailsPage,
  ClientReportPage,
  ClientsPage,
  CodeGroupsPage,
  CollisionCodeGroupsPage,
  CollisionCostPage,
  CollisionDashboardPage,
  CollisionDetailsPage,
  CollisionFieldsPage,
  CollisionGeoCodingPage,
  CollisionTesFieldsPage,
  CollisionXMLImportSettingsPage,
  CollisionsPage,
  ConfirmationLevelsPage,
  CopySettingsPage,
  CountDurationsPage,
  CustomerDetailsPage,
  CustomerLanguageDetailsPage,
  CustomerLanguagesPage,
  CustomerPage,
  DataDetailsPage,
  DisplayDataPage,
  DivisionDetailsPage,
  DivisionsPage,
  DraftsPage,
  DriversPage,
  EmailTemplatesPage,
  EmpiricalBayesSetupsPage,
  EquipmentPage,
  ExportPage,
  FieldDataSourceDetailsPage,
  FieldDataSourcePage,
  FieldsPage,
  GISSummaryMapPage,
  GeoCodeGroupDetailsPage,
  GeoCodeGroupsPage,
  GeoCodingPage,
  GroupDetailsPage,
  GroupsPage,
  HomePage,
  HospitalizationDetailsPage,
  HospitalizationPage,
  ImportLogs,
  ImportTrafficStudyDataPage,
  IntersectionAADTsPage,
  IntersectionDetailsPage,
  IntersectionTesFieldsPage,
  IntersectionsPage,
  JobDetailsPage,
  JobStatusesPage,
  JobTypesPage,
  JobsPage,
  KeysPage,
  LoadDataPage,
  LogsPage,
  MMSDetailsPage,
  MMSPage,
  ModuleCodeGroupsPage,
  ModuleDataPage,
  ModuleDetailsPage,
  ModuleFieldsPage,
  ModuleOfModulesPage,
  MultiClientReportingPage,
  MunicipalityPopulationsPage,
  MyStoragePage,
  NetworkScreeningEBPage,
  NumetricConvertorPage,
  PassengersPage,
  PatientsPage,
  PatrolAreaPage,
  PedestriansPage,
  PeoplePage,
  PermanentCountStationDetailsPage,
  PermanentCountStationPage,
  PersonnelPage,
  ProfilePage,
  ProjectDetailsPage,
  ProjectsPage,
  ReportingPeriodsPage,
  RoadSegmentAADTsPage,
  RoadSegmentDetailsPage,
  RoadSegmentPage,
  RoadSegmentTesFieldsPage,
  RoleDetailsPage,
  RolesPage,
  SPFFUNCTIONSPage,
  SignCodePage,
  SignConditionPage,
  SignDescriptionPage,
  SignDetailsPage,
  SignDimensionPage,
  SignFaceMaterialPage,
  SignFacingDirectionPage,
  SignLocationTypePage,
  SignReflectiveCoatingPage,
  SignReflectiveRatingPage,
  SignTypePage,
  SignalWarrantAnalysisDetailsPage,
  SignalWarrantPredictionAnalysisPage,
  SignalWarrantSettingsPage,
  SignalWarrantSetupPage,
  SignsPage,
  SimplifiedCollisionPage,
  SiteListDetailsPage,
  SiteListPage,
  SiteSubTypeDetailsPage,
  SiteTypeDetailsPage,
  SiteTypesPage,
  SpeedPage,
  StreetsPage,
  StudyDetailsPage,
  SupportCodePage,
  SupportConditionPage,
  SupportDescriptionPage,
  SupportDetailsPage,
  SupportLocationTypePage,
  SupportMaterialPage,
  SupportPositionPage,
  SupportTypePage,
  SupportsPage,
  SwitchClientPage,
  SyncJobPage,
  TESSyncPage,
  TMCPage,
  TagsPage,
  TesReportTypePage,
  TestPage,
  TestSort,
  TrafficCounterDetailsPage,
  TrafficCountersPage,
  TrafficSectionDetailsPage,
  TrafficSectionPage,
  UserDetailsPage,
  UsersPage,
  ValidationRulesPage,
  // LocationsPage,
  VehiclesPage,
  VolumePage,
  WindowsAppKeyPage,
  WorkBooksPage,
  WorkbookDetailsPage,
  XLImportSettingDetailsPage,
  XMLImportLogDetailsPage,
  XMLImportLogsPage,
  XMLImportPage,
  XMLRedactionDetailsPage,
  XMLRedactionPage,
} from "./pages";

const routes = [
  {
    path: "/display-data",
    element: DisplayDataPage,
  },
  {
    path: "/profile",
    element: ProfilePage,
  },

  //#region General
  {
    path: "/home",
    element: HomePage,
  },
  {
    path: "/dashboard/transportationSafety",
    element: CollisionDashboardPage,
  },
  {
    path: "/dashboard/trafficOperation",
    element: GISSummaryMapPage,
  },
  {
    path: "/switchClient",
    element: SwitchClientPage,
  },
  //#endregion General

  //#region Infrastructure
  // {
  //   path: "/infrastructure/locations",
  //   element: LocationsPage,
  // },
  {
    path: "/infrastructure/intersections",
    element: IntersectionsPage,
  },
  // {
  //   path: "/infrastructure/map",
  //   element: InfrastructureMapPage,
  // },
  {
    path: "/infrastructure/roadSegments",
    element: RoadSegmentPage,
  },
  {
    path: "/infrastructure/streets",
    element: StreetsPage,
  },
  {
    path: "/infrastructure/siteList",
    element: SiteListPage,
  },
  {
    path: "/infrastructure/permanentCountStation",
    element: PermanentCountStationPage,
  },
  {
    path: "/infrastructure/permanentCountStationDetails/:stationId",
    element: PermanentCountStationDetailsPage,
  },
  {
    path: "/infrastructure/trafficSection",
    element: TrafficSectionPage,
  },
  {
    path: "/infrastructure/trafficSectionDetails/:sectionId",
    element: TrafficSectionDetailsPage,
  },
  {
    path: "/infrastructure/SiteListDetails/:areaId",
    element: SiteListDetailsPage,
  },
  {
    path: "/infrastructure/intersectionDetails/:intersectionId",
    element: IntersectionDetailsPage,
  },
  {
    path: "/infrastructure/roadSegmentDetails/:roadSegmentId",
    element: RoadSegmentDetailsPage,
  },
  //#region  InfrastructureSettings
  {
    path: "/infrastructure/settings/fields",
    element: FieldsPage,
  },
  {
    path: "/infrastructure/settings/codeGroups",
    element: CodeGroupsPage,
  },
  {
    path: "/infrastructure/settings/intersectionTesFields",
    element: IntersectionTesFieldsPage,
  },
  {
    path: "/infrastructure/settings/roadSegmentTesFields",
    element: RoadSegmentTesFieldsPage,
  },
  {
    path: "/infrastructure/settings/municipalityPopulation",
    element: MunicipalityPopulationsPage,
  },
  {
    path: "/infrastructure/updateImport/fieldDataSource",
    element: FieldDataSourcePage,
  },
  {
    path: "/infrastructure/updateImport/fieldDataSourceDetails/:fieldDataSourceId",
    element: FieldDataSourceDetailsPage,
  },
  {
    path: "/infrastructure/updateImport/loadData",
    element: LoadDataPage,
  },
  {
    path: "/infrastructure/tools/mms",
    element: MMSPage,
  },
  {
    path: "/infrastructure/tools/mmsDetails/:mmsId",
    element: MMSDetailsPage,
  },
  //#endregion infrastructureSettings
  //#endregion Infrastructure

  //#region SignInventory
  {
    path: "/sign/signs",
    element: SignsPage,
  },
  {
    path: "/sign/supports",
    element: SupportsPage,
  },
  {
    path: "/sign/supportDetails/:supportId",
    element: SupportDetailsPage,
  },
  {
    path: "/sign/signDetails/:signId",
    element: SignDetailsPage,
  },
  {
    path: "/sign/patrolArea",
    element: PatrolAreaPage,
  },
  //#region SignSetups
  {
    path: "/sign/setups/sign/code",
    element: SignCodePage,
  },
  {
    path: "/sign/setups/sign/description",
    element: SignDescriptionPage,
  },
  {
    path: "/sign/setups/sign/type",
    element: SignTypePage,
  },
  {
    path: "/sign/setups/sign/dimension",
    element: SignDimensionPage,
  },
  {
    path: "/sign/setups/sign/facingDirection",
    element: SignFacingDirectionPage,
  },
  {
    path: "/sign/setups/sign/faceMaterial",
    element: SignFaceMaterialPage,
  },
  {
    path: "/sign/setups/sign/reflectiveCoating",
    element: SignReflectiveCoatingPage,
  },
  {
    path: "/sign/setups/sign/reflectiveRating",
    element: SignReflectiveRatingPage,
  },
  {
    path: "/sign/setups/sign/condition",
    element: SignConditionPage,
  },
  {
    path: "/sign/setups/sign/locationType",
    element: SignLocationTypePage,
  },
  //#endregion SignSetup

  //#region SupportSetup
  {
    path: "/sign/setups/support/code",
    element: SupportCodePage,
  },
  {
    path: "/sign/setups/support/description",
    element: SupportDescriptionPage,
  },
  {
    path: "/sign/setups/support/type",
    element: SupportTypePage,
  },
  {
    path: "/sign/setups/support/material",
    element: SupportMaterialPage,
  },
  {
    path: "/sign/setups/support/position",
    element: SupportPositionPage,
  },
  {
    path: "/sign/setups/support/condition",
    element: SupportConditionPage,
  },
  {
    path: "/sign/setups/support/locationType",
    element: SupportLocationTypePage,
  },
  //#endregion SupportSetup

  //#endregion SignInventory

  //#region Maintenance
  {
    path: "/maintenance/jobs",
    element: JobsPage,
  },
  {
    path: "/maintenance/jobDetails/:jobId",
    element: JobDetailsPage,
  },
  {
    path: "/maintenance/personnel",
    element: PersonnelPage,
  },
  {
    path: "/maintenance/equipments",
    element: EquipmentPage,
  },
  {
    path: "/maintenance/tags",
    element: TagsPage,
  },
  {
    path: "/maintenance/setups/jobType",
    element: JobTypesPage,
  },
  {
    path: "/maintenance/setups/jobStatus",
    element: JobStatusesPage,
  },
  //#endregion Maintenance

  //#region Collision
  {
    path: "/collision/collisions",
    element: CollisionsPage,
  },

  {
    path: "/collision/simplifiedCollisions",
    element: SimplifiedCollisionPage,
  },
  {
    path: "/collision/import/xml/xmlImportSettings",
    element: CollisionXMLImportSettingsPage,
  },
  {
    path: "/collision/import/xml/xmlImport",
    element: XMLImportPage,
  },
  {
    path: "/collision/import/xml/xmlImportLogs",
    element: XMLImportLogsPage,
  },
  {
    path: "/collision/import/xml/xmlImportLogDetails/:xmlLogId",
    element: XMLImportLogDetailsPage,
  },
  {
    path: "/collision/import/xml/xmlImportSettingDetails/:xmlSettingId",
    element: XLImportSettingDetailsPage,
  },
  {
    path: "/collision/geoCoding/:collisionId",
    element: GeoCodingPage,
  },
  {
    path: "/collision/geoCodeGroup/geoCodeGroups",
    element: GeoCodeGroupsPage,
  },
  {
    path: "/collision/geoCodeGroup/geoCodeGroupDetails/:geoCodeGroupId",
    element: GeoCodeGroupDetailsPage,
  },
  {
    path: "/collision/geoCodeGroup/collisionGeoCoding",
    element: CollisionGeoCodingPage,
  },
  {
    path: "/collision/peopleInvolved/people",
    element: PeoplePage,
  },
  {
    path: "/collision/peopleInvolved/pedestrians",
    element: PedestriansPage,
  },
  {
    path: "/collision/peopleInvolved/drivers",
    element: DriversPage,
  },
  {
    path: "/collision/peopleInvolved/passengers",
    element: PassengersPage,
  },
  {
    path: "/collision/vehicles",
    element: VehiclesPage,
  },
  {
    path: "/collision/drafts",
    element: DraftsPage,
  },
  {
    path: "/collision/collisionDetails/:collisionId",
    element: CollisionDetailsPage,
  },
  {
    path: "/collision/settings/codeGroups",
    element: CollisionCodeGroupsPage,
  },
  {
    path: "/collision/settings/validationRules",
    element: ValidationRulesPage,
  },
  {
    path: "/collision/settings/confirmationLevels",
    element: ConfirmationLevelsPage,
  },
  {
    path: "/collision/settings/fields",
    element: CollisionFieldsPage,
  },
  {
    path: "/collision/settings/tesFields",
    element: CollisionTesFieldsPage,
  },
  {
    path: "collision/settings/xmlRedaction",
    element: XMLRedactionPage,
  },
  {
    path: "collision/settings/xmlRedactionDetails/:id",
    element: XMLRedactionDetailsPage,
  },

  //#endregion Collision

  //#region Traffic Study
  {
    path: "/trafficStudy/setups/reportingPeriod",
    element: ReportingPeriodsPage,
  },
  {
    path: "/trafficStudy/studies/allStudies",
    element: AllStudiesPage,
  },
  {
    path: "/trafficStudy/studies/studyDetails/:studyType/:studyId",
    element: StudyDetailsPage,
  },
  {
    path: "/trafficStudy/setups/countDurations",
    element: CountDurationsPage,
  },
  {
    path: "/trafficStudy/import/importData",
    element: ImportTrafficStudyDataPage,
  },
  {
    path: "/trafficStudy/import/importLogs",
    element: ImportLogs,
  },
  {
    path: "/trafficStudy/setups/trafficCounters",
    element: TrafficCountersPage,
  },
  {
    path: "/trafficStudy/setups/trafficCountersDetails/:trafficCounterId",
    element: TrafficCounterDetailsPage,
  },
  {
    path: "/trafficStudy/setups/signalWarrantSetting",
    element: SignalWarrantSettingsPage,
  },
  {
    path: "/trafficStudy/setups/allWayStopWarrantSetting",
    element: AllWayStopWarrantSettingsPage,
  },
  {
    path: "/trafficStudy/setups/AADTPatterns",
    element: AADTPatternsPage,
  },
  {
    path: "/trafficStudy/setups/AADTPatternDetails/:AADTPatternId",
    element: AADTPatternDetailsPage,
  },
  {
    path: "/trafficStudy/aadts/intersectionAADTs",
    element: IntersectionAADTsPage,
  },
  {
    path: "/trafficStudy/aadts/aadtDetails/:aadtType/:aadtId",
    element: AADTDetailsPage,
  },
  {
    path: "/trafficStudy/aadts/roadSegmentAADTs",
    element: RoadSegmentAADTsPage,
  },
  {
    path: "/trafficStudy/studies/tmc",
    element: TMCPage,
  },
  {
    path: "/trafficStudy/studies/volume",
    element: VolumePage,
  },
  {
    path: "/trafficStudy/studies/speed",
    element: SpeedPage,
  },
  {
    path: "/trafficStudy/import/numetricConvertor",
    element: NumetricConvertorPage,
  },
  {
    path: "/trafficStudy/tools/AADTPrediction",
    element: AADTPredictionPage,
  },
  {
    path: "/trafficStudy/tools/AADTPredictionDetails/:ProjectType/:AADTPredictionId",
    element: AADTPredictionDetailsPage,
  },
  {
    path: "/trafficStudy/tools/Export",
    element: ExportPage,
  },
  {
    path: "/trafficStudy/setups/AADTPredictionGeneralSetting",
    element: AADTPredictionGeneralSettingPage,
  },
  //#endregion Traffic Study

  //#region Module of Modules
  {
    path: "/moduleOfModules/modules",
    element: ModuleOfModulesPage,
  },
  {
    path: "/moduleOfModules/ModulesDetails/:moduleId",
    element: ModuleDetailsPage,
  },
  {
    path: "/moduleOfModules/settings/fields",
    element: ModuleFieldsPage,
  },
  {
    path: "/moduleOfModules/settings/codeGroups",
    element: ModuleCodeGroupsPage,
  },
  {
    path: "/moduleOfModules/data/:moduleId",
    element: ModuleDataPage,
  },
  {
    path: "/moduleOfModules/dataDetails/:moduleId/:dataId",
    element: DataDetailsPage,
  },
  //#endregion Module of Modules

  //#region Hospitalization
  {
    path: "/hospitalization/hospitalizations",
    element: HospitalizationPage,
  },
  {
    path: "/hospitalization/patients",
    element: PatientsPage,
  },
  {
    path: "/hospitalization/hospitalizationDetails/:hospitalizationId",
    element: HospitalizationDetailsPage,
  },
  //#endregion Hospitalization

  //#region safety
  {
    path: "/safety/workbooks",
    element: WorkBooksPage,
  },
  {
    path: "/safety/workbookDetails/:workbookId",
    element: WorkbookDetailsPage,
  },
  {
    path: "/safety/analysis/networkScreeningEBDetails/:analysisId",
    element: NetworkScreeningEBPage,
  },
  {
    path: "/safety/setups/EmpiricalBayesSettings",
    element: EmpiricalBayesSetupsPage,
  },
  {
    path: "/safety/setups/collisionCost",
    element: CollisionCostPage,
  },
  {
    path: "/safety/setups/siteTypes",
    element: SiteTypesPage,
  },
  {
    path: "/safety/setups/siteTypeDetails/:siteTypeId",
    element: SiteTypeDetailsPage,
  },
  {
    path: "/safety/setups/siteSubTypeDetails/:siteTypeId/:subTypeId",
    element: SiteSubTypeDetailsPage,
  },
  {
    path: "/safety/setups/SPFFunctions",
    element: SPFFUNCTIONSPage,
  },
  //#endregion safety
  {
    path: "/systemicScreening/projects",
    element: ProjectsPage,
  },
  {
    path: "/systemicScreening/projectDetails/:projectId",
    element: ProjectDetailsPage,
  },
  {
    path: "/systemicScreening/SignalWarrantPredictionAnalysis/:projectId/:analysisId",
    element: SignalWarrantPredictionAnalysisPage,
  },
  {
    path: "/systemicScreening/SignalWarrantAnalysisDetails/:analysisResultId",
    element: SignalWarrantAnalysisDetailsPage,
  },
  {
    path: "/systemicScreening/setups/signalWarrant",
    element: SignalWarrantSetupPage,
  },
  //#region Systemic Screening

  //#endregion Systemic Screening


  //#region Log
  {
    path: "/log/logs",
    element: LogsPage,
  },
  //#endregion Log

  //#region Phoenix
  {
    path: "/phoenix/appVersions",
    element: AppVersionsPage,
  },
  {
    path: "/phoenix/clientEnvironments",
    element: ClientEnvironmentsPage,
  },
  //#endregion Phoenix

  //#region Settings
  {
    path: "/settings/copySettings",
    element: CopySettingsPage,
  },
  {
    path: "/settings/clients",
    element: ClientsPage,
  },
  {
    path: "/settings/WindowsAppKey",
    element: WindowsAppKeyPage,
  },
  {
    path: "/settings/clientDetails/:clientId",
    element: ClientDetailsPage,
  },
  {
    path: "/settings/applicationVersions",
    element: ApplicationVersionsPage,
  },
  {
    path: "/settings/emailTemplates",
    element: EmailTemplatesPage,
  },
  {
    path: "/settings/sync/tesSync",
    element: TESSyncPage,
  },
  {
    path: "/settings/sync/syncJob",
    element: SyncJobPage,
  },
  //#endregion Settings

  //#region Users
  {
    path: "/users/users",
    element: UsersPage,
  },
  {
    path: "/users/divisions",
    element: DivisionsPage,
  },
  {
    path: "/users/divisionDetails/:divisionId",
    element: DivisionDetailsPage,
  },
  {
    path: "/users/roles",
    element: RolesPage,
  },
  {
    path: "/users/roleDetails/:roleId",
    element: RoleDetailsPage,
  },
  {
    path: "/users/userDetails/:userId",
    element: UserDetailsPage,
  },
  {
    path: "/users/AddUser",
    element: AddUserPage,
  },
  //#endregion Users


  //#region Storage
  {
    path: "/storage/myStorage",
    element: MyStoragePage,
  },

  //#endregion Storage
  //#region Reports
  {
    path: "/report/tesReportType",
    element: TesReportTypePage,
  },
  {
    path: "/report/clientReports",
    element: ClientReportPage,
  },
  {
    path: "/report/multiClientReporting",
    element: MultiClientReportingPage,
  },
  {
    path: "/report/clientReportDetails/:reportId",
    element: ClientReportDetailsPage,
  },
  //#endregion Reports

  //Translation
  {
    path: "/translation/baseLanguages",
    element: BaseLanguagesPage,
  },
  {
    path: "/translation/baseLanguageDetails/:languageId",
    element: BaseLanguageDetailsPage,
  },
  {
    path: "/translation/groups",
    element: GroupsPage,
  },
  {
    path: "/translation/groupDetails/:languageId",
    element: GroupDetailsPage,
  },
  {
    path: "/translation/customerLanguages",
    element: CustomerLanguagesPage,
  },
  {
    path: "/translation/customerLanguageDetails/:languageId",
    element: CustomerLanguageDetailsPage,
  },
  {
    path: "/translation/customer",
    element: CustomerPage,
  },
  {
    path: "/translation/customerDetails/:clientId",
    element: CustomerDetailsPage,
  },
  {
    path: "/translation/keys",
    element: KeysPage,
  },

  //#endregion Translation

  {
    path: "/test",
    element: TestPage,
  },

  {
    path: "/testsort",
    element: TestSort,
  },
  //Test
];

export default routes.map((route) => {
  return {
    ...route,
    element: withNavigationWatcher(route.element, route.path),
  };
});
