//#region imports
import React, { useState, useRef } from "react";
import { Button } from 'devextreme-react/button';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Pager, Paging, Button as DevExpressButton, Lookup, Export, SortByGroupSummaryInfo, Summary, GroupItem, Search,
} from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { DateBox, Form, NumberBox, ScrollView, SelectBox, Switch, TextArea, TextBox } from 'devextreme-react';
import { ModalMode } from '../../../../../types/general/enums/generalEnums';
import { useTranslation } from "react-i18next";
import { VMCollisionGroupedField, VMField } from "../../../../../types/field/dto/fieldDTO";
import { CustomerCollision } from "../../../../../types/collision/collisionTypes";
import { GeneralSetting } from "../../../../../contexts/clientSetting";
import { SimpleItem } from "devextreme-react/form";
import { ValidationGroup } from 'devextreme-react/validation-group';
import { Validator, RequiredRule } from 'devextreme-react/validator';
import { v4 as uuid } from 'uuid';
import { custom } from "devextreme/ui/dialog";
import { FieldType } from "../../../../../types/field/enums/fieldEnums";
import { DatePickerDateCalculator, GridActualDateCalculator } from "../../../../../utils/dateTimeTools";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
//#endregion imports

//#region props
interface IPros {
    initDataFields: VMCollisionGroupedField;
    initDataCollision: CustomerCollision;
    generalSettings: GeneralSetting | null;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    validationRef: React.RefObject<ValidationGroup>;
    setInitDataCollision: React.Dispatch<React.SetStateAction<any>>;
    isLocked: boolean
}
//#endregion props
const Passengers = (props: IPros) => {
    //#region consts
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const [showModal, setShowModal] = useState(false);
    const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
    const [selectedData, setSelectedData] = useState<{ [key: string]: any }>({});
    const { t } = useTranslation();
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    //#endregion consts
    //#region functions
    function onChangeField(name: string, value: any, isUpperCase: boolean) {
        setSelectedData({
            ...selectedData,
            [name]: isUpperCase ? value.toUpperCase() : value
        })
        props.setDataChanged(true);
    }
    function onCloseModal() {
        setSelectedData({});
        setShowModal(false);
    }
    function onNew() {
        setModalMode(ModalMode.Add);
        setShowModal(true);
    }
    function onEdit(d: any) {
        let newData = {};
        Object.assign(newData, d.row.data);
        setModalMode(ModalMode.Edit);
        setSelectedData(newData);
        setShowModal(true);
    }
    function onAdd() {
        props.setInitDataCollision({
            ...props.initDataCollision,
            persons: [
                ...props.initDataCollision.persons,
                { ...selectedData, Id: uuid(), InvolvedAs: 4 }
            ]
        });
        dataGridRef.current?.instance.refresh();
        onCloseModal();
    }
    function onUpdate() {
        const persons = props.initDataCollision.persons;
        const selectedDataIndex: number = persons.findIndex((x: any) => x.Id === selectedData["Id"]);
        persons[selectedDataIndex] = selectedData;
        props.setInitDataCollision({
            ...props.initDataCollision,
            persons: persons
        });
        dataGridRef.current?.instance.refresh();
        onCloseModal();
    }
    async function onDelete(d: any) {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: t("deleteWarningMessage"),
            buttons: [{
                text: t("yes"),
                onClick: async (e) => {
                    props.setInitDataCollision({
                        ...props.initDataCollision,
                        persons: [
                            ...props.initDataCollision.persons.filter(x => x.Id !== d.row.data.Id)
                        ]
                    });
                    dataGridRef.current?.instance.refresh();
                    return { buttonText: e.component.option("text") }
                }
            },
            {
                text: t("no"),
                onClick: (e) => {
                    return { buttonText: e.component.option("text") }
                }
            },]
        });
        myDialog.show();
    }

    //#endregion functions
    return (
        <div className={`collisionDetails-passengers ${compactViewModel ? "compactStyle" : ""}`}>
            <React.Fragment>
                <div className="row" style={{ marginTop: 20, padding: 10 }}>
                    <div className="row" >
                        <div className='rightColumn'>
                            <Button
                                onClick={onNew}
                                icon="fa-solid fa-circle-plus"
                                text={t('passenger')}
                                disabled={props.isLocked}
                            />
                        </div>
                    </div>
                    {props.initDataFields != null &&
                        <DataGrid id="gridContainer"
                            ref={dataGridRef}
                            dataSource={props.initDataCollision?.persons?.filter(x => x.InvolvedAs === 4)}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            hoverStateEnabled={true}
                            remoteOperations={false}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            onExporting={OnExporting}
                        >
                            <Export enabled={true} allowExportSelectedData={true} />
                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />

                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />


                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />
                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>

                            <SortByGroupSummaryInfo
                                summaryItem="Total Count"
                                sortOrder="desc"
                            />
                            <Summary>
                                <GroupItem
                                    summaryType="count"
                                    alignByColumn
                                    name="Total Count"
                                />
                            </Summary>
                            <Column dataField="Id"
                                caption={t('id')}
                                alignment='left'
                                visible={false}
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column dataField="VehicleId"
                                caption={t('vehicleIndex')}
                                alignment='left'
                            >
                                <Lookup dataSource={SortObjectByPropName(props.initDataCollision?.vehicles, "Index")} valueExpr="Id" displayExpr="Index" />
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            {/* eslint-disable-next-line array-callback-return */}
                            {props.initDataFields.passengerFields && props.initDataFields.passengerFields.map((f: VMField, i: number) => {
                                if (f.fieldType === FieldType.List) {
                                    return (
                                        <Column
                                            dataField={f.name}
                                            caption={f.labelText}
                                            key={i}
                                        >
                                            <Lookup dataSource={SortObjectByPropName(f.fieldValues, "name")} valueExpr="id" displayExpr="name" />
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                    );
                                } else if (f.fieldType === FieldType.Date) {
                                    <Column
                                        dataField={f.name}
                                        caption={f.labelText}
                                        key={i}
                                        dataType="date"
                                        calculateCellValue={(e: any) => GridActualDateCalculator(e, f, props.generalSettings)}
                                        format={props.generalSettings?.dateFormat}
                                    >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                } else if (f.fieldType === FieldType.Time || f.fieldType === FieldType.DateTime) {
                                    <Column
                                        dataField={f.name}
                                        caption={f.labelText}
                                        key={i}
                                        calculateCellValue={(e: any) => GridActualDateCalculator(e, f, props.generalSettings)}
                                        dataType="datetime"
                                        format={props.generalSettings?.dateTimeFormat}
                                    >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>
                                }
                                else {
                                    return (
                                        <Column
                                            dataField={f.name}
                                            caption={f.labelText}
                                            alignment="left"
                                        >
                                            <HeaderFilter>
                                                <Search enabled />
                                            </HeaderFilter>
                                        </Column>
                                    )
                                }
                            })
                            }
                            <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                                <DevExpressButton hint={t('edit')} onClick={onEdit} icon="fa-solid fa-pencil">

                                </DevExpressButton>
                                <DevExpressButton hint={t('delete')} onClick={onDelete} icon="fa-solid fa-trash-can">

                                </DevExpressButton>
                            </Column>
                        </DataGrid>
                    }

                    <Popup
                        width={"50%"}
                        height={"auto"}
                        visible={showModal}
                        resizeEnabled={true}
                        showTitle={true}
                        title={modalMode === ModalMode.Add ? t('add') : t('update')}
                        hideOnOutsideClick={false}
                        showCloseButton={true}
                        onHiding={() => onCloseModal()}
                    >
                        <ScrollView width='100%' height='100%'>
                            <ValidationGroup
                                ref={props.validationRef}
                            >
                                <Form
                                    colCount={2}>
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={t('vehicleIndex')}
                                            valueExpr='Id'
                                            displayExpr='Index'
                                            value={selectedData.VehicleId}
                                            onValueChange={e => onChangeField("VehicleId", e, false)}
                                            items={SortObjectByPropName(props.initDataCollision.vehicles, "Index")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                    {/* eslint-disable-next-line array-callback-return */}
                                    {props.initDataFields?.passengerFields?.map((f: VMField, i: number) => {
                                        if (f.fieldType === FieldType.String) {
                                            return (
                                                <SimpleItem colSpan={1} >
                                                    <TextBox
                                                        label={f.labelText}
                                                        labelMode='static'
                                                        key={i}
                                                        value={selectedData[f.name]}
                                                        onValueChange={e => onChangeField(f.name, e, f.isUpperCase)}
                                                    >
                                                        {f.isRequired &&
                                                            <Validator>
                                                                <RequiredRule message={t('fieldIsRequired')} />
                                                            </Validator>
                                                        }
                                                    </TextBox>
                                                </SimpleItem>
                                            );
                                        }
                                        if (f.fieldType === FieldType.Number) {
                                            return (
                                                <SimpleItem colSpan={1} >
                                                    <NumberBox
                                                        step={0}
                                                        label={f.labelText}
                                                        labelMode='static'
                                                        key={i}
                                                        value={selectedData[f.name]}
                                                        onValueChange={e => onChangeField(f.name, e, f.isUpperCase)}
                                                    >
                                                        {f.isRequired &&
                                                            <Validator>
                                                                <RequiredRule message={t('fieldIsRequired')} />
                                                            </Validator>
                                                        }
                                                    </NumberBox>
                                                </SimpleItem>
                                            );
                                        }
                                        if (f.fieldType === FieldType.Boolean) {
                                            return (
                                                <SimpleItem colSpan={1} >
                                                    <div >{f.labelText}</div>
                                                    <Switch
                                                        style={{ marginTop: 5, fontWeight: 'bold' }}
                                                        value={selectedData[f.name]}
                                                        onValueChange={e => onChangeField(f.name, e, false)}
                                                        hint={f.labelText}
                                                    />
                                                </SimpleItem>
                                            );
                                        }
                                        if (f.fieldType === FieldType.Date) {
                                            return (
                                                <SimpleItem colSpan={1}>
                                                    <DateBox
                                                        type="date"
                                                        label={f.labelText}
                                                        value={f.exactDT === true ? DatePickerDateCalculator(selectedData[f.name]) : selectedData[f.name]}
                                                        dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                                        onValueChange={e => onChangeField(f.name, e, false)}
                                                        pickerType="calendar"
                                                        placeholder={props.generalSettings?.dateFormat}
                                                        displayFormat={props.generalSettings?.dateFormat}
                                                        useMaskBehavior={true}
                                                        openOnFieldClick={true}
                                                        showClearButton={true}
                                                    >
                                                        {f.isRequired &&

                                                            <Validator>
                                                                <RequiredRule message={t('fieldIsRequired')} />
                                                            </Validator>
                                                        }
                                                    </DateBox>
                                                </SimpleItem>
                                            );
                                        }
                                        if (f.fieldType === FieldType.Time) {
                                            return (
                                                <SimpleItem colSpan={1}>
                                                    <DateBox
                                                        type="time"
                                                        label={f.labelText}
                                                        value={f.exactDT === true ? DatePickerDateCalculator(selectedData[f.name]) : selectedData[f.name]}
                                                        dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                                        onValueChange={e => onChangeField(f.name, e, false)}
                                                        pickerType="rollers"
                                                        placeholder={props.generalSettings?.timeFormat}
                                                        displayFormat={props.generalSettings?.timeFormat}
                                                        useMaskBehavior={true}
                                                        openOnFieldClick={true}
                                                        showClearButton={true}
                                                    >
                                                        {f.isRequired &&
                                                            <Validator>
                                                                <RequiredRule message={t('fieldIsRequired')} />
                                                            </Validator>
                                                        }
                                                    </DateBox>
                                                </SimpleItem>
                                            );
                                        }
                                        if (f.fieldType === FieldType.DateTime) {
                                            return (
                                                <SimpleItem colSpan={1}>
                                                    <DateBox
                                                        type="datetime"
                                                        label={f.labelText}
                                                        value={f.exactDT === true ? DatePickerDateCalculator(selectedData[f.name]) : selectedData[f.name]}
                                                        dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                                        onValueChange={e => onChangeField(f.name, e, false)}
                                                        pickerType="calendar"
                                                        placeholder={props.generalSettings?.dateTimeFormat}
                                                        displayFormat={props.generalSettings?.dateTimeFormat}
                                                        useMaskBehavior={true}
                                                        openOnFieldClick={true}
                                                        showClearButton={true}
                                                    >
                                                        {f.isRequired &&
                                                            <Validator>
                                                                <RequiredRule message={t('fieldIsRequired')} />
                                                            </Validator>
                                                        }
                                                    </DateBox>
                                                </SimpleItem>
                                            );
                                        }
                                        if (f.fieldType === FieldType.List) {
                                            return (
                                                <SimpleItem colSpan={1}>
                                                    <SelectBox
                                                        placeholder=""
                                                        label={f.labelText}
                                                        valueExpr='id'
                                                        displayExpr='name'
                                                        value={selectedData[f.name]}
                                                        onValueChange={e => onChangeField(f.name, e, f.isUpperCase)}
                                                        items={SortObjectByPropName(f.fieldValues, "name")}
                                                        showClearButton={true}
                                                        searchEnabled={true}
                                                    >
                                                        {f.isRequired &&
                                                            <Validator>
                                                                <RequiredRule message={t('fieldIsRequired')} />
                                                            </Validator>
                                                        }
                                                    </SelectBox>
                                                </SimpleItem>
                                            );
                                        }
                                        if (f.fieldType === FieldType.TextArea) {
                                            return (
                                                <SimpleItem colSpan={1}>
                                                    <TextArea
                                                        label={f.labelText}
                                                        value={selectedData[f.name]}
                                                        onValueChange={e => onChangeField(f.name, e, f.isUpperCase)}
                                                    >
                                                        {f.isRequired &&
                                                            <Validator>
                                                                <RequiredRule message={t('fieldIsRequired')} />
                                                            </Validator>
                                                        }
                                                    </TextArea>
                                                </SimpleItem>
                                            );
                                        }
                                    })
                                    }
                                </Form>
                            </ValidationGroup>
                            <div>
                                <div className='rightColumn'>
                                    <Button
                                        className='tes-modal-btn-cancel'
                                        style={{ marginRight: 20, marginTop: '10px' }}
                                        onClick={() => onCloseModal()}
                                        text={t('cancel')}
                                    />
                                    {modalMode === ModalMode.Add ?
                                        <Button
                                            style={{ marginTop: '10px' }}
                                            className='tes-modal-btn-add'
                                            onClick={onAdd}
                                            text={t('add')}
                                        />
                                        :
                                        <Button
                                            style={{ marginTop: '10px' }}

                                            className='tes-modal-btn-add'
                                            onClick={onUpdate}
                                            text={t('update')}
                                        />
                                    }
                                </div>
                            </div>

                        </ScrollView>
                    </Popup>
                </div>
            </React.Fragment>
        </div>
    );
}
export default Passengers;