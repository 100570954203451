
import { Button } from "devextreme-react/button";
import DataGrid, {
    Column,
    ColumnChooser,
    Button as DevExpressButton, Export,
    FilterRow, HeaderFilter,
    Pager, Paging,
    Search,
    SearchPanel
} from 'devextreme-react/data-grid';
import LoadPanel from "devextreme-react/load-panel";
import { Popup } from 'devextreme-react/popup';
import { custom } from "devextreme/ui/dialog";
import notify from "devextreme/ui/notify";
import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { FMSPermissions } from "../../constants/Permissions";
import { useAuth } from "../../contexts/auth";
import { FileManagementApiUrl } from "../../environment/routeSettings";
import { FileType } from "../../types/fileManagement/dto/enums/fileManagementEnums";
import { VMStorage } from "../../types/fileManagement/dto/fileManagementDTO";
import { GeneralSetting } from "../../types/setting/gridSettingTypes";
import { OnExporting, calculateFileTypeCell } from "../../utils/dataGridTools";
import { useScreenSize } from "../../utils/media-query";
import { TesPost } from "../../utils/rest";
import "./filePicker.scss";

interface IProps {
    label: string;
    isLocked: boolean;
    addFromFileStorage: (Data: VMStorage[]) => void
    disable: boolean;
    lstLastFiles: any[];
    multiFileMode: boolean;
    AddFolderAllowed: boolean;
}


const FilePicker = (props: IProps) => {
    const { isXLarge, is2xLarge } = useScreenSize();
    const [initDataFile, setInitDataFile] = useState<VMStorage[]>([])
    const [fileBasket, setFileBasket] = useState<VMStorage[]>([])
    const [currentPath, setCurrentPath] = useState<string>("")
    const [showFilePicker, setShowFilePicker] = useState(false);
    const [showBasket, setShowBasket] = useState(false);
    const [showLoading, setShowLoading] = useState(false);
    const { t } = useTranslation();
    const [generalSetting] = useState<GeneralSetting>(new GeneralSetting());
    const { user } = useAuth();
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const dataGridBasketRef = useRef<DataGrid<any, any>>(null);
    const [gridHeight, setGridHeight] = useState<number | string>("33em")


    useEffect(() => {
        if (isXLarge) {
            setGridHeight("48em");
        }
        else if (is2xLarge) {
            setGridHeight(900)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    useEffect(() => {
        async function fetchMyAPI() {
            try {
                setShowLoading(true)
                if (user?.permissions?.includes(FMSPermissions.FMS_Admin)) {
                    await onGetData('')
                    return
                } else {
                    await onGetData(user?.userName + '/')
                    return
                }
                //setShowLoading(false)
            } catch (ex) {
                // setShowLoading(false)
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        if (showFilePicker) {
            fetchMyAPI()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showFilePicker]);


    async function onGetData(path: string) {
        //   setShowLoading(true)
        var res = await TesPost(FileManagementApiUrl() + "/api/tesFiles/getFiles", { path: path }, true);
        setInitDataFile(res);
        setCurrentPath(path)
        setShowLoading(false)
    }

    async function add2FileBasket(f: any) {
        if (f.row && f.row.data.fileType === FileType.Folder) {
            if (props.AddFolderAllowed === true) {    
            var lstData = await TesPost(FileManagementApiUrl() + "/api/tesFiles/getDocumentsUnderPath", { path: currentPath + f.row.data.fileName + "/" }, true);
            lstData = lstData.filter((x:VMStorage) => x.fileType !== FileType.Folder)
            setFileBasket([...fileBasket, ...lstData])
            notify(t("addedToBasketSuccessfully"), "success", 5000)
            } else {
            notify(t("youCantAddFoldersYouCanOnlyAddOneFile"), "error", 5000)
            }
        } else {
            if (props.multiFileMode === true) {
                if (fileBasket.some(x => x.filePath === f.row.data.filePath)) {
                    notify(t("theFileAlreadyExistInBasket"), "error", 5000)
                } else {
                    setFileBasket([...fileBasket, f.row.data])
                    notify(t("addedToBasketSuccessfully"), "success", 5000)
                }
            } else {
                props.addFromFileStorage([f.row.data])
                setShowFilePicker(false)
            }
        }
    }

    function onGoBack() {
        if (currentPath === '') {
            return
        }
        let arr = []
        arr = currentPath.split('/')
        if (arr.length === 2) {
            setShowLoading(true)
            if (user?.permissions?.includes(FMSPermissions.FMS_Admin)) {
                onGetData('')
                return
            } else {
                onGetData(user?.userName + '/')
                return
            }
        } else if (arr.length > 2) {
            arr.pop();
            arr.pop();
            var pat = arr?.join("/") + "/"
            onGetData(pat)
            return
        }
    }


    function onRowClick(selectedRowData: any) {
        dataGridRef.current?.instance.clearFilter();
        if (selectedRowData.data?.fileType === FileType.Folder) {
            setShowLoading(true)
            onGetData(currentPath + selectedRowData.data?.fileName + "/");
        } else {
            notify(t("toAddAFileToBasketClickOnPlusIconOnTheRightSideOfRow"), "warning", 5000)
        }
    }

    async function onOpenPopUp() {
        if (!props.isLocked) {
            setShowFilePicker(true);
        }
    }

    function GoToBasket() {
        setShowBasket(true)
    }

    function onAdd() {
        setShowFilePicker(false)
        props.addFromFileStorage(fileBasket)
        setFileBasket([])
    }

    function onCancel() {
        setShowFilePicker(false)
        setFileBasket([])
    }

    function onDelete(d: any) {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: t("deleteWarningMessage"),
            buttons: [
                {
                    text: t("yes"),
                    onClick: (e) => {
                        try {
                            setFileBasket(fileBasket.filter(x => x.id !== d.row.data.id))
                            notify(t("dataSuccessfullyDeleted"), "success", 5000);
                        } catch {
                            notify(t("someErrorOccurred"), "error", 5000);
                        }
                        return { buttonText: e.component.option("text") };
                    },
                },
                {
                    text: t("no"),
                    onClick: (e) => {
                        return { buttonText: e.component.option("text") };
                    },
                },
            ],
        });
        myDialog.show();
    }

    function emptyBasket() {
        let myDialog = custom({
            title: t("warning"),
            messageHtml: t("emptyBasketWarningMessage"),
            buttons: [
                {
                    text: t("yes"),
                    onClick: (e) => {
                        try {
                            setFileBasket([])
                            notify(t("dataSuccessfullyDeleted"), "success", 5000);
                        } catch {
                            notify(t("someErrorOccurred"), "error", 5000);
                        }
                        return { buttonText: e.component.option("text") };
                    },
                },
                {
                    text: t("no"),
                    onClick: (e) => {
                        return { buttonText: e.component.option("text") };
                    },
                },
            ],
        });
        myDialog.show();
    }

    return (
        <div>

            <Button
                onClick={() => onOpenPopUp()}
                className='tes-modal-btn-add rightColumn'
                style={{ marginTop: "0.5rem", marginRight: "auto" }}
                text={t('filePicker')}
                disabled={props.disable}
            />

            <Popup
                width={"85%"}
                height={"95%"}
                visible={showFilePicker}
                resizeEnabled={true}
                showTitle={true}
                title={t("filePicker")}
                // onShown={x => setShowLoading(true)}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowFilePicker(false)}
            >
                        <div className="dx-card" style={{marginTop:"-1rem"}}>
                            <div className="leftColumn">
                                <Button
                                    onClick={() => onGoBack()}
                                    icon="fa-solid fa-arrow-left"
                                    hint={t("goBack")}
                                />
                            </div>
                            <div className="rightColumn row" style={{ marginBottom: -17, marginTop: 5 }}>
                                <div className="rightColumn fileBasketBadge" style={{ display: props.multiFileMode ? "block" : "none" }}>
                                    <label>
                                        {fileBasket.length === 0 ? t("basketIsEmpty") : `${fileBasket.length} ${t("filesInBasket")}`}
                                    </label>
                                </div>
                                <div className="rightColumn" style={{ display: props.multiFileMode ? "block" : "none" }}>
                                    <Button
                                        onClick={() => GoToBasket()}
                                        icon="fa-solid fa-basket-shopping"
                                        hint={t("goToBasket")}
                                    />
                                </div>
                            </div>
                        </div>
                        <LoadPanel
                            shadingColor="rgba(0,0,0,0.4)"
                            visible={showLoading}
                            showIndicator={true}
                            shading={true}
                            showPane={true}
                        />
                        <DataGrid
                            id="FileChooserDataGrid"
                            dataSource={initDataFile}
                            ref={dataGridRef}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            selection={{ mode: 'single' }}
                            onRowClick={onRowClick}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            height={gridHeight}
                            onExporting={OnExporting}
                        >
                            <Export enabled={true} allowExportSelectedData={true} />
                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />
                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />

                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />

                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>

                            <Column alignment="left" dataField="fileType"
                                cellRender={calculateFileTypeCell}
                                width={100}
                                caption={t("fileType")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="fileName"
                                caption={t("fileName")}
                                defaultSortOrder="asc"
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="uploadDate" dataType="datetime" format={generalSetting.dateTimeFormat}
                                caption={t("uploadDate")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column
                                type="buttons"
                                caption={t("actions")}
                                width={70}
                            // fixed={true}
                            // fixedPosition="right"
                            >
                                <DevExpressButton
                                    hint={t('addToBasket')}
                                    onClick={add2FileBasket}
                                    icon="fa-solid fa-circle-plus"
                                >
                                </DevExpressButton>
                            </Column>

                        </DataGrid>
                        {props.multiFileMode && <Button
                            onClick={onAdd}
                            className='tes-modal-btn-add rightColumn'
                            style={{ marginTop: "0.5rem" }}
                            text={t('next')}
                        />}
                        <Button
                            onClick={onCancel}
                            className='tes-modal-btn-cancel rightColumn'
                            style={{ marginTop: "0.5rem" }}
                            text={t('cancel')}
                        />
            </Popup>

            <Popup
                width={"60%"}
                height={"95%"}
                visible={showBasket}
                resizeEnabled={true}
                showTitle={true}
                title={t("basket")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowBasket(false)}>
                    <div>
                        <div className="dx-card" style={{marginTop:"-1rem"}}>
                            <div className="rightColumn row" style={{ marginBottom: -17, marginTop: 5 }}>
                                <Button
                                    onClick={() => emptyBasket()}
                                    icon="fa-solid fa-trash"
                                    hint={t("emptyBasket")}
                                />
                            </div>
                        </div>
                        <DataGrid
                            ref={dataGridBasketRef}
                            dataSource={fileBasket}
                            rowAlternationEnabled={true}
                            showBorders={true}
                            selection={{ mode: 'single' }}
                            onRowClick={onRowClick}
                            hoverStateEnabled={true}
                            remoteOperations={true}
                            allowColumnReordering={true}
                            allowColumnResizing={true}
                            columnAutoWidth={true}
                            height={gridHeight}
                            onExporting={OnExporting}
                        >
                            <Export enabled={true} allowExportSelectedData={true} />
                            <Paging enabled={true} defaultPageSize={100} />
                            <Pager
                                showPageSizeSelector={true}
                                allowedPageSizes={[100, 200, 300, 400, 500]}
                                showNavigationButtons={true}
                                showInfo={true}
                                visible={true}
                            />
                            <FilterRow visible={true}
                                applyFilter="auto" />

                            <HeaderFilter visible={true} />

                            <SearchPanel visible={true}
                                width={285}
                                placeholder={t("search...")} />

                            <ColumnChooser width={350} height={400}
                                enabled={true}
                                mode="select"
                                sortOrder="asc"
                            >
                                <Search enabled />
                            </ColumnChooser>

                            <Column alignment="left" dataField="fileType"
                                cellRender={calculateFileTypeCell}
                                //  calculateCellValue={this.calculateFileType.bind(this)}
                                width={100}
                                caption={t("fileType")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="fileName"
                                caption={t("fileName")}
                                defaultSortOrder="asc"
                            >
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>

                            <Column alignment="left" dataField="uploadDate" dataType="datetime" format={generalSetting.dateTimeFormat}
                                caption={t("uploadDate")}>
                                <HeaderFilter>
                                    <Search enabled />
                                </HeaderFilter>
                            </Column>
                            <Column
                                type="buttons"
                                caption={t("actions")}
                                width={110}
                            // fixed={true}
                            // fixedPosition="right"
                            >
                                <DevExpressButton
                                    hint={t("delete")}
                                    onClick={onDelete}
                                    icon="fa-solid fa-trash-can"
                                ></DevExpressButton>
                            </Column>
                        </DataGrid>
                        <Button
                            onClick={() => setShowBasket(false)}
                            className='tes-modal-btn-cancel rightColumn'
                            style={{ marginTop: "1rem" }}
                            text={t('close')}
                        />
                    </div>
            </Popup>
        </div >
    );
}
export default FilePicker;