import React, { useCallback, useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Summary,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  GroupItem,
  Export,
  Lookup,
  SortByGroupSummaryInfo,
  Search
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import {
  RequestErrorHandling,
  TesGet,
  TesPost,
} from "../../../../utils/rest";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import "./fieldDataSource.scss";
import { useNavigate } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { FieldDataSource } from "../../../../types/infrastructure/infrastructureTypes";
import { NameValue, RequestResponseResult } from "../../../../types/general/generalTypes";
import { Enum2Array } from "../../../../utils/enumTools";
import { DataSourceType, LocationType } from "../../../../types/infrastructure/enums/infrastructureEnums";
import { InfrastructureApiUrl } from "../../../../environment/routeSettings";
import Permission from "../../../../components/permission/permision";
import { TMSPermissions } from "../../../../constants/Permissions";
import { OnExporting } from "../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { ContextMenu } from "devextreme-react";
import { RightClickMenu } from "../../../../utils/rightClickMenu";
import getTitle from "../../../../components/title/getTitle";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";

const FieldDataSources = () => {

  const [initData, setInitData] = useState<FieldDataSource[]>([new FieldDataSource()]);
  const [lstDataSourceType, setLstDataSourceType] = useState<NameValue[]>([]);
  const [lstInfrastructureType, setLstInfrastructureType] = useState<NameValue[]>([]);
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const history = useNavigate();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('other')}
  ];
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  const title = getTitle('/infrastructure/updateImport/fieldDataSource', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialData();
        setLstDataSourceType(Enum2Array(DataSourceType));
        setLstInfrastructureType(Enum2Array(LocationType));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitialData() {
    const postObj = {customerId: localStorage.getItem("selectedCustomerId")}
    const res =
      await TesPost(
        InfrastructureApiUrl() +
        "/api/FieldDataSource/GetCustomerFieldDataSources/",
        postObj,
        true) as RequestResponseResult<FieldDataSource[]>
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results)
    } else {
      await RequestErrorHandling(res);
    }
  }

  const navigateToFileDataSourceDetails = useCallback(() => {
    history("/infrastructure/updateImport/fieldDataSourceDetails/AddNew");
  }, [history]);

  // function onRowClicked(data: any) {
  //   history('/infrastructure/updateImport/fieldDataSourceDetails/' + data.data.id);
  // }

  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/infrastructure/updateImport/fieldDataSourceDetails/${e.data.id}`, "_blank");
      } else {
        history(`/infrastructure/updateImport/fieldDataSourceDetails/${e.data.id}`);
      }
    }
  }

  return (
    <Permission
      allowed={[TMSPermissions.TMS_Admin]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, "/infrastructure/updateImport/fieldDataSourceDetails/")}
          />
          <h2 className={"content-block"}>{t("fieldDataSource")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={navigateToFileDataSourceDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("fieldDataSource")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                onRowClick={onRowClicked}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} />
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="customerId" caption={t("customerId")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="dataSourceName" caption={t("dataSourceName")} visible={true}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="dataSourceType" caption={t("dataSourceType")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstDataSourceType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
                <Column dataField="infrastructureType" caption={t("infrastructureType")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(lstInfrastructureType, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default FieldDataSources;
