//#region imports
import React, { useCallback, useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Summary,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  GroupItem,
  Export,
  SortByGroupSummaryInfo,
  Search,
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import notify from "devextreme/ui/notify";
import "./AADTPatterns.scss";
import Permission from "../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import { useNavigate } from "react-router-dom";
import { AADTPattern } from "../../../../types/trafficStudy/trafficStudyTypes";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import { OnExporting } from "../../../../utils/dataGridTools";
import { ContextMenu } from "devextreme-react";
import { RightClickMenu } from "../../../../utils/rightClickMenu";
import getTitle from "../../../../components/title/getTitle";
import { LazyLoadingRequestBase, RequestResponseResult } from "../../../../types/general/generalTypes";
import { TrafficCounterDTO } from "../../../../types/trafficStudy/dtos/TrafficCounterDTO";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";

//#endregion imports
const AADTPatterns = () => {
  //#region consts
  const [initData, setInitData] = useState<AADTPattern[]>([]);
  //   const [lstStudyTypes, setLstStudyTypes] = useState<NameValue[]>([])
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const { t } = useTranslation();
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    { text: t('openInNewTab'), icon: "fa-solid fa-up-right-from-square" },
    // { text: t('openLocation') , icon: "fa-solid fa-location-dot"}
  ];
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  //#endregion
  //#region functions

  const title = getTitle('/trafficStudy/setups/AADTPatterns', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        // setLstStudyTypes(Enum2Array(StudyType))
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    try {
      var res = await TesPost(
        TrafficApiUrl() +
        "/api/Setups/GetCustomerAADTPatterns",
        {
          customerId: localStorage.getItem("selectedCustomerId")
        } as LazyLoadingRequestBase,
        true
      ) as RequestResponseResult<AADTPattern[]>
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
  }


  function onRowClicked(e: any) {
    const isCtrlKeyPressed = e.event.ctrlKey || e.event.metaKey;
    if (e.data.id !== undefined) {

      if (isCtrlKeyPressed) {
        window.open(`/trafficStudy/setups/AADTPatternDetails/${e.data.id}`, "_blank");
      } else {
        history(`/trafficStudy/setups/AADTPatternDetails/${e.data.id}`);
      }
    }
  }
  //#endregion functions

  const navigateToAADTPatternDetails = useCallback(() => {
    history("/trafficStudy/setups/AADTPatternDetails/AddNew");
  }, [history]);

  return (
    <Permission
      allowed={[TrafficStudyPermissions.TrafficStudy_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.data.id, "/trafficStudy/setups/AADTPatternDetails/")}
          />
          <h2 className={"content-block"}>{t("AADTPatterns")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button
                    onClick={navigateToAADTPatternDetails}
                    icon="fa-solid fa-circle-plus"
                    text={t("AADTPatterns")}
                  />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onRowClick={onRowClicked}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
                onContextMenuPreparing={e => { setSelectedRowData(e) }}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column dataField="id" caption={t("id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="customerId" caption={t("customerId")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="divisionId" caption={t("divisionId")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="name" caption={t("name")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isDefault" caption={t("isDefault")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default AADTPatterns;
