//#region imports
import React, { useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Summary,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  GroupItem,
  Export,
  Search,
  Lookup,
} from "devextreme-react/data-grid";
import { createStore } from "devextreme-aspnet-data-nojquery";
import { useTranslation } from "react-i18next";
import { useClientSetting } from "../../../contexts/clientSetting";
import "./patients.scss";
import Permission from "../../../components/permission/permision";
import { CollisionPermissions } from "../../../constants/Permissions";
import { CollisionApiUrl } from "../../../environment/routeSettings";
import { OnExporting } from "../../../utils/dataGridTools";
import GridTotalCalculator from "../../../utils/girdTotalCalculator";
import { SortObjectByPropName } from "../../../utils/arrayTools";

//#endregion imports

const dataSource = createStore({
  loadUrl: `${CollisionApiUrl()
    }/api/Hospitalizations/GetAllPatients/${localStorage.getItem(
      "selectedCustomerId"
    )}`,
  onBeforeSend: (method, ajaxOptions) => {
    ajaxOptions.xhrFields = { withCredentials: false };
    ajaxOptions.headers = {
      'X-CSRF': '1'
    };
  },
});
const Patients = () => {
  //#region consts
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const { t } = useTranslation();
  const { generalSetting, customerDivisions } = useClientSetting();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  //#endregion

  return (
    <Permission
      allowed={[
        CollisionPermissions.Collision_V_Hospitalization,
        CollisionPermissions.Collision_D_Hospitalization,
      ]}
      hasFeedBackElement={true}
    >
      <div className={`patients ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("patients")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={dataSource}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                {/* <StateStoring enabled={true} type="localStorage" storageKey={GridType.Street.toString()} savingTimeout={500} /> */}
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column
                  alignment="left"
                  dataField="Id"
                  caption={t("id")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="accidentNumber" caption={t("accidentNumber")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="patientName"
                  defaultSortOrder="asc"
                  caption={t("patientName")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="patientId" caption={t("patientId")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="hospital" caption={t("hospital")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="admittedDate"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  caption={t("admissionDate")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="diagnosis" caption={t("diagnosisInjury")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="dischargedDate"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  caption={t("DischargedDateDeath")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                    dataField="DivisionId"
                    allowSorting={true}
                    visible={false}
                    caption={t("division")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                    <Lookup
                      dataSource={SortObjectByPropName(customerDivisions!, "name")}
                      valueExpr="id"
                      displayExpr="name"
                    />
                  </Column>
              </DataGrid>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default Patients;
