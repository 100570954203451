import React, { useEffect, useState } from 'react';
import ScrollView from "devextreme-react/scroll-view";
import { Form, SimpleItem } from "devextreme-react/form";
import { Button, DataGrid, Popup } from "devextreme-react";
import { t } from 'i18next';
import { useAuth } from '../../contexts/auth';
import notify from 'devextreme/ui/notify';
import { TrafficApiUrl } from '../../environment/routeSettings';
import { RequestResponseResult } from '../../types/general/generalTypes';
import { RequestErrorHandling, TesPost } from '../../utils/rest';
import { ResponseCode } from '../../types/general/enums/generalEnums';
import { RecalculationRequestDTO } from '../../types/trafficStudy/dtos/studyDto';
import { StudyType } from '../../types/trafficStudy/enums/trafficStudyEnums';

interface IPros {
    datagridRef: React.RefObject<DataGrid<any, any>>;
}

const RecalculateStudy = (props: IPros) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedStudyTypes, setSelectedStudyTypes] = useState<StudyType[]>([]);
    const { activeLoading } = useAuth();


    // useEffect(() => {
    //     async function fetchMyAPI() {
    //         try {
    //             if (activeLoading) activeLoading(true);

    //             if (activeLoading) activeLoading(false);
    //         } catch (ex) {
    //             if (activeLoading) activeLoading(false);
    //             notify(t("someErrorOccurred") + ex, "error", 5000);
    //         }
    //     }
    //     fetchMyAPI()
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, []);


    function onClick() {
        if ((props.datagridRef.current?.instance.getSelectedRowKeys().map(x => x.id) as string[]).length === 0) {
            notify(t('noSelectedRows'), 'warning', 3000)
            return
        }
        console.log(!selectedStudyTypes.includes(StudyType.Volume) && !selectedStudyTypes.includes(StudyType.Speed))
        setSelectedStudyTypes(props.datagridRef.current?.instance.getSelectedRowsData().map(x => x.studyType) as StudyType[])
        setShowModal(true)
    }

    function onClose() {
        setShowModal(false)
    }

    async function onRecalculateReportingPeriod() {
        try {
            const postObj: RecalculationRequestDTO = {
                customerId: localStorage.getItem("selectedCustomerId") as string,
                studyIds: props.datagridRef.current?.instance.getSelectedRowKeys().map(x => x.id) as string[]
            };
            if (activeLoading) activeLoading(true);
            const res: RequestResponseResult<null> = await TesPost(
                TrafficApiUrl() + "/api/Recalculations/ReportingPeriod",
                postObj,
                true
            );
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyAdded"), "success", 5000);
                onClose()
                props.datagridRef.current?.instance.refresh()
                return;
            } else if (res.responseCode === ResponseCode.WorkOrderExist) {
                notify(t("workOrderExist"), "error", 5000);
            } else {
                await RequestErrorHandling(res);;
            }
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }



    async function onRecalculateSignal() {
        try {
            const postObj: RecalculationRequestDTO = {
                customerId: localStorage.getItem("selectedCustomerId") as string,
                studyIds: props.datagridRef.current?.instance.getSelectedRowKeys().map(x => x.id) as string[]
            };
            if (activeLoading) activeLoading(true);
            const res: RequestResponseResult<null> = await TesPost(
                TrafficApiUrl() + "/api/Recalculations/SignalWarrants",
                postObj,
                true
            );
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyAdded"), "success", 5000);
                onClose()
                props.datagridRef.current?.instance.refresh()
                return;
            } else if (res.responseCode === ResponseCode.WorkOrderExist) {
                notify(t("workOrderExist"), "error", 5000);
            } else {
                await RequestErrorHandling(res);;
            }
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }


    async function onRecalculateAllWay() {
        try {
            const postObj: RecalculationRequestDTO = {
                customerId: localStorage.getItem("selectedCustomerId") as string,
                studyIds: props.datagridRef.current?.instance.getSelectedRowKeys().map(x => x.id) as string[]
            };
            if (activeLoading) activeLoading(true);
            const res: RequestResponseResult<null> = await TesPost(
                TrafficApiUrl() + "/api/Recalculations/AllWayStopWarrants",
                postObj,
                true
            );
            props.datagridRef.current?.instance.refresh()
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyAdded"), "success", 5000);
                onClose()
                return;
            } else if (res.responseCode === ResponseCode.WorkOrderExist) {
                notify(t("workOrderExist"), "error", 5000);
            } else {
                await RequestErrorHandling(res);
            }
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }

    async function onRecalculateSpeedStatistics() {
        try {
            const postObj: RecalculationRequestDTO = {
                customerId: localStorage.getItem("selectedCustomerId") as string,
                studyIds: props.datagridRef.current?.instance.getSelectedRowKeys().map(x => x.id) as string[]
            };
            if (activeLoading) activeLoading(true);
            const res: RequestResponseResult<null> = await TesPost(
                TrafficApiUrl() + "/api/Recalculations/SpeedStatistics",
                postObj,
                true
            );
            props.datagridRef.current?.instance.refresh()
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyAdded"), "success", 5000);
                onClose()
                return;
            } else if (res.responseCode === ResponseCode.WorkOrderExist) {
                notify(t("workOrderExist"), "error", 5000);
            } else {
                await RequestErrorHandling(res);;
            }
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }

    async function onRecalculateAADT() {
        try {
            const postObj: RecalculationRequestDTO = {
                customerId: localStorage.getItem("selectedCustomerId") as string,
                studyIds: props.datagridRef.current?.instance.getSelectedRowKeys().map(x => x.id) as string[]
            };
            if (activeLoading) activeLoading(true);
            const res: RequestResponseResult<null> = await TesPost(
                TrafficApiUrl() + "/api/Recalculations/AADT",
                postObj,
                true
            );
            props.datagridRef.current?.instance.refresh()
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyAdded"), "success", 5000);
                onClose()
                return;
            } else if (res.responseCode === ResponseCode.WorkOrderExist) {
                notify(t("workOrderExist"), "error", 5000);
            } else {
                await RequestErrorHandling(res);;
            }
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }

    return (
        <React.Fragment>

            <Button
                onClick={() => onClick()}
                icon={'fa-solid fa-calculator'}
                style={{ marginLeft: 5 }}
                hint={t('recalculateStudies')}
            />

            <Popup
                visible={showModal}
                width={"40%"}
                height={"auto"}
                resizeEnabled={true}
                showTitle={true}
                title={t("recalculateStudies")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onClose()}
            >
                <ScrollView width="100%" height="100%">
                    <Form colCount={2}>
                        <SimpleItem colSpan={1}>
                            <Button
                                onClick={() => onRecalculateReportingPeriod()}
                                icon={"fa-solid fa-calculator"}
                                text={t('reportingPeriods')}
                            />
                        </SimpleItem>
                        {(!selectedStudyTypes.includes(StudyType.Volume) && !selectedStudyTypes.includes(StudyType.Speed)) &&
                            <SimpleItem colSpan={1}>
                                <Button
                                    onClick={() => onRecalculateSignal()}
                                    icon={'fa-solid fa-calculator'}
                                    text={t('signalWarrants')}
                                />
                            </SimpleItem>
                        }
                        {(!selectedStudyTypes.includes(StudyType.Volume) && !selectedStudyTypes.includes(StudyType.Speed)) &&
                            <SimpleItem colSpan={1}>
                                <Button
                                    onClick={() => onRecalculateAllWay()}
                                    icon={'fa-solid fa-calculator'}
                                    text={t('allWayStopWarrant')}
                                />
                            </SimpleItem>
                        }

                        {(selectedStudyTypes.includes(StudyType.Speed) && !selectedStudyTypes.includes(StudyType.Volume) && !selectedStudyTypes.includes(StudyType.TMC)) &&
                            <SimpleItem colSpan={1}>
                                <Button
                                    onClick={() => onRecalculateSpeedStatistics()}
                                    icon={'fa-solid fa-calculator'}
                                    text={t('speedStatistics')}
                                />
                            </SimpleItem>
                        }
                        <SimpleItem colSpan={1}>
                            <Button
                                onClick={() => onRecalculateAADT()}
                                icon={'fa-solid fa-calculator'}
                                text={t('aadt')}
                            />
                        </SimpleItem>
                    </Form>
                    <div>
                        <div className="rightColumn" style={{ marginTop: 10 }}>

                            <Button
                                className="tes-modal-btn-add"
                                onClick={() => onClose()}
                                text={t("close")}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>

        </React.Fragment>
    )


}

export default RecalculateStudy;