import React, { useEffect, useRef, useState } from 'react';
import { StudyType, StudyValidationLogType } from '../../../../../../types/trafficStudy/enums/trafficStudyEnums';
import { MessageType } from '../../../../../../types/general/enums/generalEnums';
import { GeneralSetting } from '../../../../../../types/setting/gridSettingTypes';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { StudyBaseDTO } from '../../../../../../types/trafficStudy/dtos/studyDto';
import { NameValue } from '../../../../../../types/general/generalTypes';
import { Enum2Array } from '../../../../../../utils/enumTools';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Pager, Paging, Lookup, Button as DevExpressButton, Export, SortByGroupSummaryInfo, Summary, GroupItem, Search
} from 'devextreme-react/data-grid';
import { Popup, ValidationGroup } from 'devextreme-react';
import { SimpleTrafficCounterDTO } from '../../../../../../types/trafficStudy/dtos/TrafficCounterDTO';
import { ImportStatus } from '../../../../../../types/trafficStudy/enums/importEnums';
import { OnExporting } from '../../../../../../utils/dataGridTools';
import { SortObjectByPropName } from '../../../../../../utils/arrayTools';

// props
interface IPros {
    initDataBase: StudyBaseDTO,
    generalSettings: GeneralSetting | null;
    lstTrafficCounter: SimpleTrafficCounterDTO[] | undefined;
    validationRef: React.RefObject<ValidationGroup>;
}

const Logs = (props: IPros) => {
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const dataGrid2Ref = useRef<DataGrid<any, any>>(null);
    const [lstLogLevel, setLstLogLevel] = useState<NameValue[]>([]);
    const [lstLogType, setLstLogType] = useState<NameValue[]>([]);
    const [lstStudyType, setLstStudyType] = useState<NameValue[]>([]);
    const [lstStatus, setLstStatus] = useState<NameValue[]>([]);
    const [viewModal, setViewModal] = useState<boolean>(false);
    const [note, setNote] = useState<string>("");
    const { t } = useTranslation();
    const compactViewModel: boolean =(JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                setLstLogLevel(Enum2Array(MessageType));
                setLstLogType(Enum2Array(StudyValidationLogType));
                setLstStudyType(Enum2Array(StudyType));
                setLstStatus(Enum2Array(ImportStatus));
            } catch (ex) {
                notify(t("someErrorOccurred") + ex, "error", 2500);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    function onView(d: any) {
        setViewModal(true)
        setNote(d.row.data?.note)
    }

    return (
        <div className={`studyDetails-validations ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
            <div className='row' style={{ margin: "1.5rem" }}>
                <ValidationGroup
                    ref={props.validationRef}>
                    <p className={"detailsHeading"}>{t("logs")}</p>
                    {!compactViewModel && <hr className="line" style={{ marginTop: "1rem" }}></hr>}
                    <DataGrid id="logsGrid"
                        ref={dataGridRef}
                        dataSource={props.initDataBase?.logs ?? []}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        hoverStateEnabled={true}
                        remoteOperations={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        columnAutoWidth={true}
                        style={{ margin: "0 1rem 1rem" }}
                        onExporting={OnExporting}
                    >
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging enabled={true} defaultPageSize={100} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[100, 200, 300, 400, 500]}
                            showNavigationButtons={true}
                            showInfo={true}
                            visible={true}
                        />
                        <FilterRow visible={true}
                            applyFilter="auto" />

                        <HeaderFilter visible={true} />

                        <SearchPanel visible={true}
                            width={285}
                            placeholder={t("search...")} />
                        <ColumnChooser width={350} height={400}
                            enabled={true}
                            mode="select"
                            sortOrder="asc"
                        >
                            <Search enabled />
                        </ColumnChooser>
                        <SortByGroupSummaryInfo
                            summaryItem="Total Count"
                            sortOrder="desc"
                        />
                        <Summary>
                            <GroupItem
                                summaryType="count"
                                alignByColumn
                                name="Total Count"
                            />
                        </Summary>
                        <Column dataField="id" caption={t("id")} visible={false}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column dataField="description" caption={t("description")} visible={true}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column dataField="note" caption={t("note")} visible={true}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column
                            dataField="logDT"
                            caption={t("logDT")}
                            dataType="datetime"
                            visible={true}
                            format={props.generalSettings?.dateTimeFormat}
                        >
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column dataField="logLevel" caption={t("logLevel")}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                            <Lookup
                                dataSource={SortObjectByPropName(lstLogLevel, "name")}
                                valueExpr="value"
                                displayExpr="name"
                            />
                        </Column>
                        <Column dataField="logType" caption={t("logType")}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                            <Lookup
                                dataSource={SortObjectByPropName(lstLogType, "name")}
                                valueExpr="value"
                                displayExpr="name"
                            />
                        </Column>
                    </DataGrid>

                    <p className={"detailsHeading"}>{t("importLogs")}</p>
                    {!compactViewModel && <hr className="line" style={{ marginTop: "1rem" }}></hr>}
                    <DataGrid id="importLogsGrid"
                        ref={dataGrid2Ref}
                        dataSource={props.initDataBase?.importLogs ?? []}
                        rowAlternationEnabled={true}
                        showBorders={true}
                        hoverStateEnabled={true}
                        remoteOperations={true}
                        allowColumnReordering={true}
                        allowColumnResizing={true}
                        // columnAutoWidth={true}
                        style={{ margin: "0 1rem 1rem" }}
                        onExporting={OnExporting}
                    >
                        <Export enabled={true} allowExportSelectedData={true} />
                        <Paging enabled={true} defaultPageSize={100} />
                        <Pager
                            showPageSizeSelector={true}
                            allowedPageSizes={[100, 200, 300, 400, 500]}
                            showNavigationButtons={true}
                            showInfo={true}
                            visible={true}
                        />
                        <FilterRow visible={true}
                            applyFilter="auto" />

                        <HeaderFilter visible={true} />

                        <SearchPanel visible={true}
                            width={285}
                            placeholder={t("search...")} />
                        <ColumnChooser width={350} height={400}
                            enabled={true}
                            mode="select"
                            sortOrder="asc"
                        >
                            <Search enabled />
                        </ColumnChooser>
                        <SortByGroupSummaryInfo
                            summaryItem="Total Count"
                            sortOrder="desc"
                        />
                        <Summary>
                            <GroupItem
                                summaryType="count"
                                alignByColumn
                                name="Total Count"
                            />
                        </Summary>
                        <Column dataField="trafficCounterId" caption={t("trafficCounterId")} >
                            <Lookup dataSource={props.lstTrafficCounter} valueExpr={"id"} displayExpr={"name"} />
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>

                        <Column dataField="binMapId" caption={t("binMapId")} >
                            <Lookup dataSource={props.lstTrafficCounter?.flatMap(x => x.binMaps)!} valueExpr={"id"} displayExpr={"name"} />
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>

                        <Column dataField="fileKey" caption={t("fileKey")}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>

                        <Column dataField="studyType" caption={t("studyType")} >
                            <Lookup dataSource={SortObjectByPropName(lstStudyType, "name")} valueExpr={"value"} displayExpr={"name"} />
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>

                        <Column dataField="status" caption={t("status")}>
                            <Lookup dataSource={SortObjectByPropName(lstStatus, "name")} valueExpr={"value"} displayExpr={"name"} />
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column dataField="note" caption={t("note")} visible={true}>
                            <HeaderFilter>
                                <Search enabled />
                            </HeaderFilter>
                        </Column>
                        <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                            <DevExpressButton hint={t('view')} onClick={onView} icon="fa-solid fa-eye">

                            </DevExpressButton>

                        </Column>
                    </DataGrid>
                </ValidationGroup>
            </div>


            <Popup
                width={"50%"}
                height={"auto"}
                visible={viewModal}
                resizeEnabled={true}
                showTitle={true}
                title={t("note")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setViewModal(false)}
            >
                <p>{note}</p>
            </Popup>
        </React.Fragment>
        </div>
    );

}
export default Logs;

