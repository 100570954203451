import React, { useEffect, useState } from "react";
import Tabs from "devextreme-react/tabs";
import tabTitles from "./data/index";
//component imports
import Details from "./components/details/details";
import Locations from "./components/location/location";
import {
  ITrafficSectionParams,
  Location,
  TrafficSection,
} from "../../../types/infrastructure/infrastructureTypes";
import { RequestErrorHandling, TesGet, TesPost, TesPut } from "../../../utils/rest";
import { useNavigate, useParams } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { Button } from "devextreme-react";
import { t } from "i18next";
import { custom } from "devextreme/ui/dialog";
import Permission from "../../../components/permission/permision";
import { InfrastructurePermissions } from "../../../constants/Permissions";
import { useAuth } from "../../../contexts/auth";
import { AuthApiUrl, InfrastructureApiUrl } from "../../../environment/routeSettings";
import { VMLocation } from "../../../types/infrastructure/dto/locationdDto";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { PagePermission, ResponseCode } from "../../../types/general/enums/generalEnums";
import getTitle from "../../../components/title/getTitle";

const TrafficSectionDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const params = useParams<ITrafficSectionParams>();
  const [backPermission, setBackPermission] = useState(false);
  const [lstUserIds, setLstUserIds] = useState<string[]>();
  const [lstGroupIds, setLstGroupIds] = useState<string[]>();
  const [isLocked, setIsLocked] = useState(true);
  const [intersections, setIntersections] = useState<Location[]>([]);
  const [roadSegments, setRoadSegments] = useState<Location[]>([]);
  const [trafficSection, setTrafficSection] = useState<TrafficSection>(new TrafficSection());
  const [initDataTrafficSection, setInitDataTrafficSection] = useState<TrafficSection>(new TrafficSection());
  const [initDataUser, setInitDataUser] = useState([]);
  const [initDataGroup, setInitDataGroup] = useState([]);
  const { activeLoading } = useAuth();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { getPagePermission } = useAuth();
  const [pagePermissionStatus, setPagePermissionStatus] = useState<PagePermission>(PagePermission.Deny);

  const title = getTitle('/infrastructure/trafficSectionDetails', `${initDataTrafficSection.name}`);
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataGroup();
        await getInitialDataUser();
        if (params.sectionId !== "AddNew") {
          await getInitDataTrafficSection(params.sectionId!);
        } else {
          setIsLocked(false);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.sectionId]);

  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  async function getInitDataTrafficSection(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = await TesGet(InfrastructureApiUrl() + "/api/TrafficSections/GetTrafficSectionDetails/" + id, true) as RequestResponseResult<TrafficSection>;
      if (res.responseCode === ResponseCode.OK) {
        setInitDataTrafficSection(res.results);
        if (getPagePermission) setPagePermissionStatus(getPagePermission([InfrastructurePermissions.Infrastructure_D_Setups,
          InfrastructurePermissions.Infrastructure_D_Street,], res.results));
        if (backPermission === true) {
          history(-1);
        }
        if (activeLoading) activeLoading(false);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  function changeLockStatus() {
    setIsLocked(!isLocked);
  }

  //details functions
  const onChangeDetailsFields = (key: string, value: any) => {
    setTrafficSection({ ...trafficSection, [key]: value });
  };

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.sectionId !== "AddNew") {
                  //updateIntersection()
                } else {
                  addSectionList();
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  async function update() {
    let postObj: TrafficSection = {
      customerId: localStorage.getItem("selectedCustomerId") as string,
      name: initDataTrafficSection.name,
      locations: initDataTrafficSection.locations,
      divisionId: initDataTrafficSection.divisionId
    };
    try {
      await TesPost(InfrastructureApiUrl() + "/api/TrafficSections/UpdateTrafficSection", postObj, true);
      await getInitDataTrafficSection(params.sectionId!);
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (backPermission === true) {
        history(-1);
        return;
      }
    } catch {
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }
  //add SectionList function
  async function addSectionList() {
    try {
      let postObj: TrafficSection = {
        customerId: localStorage.getItem("selectedCustomerId") as string,
        name: initDataTrafficSection.name,
        locations: initDataTrafficSection.locations,
        divisionId: initDataTrafficSection.divisionId
      };
      await TesPost(InfrastructureApiUrl() + "/api/TrafficSections/AddTrafficSection", postObj, true);
      history(-1);
      notify(t('dataSuccessfullyUpdated'), 'success', 5000);
      if (activeLoading) activeLoading(false);
    } catch {
      notify('someErrorOccured', 'error', 5000);
    }
  }


  //permissions function
  async function getInitialDataUser() {
    setInitDataUser(
      await TesGet(
        AuthApiUrl() +
        "/api/user/GetUsersOfClient/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }

  async function getInitialDataGroup() {
    setInitDataGroup(
      await TesGet(
        AuthApiUrl() +
        "/api/Groups/" +
        localStorage.getItem("selectedCustomerId"),
        true
      )
    );
  }
  // async function getInitialDataShareArea(id: string) {
  //   setInitDataShare(
  //     await TesGet(
  //       InfrastructureApiUrl() + "/api/Areas/GetUserSharedArea/" + id,
  //       true
  //     )
  //   );
  // }

  function onPermissionChange(data: any) {
    setLstGroupIds(data.lstGroupIds);
    setLstUserIds(data.lstUserIds);
  }

  //Location Functions
  // async function getAreaLocations(areaId: string) {
  //   const response = await TesGet(
  //     InfrastructureApiUrl() + "/api/Areas/GetLocations/" + areaId,
  //     true
  //   );
  //   setIntersections(
  //     response
  //       .filter((x: any) => x.locationType == LocationType.Intersection)
  //       .map((x: any) => ({
  //         id: x.id,
  //         description: x.description,
  //         geoId: x.geoId,
  //       }))
  //   );
  //   setRoadSegments(
  //     response
  //       .filter((x: any) => x.locationType == LocationType.Midblock)
  //       .map((x: any) => ({
  //         id: x.id,
  //         description: x.description,
  //         geoId: x.geoId,
  //       }))
  //   );
  // }

  function updateIntersection(lstIntersections: Location[] | undefined) {
    if (lstIntersections !== undefined) {
      setIntersections(lstIntersections);
    }
  }

  function updateRoadSegment(lstRoadSegments: Location[] | undefined) {
    if (lstRoadSegments !== undefined) {
      setRoadSegments(lstRoadSegments);
    }
  }

  return (
    <Permission
      allowed={[InfrastructurePermissions.Infrastructure_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={`siteListDetails ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              {params.sectionId !== "AddNew" && (
                <div>
                  {initDataTrafficSection.name &&
                    <p className={"detailsHeading"}>
                      <span>{t("trafficSection")}: </span><span>{initDataTrafficSection?.name}</span>
                    </p>
                  }
                  <hr className="line"></hr>
                </div>
              )}
              <div className="row" style={{ marginTop: 15 }}>
                <div className="leftColumn">
                  <Button
                    onClick={() => goBackPermission()}
                    icon="fa-solid fa-arrow-left"
                    hint={t("goBack")}
                  />
                </div>
                <div className="rightColumn">
                  {params.sectionId === "AddNew" && (
                    <Button
                      onClick={() => addSectionList()}
                      style={{ marginRight: '10px' }}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("save")}
                    />
                  )}
                  {params.sectionId !== "AddNew" && isLocked === false && (
                    <Button
                      onClick={() => update()}
                      icon="fa-solid fa-floppy-disk"
                      style={{ marginRight: 10 }}
                      hint={t("update")}
                    />
                  )}
                  {pagePermissionStatus === PagePermission.Edit &&  params.areaId !== "AddNew" && 
                  <Button
                    onClick={() => changeLockStatus()}
                    icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                    hint={isLocked ? t("unlock") : t("lock")}
                  />}
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <Tabs
                width={"100%"}
                dataSource={tabTitles}
                selectedIndex={selectedIndex}
                onOptionChanged={onTabsSelectionChanged}
              />

              {selectedIndex === 0 && (
                <Details
                  initDataTrafficSection={initDataTrafficSection}
                  setInitDataTrafficSection={setInitDataTrafficSection}
                  onChangeDetailsFields={onChangeDetailsFields}
                  isLocked={isLocked}
                />
              )}

              {selectedIndex === 1 && (
                <Locations
                  initDataTrafficSection={initDataTrafficSection}
                  setInitDataTrafficSection={setInitDataTrafficSection}
                  sectionId={params.sectionId!}
                  updateIntersection={updateIntersection}
                  updateRoadSegment={updateRoadSegment}
                  intersections={intersections !== undefined ? intersections : []}
                  roadSegments={roadSegments !== undefined ? roadSegments : []}
                  setDataChanged={setDataChanged}
                  isLocked={isLocked}
                />
              )}
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default TrafficSectionDetails;
