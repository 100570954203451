import React, { useEffect, useState } from "react";
import { Form, SimpleItem } from "devextreme-react/form";
import { useTranslation } from "react-i18next";
import { NumberBox, SelectBox } from "devextreme-react";
import { Enum2Array } from "../../../../../../utils/enumTools";
import { NameValue } from "../../../../../../types/general/generalTypes";
import { AADTSourceType, AADTType } from "../../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { StudyAADTCalculationResult } from "../../../../../../types/trafficStudy/dtos/studyDto";
import { SortObjectByPropName } from "../../../../../../utils/arrayTools";

// props
interface IPros {
  initData: StudyAADTCalculationResult,
  aadtType: string,
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
}

const VolumeHistory = (props: IPros) => {
  const { t } = useTranslation();
  const [lstSourceType, setLstSourceType] = useState<NameValue[]>([]);
  const compactViewModel: boolean =(JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  useEffect(() => {
    setLstSourceType(Enum2Array(AADTSourceType));
  }, []);

  function onValueChangeIntersection(name: string, value: any) {
    props.setInitData({ ...props.initData, intersectionAADT: { ...props.initData.intersectionAADT, [name]: value } })
    props.setDataChanged(true)
  }
  function onValueChangeRoadSegment(name: string, value: any) {
    props.setInitData({ ...props.initData, roadSegmentAADT: { ...props.initData.roadSegmentAADT, [name]: value } })
    props.setDataChanged(true)
  }

  return (
    <div className={`aadtDetails-aadt ${compactViewModel ? "compactStyle" : ""}`}>
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        {props.aadtType.toString() === AADTType.Intersection.toString()
          &&
          <Form colCount={3}>
             <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                label={t('year')}
                labelMode='static'
                value={props.initData?.intersectionAADT?.year}
                onValueChange={(e) => onValueChangeIntersection("year", e)}
                className="modalInput"
                disabled={true}
                style={{marginBottom:"0"}}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                format="#,###"
                step={0}
                label={t('majorVolumeAadt')}
                labelMode='static'
                value={props.initData?.intersectionAADT?.majorVolume}
                onValueChange={(e) => onValueChangeIntersection("majorVolume", e)}
                className="modalInput"
                disabled={props.isLocked}
                style={{marginBottom:"0"}}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                format="#,###"
                step={0}
                label={t('minorVolumeAadt')}
                labelMode='static'
                value={props.initData?.intersectionAADT?.minorVolume}
                onValueChange={(e) => onValueChangeIntersection("minorVolume", e)}
                className="modalInput"
                disabled={props.isLocked}
                style={{marginBottom:"0"}}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                format="#,###"
                step={0}
                label={t('northApproachAADT')}
                labelMode='static'
                value={props.initData?.intersectionAADT?.northApproachAADT}
                onValueChange={(e) => onValueChangeIntersection("northApproachAADT", e)}
                className="modalInput"
                disabled={props.isLocked}
                style={{marginBottom:"0"}}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                format="#,###"
                step={0}
                label={t('eastApproachAADT')}
                labelMode='static'
                value={props.initData?.intersectionAADT?.eastApproachAADT}
                onValueChange={(e) => onValueChangeIntersection("eastApproachAADT", e)}
                className="modalInput"
                disabled={props.isLocked}
                style={{marginBottom:"0"}}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                format="#,###"
                step={0}
                label={t('southApproachAADT')}
                labelMode='static'
                value={props.initData?.intersectionAADT?.southApproachAADT}
                onValueChange={(e) => onValueChangeIntersection("southApproachAADT", e)}
                className="modalInput"
                disabled={props.isLocked}
                style={{marginBottom:"0"}}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                format="#,###"
                step={0}
                label={t('westApproachAADT')}
                labelMode='static'
                value={props.initData?.intersectionAADT?.westApproachAADT}
                onValueChange={(e) => onValueChangeIntersection("westApproachAADT", e)}
                className="modalInput"
                disabled={props.isLocked}
                style={{marginBottom:"0"}}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t('sourceType')}
                valueExpr="value"
                displayExpr="name"
                labelMode='static'
                value={props.initData?.intersectionAADT?.sourceType}
                onValueChange={(e) => onValueChangeIntersection("sourceType", e)}
                className="modalInput"
                items={SortObjectByPropName(lstSourceType, "name")}
                disabled={props.isLocked}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                format="#,###"
                step={0}
                label={t('totalVolumeAadt')}
                labelMode='static'
                value={props.initData?.intersectionAADT?.majorVolume + props.initData?.intersectionAADT?.minorVolume}
                onValueChange={(e) => onValueChangeIntersection("totalVolume", e)}
                className="modalInput"
                disabled={true}
                style={{marginBottom:"0"}}
              />
            </SimpleItem>
          </Form>
        }
        {props.aadtType.toString() === AADTType.RoadSegment.toString() &&
          <Form colCount={3}>
             <SimpleItem colSpan={1}>
              <NumberBox
                step={0}
                label={t('year')}
                labelMode='static'
                value={props.initData?.roadSegmentAADT?.year}
                onValueChange={(e) => onValueChangeRoadSegment("year", e)}
                className="modalInput"
                disabled={true}
                style={{marginBottom:"0"}}
              />
            </SimpleItem>
            <SimpleItem colSpan={1}>
              <NumberBox
                format="#,###"
                step={0}
                label={t('totalVolumeAadt')}
                labelMode='static'
                value={props.initData?.roadSegmentAADT?.segmentVolume}
                onValueChange={(e) => onValueChangeRoadSegment("segmentVolume", e)}
                className="modalInput"
                disabled={true}
                style={{marginBottom:"0"}}
              />
            </SimpleItem>
            {/* <SimpleItem colSpan={1}>
              <NumberBox
                format="#,###"
                step={0}
                label={t('totalVolume')}
                labelMode='static'
                value={props.initData?.roadSegmentAADT?.segmentVolume}
               // onValueChange={(e) => onValueChangeRoadSegment("totalVolume", e)}
                className="modalInput"
                disabled={true}
                style={{marginBottom:"0"}}
              />
            </SimpleItem> */}
            <SimpleItem colSpan={1}>
              <SelectBox
                placeholder=""
                label={t('sourceType')}
                valueExpr="value"
                displayExpr="name"
                labelMode='static'
                value={props.initData?.roadSegmentAADT?.sourceType}
                onValueChange={(e) => onValueChangeRoadSegment("sourceType", e)}
                className="modalInput"
                items={SortObjectByPropName(lstSourceType, "name")}
                disabled={true}
                showClearButton={true}
                searchEnabled={true}
              />
            </SimpleItem>
          </Form>
        }
      </div>
    </React.Fragment>
    </div>
  );
};
export default VolumeHistory;
