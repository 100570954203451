import React from "react";
import { Form, SimpleItem } from "devextreme-react/form";
import { useTranslation } from "react-i18next";
import { DateBox, TextBox } from "devextreme-react";
import { PermanentCountStation } from "../../../../../types/infrastructure/infrastructureTypes";
import { GeneralSetting } from "../../../../../contexts/clientSetting";

// props
interface IPros {
  initData: PermanentCountStation,
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  generalSettings: GeneralSetting | null;
}

const General = (props: IPros) => {
  const { t } = useTranslation();


  function onValueChange(name: string, value: any) {
    props.setInitData({ ...props.initData, [name]: value })
    props.setDataChanged(true)
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2}>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("name")}
              labelMode="static"
              value={props.initData?.name}
              onValueChange={(e) => onValueChange("name", e)}
              disabled={props.isLocked}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("description")}
              labelMode="static"
              value={props.initData?.description}
              onValueChange={(e) => onValueChange("description", e)}
              disabled={props.isLocked}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <DateBox
                label={t("installationDate")}
                value={props.initData?.installationDate}
                type="date"
                onValueChange={(e) => onValueChange("installationDate", e)}
                pickerType="calendar"
                placeholder={props.generalSettings?.dateFormat}
                displayFormat={props.generalSettings?.dateFormat}
                useMaskBehavior={true}
                openOnFieldClick={true}
                showClearButton={true}
            />
          </SimpleItem>
        </Form>
      </div>
    </React.Fragment>
  );
};
export default General;
