import React, { useCallback, useEffect } from 'react';
import { Form, SimpleItem } from 'devextreme-react/form';
import { useTranslation } from 'react-i18next';
import { LocationPickerMode, LocationType } from '../../../../../types/infrastructure/enums/infrastructureEnums';
import InfrastructureLocationPicker from '../../../../../components/infrastructureLocationPicker/infrastructureLocationPicker';
import { TesMapModule } from '../../../../../types/general/enums/generalEnums';
import { VMMapLocation } from '../../../../../types/collision/dto/collisionDtos';
import { useAuth } from '../../../../../contexts/auth';
import notify from 'devextreme/ui/notify';
import { SignalWarrantsPredictionResult } from '../../../../../types/systematicScreening/systematicScreeningTypes';
import { PermanentCountStation } from '../../../../../types/infrastructure/infrastructureTypes';
import { VMLocation } from '../../../../../types/infrastructure/dto/locationdDto';

// props
interface IPros {
    initData: PermanentCountStation,
    setInitData: React.Dispatch<React.SetStateAction<any>>;
    didMount: boolean,
    tesModule: TesMapModule;
    componentMode: LocationPickerMode;
    isLocked: boolean
}

const Map = (props: IPros) => {
    const { t } = useTranslation();
    const { activeLoading } = useAuth();

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function setInfrastructureData(
        inData: VMLocation) {
        props.setInitData({
          ...props.initData,
          locationId: inData.id,
        });
      }


    // const handleLocationChange = useCallback(async (loc: VMMapLocation) => {
    //     props.setInitData({ ...props.initData })

    //   }, []);

    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <Form colCount={2}>
                    <SimpleItem colSpan={2}>
                        <InfrastructureLocationPicker
                        renderMap={true}
                        didMount={props.didMount}
                        setInfrastructureData={setInfrastructureData}
                        initLocationData={{
                          customerId: props.initData.customerId,
                          id: props.initData.locationId,
                        }}
                        tesModule={TesMapModule.CollisionDetails}
                        // handleLocationChange={handleLocationChange}
                        // tempMapLocation={props.tempMapLocation}
                        componentMode={LocationPickerMode.TextBox}
                        // selectedMapLocation={props.mapLocation}
                        renderCoordinate={false}
                        isLocked={props.isLocked}
                        showIcons={true}
                        />
                    </SimpleItem>
                </Form>
            </div>
        </React.Fragment>
    );


}
export default Map;