import React from 'react';
import { Location } from '../../../../../types/infrastructure/infrastructureTypes';
import { TesField } from '../../../../../types/field/fieldType';
import { FieldCategoryType, FieldPermission, FieldType } from '../../../../../types/field/enums/fieldEnums';
import { Switch } from 'devextreme-react/switch';
import DateBox from 'devextreme-react/date-box';
import { SelectBox, TextBox, ValidationGroup } from 'devextreme-react';
import { GeneralSetting } from '../../../../../types/setting/gridSettingTypes';
import { t } from 'i18next';
import { PageMode } from '../../../../../types/general/enums/generalEnums';
import { DatePickerDateCalculator } from '../../../../../utils/dateTimeTools';
import { SimpleItem, Form } from 'devextreme-react/form';
import { INameId } from '../../../../../types/general/generalTypes';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';


interface IPros {
    initDataFields: TesField[] | undefined;
    siteSubeTypes: INameId[] | undefined;
    location: Location;
    setLocation: React.Dispatch<React.SetStateAction<any>>
    generalSettings: GeneralSetting | null;
    isLocked: boolean;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    pageMode: number
    validationRef: React.RefObject<ValidationGroup>
}
const Operation = (props: IPros) => {

    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
    function onChangeFields(name: string, value: any, isUpperCase: boolean) {
        props.setLocation({
            ...props.location,
            general: {
                ...props.location.general,
                [name]: value
            }
        })
        if (props.pageMode === PageMode.Update) {
            props.setDataChanged(true)
        }
    }

    function onChangeConstantFields(name: string, value: any) {
        props.setLocation({
            ...props.location,
            [name]: value
        })
        if (props.pageMode === PageMode.Update) {
            props.setDataChanged(true);
        }
    }

    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <ValidationGroup
                    ref={props.validationRef}
                >


                    <Form colCount={2}>
                        <SimpleItem colSpan={1}>
                            <SelectBox
                                placeholder=""
                                label={t('siteSubType')}
                                labelMode='static'
                                items={props.siteSubeTypes}
                                displayExpr="name"
                                valueExpr="id"
                                value={props.location.siteSubTypeId}
                                onValueChange={e => onChangeConstantFields("siteSubTypeId", e)}
                                disabled={props.isLocked}
                                showClearButton={true}
                                searchEnabled={true}
                            />
                        </SimpleItem>
                        {/* eslint-disable-next-line array-callback-return */}
                        {props.initDataFields && props.initDataFields.filter(x => x.fieldCategoryType === FieldCategoryType.InfrastructureMidBlockOperation).map((f, i) => {
                            if (f.fieldType === FieldType.String) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <TextBox
                                            style={{ marginBottom: !compactViewModel ? 20 : 0 }}
                                            label={f.labelText}
                                            labelMode='static'
                                            value={props.location.general && props.location.general[f.name]}
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            disabled={props.isLocked}
                                        />
                                    </SimpleItem>
                                );
                            }
                            if (f.fieldType === FieldType.Number || f.fieldType === FieldType.DecimalNumber || f.fieldType === FieldType.DoubleNumber) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <TextBox
                                            style={{ marginBottom: !compactViewModel ? 20 : 0 }}
                                            label={f.labelText}
                                            labelMode='static'
                                            value={props.location.general && props.location.general[f.name]}
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            disabled={props.isLocked}
                                        />
                                    </SimpleItem>
                                );
                            }

                            if (f.fieldType === FieldType.Boolean) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <div style={{ fontWeight: "bold" }}>{f.labelText}</div>
                                        <Switch
                                            style={{ marginBottom: !compactViewModel ? 20 : 0 }}
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            value={props.location.general && props.location.general[f.name]}
                                            key={i}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                        />
                                    </SimpleItem>
                                );
                            }

                            if (f.fieldType === FieldType.Date) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <DateBox
                                            style={{ marginBottom: !compactViewModel ? 20 : 0 }}
                                            label={f.labelText}
                                            value={f.exactDT === true ? DatePickerDateCalculator(props.location.general[f.name]) : props.location.general[f.name]}
                                            dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                            type="date"
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            pickerType="calendar"
                                            placeholder={props.generalSettings?.dateFormat}
                                            displayFormat={props.generalSettings?.dateFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            showClearButton={true}
                                        />
                                    </SimpleItem>
                                );
                            }

                            if (f.fieldType === FieldType.Time) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <DateBox
                                            style={{ marginBottom: !compactViewModel ? 20 : 0 }}
                                            label={f.labelText}
                                            value={f.exactDT === true ? DatePickerDateCalculator(props.location.general[f.name]) : props.location.general[f.name]}
                                            dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                            type="time"
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            pickerType="rollers"
                                            placeholder={props.generalSettings?.timeFormat}
                                            displayFormat={props.generalSettings?.timeFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            showClearButton={true}
                                        />
                                    </SimpleItem>
                                );
                            }

                            if (f.fieldType === FieldType.DateTime) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <DateBox
                                            style={{ marginBottom: !compactViewModel ? 20 : 0 }}
                                            label={f.labelText}
                                            value={f.exactDT === true ? DatePickerDateCalculator(props.location.general[f.name]) : props.location.general[f.name]}
                                            dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                                            type="datetime"
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            pickerType="calendar"
                                            placeholder={props.generalSettings?.dateTimeFormat}
                                            displayFormat={props.generalSettings?.dateTimeFormat}
                                            useMaskBehavior={true}
                                            openOnFieldClick={true}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            showClearButton={true}
                                        />
                                    </SimpleItem>
                                );
                            }


                            if (f.fieldType === FieldType.List) {
                                return (
                                    <SimpleItem colSpan={1}>
                                        <SelectBox
                                            placeholder=""
                                            style={{ marginBottom: !compactViewModel ? 20 : 0 }}
                                            items={SortObjectByPropName(f.fieldValues, "name")}
                                            displayExpr="name"
                                            valueExpr="id"
                                            labelMode='static'
                                            label={f.labelText}
                                            value={props.location.general && props.location.general[f.name]}
                                            onValueChange={e => onChangeFields(f.name, e, true)}
                                            key={i}
                                            showClearButton={true}
                                            disabled={props.isLocked || f.permission === FieldPermission.View || f.readOnlyField}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                );
                            }

                        })
                        }
                    </Form>
                </ValidationGroup>
            </div>
        </React.Fragment>
    );
}
export default Operation;