import React, { useEffect, useState } from "react";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import notify from "devextreme/ui/notify";
import { Button, NumberBox, Switch, TagBox } from "devextreme-react";
import "./signalWarrantSetups.scss";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import Form, { SimpleItem } from "devextreme-react/form";
import { NameValueNumber, RequestResponseResult } from "../../../../types/general/generalTypes";
import { TrafficApiUrl } from "../../../../environment/routeSettings";
import Permission from "../../../../components/permission/permision";
import SafetyPermissions from "../../../../constants/Permissions/SafetyPermissions";
import { SignalWarrantsPredictionSettings } from "../../../../types/systematicScreening/systematicScreeningTypes";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { TMSPermissions } from "../../../../constants/Permissions";

const SignalWarrantSetup = () => {
  const [initData, setInitData] = useState<SignalWarrantsPredictionSettings>(new SignalWarrantsPredictionSettings())
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const [isLocked, setIsLocked] = useState(true);
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const currentYear = new Date().getFullYear();
  const last15Years: NameValueNumber[] = Array.from({ length: 15 }, (_, index) => {
    const year = currentYear - index;
    return { name: year.toString(), value: year };
  });

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    const res = (await TesGet(TrafficApiUrl() + "/api/SignalWarrantPredictionAnalysis/GetSignalWarrantPredictionAnalysisSettings/" + localStorage.getItem('selectedCustomerId'), true)) as RequestResponseResult<SignalWarrantsPredictionSettings>;
    setInitData(res.results)
  };

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  function onValueChange(name: string, value: any) {
    setInitData({ ...initData, [name]: value });
  }

  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      let postObj: SignalWarrantsPredictionSettings = { ...initData, customerId: localStorage.getItem('selectedCustomerId')! }
      const res = (await TesPost(
        TrafficApiUrl() + "/api/SignalWarrantPredictionAnalysis/AddUpdateSignalWarrantPredAnalysisSettings",
        postObj,
        true
      )) as RequestResponseResult<SignalWarrantsPredictionSettings>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results);
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  return (
    <Permission
      allowed={[TMSPermissions.TMS_Admin]}
      hasFeedBackElement={true}
    >
      <div className={`empiricalBayes ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("signalWarrant")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card"}>
              <div className="row" style={{ marginTop: 15 }}>
                <div className="rightColumn">
                  <Button
                    id="rowButtons"
                    onClick={() => LockHandler()}
                    icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                    hint={isLocked ? t("unlock") : t("lock")}
                  />
                </div>
                <div className="rightColumn">
                  {isLocked === false && (
                    <Button
                      id="rowButtons"
                      onClick={() => onUpdate()}
                      icon="fa-solid fa-floppy-disk"
                      hint={t("update")}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className={"dx-card "}>
              <Form colCount={2}>
                <SimpleItem colSpan={1} >
                  <NumberBox
                    label={t("distanceThreshold")}
                    labelMode="static"
                    value={initData?.distanceThreshold}
                    onValueChange={(e) => onValueChange("distanceThreshold", e)}
                    disabled={isLocked}
                  />
                </SimpleItem>


                <SimpleItem colSpan={1} >
                  <NumberBox
                    label={t("predictionYear")}
                    labelMode="static"
                    value={initData?.predictionYear}
                    onValueChange={(e) => onValueChange("predictionYear", e)}
                    disabled={isLocked}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1} >
                  <NumberBox
                    label={t("pastYearsPrediction")}
                    labelMode="static"
                    value={initData?.pastYearsPrediction}
                    onValueChange={(e) => onValueChange("pastYearsPrediction", e)}
                    disabled={isLocked}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1} >
                  <NumberBox
                    label={t("maxGrowthRateThreshold")}
                    labelMode="static"
                    value={initData?.maxGrowthRateThreshold}
                    onValueChange={(e) => onValueChange("maxGrowthRateThreshold", e)}
                    disabled={isLocked}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1} >
                  <NumberBox
                    label={t("minGrowthRateThreshold")}
                    labelMode="static"
                    value={initData?.minGrowthRateThreshold}
                    onValueChange={(e) => onValueChange("minGrowthRateThreshold", e)}
                    disabled={isLocked}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1} >
                  <NumberBox
                    label={t("municipalityDefaultGrowth")}
                    labelMode="static"
                    value={initData?.municipalityDefaultGrowth}
                    onValueChange={(e) => onValueChange("municipalityDefaultGrowth", e)}
                    disabled={isLocked}
                  />
                </SimpleItem>

                <SimpleItem colSpan={1} >
                  <div >{t('runForAllYears')}</div>
                  <Switch
                    style={{ marginTop: 5, fontWeight: 'bold' }}
                    value={initData?.runForAllYears}
                    onValueChange={e => onValueChange(
                      "runForAllYears", e)}
                    hint={t('runForAllYears')}
                    disabled={isLocked}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <TagBox
                    label={t("excludeYears")}
                    labelMode="static"
                    className="modalInput"
                    dataSource={SortObjectByPropName(last15Years, "name")}
                    value={initData?.excludeYears}
                    valueExpr="value"
                    displayExpr="name"
                    onValueChange={(e) => onValueChange("excludeYears", e)}
                    disabled={isLocked}
                  />
                </SimpleItem>

              </Form>
            </div>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};

export default SignalWarrantSetup;
