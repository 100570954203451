import React, { useState } from 'react';
import { TesPost } from '../../../../../utils/rest';
import { Button } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';
import { ScrollView } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { AttachmentDownloadURL } from '../../../../../types/general/generalTypes';
import { NewAttachmentType } from '../../../../../types/sign/enums/signEnums';
import { CollisionApiUrl } from '../../../../../environment/routeSettings';
import { AttachmentTypeFinder, FileKeyCutterWithExtension } from '../../../../../utils/fileTools';
import { t } from 'i18next';
import { CustomerCollision } from '../../../../../types/collision/collisionTypes';
import { Tooltip } from 'devextreme-react/tooltip';

// props
interface IPros {
    initDataCollision: CustomerCollision;
}

const Attachments = (props: IPros) => {
    const [selectedFileName, setSelectedFileName] = useState<string>("");
    const [url, setUrl] = useState<string>("");
    const [showDownloadModal, setShowDownloadModal] = useState<boolean>(false);
    const [showURLModal, setShowURLModal] = useState<boolean>(false);
    const [downloadIconColor, setDownloadIconColor] = useState<string>("#F0B70D");
    const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";


    const onMouseIn = (event: any, name: string) => {
        setSelectedFileName(name)
    };

    const onMouseOutDelete = (event: any, name: string) => {
        setSelectedFileName("")
    };

    async function showPicAttachment(fileKey: string) {
        var res = await TesPost(CollisionApiUrl() + "/api/Collisions/DownloadAttachment", { key: fileKey }, true) as AttachmentDownloadURL
        setUrl(res.downloadUrl);
        setShowDownloadModal(true)
    }
    async function showDocAttachment(fileKey: string) {
        var res = await TesPost(CollisionApiUrl() + "/api/Collisions/DownloadAttachment", { key: fileKey }, true) as AttachmentDownloadURL
        setUrl(res.downloadUrl);
        setShowURLModal(true)
    }



    return (
        <div className={`collision-attachments ${compactViewModel ? "compactStyle" : ""}`}>

            <React.Fragment>

                {props.initDataCollision?.attachments === null ?
                    <div className='row' style={{ marginTop: 20, padding: 10 }}>
                        <div className='halfColumn' >
                            <p style={{ fontWeight: 'bold' }}>{t("thereIsNoDataInThisTab")}</p>
                        </div>
                    </div>
                    :
                    <div className={"content-block"}>
                        <div className={" responsive-paddings"}>
                            <div className='row'>
                                <div style={{ display: "flex", flexWrap: "wrap" }}>
                                    {props.initDataCollision?.attachments && props.initDataCollision?.attachments?.map((f, index) => (
                                        <div style={{ width: '12%', justifyContent: 'center', alignItems: 'center', margin: "0 1.5rem -1rem 0" }} onMouseLeave={e => onMouseOutDelete(e, f.id)} onMouseOver={e => onMouseIn(e, f.id)} >
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.image &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-image ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showPicAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.video &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-youtube ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className="fa-solid fa-download fa-xl"
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.location &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-location-dot ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.clone &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-compact-disc ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.compressed &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-file-zipper ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.word &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-file-word ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.html &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-file-code ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.note &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-file-lines ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.pdf &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-file-pdf ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.music &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-headphones ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.powerpoint &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-file-powerpoint ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.excel &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-file-excel ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                            {AttachmentTypeFinder(f.key) === NewAttachmentType.unknown &&
                                                <div style={{ height: "8rem", display: "flex", flexDirection: "column" }} >
                                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                                        <div style={{ width: "40%" }}>
                                                            <i className={`fa-solid fa-file-circle-question ${!compactViewModel ? 'fa-3x' : 'fa-2x'}`}></i>
                                                        </div>
                                                        {selectedFileName === f.id &&
                                                            <div style={{ width: "60%", display: "flex", alignItems: "flex-start", justifyContent: "center" }}>
                                                                <i
                                                                    className={`fa-solid fa-download ${!compactViewModel ? 'fa-xl' : 'fa-lg'}`}
                                                                    onClick={() => showDocAttachment(f.key)}
                                                                    style={{ display: "block", margin: "1rem 0.5rem 0 0", cursor: "pointer", color: downloadIconColor }}
                                                                    onMouseOver={e => setDownloadIconColor("#212121")}
                                                                    onMouseLeave={e => setDownloadIconColor("#F0B70D")}
                                                                >
                                                                </i>

                                                            </div>}
                                                    </div>
                                                    <p style={{ width: '100px', marginTop: "0rem", cursor: "default" }} id={`previousFile${index}`}>{FileKeyCutterWithExtension(f.key, 15)}</p>
                                                    {f.key.length > 15 &&
                                                        <Tooltip
                                                            target={`#previousFile${index}`}
                                                            showEvent="mouseenter"
                                                            hideEvent="mouseleave"
                                                            hideOnOutsideClick={false}
                                                        >
                                                            <div>{f.key}</div>
                                                        </Tooltip>}
                                                </div>
                                            }
                                        </div>
                                    ))}
                                </div>


                            </div>

                            {/* Image modal */}
                            <Popup
                                width={"50%"}
                                height={"auto"}
                                visible={showDownloadModal}
                                resizeEnabled={true}
                                showTitle={true}
                                title={t('download')}
                                hideOnOutsideClick={false}
                                showCloseButton={true}
                                onHiding={() => setShowDownloadModal(false)}
                            >
                                <ScrollView width='100%' height='100%'>
                                    <div style={{ height: "90%" }}>

                                        <div className="row">
                                            <div className="col-md-12">
                                                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                                                <img src={url} id="pic" style={{ width: '100%', height: '100%', cursor: 'pointer', objectFit: 'contain' }} />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='rightColumn' style={{ marginTop: 10 }}>
                                            <Button
                                                className='tes-modal-btn-add'
                                                style={{ marginRight: 20 }}
                                                onClick={() => window.open(url, "_blank")}
                                                text={t('download')}
                                            />
                                            <Button
                                                className='tes-modal-btn-add'
                                                onClick={() => { navigator.clipboard.writeText(url); notify(t("linkCopied"), "success", 5000); }}
                                                text={t('copyLink')}
                                            />

                                        </div>
                                    </div>
                                </ScrollView>
                            </Popup>


                            {/* URL modal */}
                            <Popup
                                width={"50%"}
                                height={"auto"}
                                visible={showURLModal}
                                resizeEnabled={true}
                                showTitle={true}
                                title={t('download')}
                                hideOnOutsideClick={false}
                                showCloseButton={true}
                                onHiding={() => setShowURLModal(false)}
                            >
                                <ScrollView width='100%' height='100%'>
                                    <div style={{ height: "90%" }}>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <span style={{ fontWeight: 'bold' }}>{t("link")}: </span>
                                                <a href={url} style={{ textDecoration: 'underline', color: '#404040' }}>{url}</a>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <div className='rightColumn' style={{ marginTop: 10 }}>
                                            <Button
                                                className='tes-modal-btn-add'
                                                style={{ marginRight: 20 }}
                                                onClick={() => window.open(url, "_blank")}
                                                text={t('download')}
                                            />
                                            <Button
                                                className='tes-modal-btn-add'
                                                onClick={() => { navigator.clipboard.writeText(url); notify(t("linkCopied"), "success", 5000); }}
                                                text={t('copyLink')}
                                            />

                                        </div>
                                    </div>
                                </ScrollView>
                            </Popup>





                        </div>
                    </div>
                }
            </React.Fragment>
        </div>
    );


}
export default Attachments;