import React, {  useState } from 'react';
import ScrollView from "devextreme-react/scroll-view";
import { Form, SimpleItem } from "devextreme-react/form";
import { Button, DataGrid, Popup } from "devextreme-react";
import { t } from 'i18next';
import notify from 'devextreme/ui/notify';

interface IPros {
    datagridRef: React.RefObject<DataGrid<any, any>>
}

const RecalculateCollision = (props: IPros) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    
    function onOk() {

    }



    function onClick() {
        if ((props.datagridRef.current?.instance.getSelectedRowKeys().map(x => x.id) as string[]).length === 0) {
            notify(t('noSelectedRows'), 'warning', 3000)
            return
        }
        setShowModal(true)
    }

    function onClose() {
        setShowModal(false)
    }

    return (
        <React.Fragment>
            <Button
                onClick={() => onClick()}
                icon={'fa-solid fa-calculator'}
                style={{ marginLeft: 10 }}
                hint={t('recalculateCollision')}
            />

            <Popup
                visible={showModal}
                width={"40%"}
                height={"auto"}
                resizeEnabled={true}
                showTitle={true}
                title={t("recalculateCollisions")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onClose()}
            >
                <ScrollView width="100%" height="100%">
                    <Form colCount={2}>
                        <SimpleItem colSpan={1}>
                            <Button
                                //onClick={}
                                icon={"fa-solid fa-calculator"}
                                text={t('simplifiedCollision')}
                            />
                        </SimpleItem>
                        {/* <SimpleItem colSpan={1}>
                            <Button
                                //onClick={}
                                icon={'fa-solid fa-calculator'}
                                text={t('signalWarrants')}
                            />
                        </SimpleItem>
                        <SimpleItem colSpan={1}>
                            <Button
                                //onClick={}
                                icon={'fa-solid fa-calculator'}
                                text={t('allWayStopWarrant')}
                            />
                        </SimpleItem>
                        <SimpleItem colSpan={1}>
                            <Button
                                //onClick={}
                                icon={'fa-solid fa-calculator'}
                                text={t('aadt')}
                            />
                        </SimpleItem> */}
                    </Form>
                    <div>
                        <div className="rightColumn" style={{marginTop:30}}>

                            <Button
                                className="tes-modal-btn-add"
                                onClick={() => onClose()}
                                text={t("close")}
                            />
                        </div>
                    </div>
                </ScrollView>
            </Popup>

        </React.Fragment>
    )


}

export default RecalculateCollision;