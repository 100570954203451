import {
  CollisionPermissions,
  CustomerPermissions,
  FMSPermissions,
  InfrastructurePermissions,
  LogPermissions,
  MaintenancePermissions,
  SignPermissions,
  TMSPermissions,
  UserPermissions,
  ModuleOfModulePermissions,
  TrafficStudyPermissions,
} from "./constants/Permissions";
import SafetyPermissions from "./constants/Permissions/SafetyPermissions";
import dashboardPermissions from "./constants/Permissions/dashboardPermissions";
import SystematicScreeningPermissions from "./constants/Permissions/systematicScreeningPermissions";
import i18n from "./react-i18next.d";
i18n.loadNamespaces("sidebar");

export const navigation = [
  {
    text: i18n.t("home"),
    path: "/home",
    icon: "fa-solid fa-house",
    allowed: [CustomerPermissions.Customer_V_Web],
  },
  {
    text: i18n.t("dashboard"),
    icon: "fa-solid fa-chart-simple",
    allowed: [
      dashboardPermissions.Dashboard_V_TransportationSafety,
      dashboardPermissions.Dashboard_V_TransportationOperation,
    ],
    items: [
      {
        text: i18n.t("transportationSafety"),
        path: "/dashboard/transportationSafety",
        allowed: [dashboardPermissions.Dashboard_V_TransportationSafety],
      },
      {
        text: i18n.t("trafficOperation"),
        path: "/dashboard/trafficOperation",
        allowed: [dashboardPermissions.Dashboard_V_TransportationOperation],
      },
    ],
  },

  {
    text: i18n.t("infrastructure"),
    icon: "fa-solid fa-map-location-dot",
    allowed: [
      InfrastructurePermissions.Infrastructure_D_Street,
      InfrastructurePermissions.Infrastructure_R_Site,
      InfrastructurePermissions.Infrastructure_V_Street,
      InfrastructurePermissions.Infrastructure_D_Site,
      InfrastructurePermissions.Infrastructure_R_Site,
      InfrastructurePermissions.Infrastructure_V_Site,
      InfrastructurePermissions.Infrastructure_D_Setups,
      InfrastructurePermissions.Infrastructure_D_Area,
    ],
    items: [
      // {
      //   text: i18n.t("infrastructureMap"),
      //   path: "/infrastructure/map",
      //   allowed: [TMSPermissions.TMS_Admin],
      // },
      {
        text: i18n.t("intersections"),
        path: "/infrastructure/intersections",
        allowed: [
          InfrastructurePermissions.Infrastructure_D_Site,
          InfrastructurePermissions.Infrastructure_V_Site,
          InfrastructurePermissions.Infrastructure_R_Site,
        ],
      },
      {
        text: i18n.t("roadSegments"),
        path: "/infrastructure/roadSegments",
        allowed: [
          InfrastructurePermissions.Infrastructure_D_Site,
          InfrastructurePermissions.Infrastructure_R_Site,
          InfrastructurePermissions.Infrastructure_V_Site,
        ],
      },
      {
        text: i18n.t("streets"),
        path: "/infrastructure/streets",
        allowed: [
          InfrastructurePermissions.Infrastructure_D_Street,
          InfrastructurePermissions.Infrastructure_R_Street,
          InfrastructurePermissions.Infrastructure_V_Street,
        ],
      },
      {
        text: i18n.t("siteList"),
        path: "/infrastructure/siteList",
        allowed: [InfrastructurePermissions.Infrastructure_D_Area],
      },
      {
        text: i18n.t("permanentCountStation"),
        path: "/infrastructure/permanentCountStation",
        allowed: [
          // InfrastructurePermissions.Infrastructure_D_Setups,
          // InfrastructurePermissions.Infrastructure_D_Street,
          // InfrastructurePermissions.Infrastructure_R_Street,
          // InfrastructurePermissions.Infrastructure_V_Street,
          TMSPermissions.TMS_Admin
        ],
      },
      {
        text: i18n.t("trafficSection"),
        path: "/infrastructure/trafficSection",
        allowed: [
          // InfrastructurePermissions.Infrastructure_D_Setups,
          // InfrastructurePermissions.Infrastructure_D_Street,
          // InfrastructurePermissions.Infrastructure_R_Street,
          // InfrastructurePermissions.Infrastructure_V_Street,
          TMSPermissions.TMS_Admin
        ],
      },
      {
        text: i18n.t("updateImport"),
        allowed: [TMSPermissions.TMS_Admin],
        items: [
          {
            text: i18n.t("fieldDataSource"),
            path: "/infrastructure/updateImport/fieldDataSource",
            allowed: [TMSPermissions.TMS_Admin],
          },
          {
            text: i18n.t("loadData"),
            path: "/infrastructure/updateImport/loadData",
            allowed: [TMSPermissions.TMS_Admin],
          },
        ],
      },
      {
        text: i18n.t("tools"),
        allowed: [TMSPermissions.TMS_Admin],
        items: [
          {
            text: i18n.t("mms"),
            path: "/infrastructure/tools/mms",
            allowed: [TMSPermissions.TMS_Admin],
          },
        ],
      },
      {
        text: i18n.t("settings"),
        allowed: [InfrastructurePermissions.Infrastructure_D_Setups],
        items: [
          {
            text: i18n.t("fields"),
            path: "/infrastructure/settings/fields",
            allowed: [InfrastructurePermissions.Infrastructure_D_Setups],
          },
          {
            text: i18n.t("codeGroups"),
            path: "/infrastructure/settings/codeGroups",
            allowed: [InfrastructurePermissions.Infrastructure_D_Setups],
          },
          {
            text: i18n.t("municipalityPopulation"),
            path: "/infrastructure/settings/municipalityPopulation",
            allowed: [InfrastructurePermissions.Infrastructure_D_Setups],
          },
          {
            text: i18n.t("intersectionTesFields"),
            path: "/infrastructure/settings/intersectionTesFields",
            allowed: [InfrastructurePermissions.Infrastructure_D_Setups],
          },
          {
            text: i18n.t("roadSegmentTesFields"),
            path: "/infrastructure/settings/roadSegmentTesFields",
            allowed: [InfrastructurePermissions.Infrastructure_D_Setups],
          },
        ],
      },
    ],
  },

  {
    text: i18n.t("collision"),
    icon: "fa-solid fa-car-burst",
    allowed: [
      CollisionPermissions.Collision_D_Field,
      CollisionPermissions.Collision_V,
      CollisionPermissions.Collision_V_MyCollision,
      CollisionPermissions.Collision_D,
      CollisionPermissions.Collision_E,
      CollisionPermissions.Collision_Approve,
      CollisionPermissions.Collision_D_ValidationRule,
    ],
    items: [
      {
        text: i18n.t("collisions"),
        path: "/collision/collisions",
        allowed: [
          CollisionPermissions.Collision_V,
          CollisionPermissions.Collision_V_MyCollision,
          CollisionPermissions.Collision_D,
          CollisionPermissions.Collision_E,
          CollisionPermissions.Collision_Approve,
        ],
      },
      {
        text: i18n.t("peopleInvolved"),
        // path: "/collision/peopleInvolved",
        allowed: [
          CollisionPermissions.Collision_V,
          CollisionPermissions.Collision_V_MyCollision,
          CollisionPermissions.Collision_D,
          CollisionPermissions.Collision_E,
          CollisionPermissions.Collision_Approve,
        ],
        items: [
          {
            text: i18n.t("people"),
            path: "/collision/peopleInvolved/people",
            allowed: [
              CollisionPermissions.Collision_V,
              CollisionPermissions.Collision_V_MyCollision,
              CollisionPermissions.Collision_D,
              CollisionPermissions.Collision_E,
              CollisionPermissions.Collision_Approve,
            ],
          },
          {
            text: i18n.t("drivers"),
            path: "/collision/peopleInvolved/drivers",
            allowed: [
              CollisionPermissions.Collision_V,
              CollisionPermissions.Collision_V_MyCollision,
              CollisionPermissions.Collision_D,
              CollisionPermissions.Collision_E,
              CollisionPermissions.Collision_Approve,
            ],
          },
          {
            text: i18n.t("passengers"),
            path: "/collision/peopleInvolved/passengers",
            allowed: [
              CollisionPermissions.Collision_V,
              CollisionPermissions.Collision_V_MyCollision,
              CollisionPermissions.Collision_D,
              CollisionPermissions.Collision_E,
              CollisionPermissions.Collision_Approve,
            ],
          },
          {
            text: i18n.t("pedestrians"),
            path: "/collision/peopleInvolved/pedestrians",
            allowed: [
              CollisionPermissions.Collision_V,
              CollisionPermissions.Collision_V_MyCollision,
              CollisionPermissions.Collision_D,
              CollisionPermissions.Collision_E,
              CollisionPermissions.Collision_Approve,
            ],
          },
        ]
      },
      {
        text: i18n.t("vehicles"),
        path: "/collision/vehicles",
        allowed: [
          CollisionPermissions.Collision_V,
          CollisionPermissions.Collision_V_MyCollision,
          CollisionPermissions.Collision_D,
          CollisionPermissions.Collision_E,
          CollisionPermissions.Collision_Approve,
        ],
      },
      {
        text: i18n.t("drafts"),
        path: "/collision/drafts",
        allowed: [
          CollisionPermissions.Collision_V_MyCollision,
          CollisionPermissions.Collision_D,
          CollisionPermissions.Collision_Approve,
        ],
      },
      {
        text: i18n.t("import"),
        allowed: [
          TMSPermissions.TMS_Admin,
          CollisionPermissions.Collision_GeoCode,
        ],
        items: [
          {
            text: i18n.t("xmlTagSettings"),
            path: "/collision/import/xml/xmlImportSettings",
            allowed: [TMSPermissions.TMS_Admin],
          },
          {
            text: i18n.t("xmlImport"),
            path: "/collision/import/xml/xmlImport",
            allowed: [
              TMSPermissions.TMS_Admin,
              CollisionPermissions.Collision_GeoCode,
            ],
          },
          {
            text: i18n.t("xmlImportLogs"),
            path: "/collision/import/xml/xmlImportLogs",
            allowed: [
              TMSPermissions.TMS_Admin,
              CollisionPermissions.Collision_GeoCode,
            ],
          },
        ],
      },
      {
        text: i18n.t("geoCodeGroup"),
        allowed: [
          TMSPermissions.TMS_Admin,
          CollisionPermissions.Collision_GeoCode,
        ],
        items: [
          {
            text: i18n.t("geoCodeGroups"),
            path: "/collision/geoCodeGroup/geoCodeGroups",
            allowed: [
              TMSPermissions.TMS_Admin,
              CollisionPermissions.Collision_GeoCode,
            ],
          },
        ],
      },
      {
        text: i18n.t("simplifiedCollisions"),
        path: "/collision/simplifiedCollisions",
        allowed: [TMSPermissions.TMS_Admin],
      },
      {
        text: i18n.t("settings"),
        allowed: [
          CollisionPermissions.Collision_D_Field,
          CollisionPermissions.Collision_D_ValidationRule,
        ],
        items: [
          {
            text: i18n.t("fields"),
            path: "/collision/settings/fields",
            allowed: [CollisionPermissions.Collision_D_Field],
          },
          {
            text: i18n.t("codeGroups"),
            path: "/collision/settings/codeGroups",
            allowed: [CollisionPermissions.Collision_D_Field],
          },
          {
            text: i18n.t("tesFields"),
            path: "/collision/settings/tesFields",
            allowed: [CollisionPermissions.Collision_D_Field],
          },
          {
            text: i18n.t("validationRules"),
            path: "/collision/settings/validationRules",
            allowed: [
              CollisionPermissions.Collision_D_Field,
              CollisionPermissions.Collision_D_ValidationRule,
            ],
          },
          {
            text: i18n.t("confirmationLevel"),
            path: "/collision/settings/confirmationLevels",
            allowed: [CollisionPermissions.Collision_D_Field],
          },
          {
            text: i18n.t("xmlRedaction"),
            path: "/collision/settings/xmlRedaction",
            allowed: [TMSPermissions.TMS_Admin],
          },
        ],
      },
    ],
  },

  {
    text: i18n.t("hospitals"),
    icon: "fa-solid fa-house-medical",
    allowed: [
      CollisionPermissions.Collision_D_Hospitalization,
      CollisionPermissions.Collision_V_Hospitalization,
    ],
    items: [
      {
        text: i18n.t("hospitalizations"),
        path: "/hospitalization/hospitalizations",
        allowed: [
          CollisionPermissions.Collision_D_Hospitalization,
          CollisionPermissions.Collision_V_Hospitalization,
        ],
      },
      {
        text: i18n.t("patients"),
        path: "/hospitalization/patients",
        allowed: [
          CollisionPermissions.Collision_D_Hospitalization,
          CollisionPermissions.Collision_V_Hospitalization,
        ],
      },
    ],
  },

  {
    text: i18n.t("trafficStudy"),
    icon: "fa-solid fa-traffic-light",
    allowed: [
      TrafficStudyPermissions.TrafficStudy_D_Setups,
      TrafficStudyPermissions.TrafficStudy_D_Study,
      TrafficStudyPermissions.TrafficStudy_E_Study,
      TrafficStudyPermissions.TrafficStudy_R_Study,
      TrafficStudyPermissions.TrafficStudy_V_Study,
    ],
    items: [
      {
        text: i18n.t("aadts"),
        allowed: [
          TrafficStudyPermissions.TrafficStudy_D_Study,
          TrafficStudyPermissions.TrafficStudy_E_Study,
          TrafficStudyPermissions.TrafficStudy_V_Study,
        ],
        items: [
          {
            text: i18n.t("intersectionAADTs"),
            path: "/trafficStudy/aadts/intersectionAADTs",
            allowed: [
              TrafficStudyPermissions.TrafficStudy_D_Study,
              TrafficStudyPermissions.TrafficStudy_E_Study,
              TrafficStudyPermissions.TrafficStudy_V_Study,
            ],
          },
          {
            text: i18n.t("roadSegmentAADTs"),
            path: "/trafficStudy/aadts/roadSegmentAADTs",
            allowed: [
              TrafficStudyPermissions.TrafficStudy_D_Study,
              TrafficStudyPermissions.TrafficStudy_E_Study,
              TrafficStudyPermissions.TrafficStudy_V_Study,
            ],
          },
        ],
      },
      {
        text: i18n.t("studies"),
        allowed: [
          TrafficStudyPermissions.TrafficStudy_D_Study,
          TrafficStudyPermissions.TrafficStudy_E_Study,
          TrafficStudyPermissions.TrafficStudy_R_Study,
          TrafficStudyPermissions.TrafficStudy_V_Study,
        ],
        items: [
          {
            text: i18n.t("allStudies"),
            path: "/trafficStudy/studies/allStudies",
            allowed: [
              TrafficStudyPermissions.TrafficStudy_D_Study,
              TrafficStudyPermissions.TrafficStudy_E_Study,
              TrafficStudyPermissions.TrafficStudy_R_Study,
              TrafficStudyPermissions.TrafficStudy_V_Study,
            ],
          },
          {
            text: i18n.t("tmc"),
            path: "/trafficStudy/studies/tmc",
            allowed: [
              TrafficStudyPermissions.TrafficStudy_D_Study,
              TrafficStudyPermissions.TrafficStudy_E_Study,
              TrafficStudyPermissions.TrafficStudy_V_Study,
            ],
          },
          {
            text: i18n.t("volume"),
            path: "/trafficStudy/studies/volume",
            allowed: [
              TrafficStudyPermissions.TrafficStudy_D_Study,
              TrafficStudyPermissions.TrafficStudy_E_Study,
              TrafficStudyPermissions.TrafficStudy_V_Study,
            ],
          },
          {
            text: i18n.t("speed"),
            path: "/trafficStudy/studies/speed",
            allowed: [
              TrafficStudyPermissions.TrafficStudy_D_Study,
              TrafficStudyPermissions.TrafficStudy_E_Study,
              TrafficStudyPermissions.TrafficStudy_V_Study,
            ],
          },
        ],
      },
      {
        text: i18n.t("trafficStudyImport"),
        allowed: [TrafficStudyPermissions.TrafficStudy_D_Study],
        items: [
          {
            text: i18n.t("importTrafficStudyDataPage"),
            path: "/trafficStudy/import/importData",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Study],
          },
          {
            text: i18n.t("numetricConvertor"),
            path: "/trafficStudy/import/numetricConvertor",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Study],
          },
          {
            text: i18n.t("trafficStudyImportLogs"),
            path: "/trafficStudy/import/importLogs",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Study],
          },
        ],
      },
      {
        text: i18n.t("tools"),
        allowed: [TrafficStudyPermissions.TrafficStudy_D_Study],
        items: [
          {
            text: i18n.t("aadtPrediction"),
            path: "/trafficStudy/tools/AADTPrediction",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Study],
          },
          {
            text: i18n.t("export"),
            path: "/trafficStudy/tools/export",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Study],
          },
        ],
      },
      {
        text: i18n.t("setups"),
        allowed: [TrafficStudyPermissions.TrafficStudy_D_Setups],
        items: [
          {
            text: i18n.t("reportingPeriod"),
            path: "/trafficStudy/setups/reportingPeriod",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Setups],
          },
          {
            text: i18n.t("trafficCounters"),
            path: "/trafficStudy/setups/trafficCounters",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Setups],
          },
          {
            text: i18n.t("signalWarrants"),
            path: "/trafficStudy/setups/signalWarrantSetting",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Setups],
          },
          {
            text: i18n.t("allWayStopWarrant"),
            path: "/trafficStudy/setups/allWayStopWarrantSetting",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Setups],
          },
          {
            text: i18n.t("AADTPredictionSetting"),
            path: "/trafficStudy/setups/AADTPredictionGeneralSetting",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Setups],
          },
          {
            text: i18n.t("AADTPatterns"),
            path: "/trafficStudy/setups/AADTPatterns",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Setups],
          },
          {
            text: i18n.t("countDurations"),
            path: "/trafficStudy/setups/countDurations",
            allowed: [TrafficStudyPermissions.TrafficStudy_D_Setups],
          },
        ],
      },
    ],
  },
  //safety
  {
    text: i18n.t("safety"),
    icon: "fa-solid fa-shield-halved",
    allowed: [
      TMSPermissions.TMS_Admin,
      SafetyPermissions.Safety_D_Setups,
      SafetyPermissions.Safety_D_Workbook,
      SafetyPermissions.Safety_V_Workbook,
      SafetyPermissions.Safety_R_Workbook,
    ],
    items: [
      {
        text: i18n.t("workbooks"),
        path: "/safety/workbooks",
        allowed: [
          TMSPermissions.TMS_Admin,
          SafetyPermissions.Safety_D_Workbook,
          SafetyPermissions.Safety_V_Workbook,
          SafetyPermissions.Safety_R_Workbook,
        ],
      },
      {
        text: i18n.t("setups"),
        allowed: [TMSPermissions.TMS_Admin, SafetyPermissions.Safety_D_Setups],
        items: [
          {
            text: i18n.t("empiricalBayes"),
            path: "/safety/setups/EmpiricalBayesSettings",
            allowed: [
              TMSPermissions.TMS_Admin,
              SafetyPermissions.Safety_D_Setups,
            ],
          },
          {
            text: i18n.t("collisionCost"),
            path: "/safety/setups/collisionCost",
            allowed: [
              TMSPermissions.TMS_Admin,
              SafetyPermissions.Safety_D_Setups,
            ],
          },
          {
            text: i18n.t("siteTypes"),
            path: "/safety/setups/siteTypes",
            allowed: [
              TMSPermissions.TMS_Admin,
              SafetyPermissions.Safety_D_Setups,
            ],
          },
          {
            text: i18n.t("spfFunctions"),
            path: "/safety/setups/SPFFunctions",
            allowed: [
              TMSPermissions.TMS_Admin,
              SafetyPermissions.Safety_D_Setups,
            ],
          },
        ],
      },
    ],
  },
  //Systemic Screening

  {
    text: i18n.t("systematicScreening"),
    icon: "fa-solid fa-magnifying-glass-chart",
    allowed: [
      TMSPermissions.TMS_Admin,
      SystematicScreeningPermissions.SystematicScreening_D_AadtPrediction,
      SystematicScreeningPermissions.SystematicScreening_V_AadtPrediction,
      SystematicScreeningPermissions.SystematicScreening_D_SWP,
      SystematicScreeningPermissions.SystematicScreening_V_SWP,
    ],
    items: [
      {
        text: i18n.t("signalWarrantsPrediction"),
        allowed: [
          TMSPermissions.TMS_Admin,
          SystematicScreeningPermissions.SystematicScreening_D_AadtPrediction,
          SystematicScreeningPermissions.SystematicScreening_V_AadtPrediction,
          SystematicScreeningPermissions.SystematicScreening_D_SWP,
          SystematicScreeningPermissions.SystematicScreening_V_SWP,
        ],
        items: [
          {
            text: i18n.t("projects"),
            path: "/systemicScreening/projects",
            allowed: [
              TMSPermissions.TMS_Admin,
              SystematicScreeningPermissions.SystematicScreening_D_AadtPrediction,
              SystematicScreeningPermissions.SystematicScreening_V_AadtPrediction,
              SystematicScreeningPermissions.SystematicScreening_D_SWP,
              SystematicScreeningPermissions.SystematicScreening_V_SWP,
            ],
          },
        ],
      },
      {
        text: i18n.t("setups"),
        allowed: [TMSPermissions.TMS_Admin],
        items: [
          {
            text: i18n.t("signalWarrant"),
            path: "/systemicScreening/setups/signalWarrant",
            allowed: [TMSPermissions.TMS_Admin],
          },
        ],
      },
    ],
  },

  //sign
  {
    text: i18n.t("signInventory"),
    icon: "fa-solid fa-signs-post",
    allowed: [
      SignPermissions.Sign_D,
      SignPermissions.Sign_D_Setup,
      SignPermissions.Sign_E,
      SignPermissions.Sign_R,
      SignPermissions.Sign_Sync,
      SignPermissions.Sign_V,
      SignPermissions.Sign_V_Report,
    ],
    items: [
      {
        text: i18n.t("sign"),
        path: "/sign/signs",
        allowed: [
          SignPermissions.Sign_D,
          SignPermissions.Sign_R,
          SignPermissions.Sign_V,
          SignPermissions.Sign_E,
        ],
      },
      {
        text: i18n.t("support"),
        path: "/sign/supports",
        allowed: [
          SignPermissions.Sign_D,
          SignPermissions.Sign_R,
          SignPermissions.Sign_V,
          SignPermissions.Sign_E,
        ],
      },
      {
        text: i18n.t("patrolArea"),
        path: "/sign/patrolArea",
        allowed: [
          SignPermissions.Sign_D,
          SignPermissions.Sign_R,
          SignPermissions.Sign_V,
          SignPermissions.Sign_E,
        ],
      },
      {
        text: i18n.t("setups"),
        allowed: [SignPermissions.Sign_D_Setup],
        items: [
          {
            text: i18n.t("sign"),
            allowed: [SignPermissions.Sign_D_Setup],
            items: [
              {
                text: i18n.t("code"),
                path: "/sign/setups/sign/code",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("description"),
                path: "/sign/setups/sign/description",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("type"),
                path: "/sign/setups/sign/type",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("dimension"),
                path: "/sign/setups/sign/dimension",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("facingDirection"),
                path: "/sign/setups/sign/facingDirection",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("faceMaterial"),
                path: "/sign/setups/sign/faceMaterial",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("reflectiveCoating"),
                path: "/sign/setups/sign/reflectiveCoating",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("reflectiveRating"),
                path: "/sign/setups/sign/reflectiveRating",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("condition"),
                path: "/sign/setups/sign/condition",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("locationType"),
                path: "/sign/setups/sign/locationType",
                allowed: [SignPermissions.Sign_D_Setup],
              },
            ],
          },
          {
            text: i18n.t("support"),
            allowed: [SignPermissions.Sign_D_Setup],
            items: [
              {
                text: i18n.t("code"),
                path: "/sign/setups/support/code",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("description"),
                path: "/sign/setups/support/description",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("type"),
                path: "/sign/setups/support/type",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("material"),
                path: "/sign/setups/support/material",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("position"),
                path: "/sign/setups/support/position",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("condition"),
                path: "/sign/setups/support/condition",
                allowed: [SignPermissions.Sign_D_Setup],
              },
              {
                text: i18n.t("locationType"),
                path: "/sign/setups/support/locationType",
                allowed: [SignPermissions.Sign_D_Setup],
              },
            ],
          },
        ],
      },
    ],
  },

  {
    text: i18n.t("maintenance"),
    icon: "fa-solid fa-screwdriver-wrench",
    allowed: [
      MaintenancePermissions.Maintenance_D_Job,
      MaintenancePermissions.Maintenance_E_Job,
      MaintenancePermissions.Maintenance_R_Job,
      MaintenancePermissions.Maintenance_V_AllJob,
      MaintenancePermissions.Maintenance_CompleteJob,
      MaintenancePermissions.Maintenance_D_Setups,
      MaintenancePermissions.Maintenance_D_Personnel,
      MaintenancePermissions.Maintenance_D_Equipment,
    ],
    items: [
      {
        text: i18n.t("jobs"),
        path: "/maintenance/jobs",
        allowed: [
          MaintenancePermissions.Maintenance_D_Job,
          MaintenancePermissions.Maintenance_E_Job,
          MaintenancePermissions.Maintenance_R_Job,
          MaintenancePermissions.Maintenance_V_AllJob,
          MaintenancePermissions.Maintenance_CompleteJob,
        ],
      },
      {
        text: i18n.t("personnel"),
        path: "/maintenance/personnel",
        allowed: [MaintenancePermissions.Maintenance_D_Personnel],
      },
      {
        text: i18n.t("equipments"),
        path: "/maintenance/equipments",
        allowed: [MaintenancePermissions.Maintenance_D_Equipment],
      },
      {
        text: i18n.t("tags"),
        path: "/maintenance/tags",
        allowed: [MaintenancePermissions.Maintenance_D_Setups],
      },
      {
        text: i18n.t("setups"),
        allowed: [MaintenancePermissions.Maintenance_D_Setups],
        items: [
          {
            text: i18n.t("jobType"),
            path: "/maintenance/setups/jobType",
            allowed: [MaintenancePermissions.Maintenance_D_Setups],
          },
          {
            text: i18n.t("jobStatus"),
            path: "/maintenance/setups/jobStatus",
            allowed: [MaintenancePermissions.Maintenance_D_Setups],
          },
        ],
      },
    ],
  },

  {
    text: i18n.t("moduleOfModules"),
    icon: "fa-solid fa-cubes",
    allowed: [
      ModuleOfModulePermissions.ModuleOfModule_D_Data,
      ModuleOfModulePermissions.ModuleOfModule_D_Module,
      ModuleOfModulePermissions.ModuleOfModule_D_Setups,
      ModuleOfModulePermissions.ModuleOfModule_E_Data,
      ModuleOfModulePermissions.ModuleOfModule_V_Data,
    ],
    items: [
      {
        text: i18n.t("modules"),
        path: "/moduleOfModules/modules",
        allowed: [
          ModuleOfModulePermissions.ModuleOfModule_D_Data,
          ModuleOfModulePermissions.ModuleOfModule_D_Module,
          ModuleOfModulePermissions.ModuleOfModule_D_Setups,
          ModuleOfModulePermissions.ModuleOfModule_E_Data,
          ModuleOfModulePermissions.ModuleOfModule_V_Data,
        ],
      },
      {
        text: i18n.t("settings"),
        allowed: [ModuleOfModulePermissions.ModuleOfModule_D_Setups],
        items: [
          {
            text: i18n.t("fields"),
            path: "/moduleOfModules/settings/fields",
            allowed: [ModuleOfModulePermissions.ModuleOfModule_D_Setups],
          },
          {
            text: i18n.t("codeGroups"),
            path: "/moduleOfModules/settings/codeGroups",
            allowed: [ModuleOfModulePermissions.ModuleOfModule_D_Setups],
          },
        ],
      },
    ],
  },

  {
    text: i18n.t("storage"),
    icon: "fa-solid fa-database",
    allowed: [FMSPermissions.FMS_V_File],
    items: [
      {
        text: i18n.t("myStorage"),
        path: "/storage/myStorage",
        allowed: [FMSPermissions.FMS_V_File],
      },
    ],
  },

  {
    text: i18n.t("users"),
    icon: "fa-solid fa-users",
    allowed: [
      UserPermissions.User_D,
      UserPermissions.User_E,
      CustomerPermissions.Customer_D_User,
      CustomerPermissions.Customer_E_User,
      CustomerPermissions.Customer_D_Group,
      CustomerPermissions.Customer_A_Group,
    ],
    items: [
      {
        text: i18n.t("users"),
        path: "/users/users",
        allowed: [
          UserPermissions.User_D,
          UserPermissions.User_E,
          CustomerPermissions.Customer_D_User,
          CustomerPermissions.Customer_E_User,
          CustomerPermissions.Customer_D_Group,
          CustomerPermissions.Customer_A_Group,
          CustomerPermissions.Customer_D_Division,
        ],
      },
      {
        text: i18n.t("roles"),
        path: "/users/roles",
        allowed: [
          CustomerPermissions.Customer_D_Group,
          CustomerPermissions.Customer_A_Group,
        ],
      },
      {
        text: i18n.t("divisions"),
        path: "/users/divisions",
        allowed: [CustomerPermissions.Customer_D_Division],
      },
    ],
  },

  {
    text: i18n.t("settings"),
    icon: "fa-solid fa-sliders",
    allowed: [
      TMSPermissions.TMS_Admin,
      TMSPermissions.TMS_D_AppVersion,
      TMSPermissions.TMS_D_Classification,
      TMSPermissions.TMS_D_Customer,
      TMSPermissions.TMS_D_VersioningApplications,
    ],
    items: [
      {
        text: i18n.t("copySettings"),
        path: "/settings/copySettings",
        allowed: [TMSPermissions.TMS_Admin],
      },
      {
        text: i18n.t("clients"),
        path: "/settings/clients",
        allowed: [TMSPermissions.TMS_Admin],
      },
      {
        text: i18n.t("applicationVersions"),
        path: "/settings/applicationVersions",
        allowed: [TMSPermissions.TMS_Admin],
      },
      {
        text: i18n.t("emailTemplates"),
        path: "/settings/emailTemplates",
        allowed: [TMSPermissions.TMS_Admin],
      },
      {
        text: i18n.t("sync"),
        allowed: [TMSPermissions.TMS_Admin],
        items: [
          {
            text: i18n.t("tesSync"),
            path: "/settings/sync/tesSync",
            allowed: [TMSPermissions.TMS_Admin],
          },
          {
            text: i18n.t("syncJob"),
            path: "/settings/sync/syncJob",
            allowed: [TMSPermissions.TMS_Admin],
          },
        ],
      },
      {
        text: i18n.t("windowsAppKey"),
        path: "/settings/windowsAppKey",
        allowed: [TMSPermissions.TMS_Admin],
      },
    ],
  },

  {
    text: i18n.t("translation"),
    icon: "fa-solid fa-language",
    allowed: [TMSPermissions.TMS_Admin],
    items: [
      {
        text: i18n.t("baseLanguages"),
        path: "/translation/baseLanguages",
        allowed: [TMSPermissions.TMS_Admin],
      },
      {
        text: i18n.t("groups"),
        path: "/translation/groups",
        allowed: [TMSPermissions.TMS_Admin],

      },
      {
        text: i18n.t("customerLanguages"),
        path: "/translation/customerLanguages",
        allowed: [TMSPermissions.TMS_Admin],
      },
      {
        text: i18n.t("customer"),
        path: "/translation/customer",
        allowed: [TMSPermissions.TMS_Admin],
      },
      {
        text: i18n.t("keys"),
        path: "/translation/keys",
        allowed: [TMSPermissions.TMS_Admin],
      },
    ],
  },

  {
    text: i18n.t("log"),
    icon: "fa-regular fa-file-lines",
    allowed: [LogPermissions.Log_V],
    items: [
      {
        text: i18n.t("logs"),
        path: "/log/logs",
        allowed: [LogPermissions.Log_V],
      },
    ],
  },

  {
    text: i18n.t("phoenix"),
    icon: "fa-brands fa-phoenix-squadron",
    allowed: [
      TMSPermissions.TMS_D_AppVersion,
      CustomerPermissions.Customer_D_Environment,
    ],
    items: [
      {
        text: i18n.t("appVersions"),
        path: "/phoenix/appVersions",
        allowed: [TMSPermissions.TMS_D_AppVersion],
      },
      {
        text: i18n.t("clientEnvironments"),
        path: "/phoenix/clientEnvironments",
        allowed: [CustomerPermissions.Customer_D_Environment],
      },
    ],
  },

  {
    text: i18n.t("reports"),
    icon: "fa-solid fa-chart-line",
    allowed: [CustomerPermissions.Customer_D_Report],
    items: [
      {
        text: i18n.t("tesReportType"),
        path: "/report/tesReportType",
        allowed: [TMSPermissions.TMS_Admin],
      },
      {
        text: i18n.t("clientReports"),
        path: "/report/clientReports",
        allowed: [CustomerPermissions.Customer_D_Report],
      },
      {
        text: i18n.t("multiClientReporting"),
        path: "/report/multiClientReporting",
        allowed: [TMSPermissions.TMS_Admin],
      },
      {
        text: i18n.t("reportDesigner"),
        path: "/report/reportDesigner",
        allowed: [CustomerPermissions.Customer_D_Report],
      },
    ],
  },

  {
    text: i18n.t("documentation"),
    icon: "fa-solid fa-book",
    allowed: [CustomerPermissions.Customer_V_Web],
    items: [
      {
        text: i18n.t("documentation"),
        path: "https://support.tes.ca/kb/cloud/doku.php",
        allowed: [CustomerPermissions.Customer_V_Web],
      },
    ],
  },
  // {
  //   text: "test",
  //   path: "/test",
  //   icon: "fa-solid fa-question",
  //   allowed: [TMSPermissions.TMS_Admin],
  // }
];
