import { DataGrid, DateBox, Form, NumberBox, Popup, ScrollView, SelectBox, TagBox, TextBox } from "devextreme-react";
import { Button } from "devextreme-react/button";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import { BaseMapLayer, MapSetting } from "../../../../../types/infrastructure/infrastructureTypes";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../../utils/rest";
import notify from "devextreme/ui/notify";
import { useAuth } from "../../../../../contexts/auth";
import { AuthApiUrl, InfrastructureApiUrl } from "../../../../../environment/routeSettings";
import { SimpleItem } from "devextreme-react/form";
import { NameValue, RequestResponseResult } from "../../../../../types/general/generalTypes";
import { Enum2Array } from "../../../../../utils/enumTools";
import { FieldType } from "../../../../../types/field/enums/fieldEnums";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { useClientSetting } from "../../../../../contexts/clientSetting";
import { VMFindUser } from "../../../../../types/identity/dto/identityDTO";
import { ModalMode, ResponseCode } from "../../../../../types/general/enums/generalEnums";
import {
  Column, ColumnChooser, Button as DevExpressButton,
  FilterRow, HeaderFilter, Pager, Paging, Search, SearchPanel
} from "devextreme-react/data-grid";
import { custom } from "devextreme/ui/dialog";
import { v4 as uuidv4 } from "uuid";

interface IProps {
  clientId: string | undefined
}
const Infrastructure = (props: IProps) => {
  const { activeLoading } = useAuth();
  const [initData, setInitData] = useState<MapSetting>(new MapSetting());
  const [lstFieldType, setLstFieldType] = useState<NameValue[]>([]);
  const [initDataUsers, setInitDataUser] = useState<VMFindUser[]>([]);
  const { generalSetting } = useClientSetting();
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [showModal, setShowModal] = useState(false);
  const [currentBaseLayer, setCurrentBaseLayer] = useState<BaseMapLayer>(new BaseMapLayer());

  const onChangeDetailsFields = (key: string, value: any) => {
    setInitData({ ...initData, [key]: value });
  };

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        setLstFieldType(Enum2Array(FieldType));
        getInitData();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);




  async function getInitDataUsers() {
    try {
      var res = (await TesGet(AuthApiUrl() + "/api/user/findUser", true)) as VMFindUser[];
      setInitDataUser(res);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  async function onSave() {
    const postObj = {
      ...initData,
      customerId: props.clientId
    }
    const res = await TesPost(
      InfrastructureApiUrl() + "/api/settings/updateSetting",
      postObj,
      true
    ) as RequestResponseResult<any>;
    if (res.responseCode === ResponseCode.OK) {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyAdded"), "success", 5000);
      } catch {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred"), "error", 5000);
      }
    } else {
      await RequestErrorHandling(res);
    }
  }

  async function getInitData() {
    const res = await TesGet(
      InfrastructureApiUrl() +
      "/api/Settings/GetInfrastructureSettings/" +
      props.clientId,
      true
    ) as RequestResponseResult<MapSetting>
    if (res.responseCode === ResponseCode.OK) {
      setInitData(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  //modal functions
  function onCloseModal() {
    setCurrentBaseLayer(new BaseMapLayer());
    setShowModal(false);

  }
  //modal mode functions
  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }

  function onEdit(d: any) {
    let newData = new BaseMapLayer();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setCurrentBaseLayer(newData);
    setShowModal(true);
  }

  //change fields functions
  const onValueChanged = (key: string, value: any) => {
    setCurrentBaseLayer({ ...currentBaseLayer, [key]: value });
  };



  function onAdd() {
    setInitData({
      ...initData,
      customerId: props.clientId!,
      baseMapLayers: [...initData?.baseMapLayers,
      { ...currentBaseLayer, id: uuidv4() }]
    });



    setShowModal(false);
  }

  function onUpdate() {
    const baseLayers = initData?.baseMapLayers;
    const selectedIndex: number = baseLayers?.findIndex(
      (x: BaseMapLayer) => x.id === currentBaseLayer.id
    );
    baseLayers[selectedIndex] = currentBaseLayer;
    setInitData({
      ...initData,
      customerId: props.clientId!,
      baseMapLayers: baseLayers,
    });
    dataGridRef.current?.instance.refresh();
    setShowModal(false);
  }

  function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: (e) => {
            try {
              setInitData({
                ...initData,
                baseMapLayers: initData?.baseMapLayers.filter(x => x.id !== d.row.data.id)
              });
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }


  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={2} className='popupFields'>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("intersectionFileAddress")}
              labelMode="static"
              value={initData?.intersectionFileAddress}
              onValueChange={(e) =>
                onChangeDetailsFields("intersectionFileAddress", e)
              }
              style={{ marginBottom: "1rem" }}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("roadSegmentFileAddress")}
              labelMode="static"
              value={initData?.midblockFileAddress}
              onValueChange={(e) =>
                onChangeDetailsFields("midblockFileAddress", e)
              }
            />
          </SimpleItem>
        </Form>
        <Form colCount={4}>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("intersectionGeoIdName")}
              labelMode="static"
              value={initData?.intersectionGeoIdName}
              onValueChange={(e) =>
                onChangeDetailsFields("intersectionGeoIdName", e)
              }
              style={{ marginBottom: "1rem" }}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <SelectBox
              placeholder=""
              label={t('intersectionGeoIdType')}
              valueExpr="value"
              displayExpr="name"
              labelMode='static'
              value={initData?.intersectionGeoIdType}
              onValueChange={(e) => onChangeDetailsFields("intersectionGeoIdType", e)}
              className="modalInput"
              items={SortObjectByPropName(lstFieldType, "name")}
              showClearButton={true}
              searchEnabled={true}
            />
          </SimpleItem>

          <SimpleItem colSpan={1}>
            <TextBox
              label={t("roadSegmentGeoIdName")}
              labelMode="static"
              value={initData?.midblockGeoIdName}
              onValueChange={(e) =>
                onChangeDetailsFields("midblockGeoIdName", e)
              }
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <SelectBox
              placeholder=""
              label={t('midblockGeoIdType')}
              valueExpr="value"
              displayExpr="name"
              labelMode='static'
              value={initData?.midblockGeoIdType}
              onValueChange={(e) => onChangeDetailsFields("midblockGeoIdType", e)}
              className="modalInput"
              items={SortObjectByPropName(lstFieldType, "name")}
              showClearButton={true}
              searchEnabled={true}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <NumberBox
              step={0}
              label={t("latitude")}
              labelMode="static"
              value={initData?.latitude}
              showSpinButtons={true}
              showClearButton={true}
              onValueChange={(e) => onChangeDetailsFields("latitude", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <NumberBox
              step={0}
              label={t("longitude")}
              labelMode="static"
              value={initData?.longitude}
              showSpinButtons={true}
              showClearButton={true}
              onValueChange={(e) => onChangeDetailsFields("longitude", e)}
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TextBox
              label={t("arcGisApiKey")}
              labelMode="static"
              value={initData?.arcGisApiKey}
              onValueChange={(e) =>
                onChangeDetailsFields("arcGisApiKey", e)
              }
            />
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <NumberBox
              label={t("wkidNumber")}
              labelMode="static"
              value={initData?.wkidNumber}
              onValueChange={(e) => onChangeDetailsFields("wkidNumber", e)
              }
            />
          </SimpleItem>

          <SimpleItem colSpan={1}>
            <DateBox
              type="date"
              label={t("expiryDate")}
              value={initData?.apiKeyExpiryDT}
              onValueChange={(e) =>
                onChangeDetailsFields("apiKeyExpiryDT", e)
              } pickerType="calendar"
              placeholder={generalSetting?.dateTimeFormat}
              displayFormat={generalSetting?.dateTimeFormat}
              useMaskBehavior={true}
              openOnFieldClick={true}
              showClearButton={true}
            >
            </DateBox>
          </SimpleItem>
          <SimpleItem colSpan={1}>
            <TagBox
              dataSource={SortObjectByPropName(initDataUsers, "userName")}
              valueExpr="email"
              value={initData?.expiryEmailNotification}
              labelMode="static"
              label={t('emails')}
              displayExpr="userName"
              onValueChange={(e) =>
                onChangeDetailsFields("expiryEmailNotification", e)
              } searchEnabled={true} />
          </SimpleItem>
        </Form>
        <div className="row">
          <div className="rightColumn">
            <Button text={t("baseMapLayer")} onClick={onNew} icon="fa-solid fa-circle-plus" />
          </div>
        </div>
        <DataGrid
          id="gridContainer"
          ref={dataGridRef}
          dataSource={initData?.baseMapLayers}
          rowAlternationEnabled={true}
          showBorders={true}
          style={{ height: "100%" }}
          //keyExpr="id"
          hoverStateEnabled={true}
          remoteOperations={true}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnAutoWidth={true}
        >
          <Paging enabled={true} defaultPageSize={100} />
          <Pager
            showPageSizeSelector={true}
            allowedPageSizes={[100, 200, 300, 400, 500]}
            showNavigationButtons={true}
            showInfo={true}
            visible={true}
          />
          <FilterRow visible={true} applyFilter="auto" />
          <HeaderFilter visible={true} />
          <SearchPanel visible={true} width={285} placeholder={t("search...")} />
          <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
            <Search enabled />
          </ColumnChooser>
          <Column dataField={"title"} caption={t("title")}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField={"id"} caption={t("id")} visible={false}>
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column dataField={"url"} caption={t("url")}>
            {" "}
            <HeaderFilter>
              <Search enabled />
            </HeaderFilter>
          </Column>
          <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
            <DevExpressButton
              hint={t("edit")}
              onClick={onEdit}
              icon="fa-solid fa-pencil"
            ></DevExpressButton>
            <DevExpressButton
              hint={t("delete")}
              onClick={onDelete}
              icon="fa-solid fa-trash-can"
            ></DevExpressButton>
          </Column>
        </DataGrid>


        <Popup
          width={"50%"}
          height={"auto"}
          visible={showModal}
          resizeEnabled={true}
          showTitle={true}
          title={modalMode === ModalMode.Add ? t("add") : t("update")}
          hideOnOutsideClick={false}
          showCloseButton={true}
          onHiding={() => onCloseModal()}
        >
          <ScrollView width="100%" height="100%">
            <Form colCount={2} className="popupFields">
              <SimpleItem>
                <TextBox
                  label={t("title")}
                  labelMode="static"
                  value={currentBaseLayer?.title}
                  onValueChange={(e) => onValueChanged("title", e)}
                  className="modalInput"
                  style={{ marginBottom: "-1rem" }}
                />
              </SimpleItem>
              <SimpleItem>
                <TextBox
                  label={t("url")}
                  labelMode="static"
                  value={currentBaseLayer?.url}
                  onValueChange={(e) => onValueChanged("url", e)}
                  className="modalInput"
                />
              </SimpleItem>
            </Form>
            <div>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModal()}
                  text={t("cancel")}
                />
                {modalMode === ModalMode.Add ? (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onAdd}
                    text={t("add")}
                  />
                ) : (
                  <Button
                    className="tes-modal-btn-add"
                    onClick={onUpdate}
                    text={t("update")}
                  />
                )}
              </div>
            </div>
          </ScrollView>
        </Popup>

        <div className="rightColumn">
          <Button
            className="tes-modal-btn-add saveButton"
            onClick={onSave}
            text={t("save")}
          />
        </div>
      </div>
    </React.Fragment>
  );
};
export default Infrastructure;
