import React, { useEffect, useState } from 'react';
import { RequestErrorHandling, TesPost, TesPut } from '../../../../../utils/rest';
import { Button, CheckBox, DataGrid, DateBox, Popup, SelectBox } from 'devextreme-react';
import notify from 'devextreme/ui/notify';
import { LazyLoadingRequest, RequestResponseResult } from '../../../../../types/general/generalTypes';
import { CollisionApiUrl, InfrastructureApiUrl, TrafficApiUrl } from '../../../../../environment/routeSettings';
import { t } from 'i18next';
import MapFilterBuilder from '../mapFilterBuilder/mapFilterBuilder';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../../../contexts/auth';
import { useClientSetting } from '../../../../../contexts/clientSetting';
import { ResponseCode } from '../../../../../types/general/enums/generalEnums';
import { VMLocation } from '../../../../../types/infrastructure/dto/locationdDto';
import { Form, GroupItem, SimpleItem } from "devextreme-react/form";
import design from './traffic.module.scss'
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard';
import { ToMapRequest } from '../../../../../types/map/mapTypes';
import { ToMapRequestTrafficDTO, ToMapResponseTrafficDTO } from '../../../../../types/trafficStudy/dtos/studyDto';
import RadioGroup from 'devextreme-react/radio-group';
import { TrafficMapMode } from '../../../../../types/trafficStudy/enums/trafficStudyEnums';
import { useNavigate } from 'react-router';
import { CollisionMapMode } from '../../../../../types/collision/enums/collisionEnums';
import { ToMapRequestCollisionDTO, ToMapResponseCollisionDTO } from '../../../../../types/collision/dto/collisionDtos';
import CustomStore from 'devextreme/data/custom_store';
import { Column, FilterRow, HeaderFilter, Pager, Paging, SearchPanel, SortByGroupSummaryInfo, Selection, Search } from 'devextreme-react/data-grid';
import { Width } from 'devextreme-react/chart';
import { useScreenSize } from "../../../../../utils/media-query";
import { OnExporting } from '../../../../../utils/dataGridTools';
import Permission from '../../../../../components/permission/permision';
import { TMSPermissions } from '../../../../../constants/Permissions';

// props
interface IPros {
    selectedLoc?: VMLocation
    setTrafficToMapData: React.Dispatch<React.SetStateAction<any>>
    setTrafficToMapReq: React.Dispatch<React.SetStateAction<ToMapRequestTrafficDTO>>
    trafficToMapReq: ToMapRequestTrafficDTO
    setCollisionToMapData: React.Dispatch<React.SetStateAction<any>>
    setCollisionToMapReq: React.Dispatch<React.SetStateAction<any>>
    collisionToMapReq: ToMapRequestCollisionDTO
    setSelectedIndex?: React.Dispatch<React.SetStateAction<number>>
    collapsed: boolean;
    setLocationToZoom: React.Dispatch<React.SetStateAction<VMLocation>>;
    setMapTest: React.Dispatch<React.SetStateAction<boolean>>;
}


const Filter = (props: IPros) => {
    const [toMapReqBase, setToMapReqBase] = useState<ToMapRequest>({
        fromDate: new Date(new Date().getFullYear() - 4, 0, 1),
        toDate: new Date(new Date().getFullYear(), 11, 31),
        customerId: localStorage.getItem("selectedCustomerId") as string,
        filter: null
    });
    const [showModal, setShowModal] = useState<boolean>(false);
    const [showModalSearch, setShowModalSearch] = useState<boolean>(false);
    const { activeLoading } = useAuth();
    const { generalSetting } = useClientSetting();
    const { t } = useTranslation();
    const history = useNavigate();

    // const dataSource = new CustomStore({
    //     load: async (loadOption) => {
    //         return await TesPost(
    //             `${InfrastructureApiUrl()
    //             }/api/Locations/GetLocations`,
    //             {
    //                 customerId: localStorage.getItem("selectedCustomerId"),
    //                 divisionId: localStorage.getItem("selectedDivisionId"),
    //                 loadOptions: loadOption,
    //               } as LazyLoadingRequest,
    //             true
    //         );
    //     },
    // });

    const { isXLarge, is2xLarge } = useScreenSize(); const [filePickerGridHeight, setFilePickerGridHeight] = useState<number>(360)
    const [gridHeight, setGridHeight] = useState<number>(400)


    useEffect(() => {
        if (isXLarge) { setGridHeight(600) }
        else if (is2xLarge) { setGridHeight(900) }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])




    // useEffect(() => {
    //     async function fetchMyAPI() {
    //         try {
    //             if (activeLoading) activeLoading(true);
    //             props.setSelectedIndex(1)
    //             if (activeLoading) activeLoading(false);
    //         } catch (ex) {
    //             if (activeLoading) activeLoading(false);
    //             notify(t("someErrorOccurred") + ex, "error", 5000);
    //         }
    //     }
    //     if (props.selectedLoc?.id !== null && props.selectedLoc?.id !== undefined && props.selectedLoc?.id !== "") {
    //         fetchMyAPI();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.selectedLoc]);

    // useEffect(() => {
    //     async function fetchMyAPI() {
    //         try {
    //             if (activeLoading) activeLoading(true);
    //             var postObj = new ToMapRequestTrafficDTO();
    //             postObj.showAverageSpeed = (props.trafficToMapReq.showAverageSpeed || props.trafficToMapReq.showAverageSpeedByColor) ? true : false
    //             postObj.showIntersectionAADT = props.trafficToMapReq.showIntersectionAADT
    //             postObj.showRoadSegmentAADT = props.trafficToMapReq.showRoadSegmentAADT
    //             postObj.showPostedSpeed = (props.trafficToMapReq.showPostedSpeed || props.trafficToMapReq.showPostedSpeedByColor) ? true : false
    //             postObj.showLatestSpeedStudy = props.trafficToMapReq.showLatestSpeedStudy
    //             postObj.showLatestVolumeStudy = props.trafficToMapReq.showLatestVolumeStudy
    //             postObj.showLatestTMCStudy = props.trafficToMapReq.showLatestTMCStudy
    //             postObj.showCompliance = props.trafficToMapReq.showCompliance
    //             postObj.fromDate = toMapReqBase.fromDate === undefined ? new Date(new Date().getFullYear() - 5, 0, 1) : toMapReqBase.fromDate
    //             postObj.toDate = toMapReqBase.toDate === undefined ? new Date(new Date().getFullYear() - 1, 11, 31) : toMapReqBase.toDate;
    //             postObj.customerId = localStorage.getItem("selectedCustomerId") as string;
    //             var res = await TesPost(TrafficApiUrl() + "/api/report/GetDataToMap", postObj, true) as RequestResponseResult<ToMapResponseTrafficDTO>;
    //             if (res.responseCode === ResponseCode.OK) {
    //                 props.setTrafficToMapData(res.results)
    //             }
    //             await RequestErrorHandling(res);;
    //             if (activeLoading) activeLoading(false);
    //         } catch (ex) {
    //             if (activeLoading) activeLoading(false);
    //             notify(t("someErrorOccurred") + ex, "error", 5000);
    //         }
    //     }
    //     if (props.trafficToMapReq?.showPostedSpeed !== undefined || props.trafficToMapReq?.showRoadSegmentAADT !== undefined || props.trafficToMapReq?.showAverageSpeed !== undefined) {
    //         fetchMyAPI();
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [props.trafficToMapReq, toMapReqBase]);

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                var postObj = props.collisionToMapReq
                postObj.fromDate = (toMapReqBase.fromDate === undefined || toMapReqBase.fromDate === null) ? new Date(new Date().getFullYear() - 4, 0, 1) : toMapReqBase.fromDate
                postObj.toDate = (toMapReqBase.toDate === undefined || toMapReqBase.toDate === null) ? new Date(new Date().getFullYear(), 11, 31) : toMapReqBase.toDate;
                postObj.customerId = localStorage.getItem("selectedCustomerId") as string;
                postObj.divisionId = localStorage.getItem("selectedDivisionId") as string;
                var res = await TesPost(CollisionApiUrl() + "/api/reports/GetDataToMap", postObj, true) as RequestResponseResult<ToMapResponseCollisionDTO>;
                if (res.responseCode === ResponseCode.OK) {
                    props.setCollisionToMapData(res.results)
                }

                await RequestErrorHandling(res);;
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        if (props.collisionToMapReq?.showClusterData !== undefined || props.collisionToMapReq?.showCollisionLocations !== undefined) {
            fetchMyAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.collisionToMapReq, toMapReqBase]);


    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                var postObj = new ToMapRequestTrafficDTO();
                postObj.showAverageSpeed = (props.trafficToMapReq.showAverageSpeed || props.trafficToMapReq.showAverageSpeedByColor) ? true : false
                postObj.showIntersectionAADT = props.trafficToMapReq.showIntersectionAADT
                postObj.showRoadSegmentAADT = props.trafficToMapReq.showRoadSegmentAADT
                postObj.showPostedSpeed = (props.trafficToMapReq.showPostedSpeed || props.trafficToMapReq.showPostedSpeedByColor) ? true : false
                postObj.showLatestSpeedStudy = props.trafficToMapReq.showLatestSpeedStudy
                postObj.showLatestVolumeStudy = props.trafficToMapReq.showLatestVolumeStudy
                postObj.showLatestTMCStudy = props.trafficToMapReq.showLatestTMCStudy
                postObj.showCompliance = props.trafficToMapReq.showCompliance
                postObj.fromDate = toMapReqBase.fromDate === undefined ? new Date(new Date().getFullYear() - 4, 0, 1) : toMapReqBase.fromDate
                postObj.toDate = toMapReqBase.toDate === undefined ? new Date(new Date().getFullYear(), 11, 31) : toMapReqBase.toDate;
                postObj.customerId = localStorage.getItem("selectedCustomerId") as string;
                postObj.divisionId = localStorage.getItem("selectedDivisionId") as string;

                var res = await TesPost(TrafficApiUrl() + "/api/report/GetDataToMap", postObj, true) as RequestResponseResult<ToMapResponseTrafficDTO>;
                if (res.responseCode === ResponseCode.OK) {
                    props.setTrafficToMapData(res.results)
                }

                await RequestErrorHandling(res);;
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        if (props.trafficToMapReq?.show85PercentileSpeed !== undefined ||
            props.trafficToMapReq?.show85PercentileSpeed !== undefined ||
            props.trafficToMapReq?.showAverageSpeedByColor !== undefined ||
            props.trafficToMapReq?.showAverageXPostedSpeed !== undefined ||
            props.trafficToMapReq.showCompliance !== undefined ||
            props.trafficToMapReq.showIntersectionAADT !== undefined ||
            props.trafficToMapReq.showRoadSegmentAADT !== undefined ||
            props.trafficToMapReq?.showLatestSpeedStudy !== undefined ||
            props.trafficToMapReq.showLatestVolumeStudy !== undefined ||
            props.trafficToMapReq.showPostedSpeed !== undefined ||
            props.trafficToMapReq.showPostedSpeedByColor !== undefined) {
            fetchMyAPI();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.trafficToMapReq, toMapReqBase]);


    function onValueChangeToMapReqTra(name: string, value: any) {
        if (name === "mapMode") {

            if (value === TrafficMapMode.intersectionAADT) {
                props.setTrafficToMapReq({
                    ...props.trafficToMapReq,
                    showIntersectionAADT: true,
                    mapMode: TrafficMapMode.intersectionAADT
                })
            }
            if (value === TrafficMapMode.roadSegmentAADT) {
                props.setTrafficToMapReq({
                    ...props.trafficToMapReq,
                    showRoadSegmentAADT: true, mapMode: TrafficMapMode.roadSegmentAADT, showCompliance: false
                })
            }
            if (value === TrafficMapMode.compliance) {
                props.setTrafficToMapReq({
                    ...props.trafficToMapReq,
                    showCompliance: true, showRoadSegmentAADT: false, mapMode: TrafficMapMode.compliance,
                })
            }
            if (value === TrafficMapMode.None) {
                props.setTrafficToMapReq({
                    showRoadSegmentAADT: false, showIntersectionAADT: false, showAverageSpeed: false, showLatestSpeedStudy: false, showLatestTMCStudy: false, showLatestVolumeStudy: false,
                    showPostedSpeed: false,
                    showCompliance: false, mapMode: TrafficMapMode.None,
                })
            }
        }
        else {
            props.setTrafficToMapReq({ ...props.trafficToMapReq, [name]: value.value })
        }
    }


    function onValueChangeToMapReqCol(name: string, value: any) {
        if (name === "mapMode") {
            if (value === CollisionMapMode.showClusterData) {
                props.setCollisionToMapReq({ ...props.collisionToMapReq, showClusterData: true, mapMode: CollisionMapMode.showClusterData, showCollisionLocations: false })
            }
            if (value === CollisionMapMode.showCollisionLocations) {
                props.setCollisionToMapReq({ ...props.collisionToMapReq, showCollisionLocations: true, mapMode: CollisionMapMode.showCollisionLocations, showClusterData: false })
            }
            if (value === CollisionMapMode.None) {
                props.setCollisionToMapReq({ ...props.collisionToMapReq, showCollisionLocations: false, mapMode: CollisionMapMode.None, showClusterData: false })
            }
        } else {
            props.setCollisionToMapReq({ ...props.collisionToMapReq, [name]: value })
        }
    }

    function onCloseModal() {
        setShowModal(false)
    }


    function onValueChangeFilter(name: string, value: any) {
        setToMapReqBase({ ...toMapReqBase, [name]: value })
    }





    // function removeAllFilters() {
    //     props.setTrafficToMapReq({
    //         ...props.trafficToMapReq,
    //         showRoadSegmentAADT: false, showIntersectionAADT: false,
    //         showCompliance: false, mapMode: TrafficMapMode.None,
    //         showAverageSpeed: false, showLatestSpeedStudy: false, showLatestVolumeStudy: false, showLatestTMCStudy: false, showPostedSpeed: false
    //     })
    //     props.setCollisionToMapReq({ showCollisionLocations: false, mapMode: CollisionMapMode.None, showClusterData: false })
    // }

    function showLocationOnMap(d: any) {
        props.setLocationToZoom({
            locationType: d.data.locationType,
            geoId: d.data.geoId,
            id: d.locationId
        })
    }

    function mapTest() {
        props.setMapTest(true);
    }

    return (
        <React.Fragment>
            <div className="row TrafficOperation_Filter" style={{ padding: 10 }}>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-around" }}>
                    {/* filter */}
                    <div style={{ display: "flex", flexDirection: "column" }}>
                        <Permission allowed={[TMSPermissions.TMS_Developer]} hasFeedBackElement={false}>
                            <Button
                                width={20}
                                style={{ marginBottom: "0.25rem" }}
                                onClick={mapTest}
                                text={t("test")}
                            />
                        </Permission>

                        <div style={{ marginBottom: "0.25rem" }}>
                            <DateBox
                                type="date"
                                label={t('fromDate')}
                                height={40}
                                value={toMapReqBase?.fromDate}
                                dateSerializationFormat={generalSetting?.dateTimeFormat}
                                onValueChange={e => onValueChangeFilter('fromDate', e)}
                                pickerType="calendar"
                                useMaskBehavior={true}
                                openOnFieldClick={true}
                                showClearButton={true}
                            >
                            </DateBox>
                        </div>
                        <div style={{ marginTop: "0.25rem" }}>
                            <DateBox
                                type="date"
                                height={40}
                                label={t('toDate')}
                                value={toMapReqBase?.toDate}
                                dateSerializationFormat={generalSetting?.dateTimeFormat}
                                onValueChange={e => onValueChangeFilter('toDate', e)}
                                pickerType="calendar"
                                useMaskBehavior={true}
                                openOnFieldClick={true}
                                showClearButton={true}
                            >
                            </DateBox>
                        </div>
                        {/* <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around" }}>
                            <Button
                                onClick={() => setShowModal(true)}
                                icon="fa-solid fa-filter"
                                hint={t("advancedFilter")}
                            // style={{ margin: "0 0.1rem" }}
                            />
                            <Button
                                onClick={removeAllFilters}
                                icon="fa-solid fa-filter-circle-xmark"
                            // style={{ margin: "0 0.1rem" }}
                            />
                            <Button
                                onClick={() => setShowModalSearch(true)}
                                icon="fa-solid fa-search"
                                hint={t("search")}
                            // style={{ margin: "0 0.1rem" }}
                            />
                        </div> */}
                    </div>
                </div>
                {/* traffic  */}
                <CollapsibleCard isOpen={true} title={t('trafficStudy')} >
                    <Form colCount={2}>
                        {/* <SimpleItem colSpan={2}>
                            <MapFilterBuilder setToMapReq={setToMapReqBase} />
                        </SimpleItem> */}
                        {/* <GroupItem >
                            <SimpleItem colSpan={1}>
                                <div style={{ marginTop: "-0.75rem" }}>
                                    <RadioGroup
                                        layout="horizontal"
                                        id="radio-group-with-selection"
                                        items={[
                                            // { id: TrafficMapMode.postedSpeedByColor, text: t('showPostedSpeed') },
                                            // { id: TrafficMapMode.averageSpeedByColor, text: t('showAverageSpeed') },
                                            { id: TrafficMapMode.intersectionAADT, text: t('showIntersectionAADT') },
                                            { id: TrafficMapMode.roadSegmentAADT, text: t('showRoadSegmentAADT') },
                                            { id: TrafficMapMode.compliance, text: t('showCompliance') },
                                            { id: TrafficMapMode.None, text: t('none') }
                                        ]}
                                        value={props.trafficToMapReq?.mapMode}
                                        valueExpr="id"
                                        displayExpr="text"
                                        onValueChange={e => onValueChangeToMapReqTra("mapMode", e)} />
                                </div>
                            </SimpleItem>
                        </GroupItem> */}

                        <GroupItem>
                            <SimpleItem colSpan={1}>
                                <CheckBox
                                    className={`${design.checkBox}`}
                                    text={t("showIntersectionAADT")}
                                    value={props.trafficToMapReq?.showIntersectionAADT}
                                    onValueChanged={(e) => onValueChangeToMapReqTra("showIntersectionAADT", e)}
                                    style={{ width: '9rem' }}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>
                                <CheckBox
                                    className={`${design.checkBox}`}
                                    text={t("showRoadSegmentAADT")}
                                    disabled={props.trafficToMapReq?.showCompliance}
                                    value={props.trafficToMapReq?.showRoadSegmentAADT}
                                    onValueChanged={(e) => onValueChangeToMapReqTra("showRoadSegmentAADT", e)}
                                    style={{ width: '10rem' }}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>
                                <CheckBox
                                    className={`${design.checkBox}`}
                                    text={t("showCompliance")}
                                    disabled={props.trafficToMapReq?.showRoadSegmentAADT}
                                    value={props.trafficToMapReq?.showCompliance}
                                    onValueChanged={(e) => onValueChangeToMapReqTra("showCompliance", e)}
                                    style={{ width: '9rem' }}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>
                                <CheckBox
                                    className={`${design.checkBox}`}
                                    text={t("showPostedSpeed")}
                                    value={props.trafficToMapReq?.showPostedSpeed}
                                    onValueChanged={(e) => onValueChangeToMapReqTra("showPostedSpeed", e)}
                                    style={{ width: '9rem' }}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>
                                <CheckBox
                                    className={`${design.checkBox}`}
                                    text={t("showAverageSpeed")}
                                    value={props.trafficToMapReq?.showAverageSpeed}
                                    onValueChanged={(e) => onValueChangeToMapReqTra("showAverageSpeed", e)}
                                    style={{ width: '9rem' }}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>
                                <CheckBox
                                    className={`${design.checkBox}`}
                                    text={t("showSpeedStudy")}
                                    value={props.trafficToMapReq?.showLatestSpeedStudy}
                                    onValueChanged={(e) => onValueChangeToMapReqTra("showLatestSpeedStudy", e)}
                                    style={{ width: '9rem' }}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>
                                <CheckBox
                                    className={`${design.checkBox}`}
                                    text={t("showVolumeStudy")}
                                    value={props.trafficToMapReq?.showLatestVolumeStudy}
                                    onValueChanged={(e) => onValueChangeToMapReqTra("showLatestVolumeStudy", e)}
                                    style={{ width: '9rem' }}
                                />
                            </SimpleItem>
                            <SimpleItem colSpan={1}>
                                <CheckBox
                                    className={`${design.checkBox}`}
                                    text={t("showTMCStudy")}
                                    value={props.trafficToMapReq?.showLatestTMCStudy}
                                    onValueChanged={(e) => onValueChangeToMapReqTra("showLatestTMCStudy", e)}
                                    style={{ width: '9rem' }}
                                />
                            </SimpleItem>

                        </GroupItem>
                    </Form>
                </CollapsibleCard>

                {/* collision  */}
                <CollapsibleCard isOpen={true} title={t('collision')} >
                    <Form colCount={2}>
                        {/* <SimpleItem colSpan={1}>
                            <MapFilterBuilder setToMapReq={setToMapReqBase} />
                        </SimpleItem> */}
                        <SimpleItem colSpan={1}>
                            <RadioGroup
                                layout="vertical"
                                id="radio-group-with-selection"
                                items={[
                                    { id: CollisionMapMode.showClusterData, text: t('showClusterData') },
                                    { id: CollisionMapMode.showCollisionLocations, text: t('showCollisionLocations') },
                                    { id: CollisionMapMode.None, text: t('none') }
                                ]}
                                value={props.collisionToMapReq.mapMode}
                                valueExpr="id"
                                defaultValue={CollisionMapMode.None}
                                displayExpr="text"
                                onValueChange={e => onValueChangeToMapReqCol("mapMode", e)}
                                style={{ width: '9rem' }}
                            />
                        </SimpleItem>
                    </Form>
                </CollapsibleCard>

            </div>


            {/* advanced filter */}
            <Popup
                width={"60%"}
                height={"auto"}
                visible={showModal}
                resizeEnabled={true}
                showTitle={true}
                title={t("advancedFilter")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onCloseModal()}
            >
                <Form colCount={12}>
                    <SimpleItem colSpan={12}>
                        <p className={`${design.popupTitle}`}>{t("location")}</p>
                    </SimpleItem>
                    <SimpleItem colSpan={2}>
                        <CheckBox
                            className={`${design.checkBox}`}
                            text={t("intersection")}
                        //value={}
                        //onValueChanged={(e) => onValueChangeToMapReq("showPostedSpeed", e)}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={3}>
                        <SelectBox
                            placeholder=""
                            width={160}
                            height={40}
                            label={t("control")}
                            labelMode="static"
                            //value={category}
                            //onValueChange={(e) => onChangeCategory(e)}
                            //items={}
                            showClearButton={true}
                            searchEnabled={true}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={2}>
                        <CheckBox
                            className={`${design.checkBox}`}
                            text={t("roadSegment")}
                        //value={}
                        //onValueChanged={(e) => onValueChangeToMapReq("showPostedSpeed", e)}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={3}>
                        <SelectBox
                            placeholder=""
                            width={160}
                            height={40}
                            label={t("type")}
                            labelMode="static"
                            //value={category}
                            //onValueChange={(e) => onChangeCategory(e)}
                            //items={}
                            showClearButton={true}
                            searchEnabled={true}
                        />
                    </SimpleItem>
                </Form>
                <div className="rightColumn" style={{ marginTop: 40 }}>
                    <Button
                        className="tes-modal-btn-cancel"
                        style={{ marginRight: 20 }}
                        onClick={() => onCloseModal()}
                        text={t("clear")}
                    />
                    <Button
                        className="tes-modal-btn-add"
                        //onClick={onAdd}
                        text={t("apply")}
                    />
                </div>
            </Popup>

            {/* search filter */}
            <Popup
                width={"50%"}
                height={"auto"}
                visible={showModalSearch}
                resizeEnabled={true}
                showTitle={true}
                title={t("search")}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => setShowModalSearch(false)}
            >
             
                {/* <DataGrid
                    id="gridContainer"
                    dataSource={dataSource}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    remoteOperations={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    onRowClick={showLocationOnMap}
                    height={gridHeight}
                    onExporting={OnExporting}
                >
                    <SortByGroupSummaryInfo
                        summaryItem="Total Count"
                        sortOrder="desc"
                    />
                    <Paging enabled={true} defaultPageSize={20} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[10, 20, 50, 100]}
                        showNavigationButtons={true}
                    />
                    <FilterRow visible={true} applyFilter="auto" />
                    <HeaderFilter visible={true} />
                    <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                    <Column alignment="left" dataField="GeoId" caption={t("geoId")}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="Description" caption={t("description")}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>

                </DataGrid> */}

            </Popup>

        </React.Fragment>
    );


}
export default Filter;