import { Button } from 'devextreme-react/button';
import {  t } from 'i18next';
import React, { useEffect, useState } from 'react';
import notify from 'devextreme/ui/notify';
import { VMGroupProfile } from '../../../../../types/identity/dto/identityDTO';
import { RequestErrorHandling, TesPost } from '../../../../../utils/rest';
import { ResponseCode } from '../../../../../types/general/enums/generalEnums';
import { useAuth } from '../../../../../contexts/auth';
import { RequestResponseResult } from '../../../../../types/general/generalTypes';
import { Form, SimpleItem, GroupItem as GroupItemForm } from 'devextreme-react/form';
import { SelectBox } from 'devextreme-react';
import { FieldCategoryType, FieldPermission, FieldServiceType } from '../../../../../types/field/enums/fieldEnums';
import { Enum2Array } from '../../../../../utils/enumTools';
import { VMAllFieldPermission, VMUpdateFieldPermission } from '../../../../../types/field/dto/fieldDTO';
import CollapsibleCard from '../../../../../components/collapsibleCard/collapsibleCard';
import { FieldApiUrl } from '../../../../../environment/routeSettings';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';


// props
interface IPros {
    initData: VMGroupProfile,
    initDataFieldPermissions: VMAllFieldPermission[],
    setInitDataFieldPermissions: React.Dispatch<React.SetStateAction<any>>;
}

const FieldPermissions = (props: IPros) => {

    const [fieldServiceType, setFieldServiceType] = useState<FieldServiceType>(1);


    const { activeLoading, user } = useAuth();


    useEffect(() => {
    }, [user]);



    async function onSave() {
        try {
            if (activeLoading) activeLoading(true);

            const postObj = new VMUpdateFieldPermission();
            postObj.customerId = props.initData.customerId;
            postObj.fieldPermissions = props.initDataFieldPermissions;
            postObj.groupId = props.initData.id;
            postObj.fieldServiceType = fieldServiceType;

            const res = (await (TesPost(FieldApiUrl() + "/api/FieldPermissions/UpdateGroupFieldPermission", postObj, true))) as RequestResponseResult<null>
            if (activeLoading) activeLoading(false);
            if (res.responseCode === ResponseCode.OK) {
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
            } else if (res.responseCode === ResponseCode.UserNotPermitted) {
                notify(t("userNotPermitted"), "error", 5000);
            } else if (res.responseCode === ResponseCode.UpdatingError) {
                notify(t("updatingError"), "error", 5000);
            }
            await RequestErrorHandling(res);;
        } catch (ex) {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred" + ex), "error", 5000);
        }
    }

    function onFieldServiceTypeValueChange(value: any) {
        setFieldServiceType(value);
    }

    function onPermissionChange(tesFieldId: String, value: any) {
        var index = props.initDataFieldPermissions.findIndex(x => x?.tesFieldId === tesFieldId);
        let items = [...props.initDataFieldPermissions];
        items[index]!.permission = value
        props.setInitDataFieldPermissions(items);

    }



    function onِChangeAll(prm: FieldPermission) {
        let items = [...props.initDataFieldPermissions];
        items.filter(x => x.fieldServiceType === fieldServiceType).forEach(x => x.permission = prm);
        props.setInitDataFieldPermissions(items);
    }


    var lstFiledServiceType = Enum2Array(FieldServiceType);
    var lstPermission = Enum2Array(FieldPermission);

    return (
        <React.Fragment>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <Form
                    colCount={4}>
                    <GroupItemForm caption={t("fieldServiceType")}
                        name={t("fieldServiceType")}
                        visible={true}
                        colSpan={4}
                    >
                        <Form
                            colCount={4}>
                            <SimpleItem colSpan={1} >
                                <SelectBox
                                    placeholder=""
                                    label={t('serviceType')}
                                    valueExpr="value"
                                    displayExpr="name"
                                    labelMode='static'
                                    value={fieldServiceType}
                                    onValueChange={(e) => onFieldServiceTypeValueChange(e)}
                                    className="modalInput"
                                    items={SortObjectByPropName(lstFiledServiceType, "name")}
                                    showClearButton={true}
                                    searchEnabled={true}
                                />
                            </SimpleItem>

                            <SimpleItem colSpan={3}>
                                <div className='row' >
                                    <div className='rightColumn'>

                                        <Button
                                            className='tes-modal-btn-cancel saveButton'
                                            onClick={() => onِChangeAll(FieldPermission.Deny)}
                                            text={t('denyAll')}
                                        />
                                        <Button
                                            className='tes-modal-btn-add saveButton'
                                            onClick={() => onِChangeAll(FieldPermission.View)}
                                            text={t('viewAll')}
                                            style={{ marginRight: 10, marginLeft: 10 }}
                                        />
                                        <Button
                                            className='tes-modal-btn-add saveButton'
                                            onClick={() => onِChangeAll(FieldPermission.Edit)}
                                            text={t('editAll')}
                                        />
                                    </div>
                                </div>
                            </SimpleItem>
                        </Form>
                    </GroupItemForm>
                </Form>
                {fieldServiceType === FieldServiceType.Collision &&
                    <CollapsibleCard title={t('collisionGeneral')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.CollisionGeneral)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
                {fieldServiceType === FieldServiceType.Collision &&
                    <CollapsibleCard title={t('collisionRoad')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.CollisionRoad)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
                {fieldServiceType === FieldServiceType.Collision &&
                    <CollapsibleCard title={t('collisionVehicle')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.CollisionVehicle)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
                {fieldServiceType === FieldServiceType.Collision &&
                    <CollapsibleCard title={t('collisionDriver')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.CollisionDriver)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }

                {fieldServiceType === FieldServiceType.Collision &&
                    <CollapsibleCard title={t('collisionPedestrian')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.CollisionPedestrian)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
                {fieldServiceType === FieldServiceType.Collision &&
                    <CollapsibleCard title={t('collisionPerson')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.CollisionPerson)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
                {fieldServiceType === FieldServiceType.Collision &&
                    <CollapsibleCard title={t('collisionRemarks')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.CollisionRemark)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }

                {fieldServiceType === FieldServiceType.Infrastructure &&
                    <CollapsibleCard title={t('roadSegmentGeneral')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.InfrastructureMidBlockCrossSection)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
                {fieldServiceType === FieldServiceType.Infrastructure &&
                    <CollapsibleCard title={t('roadSegmentDetails')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.InfrastructureMidBlockDetails)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
                {fieldServiceType === FieldServiceType.Infrastructure &&
                    <CollapsibleCard title={t('roadSegmentOperation')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.InfrastructureMidBlockOperation)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
                {fieldServiceType === FieldServiceType.Infrastructure &&
                    <CollapsibleCard title={t('roadSegmentCrossSection')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.InfrastructureMidBlockCrossSection)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
                {fieldServiceType === FieldServiceType.Infrastructure &&
                    <CollapsibleCard title={t('intersectionGeneral')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.InfrastructureIntersectionGeneral)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
                {fieldServiceType === FieldServiceType.Infrastructure &&
                    <CollapsibleCard title={t('streetGeneral')} isOpen={false}>
                        <Form
                            style={{ marginTop: 10 }}
                            colCount={4}>
                            {props.initDataFieldPermissions && props.initDataFieldPermissions
                                .filter(x => x.fieldServiceType === fieldServiceType && x.fieldCategoryType === FieldCategoryType.InfrastructureStreetGeneral)
                                .map((p, i) => (
                                    <SimpleItem colSpan={1} >
                                        <SelectBox
                                            placeholder=""
                                            label={p?.fieldName}
                                            valueExpr="value"
                                            displayExpr="name"
                                            labelMode='static'
                                            value={p?.permission}
                                            onValueChange={(e) => onPermissionChange(p.tesFieldId, e)}
                                            className="modalInput"
                                            items={SortObjectByPropName(lstPermission, "name")}
                                            showClearButton={true}
                                            searchEnabled={true}
                                        />
                                    </SimpleItem>
                                ))}
                        </Form>
                    </CollapsibleCard>
                }
            </div>
            <div className='row' style={{ marginTop: 20, padding: 10 }}>
                <div className='rightColumn'>
                    <Button
                        className='tes-modal-btn-add saveButton'
                        onClick={onSave}
                        text={t('save')}
                    />

                </div>
            </div>

        </React.Fragment >
    );


}
export default FieldPermissions;

