import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import tabTitles from "./data/index";
import Details from "./components/details/details";
import { RequestErrorHandling, TesGet, TesPost } from "../../../../utils/rest";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { Button, ValidationGroup } from "devextreme-react";
import { custom } from "devextreme/ui/dialog";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import Permission from "../../../../components/permission/permision";
import { TrafficStudyPermissions } from "../../../../constants/Permissions";
import "./siteTypeDetails.scss";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { InfrastructureApiUrl } from "../../../../environment/routeSettings";
import SiteSubTypes from "./components/siteSubTypes/siteSubTypes";
import { ISiteTypeParam } from "../../../../types/safety/safetyTypes";
import { SiteTypeDTO } from "../../../../types/safety/dto/safetyDtos";
import SafetyPermissions from "../../../../constants/Permissions/SafetyPermissions";
import ShareURL from "../../../../components/shareURL/shareURL";

const SiteTypeDetails = () => {
  const history = useNavigate();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [dataChanged, setDataChanged] = useState(false);
  const [initData, setInitData] = useState<SiteTypeDTO>(new SiteTypeDTO());
  const params = useParams<ISiteTypeParam>();
  const generalValidationRef = useRef<ValidationGroup>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [isLocked, setIsLocked] = useState(true);
  const headingParagraphRef = useRef<HTMLParagraphElement>(null);
  const location = useLocation();
  const url = `${window.location.origin}${location.pathname}`;

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (params.siteTypeId !== "AddNew") {
          await getInitData(params.siteTypeId!);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.siteTypeId]);

  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  async function getInitData(id: string) {
    try {
      let res = await TesGet(
        InfrastructureApiUrl() + "/api/SiteTypes/GetSiteTypeDetails/" + id,
        true
      ) as RequestResponseResult<SiteTypeDTO>;
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results)
      }
      else if (res.responseCode === ResponseCode.IdNotExists) {
        notify(t("idNotExists"), "error", 5000)
        history(-1);
      }
      else {
        await RequestErrorHandling(res);;
      };
    } catch (ex) {
      notify(t("errorInFetchData") + ex, "error", 5000);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.siteTypeId !== "AddNew") {
                  updateSiteType(true);
                  setDataChanged(false)
                } else {
                  setDataChanged(false)
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              // setBackPermission(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  async function updateSiteType(backPermission: boolean) {
    try {
      // const validationRes = generalValidationRef.current?.instance.validate();
      // if (validationRes?.isValid) {
      var postObj = {
        ...initData,
        customerId: localStorage.getItem("selectedCustomerId") as string,
        id: initData.id,
        name: initData.name
      };
      if (activeLoading) activeLoading(true);
      let res = (await TesPost(
        InfrastructureApiUrl() + "/api/SiteTypes/UpdateSiteType",
        postObj,
        true
      )) as RequestResponseResult<null>;
      if (res.responseCode === ResponseCode.OK) {
        if (backPermission === true) {
          history(-1);
        }
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else {
        await RequestErrorHandling(res);;
      }
      // }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false)
  }

  const copyTextToClipboard = () => {
    if (headingParagraphRef.current) {
      const textToCopy = headingParagraphRef.current.innerText;
      navigator.clipboard.writeText(textToCopy);
      notify(t("textCopiedToClipboard"), "success", 2000);
    }
  };

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_D_Setups]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <div className={"content-block"}>
          <div className={"dx-card"}>
          {params.intersectionId !== "AddNew" && (
                <div style={{ marginTop: "1rem"}}>
                  <p ref={headingParagraphRef} className={"detailsHeading"} style={{ display: "inline" }}>
                    <span>{t('SiteType')}: {initData?.name}</span>
                  </p>
                  <Button
                    onClick={() => copyTextToClipboard()}
                    icon="fa-solid fa-copy"
                    hint={t("copy")}
                  />
                  <hr className="line" style={{ display: "block", marginTop: "1rem" }}></hr>
                </div>
              )}
            <div className="row">
              <div className="leftColumn">
                <Button
                  onClick={() => goBackPermission()}
                  icon="fa-solid fa-arrow-left"
                  hint={t("goBack")}
                />
              </div>
              <div className="rightColumn">
                {params.siteTypeId !== "AddNew" && (
                  <ShareURL
                    url={url}
                  />
                )}
                <Button
                  onClick={() => LockHandler()}
                  icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                  hint={isLocked ? t("unlock") : t("lock")}
                />
              </div>
              <div className="rightColumn">
                {isLocked === false && (
                  <Button
                    onClick={() => updateSiteType(false)}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("update")}
                  />
                )}
              </div>
            </div>
          </div>
          <div className={"dx-card"}>
            <Tabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onOptionChanged={onTabsSelectionChanged}
            />
            {selectedIndex === 0 && (
              <Details
                initData={initData}
                setDataChanged={setDataChanged}
                setInitData={setInitData}
                isLocked={isLocked}
                validationRef={generalValidationRef}
              />
            )}
            {selectedIndex === 1 && (
              <SiteSubTypes
                initData={initData}
                params={params}
                setDataChanged={setDataChanged}
                setInitData={setInitData}
                isLocked={isLocked}
              />
            )}

          </div>
        </div>
      </React.Fragment>
    </Permission>
  );
};
export default SiteTypeDetails;
