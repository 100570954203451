import React, { useRef, useEffect, useState } from "react";
import DataGrid, {
  Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Pager, Paging, Selection, Export, SortByGroupSummaryInfo, Summary, GroupItem, Search
} from 'devextreme-react/data-grid';
import { useTranslation } from "react-i18next";
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import './share.scss';
import { useAuth } from "../../../../../contexts/auth";
import notify from "devextreme/ui/notify";
import { AreaDTO } from "../../../../../types/infrastructure/dto/locationdDto";
import { OnExporting } from "../../../../../utils/dataGridTools";
import { Tabs } from "devextreme-react";
import tabTitles from "./data/index";


// props
interface IPros {
  initDataSiteList: AreaDTO;
  setInitDataSiteList: React.Dispatch<React.SetStateAction<AreaDTO>>;
  onPermissionChange: (data: any) => void;
  areaId: string,
  selectedGroupIds: any[],
  selectedUserIds: any[]
  initDataUser: any[]
  initDataGroup: any[]
  isLocked: boolean;
}

const Permissions = (props: IPros) => {
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const dataGridGroupRef = useRef<DataGrid<any, any>>(null);
  const { activeLoading } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const [selectedUserIds, setSelectedUserIds] = useState<string[]>([]);
  const [selectedGroupIds, setSelectedGroupIds] = useState<string[]>([]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (props.initDataSiteList.userIds !== undefined) {
          dataGridRef.current?.instance.selectRows(props.initDataSiteList.userIds, true)
        }
        if (props.initDataSiteList.groupIds !== undefined) {
          dataGridGroupRef.current?.instance.selectRows(props.initDataSiteList.groupIds, true)
        }
        // if (props.initDataShare.length > 0) {
        //     dataGridRef.current?.instance.selectRows(props.initDataShare, true)
        //     dataGridGroupRef.current?.instance.selectRows(props.initDataShare, true)
        // }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (selectedIndex === 0) {
      setSelectedUserIds(props.initDataSiteList.userIds || []);
    } else if (selectedIndex === 1) {
      setSelectedGroupIds(props.initDataSiteList.groupIds || []);
    }
  }, [selectedIndex, props.initDataSiteList.userIds, props.initDataSiteList.groupIds]);

  function onSelectionChange() {
    const selectedUsers = new Set(
      dataGridRef.current?.instance.getSelectedRowsData().map(x => x.id) || []
    );
    const selectedGroups = new Set(
      dataGridGroupRef.current?.instance.getSelectedRowsData().map(x => x.id) || []
    );
  
    const existingUserIds = props.initDataSiteList.userIds || [];
    const existingGroupIds = props.initDataSiteList.groupIds || [];
  
    const newUserIds = [...new Set([...existingUserIds, ...selectedUsers])];
    const newGroupIds = [...new Set([...existingGroupIds, ...selectedGroups])];
  
    const postObj = {
      lstUserIds: newUserIds,
      lstGroupIds: newGroupIds
    };
  
    props.onPermissionChange(postObj);
  
    props.setInitDataSiteList({
      ...props.initDataSiteList,
      userIds: newUserIds,
      groupIds: newGroupIds
    });
  }
  
  const { t } = useTranslation();

  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  return (
    <div className={`siteListDetails-share ${compactViewModel ? "compactStyle" : ""}`}>
      <React.Fragment>
        <div className={"dx-card "}>
          <Tabs
            width={"100%"}
            dataSource={tabTitles}
            selectedIndex={selectedIndex}
            onOptionChanged={onTabsSelectionChanged}
          />

          {selectedIndex === 0 && (
            <div className="div-content">
              <DataGrid id="shareGrids"
                ref={dataGridRef}
                dataSource={props.initDataUser}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                keyExpr="id"
                onSelectionChanged={onSelectionChange}
                onExporting={OnExporting}
                selectedRowKeys={selectedUserIds}
                //onContentReady={e => {e.component.deselectAll()}}
                //onFilterValueChange={e => {dataGridRef?.current?.instance.deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />

                <Selection
                  mode="multiple"
                  selectAllMode={'allPages'}
                  showCheckBoxesMode={'always'}
                />

                <FilterRow visible={true}
                  applyFilter="auto" />

                <HeaderFilter visible={true} />


                <SearchPanel visible={true}
                  width={285}
                  placeholder={t("search...")} />
                <ColumnChooser width={350} height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>

                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Column dataField="userName"
                  caption={t("username")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          )}

          {selectedIndex === 1 && (
            <div className="div-content">
              <DataGrid id="shareGrids"
                ref={dataGridGroupRef}
                dataSource={props.initDataGroup}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                keyExpr="id"
                onSelectionChanged={onSelectionChange}
                onExporting={OnExporting}
                selectedRowKeys={selectedGroupIds}
                //onContentReady={e => {e.component.deselectAll()}}
                //onFilterValueChange={e => {dataGridGroupRef?.current?.instance.deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />

                <Selection
                  mode="multiple"
                  selectAllMode={'allPages'}
                  showCheckBoxesMode={'always'}
                />

                <FilterRow visible={true}
                  applyFilter="auto" />

                <HeaderFilter visible={true} />


                <SearchPanel visible={true}
                  width={285}
                  placeholder={t("search...")} />
                <ColumnChooser width={350} height={400}
                  enabled={true}
                  mode="select"
                  sortOrder="asc"
                >
                  <Search enabled />
                </ColumnChooser>

                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>

                <Column dataField="name"
                  caption={t("groupName")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>
          )}
        </div>
      </React.Fragment>
    </div>
  );
}
export default Permissions;