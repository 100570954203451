import React, { useEffect, useRef, useState } from "react";
import Tabs from "devextreme-react/tabs";
import tabTitles from "./data/index";
import General from "./components/general/general";
import Location from "./components/location/location";
import { RequestErrorHandling, TesGet, TesPost } from "../../../utils/rest";
import { useNavigate, useParams } from "react-router-dom";
import notify from "devextreme/ui/notify";
import { Button, ValidationGroup } from "devextreme-react";
import { custom } from "devextreme/ui/dialog";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { PagePermission, ResponseCode, TesMapModule } from "../../../types/general/enums/generalEnums";
import Permission from "../../../components/permission/permision";
import { InfrastructureApiUrl } from "../../../environment/routeSettings";
import { IPermanentCountStationParams, PermanentCountStation } from "../../../types/infrastructure/infrastructureTypes";
import { GeneralSetting } from "../../../contexts/clientSetting";
import { LocationPickerMode } from "../../../types/infrastructure/enums/infrastructureEnums";
import getTitle from "../../../components/title/getTitle";
import { InfrastructurePermissions } from "../../../constants/Permissions";

const PermanentCountStationDetails = () => {
  const [initData, setInitData] = useState<PermanentCountStation>(new PermanentCountStation());
  const [dataChanged, setDataChanged] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabValidation = useRef<ValidationGroup>(null);
  const didMount = useRef(false);
  const history = useNavigate();
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const params = useParams<IPermanentCountStationParams>();
  const [isLocked, setIsLocked] = useState(true);
  const [generalSetting] = useState<GeneralSetting>(new GeneralSetting());
  const { getPagePermission } = useAuth();
  const [pagePermissionStatus, setPagePermissionStatus] = useState<PagePermission>(PagePermission.Deny);

  const title = getTitle('/infrastructure/permanentCountStationDetails', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        if (!didMount.current) {
          if (activeLoading) activeLoading(true);
          if (params.stationId !== "AddNew") {
            await getInitDataPermanentCountStation(params.stationId!);
          }
          else {
            setIsLocked(false)
          }
          if (activeLoading) activeLoading(false);
          return (didMount.current = true);
        }
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    console.log(initData)
  }, []);

  //function for changing the tabs
  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  async function getInitDataPermanentCountStation(id: string) {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesGet(
        InfrastructureApiUrl() + "/api/PermanentCountStations/GetPermanentCountStationDetails/" + id,
        true
      )) as RequestResponseResult<PermanentCountStation>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        setInitData(res.results)
        if (getPagePermission) setPagePermissionStatus(getPagePermission([InfrastructurePermissions.Infrastructure_D_Setups,
          InfrastructurePermissions.Infrastructure_D_Street,], res.results));
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
    }
  }

  function goBackPermission() {
    if (dataChanged === true) {
      //dialog to show if you want to save the changed the data or discard it.
      let myDialog = custom({
        title: t("warning"),
        messageHtml: t("unsavedDataWarningText"),
        buttons: [
          {
            text: t("yes"),
            onClick: (e) => {
              try {
                if (params.stationId !== "AddNew") {
                  onUpdate();
                  setDataChanged(false)
                  history(-1);
                } else {
                  onAdd();
                  setDataChanged(false)
                }
                notify(t("dataSuccessfullyUpdated"), "success", 5000);
              } catch {
                notify(t("someErrorOccurred"), "error", 5000);
              }
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("no"),
            onClick: (e) => {
              history(-1);
              return { buttonText: e.component.option("text") };
            },
          },
          {
            text: t("cancel"),
            onClick: (e) => {
              setDataChanged(false);
              return { buttonText: e.component.option("text") };
            },
          },
        ],
      });
      myDialog.show();
    } else {
      history(-1);
    }
  }

  function LockHandler() {
    setIsLocked(!isLocked);
  }

  //add and update functions
  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        InfrastructureApiUrl() + "/api/PermanentCountStations/UpdatePermanentCourtStation",
        initData,
        true
      )) as RequestResponseResult<null>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
        // history(-1);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false)
  }

  async function onAdd() {
    // console.log(postObj)
    try {
          const postObj: PermanentCountStation = {
        ...initData,
        customerId: localStorage.getItem("selectedCustomerId")!
      }
      if (activeLoading) activeLoading(true);
      const res = (await TesPost(
        InfrastructureApiUrl() + "/api/PermanentCountStations/AddPermanentCountStation",
        postObj,
        true
      )) as RequestResponseResult<PermanentCountStation>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        notify(t("dataSuccessfullyAdded"), "success", 5000);
        history(-1);
        return;
      } else {
        await RequestErrorHandling(res);;
      }
    }
    catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
    setDataChanged(false)
  }


  return (
    // <Permission
    // allowed={[ModuleOfModulePermissions.ModuleOfModule_D_Module]}
    // hasFeedBackElement={true}
    // >
    <React.Fragment>
      <div className={"content-block"}>
        <div className={"dx-card"}>

          <div className="row" style={{ marginTop: 15 }}>
            <div className="leftColumn">
              <Button
                onClick={goBackPermission}
                icon="fa-solid fa-arrow-left"
                hint={t("goBack")}
              />
            </div>
            {pagePermissionStatus === PagePermission.Edit && params.stationId !== "AddNew" && (
            <div className="rightColumn">
              <Button
                onClick={() => LockHandler()}
                icon={isLocked ? "fa-solid fa-lock" : "fa-solid fa-lock-open"}
                hint={isLocked ? t("unlock") : t("lock")}
              />
            </div>)}
            <div className="rightColumn">
              {params.stationId === "AddNew" && (
                <Button onClick={onAdd} icon="fa-solid fa-floppy-disk" hint={t("save")} />
              )}
              {params.stationId !== "AddNew" && isLocked === false && (
                <Button onClick={onUpdate} icon="fa-solid fa-floppy-disk" hint={t("update")} />
              )}
            </div>
          </div>
        </div>
        <div className={"dx-card "}>
          <Tabs
            width={"100%"}
            dataSource={tabTitles}
            selectedIndex={selectedIndex}
            onOptionChanged={onTabsSelectionChanged}
          />

          {selectedIndex === 0 &&
            <General
              initData={initData}
              setInitData={setInitData}
              isLocked={isLocked}
              setDataChanged={setDataChanged}
              generalSettings={generalSetting}
            />
          }

          {selectedIndex === 1 &&
            <Location
              initData={initData}
              setInitData={setInitData}
              didMount={didMount.current}
              tesModule={TesMapModule.InfrastructureDetails}
              componentMode={LocationPickerMode.TextBox}
              isLocked={isLocked}
            />
          }
        </div>
      </div>
    </React.Fragment>
    // </Permission>
  );
};
export default PermanentCountStationDetails;
