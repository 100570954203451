import i18n from "../../../../../react-i18next.d";

const tabTitles = [
    {
        id: 0,
        text: i18n.t('mmsCategoryByRoadSegment'),
        icon: 'fa-solid fa-file-lines',
        content: 'Details tab content',
    },
    {
        id: 1,
        text: i18n.t('mmsCategoryByRin'),
        icon: 'fa-solid fa-layer-group',
        content: 'Smoothing tab content',
    },
];

export default tabTitles;