import React, { useEffect, useState } from 'react';
import { Button, DropDownButton, Tabs, ValidationGroup } from 'devextreme-react';
import { useTranslation } from 'react-i18next';
import { AllWayStopWarrants, StudyAllWayStopWarrantRecalculationReqDTO, StudyBaseDTO, StudyReportRequest } from '../../../../../../types/trafficStudy/dtos/studyDto';
import { RequestResponseResult } from '../../../../../../types/general/generalTypes';
import { ResponseCode } from '../../../../../../types/general/enums/generalEnums';
import { GeneralSetting } from '../../../../../../contexts/clientSetting';
import { RequestErrorHandling, TesPost } from '../../../../../../utils/rest';
import { useAuth } from '../../../../../../contexts/auth';
import notify from 'devextreme/ui/notify';
import tabTitles from './data/data';
import CollectorRuralArterial from './components/collectorRuralArterial';
import UrbanArterial from './components/urbanArterials';
import Local from './components/local';
import CollisionExperience from './components/collisionExperience';
import { ClientReport } from '../../../../../../types/report/reportTypes';
import reportLocations from '../../../../../../constants/reportLocations';
import { Popup } from 'devextreme-react/popup';
import ReportViewer from '../../../../../../components/reportViewer/ReportViewer';
import { TrafficApiUrl } from '../../../../../../environment/routeSettings';
import MaxDropdownItemWidthCalculator from '../../../../../../utils/dropDownWidthCalculator';

// props
interface IPros {
  initDataBase: StudyBaseDTO,
  generalSettings: GeneralSetting | null;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  initDataReports: ClientReport[] | undefined
  validationRef: React.RefObject<ValidationGroup>;
}
const AllWayStopWarrant = (props: IPros) => {
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [showReportModal, setShowReportModal] = useState<boolean>(false);
  const [selectedReport, setSelectedReport] = useState<ClientReport>(new ClientReport());
  const [reportPayLoad, setReportPayLoad] = useState<string>();
  const dropDownOptions = { width: 215 };
  const compactViewModel: boolean =(JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
    }
    fetchMyAPI()
  }, []);

  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  async function onRecalculate() {
    try {
      if (activeLoading) activeLoading(true);
      let postObj: StudyAllWayStopWarrantRecalculationReqDTO = {
        studyId: props.initDataBase.id!,
        allWayStopWarrants: props.initDataBase.allWayStopWarrants
      };
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Study/ReCalculateAllWayStopWarrants", postObj,
        true
      )) as RequestResponseResult<AllWayStopWarrants>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        props.setInitDataBase({
          ...props.initDataBase,
          allWayStopWarrantConfigChanged: false,
          allWayStopWarrants: res.results
        })
        notify(t("dataSuccessfullyUpdated"), "success", 2500);
      } else if (res.responseCode === ResponseCode.RawDataNotExist) {
        notify(t("rawDataNotExist"), "error", 2500);
      } else if (res.responseCode === ResponseCode.StopWarrantSettingsNotExist) {
        notify(t("stopWarrantSettingsNotExist"), "error", 2500);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 2500);
    }
  }
  function onOpenReport(d: any) {
    var res: StudyReportRequest = {
      ReportTitle: t('allWayStopWarrant'),
      StudyId: props.initDataBase.id!,
      TesReportTypeName: d.itemData.tesReportType.name
    }

    setReportPayLoad(JSON.stringify([res]))
    setSelectedReport(d.itemData);
    setShowReportModal(true);
  }

  return (
    <div className={`studyDetails-allWayStopWarrant ${compactViewModel ? "compactStyle" : ""}`}>
    <React.Fragment>
      <div className='row' style={{ margin: "1rem" }}>
        <ValidationGroup
          ref={props.validationRef}
        >
          <div className={"dx-card"}  id="iconsCard" style={{ margin: "0rem 1rem 2rem 1rem" }}>
            <div className="row" style={{ margin: "0.5rem 0 -0.5rem 0" }}>
              <div className="leftColumn">
                <Button
                  icon="fa-solid fa-calculator"
                  style={{ marginRight: 10 }}
                  hint={t("recalculate")}
                  disabled={props.isLocked}
                  onClick={onRecalculate}
                />
                <DropDownButton
                  style={{ marginLeft: 10 }}
                  icon="fa-solid fa-chart-line"
                  hint={t('report')}
                  items={props.initDataReports?.filter((x: ClientReport) => [reportLocations.TrafficStudy_StudyDetails_AllWayStopWarrants].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0))}
                  dropDownOptions={{width: props.initDataReports && MaxDropdownItemWidthCalculator(props.initDataReports?.filter((x: ClientReport) => [reportLocations.TrafficStudy_StudyDetails_AllWayStopWarrants].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)))}}
                  displayExpr="name"
                  onItemClick={(d) => onOpenReport(d)}
                />
              </div>
            </div>
          </div>
          <div className={"dx-card "} style={{ margin: "1rem" }}>
            <Tabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onOptionChanged={onTabsSelectionChanged}
            />
            {selectedIndex === 0 && (
              <UrbanArterial
                allWayStopWarrants={props.initDataBase.allWayStopWarrants}
                isLocked={props.isLocked}
                setInitDataBase={props.setInitDataBase}
                initDataBase={props.initDataBase}
              />)}
            {selectedIndex === 1 && (
              <CollectorRuralArterial
                allWayStopWarrants={props.initDataBase.allWayStopWarrants}
                isLocked={props.isLocked}
                setInitDataBase={props.setInitDataBase}
                initDataBase={props.initDataBase}
              />)}
            {selectedIndex === 2 && (
              <Local
                allWayStopWarrants={props.initDataBase.allWayStopWarrants}
                isLocked={props.isLocked}
                setInitDataBase={props.setInitDataBase}
                initDataBase={props.initDataBase}
              />)}
            {selectedIndex === 3 && (
              <CollisionExperience
                allWayStopWarrants={props.initDataBase.allWayStopWarrants}
                isLocked={props.isLocked}
                setInitDataBase={props.setInitDataBase}
                initDataBase={props.initDataBase}
              />)}
          </div>

          {/* Report Popup */}
          <Popup
            visible={showReportModal}
            width={"60%"}
            height={"75%"}
            resizeEnabled={true}
            showTitle={true}
            title={t("reportViewer")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowReportModal(false)}
          >
            <ReportViewer
              selectedReport={selectedReport}
              lstSelectedIds={[]}
              payload={reportPayLoad}
              isOpen={showReportModal}
            />
          </Popup>
        </ValidationGroup>
      </div>
    </React.Fragment>
    </div>
  );
}
export default AllWayStopWarrant;