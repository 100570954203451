import React, { useState } from "react";
import TextBox from "devextreme-react/text-box";
import { Switch } from "devextreme-react/switch";
import DateBox from "devextreme-react/date-box";
import { SelectBox } from "devextreme-react/select-box";
import { CustomerCollision, ICollisionParams } from "../../../../../types/collision/collisionTypes";
import { t } from "i18next";
import { INameId, NameValue } from "../../../../../types/general/generalTypes";
import { Form, NumberBox, TextArea } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import {
  VMCollisionGroupedField,
  VMField,
} from "../../../../../types/field/dto/fieldDTO";
import { Validator, RequiredRule } from "devextreme-react/validator";
import { ValidationGroup } from "devextreme-react/validation-group";
import { GeneralSetting, useClientSetting } from "../../../../../contexts/clientSetting";
import { VMMapLocation } from "../../../../../types/collision/dto/collisionDtos";
import { FieldPermission, FieldType } from "../../../../../types/field/enums/fieldEnums";
import { DatePickerDateCalculator, FormatLabelDT } from "../../../../../utils/dateTimeTools";
import design from "./general.module.scss"
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
import { Enum2Key } from "../../../../../utils/enumTools";
import { CollisionTesStatus, GeoCodeStatus } from "../../../../../types/collision/enums/collisionEnums";
import { TMSPermissions } from "../../../../../constants/Permissions";
import Permission from "../../../../../components/permission/permision";
import { IUser } from "../../../../../types/identity/dto/identityDTO";
import { DivisionSystem } from "../../../../../types/identity/enums/identityEnums";

// props
interface IPros {
  didMount: boolean;
  initDataCollision: CustomerCollision;
  initDataFields: VMCollisionGroupedField;
  tempMapLocation: VMMapLocation;
  validationRef: React.RefObject<ValidationGroup>;
  generalSettings: GeneralSetting | null;
  mapLocation: VMMapLocation;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  setInitDataCollision: React.Dispatch<React.SetStateAction<any>>;
  setMapLocation: React.Dispatch<React.SetStateAction<any>>
  initDataUserDivisions: INameId[];
  params: Readonly<Partial<ICollisionParams>>;
  isLocked: boolean;
  lstGeoCodeStatus: NameValue[]
  lstCollisionTesStatus: NameValue[]
  user: IUser | null;
  customerDivisions: INameId[] | null;
  initDataDivisions: INameId[];
}

const General = (props: IPros) => {
  //#region consts



  function onChangeField(name: string, value: any, isUpperCase: boolean) {
    if (name === "divisionId") {
      props.setInitDataCollision({
        ...props.initDataCollision,
        [name]: value,
      });
      props.setDataChanged(true)
    } else if (name === "geoCodeStatus") {
      props.setInitDataCollision({
        ...props.initDataCollision,
        [name]: value,
      });
      props.setDataChanged(true)
    } else {
      props.setInitDataCollision({
        ...props.initDataCollision,
        general: {
          ...props.initDataCollision.general,
          [name]: isUpperCase ? value.toString().toUpperCase() : value,
        },
      });
      props.setDataChanged(true)
    }
  }

  function generateRandom(name: string) {
    var d = new Date();
    const res =
      d.getFullYear() +
      String(d.getMonth() + 1).padStart(2, "0") +
      String(d.getDate()).padStart(2, "0") +
      String(d.getHours()).padStart(2, "0") +
      String(d.getMinutes()).padStart(2, "0") +
      String(d.getMinutes()).padStart(2, "0") +
      String(d.getSeconds()).padStart(2, "0") +
      String(d.getMilliseconds()).padStart(2, "0");
    onChangeField(name, res, false);
    return res;
  }

  function value2Name(fieldValues: INameId[], value: any) {
    return fieldValues?.find(x => x.id === value)?.name ?? "";
  }

  return (
    <React.Fragment>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2}>
            {!(props.isLocked) ?
              (parseInt(localStorage.getItem("divisionSystem")!) === DivisionSystem.Multiple &&
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("division")}
                  valueExpr={"id"}
                  displayExpr={"name"}
                  labelMode="static"
                  value={props.initDataCollision.divisionId}
                  items={SortObjectByPropName(props.initDataDivisions, "name")}
                  onValueChange={(e) => onChangeField("divisionId", e, false)}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                >
                  <Validator>
                    <RequiredRule message={t("fieldIsRequired")} />
                  </Validator>
                </SelectBox>
              </SimpleItem>)
              :
              <SimpleItem colSpan={1}>
              <p className={`${design.labels} ${design.bolder}`}>{t('division')}:<span className={`${design.spans}`}> {value2Name(props.customerDivisions!, props.initDataCollision.divisionId)}</span></p>
            </SimpleItem>}

            {!(props.isLocked) ?
              <SimpleItem colSpan={1}>
                <SelectBox
                  placeholder=""
                  label={t("geoCodeStatus")}
                  valueExpr={"value"}
                  displayExpr={"name"}
                  labelMode="static"
                  value={props.initDataCollision.geoCodeStatus}
                  items={SortObjectByPropName(props.lstGeoCodeStatus, "name")}
                  onValueChange={(e) => onChangeField("geoCodeStatus", e, false)}
                  disabled={props.isLocked}
                  showClearButton={true}
                  searchEnabled={true}
                >
                </SelectBox>
              </SimpleItem>
              :
              <SimpleItem colSpan={1}>
                <p className={`${design.labels} ${design.bolder}`}>{t('geoCodeStatus')}:<span className={`${design.spans}`}> {Enum2Key(GeoCodeStatus, props.initDataCollision.geoCodeStatus)}</span></p>
              </SimpleItem>
            }



            <SimpleItem colSpan={1}>
              {!(props.isLocked) ?
                <Permission
                  allowed={[
                    TMSPermissions.TMS_Admin,
                  ]}
                  hasFeedBackElement={false}
                >
                  <SelectBox
                    placeholder=""
                    label={t("collisionTesStatus")}
                    valueExpr={"value"}
                    displayExpr={"name"}
                    labelMode="static"
                    value={props.initDataCollision.collisionTesStatus}
                    items={SortObjectByPropName(props.lstCollisionTesStatus, "name")}
                    onValueChange={(e) => onChangeField("collisionTesStatus", e, false)}
                    disabled={true}
                    showClearButton={true}
                    searchEnabled={true}
                  >
                  </SelectBox>
                </Permission>
                :
                <Permission
                  allowed={[
                    TMSPermissions.TMS_Admin,
                  ]}
                  hasFeedBackElement={false}
                >
                  <p className={`${design.labels} ${design.bolder}`}>{t('collisionTesStatus')}:<span className={`${design.spans}`}> {Enum2Key(CollisionTesStatus, props.initDataCollision.collisionTesStatus)}</span></p>
                </Permission>
              }
            </SimpleItem>

            {props.initDataFields?.generalFields &&
              props.initDataFields?.generalFields.filter(x => props.isLocked ? true : x.readOnlyField === false).map(
                // eslint-disable-next-line array-callback-return
                (f: VMField, i: number) => {
                  if (f.fieldType === FieldType.String) {
                    if (!(props.isLocked || f.permission === FieldPermission.View || f.readOnlyField)) {
                      return (
                        <SimpleItem colSpan={1}>
                          <TextBox
                            label={f.labelText}
                            labelMode="static"
                            key={i}
                            value={props.initDataCollision.general && props.initDataCollision.general[f.name]}
                            onValueChange={(e) => onChangeField(f.name, e, f.isUpperCase)}
                          >
                            {f.isRequired && (
                              <Validator>
                                <RequiredRule message={t("fieldIsRequired")} />
                              </Validator>
                            )}
                          </TextBox>
                        </SimpleItem>
                      );
                    } else {
                      return (
                        <SimpleItem colSpan={1}>
                          <p className={`${design.labels} ${design.bolder}`}>{f.labelText}:<span className={`${design.spans}`}> {props.initDataCollision.general && props.initDataCollision.general[f.name]}</span></p>
                        </SimpleItem>
                      )
                    }
                  }

                  if (f.fieldType === FieldType.Number) {
                    if (!(props.isLocked || f.permission === FieldPermission.View || f.readOnlyField)) {
                      return (
                        <SimpleItem colSpan={1}>
                          <NumberBox
                            step={0}
                            label={f.labelText}
                            labelMode="static"
                            key={i}
                            value={
                              props.initDataCollision.general &&
                              props.initDataCollision.general[f.name]
                            }
                            onValueChange={(e) => onChangeField(f.name, e, f.isUpperCase)}
                          >
                            {f.isRequired && (
                              <Validator>
                                <RequiredRule message={t("fieldIsRequired")} />
                              </Validator>
                            )}
                          </NumberBox>
                        </SimpleItem>
                      );
                    } else {
                      return (
                        <SimpleItem colSpan={1}>
                          <p className={`${design.labels} ${design.bolder}`}>{f.labelText}:<span className={`${design.spans}`}> {props.initDataCollision.general && props.initDataCollision.general[f.name]}</span></p>
                        </SimpleItem>
                      )
                    }
                  }
                  if (f.fieldType === FieldType.Boolean) {
                    if (!(props.isLocked || f.permission === FieldPermission.View || f.readOnlyField)) {
                      return (
                        <SimpleItem colSpan={1}>
                          <div className={`${design.switches} ${props.isLocked ? design.bolder : ""}`}>{f.labelText}</div>
                          <Switch
                            style={{ marginTop: 5, fontWeight: "bold" }}
                            value={
                              props.initDataCollision.general &&
                              props.initDataCollision.general[f.name]
                            }
                            onValueChange={(e) => onChangeField(f.name, e, false)}
                            hint={f.labelText}
                          />
                        </SimpleItem>
                      );
                    }
                    else {
                      if (props.params.collisionId !== "AddNew") {
                        return (
                          < SimpleItem colSpan={1} >
                            <p className={`${design.labels} ${design.bolder}`}>{f.labelText}:<span className={`${design.spans}`}> {(props.initDataCollision.general && props.initDataCollision.general[f.name] === true ? "yes" : "No")}</span></p>
                          </SimpleItem>
                        )
                      }

                    }
                  }
                  if (f.fieldType === FieldType.Date) {
                    if (!(props.isLocked || f.permission === FieldPermission.View || f.readOnlyField)) {
                      return (
                        <SimpleItem colSpan={1}>
                          <DateBox
                            type="date"
                            label={f.labelText}
                            value={props.initDataCollision.general && (f.exactDT === true ? DatePickerDateCalculator(props.initDataCollision.general[f.name]) : props.initDataCollision.general[f.name])}
                            dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                            onValueChange={(e) => onChangeField(f.name, e, false)}
                            pickerType="calendar"
                            placeholder={props.generalSettings?.dateFormat}
                            displayFormat={props.generalSettings?.dateFormat}
                            useMaskBehavior={true}
                            openOnFieldClick={true}
                            showClearButton={true}
                          >
                            {f.isRequired && (
                              <Validator>
                                <RequiredRule message={t("fieldIsRequired")} />
                              </Validator>
                            )}
                          </DateBox>
                        </SimpleItem>
                      );
                    } else {
                      return (
                        <SimpleItem colSpan={1}>
                          <p className={`${design.labels} ${design.bolder}`}>{f.labelText}:<span className={`${design.spans}`}> {props.initDataCollision.general && (FormatLabelDT(props.initDataCollision.general[f.name], f, props.generalSettings))}</span></p>
                        </SimpleItem>
                      )
                    }
                  }
                  if (f.fieldType === FieldType.Time) {
                    if (!(props.isLocked || f.permission === FieldPermission.View || f.readOnlyField)) {

                      return (
                        <SimpleItem colSpan={1}>
                          <DateBox
                            type="time"
                            label={f.labelText}
                            value={props.initDataCollision.general && (f.exactDT === true ? DatePickerDateCalculator(props.initDataCollision.general[f.name]) : props.initDataCollision.general[f.name])}
                            dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                            onValueChange={(e) => onChangeField(f.name, e, false)}
                            pickerType="rollers"
                            placeholder={props.generalSettings?.timeFormat}
                            displayFormat={props.generalSettings?.timeFormat}
                            useMaskBehavior={true}
                            openOnFieldClick={true}
                            showClearButton={true}
                          >
                            {f.isRequired && (
                              <Validator>
                                <RequiredRule message={t("fieldIsRequired")} />
                              </Validator>
                            )}
                          </DateBox>
                        </SimpleItem>
                      );
                    }
                    else {
                      return (
                        <SimpleItem colSpan={1}>
                          <p className={`${design.labels} ${design.bolder}`}>{f.labelText}:<span className={`${design.spans}`}> {props.initDataCollision.general && (FormatLabelDT(props.initDataCollision.general[f.name], f, props.generalSettings))}</span></p>
                        </SimpleItem>
                      )
                    }
                  }
                  if (f.fieldType === FieldType.DateTime) {
                    if (!(props.isLocked || f.permission === FieldPermission.View || f.readOnlyField)) {
                      return (
                        <SimpleItem colSpan={1}>
                          <DateBox
                            type="datetime"
                            label={f.labelText}
                            value={props.initDataCollision.general && (f.exactDT === true ? DatePickerDateCalculator(props.initDataCollision.general[f.name]) : props.initDataCollision.general[f.name])}
                            dateSerializationFormat={f.exactDT === true ? "yyyy-MM-ddTHH:mm:ss" : undefined}
                            onValueChange={(e) => onChangeField(f.name, e, false)}
                            pickerType="calendar"
                            placeholder={props.generalSettings?.dateTimeFormat}
                            displayFormat={props.generalSettings?.dateTimeFormat}
                            useMaskBehavior={true}
                            openOnFieldClick={true}
                            showClearButton={true}
                          >
                            {f.isRequired && (
                              <Validator>
                                <RequiredRule message={t("fieldIsRequired")} />
                              </Validator>
                            )}
                          </DateBox>
                        </SimpleItem>
                      );
                    } else {
                      return (
                        <SimpleItem colSpan={1}>
                          <p className={`${design.labels} ${design.bolder}`}>{f.labelText}:<span className={`${design.spans}`}> {props.initDataCollision.general && (FormatLabelDT(props.initDataCollision.general[f.name], f, props.generalSettings))}</span></p>
                        </SimpleItem>
                      )
                    }
                  }
                  if (f.fieldType === FieldType.List) {
                    if (!(props.isLocked || f.permission === FieldPermission.View || f.readOnlyField)) {
                      return (
                        <SimpleItem colSpan={1}>
                          <SelectBox
                            placeholder=""
                            label={f.labelText}
                            valueExpr="id"
                            displayExpr="name"
                            value={
                              props.initDataCollision.general &&
                              props.initDataCollision.general[f.name]
                            }
                            onValueChange={(e) =>
                              onChangeField(f.name, e, f.isUpperCase)
                            }
                            items={SortObjectByPropName(f.fieldValues, "name")}
                            showClearButton={true}
                            searchEnabled={true}
                          >
                            {f.isRequired && (
                              <Validator>
                                <RequiredRule message={t("fieldIsRequired")} />
                              </Validator>
                            )}
                          </SelectBox>
                        </SimpleItem>
                      );
                    }
                    else {
                      return (
                        <SimpleItem colSpan={1}>
                          <p className={`${design.labels} ${design.bolder}`}>{f.labelText}:<span className={`${design.spans}`}> {value2Name(f.fieldValues, props.initDataCollision.general && props.initDataCollision.general[f.name])}</span></p>
                        </SimpleItem>
                      )
                    }
                  }
                  if (f.fieldType === FieldType.AutoGenerate) {
                    if (!(props.isLocked || f.permission === FieldPermission.View || f.readOnlyField)) {
                      return (
                        <SimpleItem colSpan={1}>
                          <TextBox
                            label={f.labelText}
                            value={
                              props.initDataCollision.general != null
                                ? props.initDataCollision.general[f.name]
                                : generateRandom(f.name)
                            }
                          />
                        </SimpleItem>
                      );
                    } else {
                      return (
                        <SimpleItem colSpan={1}>
                          <p className={`${design.labels} ${design.bolder}`}>{f.labelText}:
                            <span> {props.initDataCollision.general &&
                              props.initDataCollision.general[f.name]}</span></p>
                        </SimpleItem>
                      )
                    }
                  }
                  if (f.fieldType === FieldType.TextArea) {
                    if (!(props.isLocked || f.permission === FieldPermission.View || f.readOnlyField)) {
                      return (
                        <SimpleItem colSpan={1}>
                          <TextArea
                            label={f.labelText}
                            value={
                              props.initDataCollision.general &&
                              props.initDataCollision.general[f.name]
                            }
                            onValueChange={(e) =>
                              onChangeField(f.name, e, f.isUpperCase)
                            }
                          >
                            {f.isRequired && (
                              <Validator>
                                <RequiredRule message={t("fieldIsRequired")} />
                              </Validator>
                            )}
                          </TextArea>
                        </SimpleItem>
                      );
                    } else {
                      return (
                        <SimpleItem colSpan={1}>

                          <p className={`${design.labels} ${design.bolder}`}>{f.labelText}:<span className={`${design.spans}`}> {props.initDataCollision.general &&
                            props.initDataCollision.general[f.name]}</span></p>
                        </SimpleItem>
                      )
                    }
                  }
                }
              )}
          </Form>
        </ValidationGroup>
    </React.Fragment >
  );
};
export default General;
