import { useState, useRef, useCallback } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import Form, {
  Item,
  Label,
  ButtonItem,
  ButtonOptions,
  RequiredRule,
  CustomRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import { t } from "i18next";
import { ResponseCode } from '../../types/general/enums/generalEnums';
import { useAuth } from '../../contexts/auth';
import notify from 'devextreme/ui/notify';
import { RequestErrorHandling, TesPut } from '../../utils/rest';
import { AuthApiUrl } from '../../environment/routeSettings';
import { RequestResponseResult } from '../../types/general/generalTypes';
import { ChangePassword } from '../../types/identity/identityTypes';


export default function ChangePasswordForm() {
  const history = useNavigate();
  const [loading, setLoading] = useState(false);
  const formData = useRef({ password: '', confirmPass: '' });
  const { userId } = useParams<{ userId: string }>();
  const { activeLoading } = useAuth();

  const onSubmit = useCallback(async (e) => {
    e.preventDefault();
    const { password } = formData.current;
    const { confirmPass } = formData.current;
    setLoading(true);

    try {
      if (activeLoading) activeLoading(true);
      const regex = new RegExp('/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/ : /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/')

      if (!(regex.test(password ?? ""))) {
        notify(t("passwordIsNotComplex"), "error", 5000);
        return;
      }

      var postObj: ChangePassword = {
        currentPass: password,
        newPass: confirmPass,
        userId: userId as string,
      }

      var res = await TesPut(AuthApiUrl() + "/api/user/ResetPassword/" + userId, postObj, true) as RequestResponseResult<ChangePassword>;
      if (res.responseCode === ResponseCode.OK) {
        return res.results
      } else if (res.responseCode === ResponseCode.TokenExpired) {
        if (activeLoading) activeLoading(false);
        notify(t("enum-TokenExpired"), "error", 5000);
      } else if (res.responseCode === ResponseCode.LoginBlocked) {
        if (activeLoading) activeLoading(false);
        notify(t("enum-LoginBlocked"), "error", 5000);
      }
      else if (res.responseCode === ResponseCode.PasswordIsNotComplex) {
        if (activeLoading) activeLoading(false);
        notify(t("enum-PasswordIsNotComplex"), "error", 5000);
      }
      else if (res.responseCode === ResponseCode.UpdatingError) {
        if (activeLoading) activeLoading(false);
        notify(t("enum-UpdatingError"), "error", 5000);
      }
      if (activeLoading) activeLoading(false);
      await RequestErrorHandling(res);;
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
    setLoading(false);
   history('/login');
  }, [history]);

  const confirmPassword = useCallback(
    ({ value }) => value === formData.current.password,
    []
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={loading}>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmPass'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {
                loading
                  ? <LoadIndicator width={'24px'} height={'24px'} visible={true} />
                  : t('continue')
              }
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
}

const passwordEditorOptions = { stylingMode: 'filled', placeholder: 'Password', mode: 'password' };
const confirmedPasswordEditorOptions = { stylingMode: 'filled', placeholder: 'Confirm Password', mode: 'password' };
