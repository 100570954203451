import { useState, useEffect } from "react";
import {
    NumberBox,
    SelectBox, TagBox,
} from "devextreme-react";
import { Form, SimpleItem } from "devextreme-react/form";
import { Button } from "devextreme-react/button";
import { t } from "i18next";
import React from "react";
import { TesGet, TesPost } from "../../../../../utils/rest";
import { useAuth } from "../../../../../contexts/auth";
import notify from "devextreme/ui/notify";
import { NameValue } from "../../../../../types/general/generalTypes";
import { DayDefinitionType, SignalWarrantAlgorithmType } from "../../../../../types/trafficStudy/enums/trafficStudyEnums";
import { Enum2Array } from '../../../../../utils/enumTools';
import { TrafficStudyGeneralSetting } from "../../../../../types/trafficStudy/trafficStudyTypes";
import { TrafficApiUrl } from "../../../../../environment/routeSettings";
import { SortObjectByPropName } from "../../../../../utils/arrayTools";
interface IProps {
    clientId: string | undefined
}
const TrafficStudy = (props: IProps) => {
    const { activeLoading } = useAuth();
    const [initData, setInitData] = useState<TrafficStudyGeneralSetting>(new TrafficStudyGeneralSetting());
    const [lstDayDefinition, setLstDayDefinition] = useState<NameValue[]>([]);
    const [lstSignalWarrantAlgorithmType, setLstSignalWarrantAlgorithmType] = useState<NameValue[]>([]);
    const lstPercentile = [{ key: 55, value: 55 }, { key: 60, value: 60 }, { key: 65, value: 65 }, { key: 70, value: 70 }, { key: 75, value: 75 }, { key: 80, value: 80 }, { key: 90, value: 90 }]
    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getInitData();
                setLstDayDefinition(Enum2Array(DayDefinitionType))
                setLstSignalWarrantAlgorithmType(Enum2Array(SignalWarrantAlgorithmType))
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    async function getInitData() {
        setInitData(await TesGet(TrafficApiUrl() + "/api/Setups/GetCustomerGeneralSettings/" + props.clientId, true));
    }

    async function onSave() {
        try {
            const postObj = {
                ...initData,
                customerId: props.clientId,
            };
            if (activeLoading) activeLoading(true);
            await TesPost(
                TrafficApiUrl() + "/api/Setups/AddUpdateGeneralSettings",
                postObj,
                true
            );
            await getInitData();
            if (activeLoading) activeLoading(false);
            notify(t("dataSuccessfullyAdded"), "success", 5000);
        } catch {
            if (activeLoading) activeLoading(false);
            notify(t("someErrorOccurred"), "error", 5000);
        }
    }

    const onValueChanged = (key: string, value: any) => {
        setInitData({ ...initData, [key]: value });
    };

    return (
        <React.Fragment>
            <div className="row" style={{ marginTop: 20, padding: 10 }}>
                <Form colCount={2} className='popupFields'>
                    <SimpleItem colSpan={1}>
                        <SelectBox
                            placeholder=""
                            label={t('tmcDayDefinition')}
                            valueExpr="value"
                            displayExpr="name"
                            labelMode='static'
                            value={initData?.tmcDayDefinition}
                            onValueChange={(e) => onValueChanged("tmcDayDefinition", e)}
                            className="modalInput"
                            items={SortObjectByPropName(lstDayDefinition, "name")}
                            showClearButton={true}
                            searchEnabled={true}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                        <SelectBox
                            placeholder=""
                            label={t('signalWarrantAlgorithmType')}
                            valueExpr="value"
                            displayExpr="name"
                            labelMode='static'
                            value={initData?.signalWarrantAlgorithmType}
                            onValueChange={(e) => onValueChanged("signalWarrantAlgorithmType", e)}
                            className="modalInput"
                            items={SortObjectByPropName(lstSignalWarrantAlgorithmType, "name")}
                            showClearButton={true}
                            searchEnabled={true}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                        <SelectBox
                            placeholder=""
                            label={t('speedDayDefinition')}
                            valueExpr="value"
                            displayExpr="name"
                            labelMode='static'
                            value={initData?.speedDayDefinition}
                            onValueChange={(e) => onValueChanged("speedDayDefinition", e)}
                            className="modalInput"
                            items={SortObjectByPropName(lstDayDefinition, "name")}
                            showClearButton={true}
                            searchEnabled={true}
                        />
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                        <TagBox
                            items={SortObjectByPropName(lstPercentile, "key")}
                            searchEnabled={true}
                            label={t('studyTypes')}
                            valueExpr="value"
                            displayExpr="key"
                            value={initData?.speedPercentiles}
                            onValueChange={(e) => onValueChanged("speedPercentiles", e)}
                        >
                        </TagBox>
                    </SimpleItem>
                    <SimpleItem colSpan={1}>
                        <NumberBox
                            style={{ marginBottom: 20 }}
                            label={t("volumeChangeThresholdError")}
                            labelMode="static"
                            value={initData?.volumeChangeThresholdError}
                            onValueChange={(e) => onValueChanged("volumeChangeThresholdError", e)}
                        />
                    </SimpleItem>
                </Form>
                <div className="rightColumn">
                    <Button
                        className="tes-modal-btn-add saveButton"
                        onClick={onSave}
                        text={t("save")}
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
export default TrafficStudy;
