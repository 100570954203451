import React, { useRef, useState } from "react";
import { t } from "i18next"
import { Button } from "devextreme-react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  Export,
  Button as DevExpressButton,
  Search,
} from "devextreme-react/data-grid";
import "./area.scss";
import SiteListPicker from "../../../../../components/siteListPicker/siteListPicker";
import { WorkBookDTO } from "../../../../../types/safety/dto/safetyDtos";
import { AreaDTO } from "../../../../../types/infrastructure/dto/locationdDto";
import Permission from "../../../../../components/permission/permision";
import SafetyPermissions from "../../../../../constants/Permissions/SafetyPermissions";
import { OnExporting } from "../../../../../utils/dataGridTools";

interface IProps {
  isLocked: boolean;
  initData: WorkBookDTO;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  dataSaved: boolean;
  setDataSaved: React.Dispatch<React.SetStateAction<any>>;
}

function Area(props: IProps) {
  const [showModal, setShowModal] = useState(false);
  const dataGridRef = useRef<DataGrid<any, any>>(null);

  function onNew() {
    setShowModal(true)
  }

  async function onDelete(d: any) {
    props.setInitData({ ...props.initData, areas: props.initData.areas.filter(x => x.id !== d.row.data.id) })
    props.setDataSaved(false)
  }

  function addFromAreaPicker(selectedArea: AreaDTO[]) {
    props.setInitData({ ...props.initData, areas: [...props.initData.areas, ...selectedArea] })
    props.setDataChanged(true)
    setShowModal(false)
    props.setDataSaved(false)
  }

  return (
    <Permission
      allowed={[SafetyPermissions.Safety_V_Workbook]}
      hasFeedBackElement={true}
    >
      <React.Fragment>
        <h2 className={"content-block"}>{t("siteList")}</h2>
        <div className={"content-block"}>
          <div className={"dx-card responsive-paddings"}>
            <div className="row">
              <div className="rightColumn">
                <Permission
                  allowed={[SafetyPermissions.Safety_D_Workbook]}
                  hasFeedBackElement={false}
                >
                  <Button
                    onClick={onNew}
                    icon="fa-solid fa-link"
                    text={t("siteList")}
                    disabled={props.isLocked}
                  />
                </Permission>
              </div>
            </div>
            <DataGrid
              id="gridContainer"
              ref={dataGridRef}
              dataSource={props.initData.areas}
              rowAlternationEnabled={true}
              showBorders={true}
              hoverStateEnabled={true}
              remoteOperations={true}
              allowColumnReordering={true}
              allowColumnResizing={true}
              columnAutoWidth={true}
              onExporting={OnExporting}
            >
              <Export enabled={true} allowExportSelectedData={true} />
              <Grouping contextMenuEnabled={true} autoExpandAll={false} />
              <GroupPanel visible={true} />
              <FilterPanel visible={true} />
              <FilterBuilderPopup position={"top"} />
              <Paging enabled={true} defaultPageSize={100} />
              <Pager
                showPageSizeSelector={true}
                allowedPageSizes={[100, 200, 300, 400, 500]}
                showNavigationButtons={true}
                showInfo={true}
                visible={true}
              />
              <FilterRow visible={true} applyFilter="auto" />
              <HeaderFilter visible={true} />
              <SearchPanel visible={true} width={285} placeholder={t("search...")} />
              <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                <Search enabled />
              </ColumnChooser>
              <Column
                dataField="name"
                allowSorting={false}
                caption={t("name")}
              >
                <HeaderFilter>
                  <Search enabled />
                </HeaderFilter>
              </Column>
              <Column type="buttons" caption={t("actions")} width={110} visible={!props.isLocked} fixed={true} fixedPosition="right">
                <DevExpressButton
                  hint={t("delete")}
                  onClick={onDelete}
                  icon="fa-solid fa-trash-can"
                ></DevExpressButton>
              </Column>
            </DataGrid>
          </div>
        </div>
        {<SiteListPicker showModal={showModal} setShowModal={setShowModal} addFromAreaPicker={addFromAreaPicker} />}
      </React.Fragment>
    </Permission>
  )
}

export default Area;