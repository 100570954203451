//#region imports
import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Summary,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  GroupItem,
  Button as DevExpressButton,
  Export,
  Lookup,
  SortByGroupSummaryInfo,
  Search,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import { TesGet, TesPost, TesDelete, TesPut } from "../../../../utils/rest";
import { ModalMode } from "../../../../types/general/enums/generalEnums";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../../contexts/auth";
import { Form, ScrollView, SelectBox, Switch, TextBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { custom } from "devextreme/ui/dialog";
import "./validationRules.scss";
import { FieldServiceType } from "../../../../types/field/enums/fieldEnums";
import { ValidationRule } from "../../../../types/collision/collisionTypes";
import { ValidationRuleLevel } from "../../../../types/field/enums/fieldEnums";
import { Enum2Array } from "../../../../utils/enumTools";
import Rule from "../../../../components/rule/Rule";
import { Rule2View } from "../../../../utils/simplifiedFieldTools";
import { VMField } from "../../../../types/field/dto/fieldDTO";
import { NameValue } from "../../../../types/general/generalTypes";
import Permission from "../../../../components/permission/permision";
import { CollisionPermissions } from "../../../../constants/Permissions";
import { FieldApiUrl } from "../../../../environment/routeSettings";
import { OnExporting } from "../../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../../utils/arrayTools";
import getTitle from "../../../../components/title/getTitle";
//#endregion imports
const ValidationRules = () => {
  //#region consts
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const [initDataValidationRules, setInitDataValidationRules] = useState<ValidationRule[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [modalMode, setModalMode] = useState<ModalMode>(ModalMode.Add);
  const [initDataFields, setInitDataFields] = useState<VMField[]>([]);
  const [selectedData, setSelectedData] = useState<ValidationRule>(new ValidationRule());
  const [lstValidationRuleLevel, setLstValidationRuleLevel] = useState<NameValue[]>([]);
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  //#endregion
  //#region functions

  const title = getTitle('/collision/settings/validationRules', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitialDataValidationRules();
        await getInitialFieldData();
        setLstValidationRuleLevel(Enum2Array(ValidationRuleLevel));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  async function getInitialDataValidationRules() {
    setInitDataValidationRules(
      await TesGet(
        FieldApiUrl() +
        "/api/ValidationRules/" +
        localStorage.getItem("selectedCustomerId") +
        "/" +
        FieldServiceType.Collision,
        true
      )
    );
  }
  const getInitialFieldData = async () => {
    let res = await TesGet(
      FieldApiUrl() +
      "/api/TesFields/GetFieldsByServiceType/" +
      localStorage.getItem("selectedCustomerId") +
      "/" +
      FieldServiceType.Collision,
      true
    );
    setInitDataFields(res);
  };
  function onCloseModal() {
    if (modalMode === ModalMode.Add) {
      setShowModal(false);
      setSelectedData(new ValidationRule());
    } else {
      setSelectedData(new ValidationRule());
      setShowModal(false);
    }
  }
  const onValueChange = (key: string, value: any) => {
    setSelectedData({ ...selectedData, [key]: value });
  };
  function onEdit(d: any) {
    let newData = new ValidationRule();
    Object.assign(newData, d.row.data);
    setModalMode(ModalMode.Edit);
    setSelectedData(newData);
    setShowModal(true);
  }
  function calculateLevelTypeCell(d: any) {
    if (d.data.validationRuleLevel === 3) {
      return (
        <Button
          height={40}
          text={t("error")}
          className="tes-modal-btn-cancel"
        // stylingMode="outlined"
        // type="danger"
        />
      );
    }
    if (d.data.validationRuleLevel === 2) {
      return (
        <Button
          height={40}
          text={t("warning")}
          className="tes-modal-btn-cancel"
        // stylingMode="outlined"
        // type="default"
        />
      );
    }
    if (d.data.validationRuleLevel === 1) {
      return (
        <Button
          height={40}
          text={t("info")}
          className="tes-modal-btn-add"
        // stylingMode="outlined"
        // type="normal"
        />
      );
    }
  }

  function onNew() {
    setModalMode(ModalMode.Add);
    setShowModal(true);
  }


  async function onAdd() {
    try {
      const postObj = {
        ...selectedData,
        customerId: localStorage.getItem("selectedCustomerId"),
        fieldServiceType: FieldServiceType.Collision,
      };
      if (activeLoading) activeLoading(true);
      await TesPost(FieldApiUrl() + "/api/validationRules", postObj, true);
      dataGridRef.current?.instance.refresh();
      await getInitialDataValidationRules();
      onCloseModal();
      if (activeLoading) activeLoading(false);
      notify(t("dataSuccessfullyAdded"), "success", 5000);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }
  async function onUpdate() {
    try {
      if (activeLoading) activeLoading(true);
      await TesPut(
        FieldApiUrl() + "/api/validationRules/" + selectedData.id,
        selectedData,
        true
      );
      await getInitialDataValidationRules();
      onCloseModal();
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }
  async function onDelete(d: any) {
    let myDialog = custom({
      title: t("warning"),
      messageHtml: t("deleteWarningMessage"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              await TesDelete(
                FieldApiUrl() + "/api/validationRules/" + d.row.data.id,
                true
              );
              await getInitialDataValidationRules();
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }
            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  //#endregion functions

  return (
    <Permission
      allowed={[CollisionPermissions.Collision_D_ValidationRule]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("validationRules")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row">
                <div className="rightColumn">
                  <Button onClick={onNew} icon="fa-solid fa-circle-plus" text={t("validationRule")} />
                </div>
              </div>
              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initDataValidationRules}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                style={{ margin: "0 1rem" }}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <SortByGroupSummaryInfo
                  summaryItem="Total Count"
                  sortOrder="desc"
                />
                <Summary>
                  <GroupItem
                    summaryType="count"
                    alignByColumn
                    name="Total Count"
                  />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column
                  dataField="validationRuleLevel"
                  caption={t("level")}
                  width={150}
                  cellRender={calculateLevelTypeCell}
                >
                  <Lookup
                    dataSource={SortObjectByPropName(lstValidationRuleLevel, "name")}
                    valueExpr="value"
                    displayExpr="name"
                  />

                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="description" caption={t("description")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="rule"
                  caption={("rule")}
                  width={"200px"}
                  calculateCellValue={(x: any) =>
                    Rule2View(x?.rule ?? '', initDataFields)
                  }
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="errorMessage" caption={t("errorMessage")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="isDeactivated" caption={t("isDeactivated")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                  <DevExpressButton
                    hint={t("edit")}
                    onClick={onEdit}
                    icon="fa-solid fa-pencil"
                  ></DevExpressButton>
                  <DevExpressButton
                    hint={t("delete")}
                    onClick={onDelete}
                    icon="fa-solid fa-trash-can"
                  ></DevExpressButton>
                </Column>
              </DataGrid>
            </div>
          </div>

          {/* Popup for add/edit  */}
          <Popup
            width={"50%"}
            height={"auto"}
            visible={showModal}
            resizeEnabled={true}
            showTitle={true}
            title={modalMode === ModalMode.Add ? t("add") : t("update")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModal()}
          >
            <ScrollView width="100%" height="100%">
              <Form colCount={2} className="popupFields">
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("description")}
                    labelMode="static"
                    value={selectedData.description}
                    onValueChange={(e) => onValueChange("description", e)}
                    className="modalInput"
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <SelectBox
                    placeholder=""
                    label={t("level")}
                    labelMode="static"
                    valueExpr={"value"}
                    displayExpr={"name"}
                    value={selectedData.validationRuleLevel}
                    onValueChange={(e) =>
                      onValueChange("validationRuleLevel", e)
                    }
                    className="modalInput"
                    items={SortObjectByPropName(lstValidationRuleLevel, "name")}
                    showClearButton={true}
                    searchEnabled={true}
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <TextBox
                    label={t("errorMessage")}
                    labelMode="static"
                    value={selectedData.errorMessage}
                    onValueChange={(e) => onValueChange("errorMessage", e)}
                    className="modalInput"
                  />
                </SimpleItem>
                <SimpleItem colSpan={1}>
                  <div>{t("isDeactivated")}</div>
                  <Switch
                    style={{ marginTop: 5, fontWeight: "bold" }}
                    value={selectedData.isDeactivated}
                    onValueChange={(e) => onValueChange("isDeactivated", e)}
                    hint={t("isDeactivated")}
                  />
                </SimpleItem>

                <SimpleItem>
                  <Rule
                    name="rule"
                    onValueChange={onValueChange}
                    defaultRuleValue={selectedData?.rule}
                    fieldServiceType={FieldServiceType.Collision}
                  />
                </SimpleItem>
              </Form>
              <div>
                <div className="rightColumn">
                  <Button
                    className="tes-modal-btn-cancel"
                    style={{ marginRight: 20 }}
                    onClick={() => onCloseModal()}
                    text={t("cancel")}
                  />
                  {modalMode === ModalMode.Add ? (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onAdd}
                      text={t("add")}
                    />
                  ) : (
                    <Button
                      className="tes-modal-btn-add"
                      onClick={onUpdate}
                      text={t("update")}
                    />
                  )}
                </div>
              </div>
            </ScrollView>
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default ValidationRules;
