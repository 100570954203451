//#region imports
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ExtraPercentile, SpeedStatisticsResult, StudyReportRequest, StudySpeedWebDetailsDTO } from '../../../../../../types/trafficStudy/dtos/studyDto';
import { NameValue } from '../../../../../../types/general/generalTypes';
import { Enum2Array } from '../../../../../../utils/enumTools';
import DataGrid, {
    Column, FilterRow, HeaderFilter, SearchPanel, ColumnChooser, Pager, Paging, Lookup, Selection, Export, SortByGroupSummaryInfo, Summary, GroupItem, Search
} from 'devextreme-react/data-grid';
import { ApproachType } from '../../../../../../types/trafficStudy/enums/trafficStudyEnums';
import { GeneralSetting } from '../../../../../../types/setting/gridSettingTypes';
import { useAuth } from '../../../../../../contexts/auth';
import notify from 'devextreme/ui/notify';
import { ClientReport } from '../../../../../../types/report/reportTypes';
import { Popup } from 'devextreme-react/popup';
import ReportViewer from '../../../../../../components/reportViewer/ReportViewer';
import reportLocations from '../../../../../../constants/reportLocations';
import { DropDownButton, ValidationGroup } from 'devextreme-react';
import { OnExporting } from '../../../../../../utils/dataGridTools';
import MaxDropdownItemWidthCalculator from '../../../../../../utils/dropDownWidthCalculator';

//#endregion imports

// props
interface IPros {
    initDataSpeed: StudySpeedWebDetailsDTO,
    generalSettings: GeneralSetting | null;
    isLocked: boolean;
    setDataChanged: React.Dispatch<React.SetStateAction<any>>;
    initDataReports: ClientReport[] | undefined
    validationRef: React.RefObject<ValidationGroup>;
}


const SpeedStatistics = (props: IPros) => {
    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const summaryDataGridRef = useRef<DataGrid<any, any>>(null);
    const [lstApproachType, setLstApproachType] = useState<NameValue[]>();
    const [flatDataSource, setFlatDataSource] = useState<SpeedStatisticsResult[]>();
    const { t } = useTranslation();
    const { activeLoading } = useAuth();
    const [showReportModal, setShowReportModal] = useState<boolean>(false);
    const [selectedReport, setSelectedReport] = useState<ClientReport>(new ClientReport());
    const [reportPayLoad, setReportPayLoad] = useState<string>();
    const dropDownOptions = { width: 270 };
    const [filteredSummaryDataSource, setFilteredSummaryDataSource] = useState<SpeedStatisticsResult[]>();
    const compactViewModel: boolean =(JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

    //#region functions
    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                setLstApproachType(Enum2Array(ApproachType));
                convert2Flat(props.initDataSpeed?.speedStatisticsResults);
                const SummaryDataSource = [props.initDataSpeed.speedStatisticsSummary, props.initDataSpeed.speedStatisticsSummaryDirection1, props.initDataSpeed.speedStatisticsSummaryDirection2]
                setFilteredSummaryDataSource(SummaryDataSource.filter((element) => element.approachType !== ApproachType.Unknown))
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function convert2Flat(data: SpeedStatisticsResult[]) {
        var objRes: SpeedStatisticsResult[] = [];
        data?.forEach(s => {
            var obj: SpeedStatisticsResult = new SpeedStatisticsResult();
            obj.approachType = s.approachType
            obj.startD = s.startD
            obj.average = s.average
            obj.compliance = s.compliance
            obj.maximumSpeed = s.maximumSpeed
            obj.minimumSpeed = s.minimumSpeed
            obj.speed85thPercentile = s.speed85thPercentile
            obj.speed95thPercentile = s.speed95thPercentile
            obj.standardDeviation = s.standardDeviation
            obj.median = s.median
            obj.postedSpeed = s.postedSpeed
            obj.pace = s.pace
            obj.pacePercent = s.pacePercent
            obj.total = s.total
            if (s.extraPercentile) {
                s.extraPercentile.forEach(e => {
                    obj[e.percentile] = e.percentileValue
                });
            }
            objRes.push(obj);
        });
        setFlatDataSource(objRes);
    }

    function onOpenReport(d: any) {
        if (dataGridRef.current?.instance.getSelectedRowKeys().length === 0) {
            notify(t('pleaseSelectSomeData'), 'warning', 5000);
            return;
        }
        var payloadData = dataGridRef.current?.instance.getSelectedRowKeys().map(x => {
            var res: StudyReportRequest = {
                EndDT: x.endDT,
                ReportTitle: t('speedStatistics'),
                StartDT: x.startD,
                ApproachType: x.approachType,
                StudyId: props.initDataSpeed.id!,
                TesReportTypeName: d.itemData.tesReportType.name
            }
            return res;
        });
        setReportPayLoad(JSON.stringify(payloadData))
        setSelectedReport(d.itemData);
        // setLstSelectedIds(dataGridRef.current?.instance.getSelectedRowKeys().map(x => x.id) as string[]);
        setShowReportModal(true);
    }
    function onCloseReportModal() {
        setShowReportModal(false);
        dataGridRef.current?.instance.deselectAll();
    }

    //#endregion functions
    return (
        <div className={`studyDetails-speedStatistics ${compactViewModel ? "compactStyle" : ""}`}>
        <React.Fragment>
            <ValidationGroup
                ref={props.validationRef}>
                <h5 style={compactViewModel ? { margin: "0rem 0 -0.2rem 1rem" } : { margin: "2rem 0 -0.2rem 1rem" }}>{t("summary")}</h5>
                <DataGrid id="speedStatisticsGrid"
                    ref={summaryDataGridRef}
                    dataSource={filteredSummaryDataSource}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    remoteOperations={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    style={{ margin: "0 1rem 1rem" }}
                    onExporting={OnExporting}
                    //onContentReady={e => {e.component.deselectAll()}}
                    //onFilterValueChange={e => {summaryDataGridRef?.current?.instance.deselectAll()}}
                >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <Selection
                        mode="multiple"
                        selectAllMode={'allPages'}
                        showCheckBoxesMode={'always'}
                    />
                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                    />
                    <FilterRow visible={true}
                        applyFilter="auto" />

                    <HeaderFilter visible={true} />

                    <SearchPanel visible={true}
                        width={285}
                        placeholder={t("search...")} />
                    <ColumnChooser width={350} height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                    >
                        <Search enabled />
                    </ColumnChooser>
                    <SortByGroupSummaryInfo
                        summaryItem="Total Count"
                        sortOrder="desc"
                    />
                    <Summary>
                        <GroupItem
                            summaryType="count"
                            alignByColumn
                            name="Total Count"
                        />
                    </Summary>
                    <Column dataField="summary" caption={t("summary")} dataType='date' format={props.generalSettings?.dateFormat} >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column dataField="approachType" caption={t("approachType")}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                        {lstApproachType !== undefined &&
                            < Lookup
                                dataSource={lstApproachType}
                                valueExpr="value"
                                displayExpr="name"
                            />
                        }
                    </Column>
                    <Column
                        dataField="average"
                        caption={t("average")}
                        format="#.00"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="median"
                        caption={t("median")}
                        format="#.00"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="speed85thPercentile"
                        caption={t("speed85thPercentile")}
                        format="#.00"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="speed95thPercentile"
                        caption={t("speed95thPercentile")}
                        format="#.00"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    {props.initDataSpeed?.speedStatisticsSummary?.length > 0 && props.initDataSpeed?.speedStatisticsResults[0]?.extraPercentile && props.initDataSpeed?.speedStatisticsResults[0]?.extraPercentile
                        .map(
                            (e: ExtraPercentile) => {
                                return (
                                    <Column
                                        dataField={e.percentile}
                                        caption={e.percentile + t('thPercentile')}
                                        format="#.00"
                                        visible={true}
                                    >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                );
                            }
                        )
                    }
                    <Column
                        dataField="compliance"
                        caption={t("compliance")}
                        format="percent"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="postedSpeed"
                        caption={t("postedSpeed")}
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="standardDeviation"
                        caption={t("standardDeviation")}
                        format="#.00"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="maximumSpeed"
                        caption={t("maximumSpeed")}
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="minimumSpeed"
                        caption={t("minimumSpeed")}
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>

                    <Column
                        dataField="pace"
                        caption={t("pace")}
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>


                    <Column
                        dataField="pacePercent"
                        caption={t("pacePercent")}
                        visible={true}
                        format="percent"
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>


                    <Column
                        dataField="total"
                        caption={t("total")}
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                </DataGrid>

                <h5 style={compactViewModel ? {margin: "0rem 0 -0.2rem 1rem"} : { margin: "2rem 0 -0.2rem 1rem" }}>{t("details")}</h5>
                <div className="row" style={{ marginTop: "1rem" }}>
                    <div className="leftColumn">
                        <DropDownButton
                            style={compactViewModel ? { marginLeft: 5, marginTop: 12, marginBottom: -4 } : { marginLeft: 5, marginTop: 12, marginBottom: -10 }}
                            icon="fa-solid fa-chart-line"
                            hint={t('report')}
                            items={props.initDataReports?.filter((x: ClientReport) => [reportLocations.TrafficStudy_StudyDetails_SpeedStatistics].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0))}
                            dropDownOptions={{width: props.initDataReports && MaxDropdownItemWidthCalculator(props.initDataReports?.filter((x: ClientReport) => [reportLocations.TrafficStudy_StudyDetails_SpeedStatistics].some(a => x.reportLocations?.map(x => x.name).indexOf(a) >= 0)))}}
                            displayExpr="name"
                            onItemClick={(d) => onOpenReport(d)}
                        />
                    </div>
                </div>
                <DataGrid id="speedStatisticsGrid"
                    ref={dataGridRef}
                    dataSource={flatDataSource}
                    rowAlternationEnabled={true}
                    showBorders={true}
                    hoverStateEnabled={true}
                    remoteOperations={true}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnAutoWidth={true}
                    style={{ margin: "0 1rem 1rem" }}
                    onExporting={OnExporting}
                    //onContentReady={e => {e.component.deselectAll()}}
                    //onFilterValueChange={e => {dataGridRef?.current?.instance.deselectAll()}}
                >
                    <Export enabled={true} allowExportSelectedData={true} />
                    <Selection
                        mode="multiple"
                        selectAllMode={'allPages'}
                        showCheckBoxesMode={'always'}
                    />
                    <Paging enabled={true} defaultPageSize={100} />
                    <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                    />
                    <FilterRow visible={true}
                        applyFilter="auto" />

                    <HeaderFilter visible={true} />

                    <SearchPanel visible={true}
                        width={285}
                        placeholder={t("search...")} />
                    <ColumnChooser width={350} height={400}
                        enabled={true}
                        mode="select"
                        sortOrder="asc"
                    >
                        <Search enabled />
                    </ColumnChooser>
                    <SortByGroupSummaryInfo
                        summaryItem="Total Count"
                        sortOrder="desc"
                    />
                    <Summary>
                        <GroupItem
                            summaryType="count"
                            alignByColumn
                            name="Total Count"
                        />
                    </Summary>
                    <Column dataField="id" caption={t("id")} visible={false}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>

                    <Column dataField="startD" caption={t("startDT")} dataType='date' format={props.generalSettings?.dateFormat} >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>

                    </Column>
                    <Column dataField="approachType" caption={t("approachType")}>
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                        {lstApproachType !== undefined &&
                            < Lookup
                                dataSource={lstApproachType}
                                valueExpr="value"
                                displayExpr="name"
                            />
                        }
                    </Column>

                    <Column
                        dataField="average"
                        caption={t("average")}
                        format="#.00"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="median"
                        caption={t("median")}
                        format="#.00"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="speed85thPercentile"
                        caption={t("speed85thPercentile")}
                        format="#.00"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="speed95thPercentile"
                        caption={t("speed95thPercentile")}
                        format="#.00"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    {props.initDataSpeed?.speedStatisticsResults?.length > 0 && props.initDataSpeed?.speedStatisticsResults[0]?.extraPercentile && props.initDataSpeed?.speedStatisticsResults[0]?.extraPercentile
                        .map(
                            (e: ExtraPercentile) => {
                                return (
                                    <Column
                                        dataField={e.percentile}
                                        caption={e.percentile + t('thPercentile')}
                                        format="#.00"
                                        visible={true}
                                    >
                                        <HeaderFilter>
                                            <Search enabled />
                                        </HeaderFilter>
                                    </Column>

                                );
                            }
                        )
                    }
                    <Column
                        dataField="compliance"
                        caption={t("compliance")}
                        format="percent"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="postedSpeed"
                        caption={t("postedSpeed")}
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="standardDeviation"
                        caption={t("standardDeviation")}
                        format="#.00"
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="maximumSpeed"
                        caption={t("maximumSpeed")}
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="minimumSpeed"
                        caption={t("minimumSpeed")}
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="pace"
                        caption={t("pace")}
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>


                    <Column
                        dataField="pacePercent"
                        caption={t("pacePercent")}
                        visible={true}
                        format="percent"
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                    <Column
                        dataField="total"
                        caption={t("total")}
                        visible={true}
                    >
                        <HeaderFilter>
                            <Search enabled />
                        </HeaderFilter>
                    </Column>
                </DataGrid>

                {/* Report Popup */}
                <Popup
                    visible={showReportModal}
                    width={"60%"}
                    height={"75%"}
                    resizeEnabled={true}
                    showTitle={true}
                    title={t("reportViewer")}
                    hideOnOutsideClick={false}
                    showCloseButton={true}
                    onHiding={() => onCloseReportModal()}
                >
                    <ReportViewer
                        selectedReport={selectedReport}
                        lstSelectedIds={[]}
                        payload={reportPayLoad}
                        isOpen={showReportModal}
                    />
                </Popup>
            </ValidationGroup>
        </React.Fragment>
        </div>
    );

}
export default SpeedStatistics;