import React from "react";
import { Form, RequiredRule, SimpleItem } from "devextreme-react/form";
import { useTranslation } from "react-i18next";
import { TextBox, ValidationGroup, Validator } from "devextreme-react";
import { SiteTypeDTO } from "../../../../../../types/safety/dto/safetyDtos";
import Permission from "../../../../../../components/permission/permision";
import SafetyPermissions from "../../../../../../constants/Permissions/SafetyPermissions";

// props
interface IPros {
  initData: SiteTypeDTO;
  setInitData: React.Dispatch<React.SetStateAction<any>>;
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroup>
}


const Details = (props: IPros) => {
  const { t } = useTranslation();

  function onValueChange(name: string, value: any) {
    props.setInitData({...props.initData, [name]:value})
  }

  return (
    <Permission allowed={[SafetyPermissions.Safety_D_Setups]} hasFeedBackElement={true}>
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2}>
            <SimpleItem colSpan={1}>
              <TextBox
                label={t("name")}
                labelMode="static"
                value={props.initData.name}
                onValueChange={(e) => onValueChange("name", e)}
                disabled={props.isLocked}
              >
                <Validator>
                  <RequiredRule message={t("fieldIsRequired")} />
                </Validator>
              </TextBox>
            </SimpleItem>

          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
    </Permission>
  );
};
export default Details;
