import React, { useEffect, useState } from 'react';
import SectionsDiagram from '../../../../../../../components/sectionsDiagram/sectionsDiagram';
import { SectionDetailType } from '../../../../../../../types/infrastructure/enums/infrastructureEnums';
import { FlatRawDataDTO, FlatRawDataReq, StudyVolumeWebDetailsDTO } from '../../../../../../../types/trafficStudy/dtos/studyDto';
import { AddDays2Date, CombineDateAndTime, CreateDateAsUTC, FormatDT, FormatLabelDT, GetHourDiff } from '../../../../../../../utils/dateTimeTools';
import { useAuth } from '../../../../../../../contexts/auth';
import { TrafficApiUrl } from '../../../../../../../environment/routeSettings';
import { RequestResponseResult } from '../../../../../../../types/general/generalTypes';
import { RequestErrorHandling, TesPost } from '../../../../../../../utils/rest';
import { ResponseCode } from '../../../../../../../types/general/enums/generalEnums';
import { SimpleItem, Form } from 'devextreme-react/form';
import { Button, CheckBox, DateBox } from 'devextreme-react';
import { GeneralSetting } from '../../../../../../../types/setting/gridSettingTypes';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import GroupedDataByTimeAndClassificationType from '../../../../../../../components/volumeTimeChart/volumeTimeChart';
import { Chart, Series, CommonSeriesSettings, Legend, ValueAxis, Title, Export as ChartExport, Tooltip, ArgumentAxis, Export, Margin, Label, Point } from 'devextreme-react/chart';
import { FieldType } from '../../../../../../../types/field/enums/fieldEnums';

// props
interface IPros {
  isLocked: boolean
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>
  initDataBase: StudyVolumeWebDetailsDTO,
  generalSettings: GeneralSetting | null;
}


const Diagram = (props: IPros) => {

  const { activeLoading } = useAuth();
  const [dateRange, setDateRange] = useState<[Date, Date]>();
  const [flatRawData, setFlatRawData] = useState<FlatRawDataDTO[]>([]);
  const [showDateRangePicker, setShowDateRangePicker] = useState<boolean>(false)
  const [dataLoaded, setDataLoaded] = useState<boolean>(false)
  const { t } = useTranslation();
  const [isCarVisible, setIsCarVisible] = useState(true);
  const [isVehicleVisible, setIsVehicleVisible] = useState(true);
  const [isCyclistVisible, setIsCyclistVisible] = useState(false);
  const [isTruckVisible, setIsTruckVisible] = useState(false);
  const [isPedestrianVisible, setIsPedestrianVisible] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date | undefined>();

  useEffect(() => {
    if (dataLoaded === false && dateRange !== undefined) {
      applyPeriodFilter();
      setDataLoaded(true);
    }
    if (selectedDate && dateRange && selectedDate < dateRange[0]) {
      setSelectedDate(dateRange[0]);
    }
  }, [dataLoaded, dateRange]);

  useEffect(() => {
    // console.log("DATA " + JSON.stringify(props.initDataBase))
    var startDT = new Date(props.initDataBase.rawDataStartDT!);
    var endDT = new Date(props.initDataBase.rawDataEndDT!);
    var duration = GetHourDiff(startDT, endDT);
    if (duration > 24) {
      setShowDateRangePicker(true)
    }

    if (duration > 72) {
      endDT = AddDays2Date(startDT, 1);
      //setTimeOfEndDate(endDT)
    }
    console.log("startDT " + startDT)
    setDateRange([startDT, endDT]);
  }, [props.initDataBase.rawDataStartDT, props.initDataBase.rawDataEndDT]);



  function isValidDate(dateObject: Date) {
    return new Date(dateObject).toString() !== 'Invalid Date';
  }

  async function applyPeriodFilter() {
    if (!isValidDate(dateRange![1])) {
      return
    }
    if (activeLoading) activeLoading(true);
    var postObj: FlatRawDataReq = {
      endDT: CreateDateAsUTC(dateRange![1]),
      startDT: CreateDateAsUTC(dateRange![0]),
      studyId: props.initDataBase.id,
      showZeroPriods: true
    }

    const res = (await TesPost(
      TrafficApiUrl() + "/api/Study/GetFlatRawData", postObj,
      true
    )) as RequestResponseResult<FlatRawDataDTO[]>;
    if (res.responseCode === ResponseCode.OK) {
      setFlatRawData(res.results);
    } else {
      await RequestErrorHandling(res);;
    }
    if (activeLoading) activeLoading(false);

  }

  function customizeArgumentAxisLabels({ value }: { value: any }) {
    const date = new Date(value);
    const minutes = date.getMinutes();
    const hour = date.getHours();
    return minutes === 0 ? `${hour}:00` : '';
  }

  function customizeTooltip({ argument, seriesName, value }: { argument: any, seriesName: any, value: any }) {
    return {
      text: `${FormatDT(argument, FieldType.DateTime, true, props.generalSettings)}, ${seriesName}, ${value}`
    };
  }

  function filterChartData() {
    if (selectedDate === undefined || !isValidDate(selectedDate)) {
      return [];
    }

    const filteredData = flatRawData.filter((item) => {
      const itemDate = new Date(item.startDT ?? ''); // Add nullish coalescing operator to handle undefined startDT
      const selectedDateStart = new Date(selectedDate);
      const selectedDateEnd = new Date(selectedDate);
      selectedDateStart.setHours(0, 0, 0, 1);
      selectedDateEnd.setHours(23, 59, 59, 999);
      return itemDate >= selectedDateStart && itemDate <= selectedDateEnd;
    });
    
    const groupedData = GroupedDataByTimeAndClassificationType(filteredData);
    return groupedData;
  }

  return (
    <React.Fragment>
      <div>
        <Form colCount={3} style={{ marginBottom: 20, marginLeft: '2.5rem' }}>
          <SimpleItem colSpan={3}>
            <p style={{ marginTop: 5, fontSize: 16 }}>The raw data is accessible for the duration
              spanning from <span style={{ fontWeight: "bold" }}>{(props.initDataBase?.rawDataStartDT !== undefined && props.initDataBase?.rawDataStartDT !== null) ? DateTime.fromISO(props.initDataBase?.rawDataStartDT.toString(), { zone: 'utc' }).toFormat(props.generalSettings?.dateTimeFormat!) : ""}</span> to <span style={{ fontWeight: "bold" }}>{props.initDataBase?.rawDataEndDT !== undefined ? DateTime.fromISO(props.initDataBase.rawDataEndDT?.toString(), { zone: 'utc' }).toFormat(props.generalSettings?.dateTimeFormat!) : ""}</span>. The report below has been generated
              for the specific dates specified in the fields.</p>
          </SimpleItem>
          {dateRange !== undefined &&
            <SimpleItem colSpan={1}>
              <DateBox
                label={t("from")}
                id="dateBoxes"
                type="datetime"
                onValueChange={e => setDateRange([e, dateRange![1]])}
                pickerType="calendar"
                placeholder={props.generalSettings?.dateTimeFormat}
                displayFormat={props.generalSettings?.dateTimeFormat}
                useMaskBehavior={true}
                openOnFieldClick={true}
                showClearButton={true}
                value={dateRange![0]}
                min={props.initDataBase.rawDataStartDT}
                max={props.initDataBase.rawDataEndDT}
              />
            </SimpleItem>
          }
          {dateRange !== undefined &&
            <SimpleItem colSpan={1}>
              <DateBox
                label={t("to")}
                id="dateBoxes"
                type="datetime"
                onValueChange={e => setDateRange([dateRange[0]!, e])}
                placeholder={props.generalSettings?.dateTimeFormat}
                displayFormat={props.generalSettings?.dateTimeFormat}
                useMaskBehavior={true}
                openOnFieldClick={true}
                showClearButton={true}
                value={dateRange![1]}
                min={dateRange![0]}
                max={new Date(dateRange![0].getFullYear(), dateRange![0].getMonth(), dateRange![0].getDate() + 3)}
              />
            </SimpleItem>
          }
          <SimpleItem colSpan={1}>
            <Button
              id="applyButton"
              onClick={() => applyPeriodFilter()}
              className='tes-modal-btn-add'
              text={t('apply')}
              style={{ marginTop: 10 }}
            />
          </SimpleItem>
        </Form>
      </div>
      {/* } */}
      <SectionsDiagram size={900}
        sectionType={props.initDataBase?.sectionType ?? SectionDetailType.NorthSouthTwoWayRoad}
        NorthStreetName=""
        EastStreetName=""
        SouthStreetName=""
        WestStreetName=""
        leftHandDrive={false}
        data={flatRawData}
        oneDayCalendarRange={dateRange}
        generalSettings={props.generalSettings}
        initDataBase={props.initDataBase}
        showPeriod={true}
      />
      {/* {console.log("Data:")}
      {console.table(flatRawData)}
      {console.table("GroupedData")}
      {console.table(GroupedDataByTimeAndClassificationType(flatRawData))} */}

      <div style={{ display: "flex", justifyContent: "center" }}>
        <DateBox
          label={t("date")}
          value={selectedDate || (dateRange ? dateRange[0] : undefined)}
          onValueChanged={(e) => setSelectedDate(e.value)}
          type="date"
          displayFormat={props.generalSettings?.dateFormat}
          pickerType="calendar"
          // showClearButton={true}
          min={dateRange ? dateRange[0] : undefined}
          max={dateRange ? dateRange[1] : undefined}
        />
      </div>

      <div style={{ marginTop: "-1rem" }}>
        <Chart id="chart" dataSource={filterChartData()}>
          <Margin top={0} bottom={10} left={20} right={20} />
          <CommonSeriesSettings argumentField="startDT" type="line" />
          <Series
            valueField="totalCountVehicle"
            name={t('total')}
            color="#F0B70D"
            axis="leftAxis"
            visible={isVehicleVisible}
          >
            <Point visible={true} symbol="circle" size={8} />
          </Series>
          <Series
            valueField="totalCountCar"
            name={t('totalCar')}
            color="#0b1d78"
            axis="leftAxis"
            visible={isCarVisible}
          >
            <Point visible={true} symbol="circle" size={12} />
          </Series>
          <Series
            valueField="totalCountCyclist"
            name={t('totalCyclist')}
            color="#f05442"
            axis="rightAxis"
            visible={isCyclistVisible}
          >
            <Point visible={true} symbol="circle" size={12} />
          </Series>
          <Series
            valueField="totalCountTruck"
            name={t('totalTruck')}
            color="#ab006f"
            axis="rightAxis"
            visible={isTruckVisible}
          >
            <Point visible={true} symbol="circle" size={12} />
          </Series>
          <Series
            valueField="totalPedestrian"
            name={t('totalPedestrian')}
            color="#5E1788"
            axis="rightAxis"
            visible={isPedestrianVisible}
          >
            <Point visible={true} symbol="circle" size={12} />
          </Series>

          <ValueAxis
            name="leftAxis"
            position="left"
            // valueType="startDT"
            showZero={true}
            valueMarginsEnabled={false}
            allowDecimals={false}
          >
            <Title text={t("totalCar/totalVolume")} />
          </ValueAxis>
          <ValueAxis
            name="rightAxis"
            position="right"
            showZero={true}
            valueMarginsEnabled={false}
            allowDecimals={false}
          >
            <Title text={t("totalCyclist/totalTruck/totalPedestrianVolume")} />
          </ValueAxis>
          <ArgumentAxis>
            <Label customizeText={customizeArgumentAxisLabels} />
          </ArgumentAxis>
          <Legend position="inside" horizontalAlignment="right" />
          <Export enabled={true} printingEnabled={false} />
          <Tooltip enabled={true} location="edge" />
          <Tooltip enabled={true} location="edge" customizeTooltip={customizeTooltip} />
        </Chart>

        <div style={{ display: "flex", justifyContent: "center" }}>
          <CheckBox
            text={t('total')}
            value={isVehicleVisible}
            onValueChanged={(e) => setIsVehicleVisible(e.value)}
            style={{ margin: "0.75rem 1rem 1rem 1rem" }}
          />
          <CheckBox
            text={t('totalCar')}
            value={isCarVisible}
            onValueChanged={(e) => setIsCarVisible(e.value)}
            style={{ margin: "0.75rem 1rem 1rem 1rem" }}
          />
          <CheckBox
            text={t('totalCyclist')}
            value={isCyclistVisible}
            onValueChanged={(e) => setIsCyclistVisible(e.value)}
            style={{ margin: "0.75rem 1rem 1rem 1rem" }}
          />
          <CheckBox
            text={t('totalTruck')}
            value={isTruckVisible}
            onValueChanged={(e) => setIsTruckVisible(e.value)}
            style={{ margin: "0.75rem 1rem 1rem 1rem" }}
          />
          <CheckBox
            text={t('totalPedestrian')}
            value={isPedestrianVisible}
            onValueChanged={(e) => setIsPedestrianVisible(e.value)}
            style={{ margin: "0.75rem 1rem 1rem 1rem" }}
          />
        </div>
      </div>

    </React.Fragment>
  );


}
export default Diagram;