import React, { useEffect, useState } from 'react';
import { Button, DropDownButton, Tabs, ValidationGroup } from 'devextreme-react';

import { useTranslation } from 'react-i18next';
import { AllWayStopWarrants, StudyBaseDTO, StudyVolumeWebDetailsDTO } from '../../../../../../types/trafficStudy/dtos/studyDto';
import { RequestResponseResult } from '../../../../../../types/general/generalTypes';
import { ResponseCode } from '../../../../../../types/general/enums/generalEnums';
import { GeneralSetting } from '../../../../../../contexts/clientSetting';
import { RequestErrorHandling, TesPost } from '../../../../../../utils/rest';
import { useAuth } from '../../../../../../contexts/auth';
import notify from 'devextreme/ui/notify';
import tabTitles from './data/data';
import Details from './components/details';
import Diagram from './components/diagram';
import { TrafficApiUrl } from '../../../../../../environment/routeSettings';

// props
interface IPros {
  initDataBase: StudyBaseDTO,
  initDataVolume: StudyVolumeWebDetailsDTO,
  generalSettings: GeneralSetting | null;
  setInitDataBase: React.Dispatch<React.SetStateAction<any>>
  isLocked: boolean;
  setDataChanged: React.Dispatch<React.SetStateAction<any>>;
  validationRef: React.RefObject<ValidationGroup>;
}
const VolumeResults = (props: IPros) => {
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const compactViewModel: boolean =(JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";

  useEffect(() => {
    async function fetchMyAPI() {
    }
    fetchMyAPI()
  }, []);

  function onTabsSelectionChanged(args: any) {
    if (args.name === "selectedIndex") {
      setSelectedIndex(args.value);
    }
  }

  async function onRecalculate() {
    try {
      if (activeLoading) activeLoading(true);
      let postObj: StudyBaseDTO = {
        ...props.initDataBase,
      };
      delete postObj.signalWarrantConfig.studyBase;
      delete postObj.rawData;
      const res = (await TesPost(
        TrafficApiUrl() + "/api/Study/ReCalculateAllWayStopWarrants", postObj,
        true
      )) as RequestResponseResult<AllWayStopWarrants>;
      if (activeLoading) activeLoading(false);
      if (res.responseCode === ResponseCode.OK) {
        props.setInitDataBase({
          ...props.initDataBase,
          allWayStopWarrantConfigChanged: false,
          allWayStopWarrants: res.results
        })
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.RawDataNotExist) {
        notify(t("rawDataNotExist"), "error", 5000);
      } else {
        await RequestErrorHandling(res);;
      }
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }
  return (
    <div className={`studyDetails-resultSummary ${compactViewModel ? "compactStyle" : ""}`}>
    <React.Fragment>
      <div className='row' style={{ margin: "1rem" }}>
        <ValidationGroup
          ref={props.validationRef}
        >
          <div className={"dx-card"} id="iconsCard" style={{ margin: "0rem 1rem 2rem 1rem" }}>
            <div className="row" style={{ margin: "0.5rem 0 -0.5rem 0" }}>
              <div className="leftColumn" style={{ marginLeft: 10 }}>
                <Button
                  icon="fa-solid fa-calculator"
                  hint={t("recalculate")}
                  onClick={onRecalculate}
                  disabled={props.isLocked}
                />
              </div>
            </div>
          </div>
          <div className={"dx-card "} style={{ margin: "1rem" }}>
            <Tabs
              width={"100%"}
              dataSource={tabTitles}
              selectedIndex={selectedIndex}
              onOptionChanged={onTabsSelectionChanged}
            />
            {selectedIndex === 0 && (
              <Diagram
                generalSettings={props.generalSettings}
                isLocked={props.isLocked}
                setInitDataBase={props.setInitDataBase}
                initDataBase={props.initDataVolume}
              />)}
            {selectedIndex === 1 && (
              <Details
                isLocked={props.isLocked}
                setInitDataBase={props.setInitDataBase}
                initDataBase={props.initDataBase}
                generalSettings={props.generalSettings}
                initDataTmc={props.initDataVolume}
              />)}

          </div>
        </ValidationGroup>
      </div>
    </React.Fragment>
    </div>
  );
}
export default VolumeResults;