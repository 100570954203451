//imports
import React, { useEffect, useState } from 'react';
import Tabs from 'devextreme-react/tabs';
import tabTitles from './data/index';
import Overview from './component/overview/overview';
import EmphasisArea from './component/emphasisArea/emphasisArea';
import Comparative from './component/comparative/comparative';
import HeatMap from './component/heatMap/heatMap';
import { RequestErrorHandling, TesGet, TesPost } from '../../../utils/rest';
import { INameId, RequestResponseResult } from '../../../types/general/generalTypes';
import { Area, TesCodeValue } from '../../../types/infrastructure/infrastructureTypes';
import { useClientSetting } from '../../../contexts/clientSetting';
import Permission from '../../../components/permission/permision';
import { CollisionPermissions } from '../../../constants/Permissions';
import { CollisionApiUrl, FieldApiUrl, InfrastructureApiUrl } from '../../../environment/routeSettings';
import { useAuth } from '../../../contexts/auth';
import notify from 'devextreme/ui/notify';
import { useTranslation } from 'react-i18next';
import { GetCollisionDatePeriodRequestDTO } from '../../../types/collision/dto/collisionDtos';
import dashboardPermissions from '../../../constants/Permissions/dashboardPermissions';
import getTitle from '../../../components/title/getTitle';
import { GeneralSettings } from '../../../types/collision/collisionTypes';
import { ResponseCode } from '../../../types/general/enums/generalEnums';

const CollisionDashboard = () => {
    //states
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [initDataSiteList, setInitDataSiteList] = useState<INameId[]>();
    const [datePeriod, setDatePeriod] = useState<GetCollisionDatePeriodRequestDTO>();
    const [initDataCollisionGeneralSetting, setInitDataCollisionGeneralSetting] = useState<GeneralSettings>();
    const [initDataTesCodeValues, setInitDataTesCodeValues] = useState<TesCodeValue>(new TesCodeValue());
    const { generalSetting } = useClientSetting();
    const { activeLoading } = useAuth();
    const { t } = useTranslation();

    const title = getTitle('/dashboard/transportationSafety', '');
    useEffect(() => {
        document.title = title;
    }, [title]);

    useEffect(() => {
        async function fetchMyAPI() {
            try {
                if (activeLoading) activeLoading(true);
                await getSiteListInitialData();
                await getInfrastructureTesCodeValues();
                await getInitDataCollisionGeneralSetting();
                await getMinMaxdate();
                if (activeLoading) activeLoading(false);
            } catch (ex) {
                if (activeLoading) activeLoading(false);
                notify(t("someErrorOccurred") + ex, "error", 5000);
            }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    //function for changing the tabs
    function onTabsSelectionChanged(args: any) {
        if (args.name === 'selectedIndex') {
            setSelectedIndex(args.value)
        }
    }

    async function getSiteListInitialData() {
        const postObj = {
            customerId: localStorage.getItem("selectedCustomerId"),
            divisionId: localStorage.getItem("selectedDivisionId"),
        }
        const res = await TesPost(InfrastructureApiUrl() + "/api/areas",
            postObj
            , true) as RequestResponseResult<Area[]>
        if (res.responseCode === ResponseCode.OK) {
            setInitDataSiteList(res.results)
        } else {
            await RequestErrorHandling(res);
        }
    }

    async function getInitDataCollisionGeneralSetting() {
        setInitDataCollisionGeneralSetting(await TesGet(CollisionApiUrl() + "/api/Settings/GetCustomerSettings/" + localStorage.getItem('selectedCustomerId'), true));
    }

    async function getMinMaxdate() {
        var postObj: GetCollisionDatePeriodRequestDTO = {
            customerId: localStorage.getItem('selectedCustomerId') as string,
        }
        var res = await TesPost(CollisionApiUrl() + "/api/Collisions/GetCollisionDatePeriod", postObj, true) as RequestResponseResult<GetCollisionDatePeriodRequestDTO>
        if (new Date(res.results.startDT?.toString()!).getUTCFullYear() === new Date('0001-01-01T00:00:00Z').getUTCFullYear()) {
            var currentDate = new Date();
            setDatePeriod({
                endDT: new Date(currentDate.getUTCFullYear()-4, 0, 1),
                startDT: new Date(currentDate.getUTCFullYear()-1, 11, 31)
            })
        } else {
            setDatePeriod(res.results);
        }
    }

    async function getInfrastructureTesCodeValues() {
        setInitDataTesCodeValues(await TesGet(FieldApiUrl() + "/api/codeValues/infrastructureTesCodeValues/" + localStorage.getItem('selectedCustomerId'), true));
    }

    return (
        <Permission
            allowed={[dashboardPermissions.Dashboard_V_TransportationSafety]}
            hasFeedBackElement={true}
        ><React.Fragment>
                <div className={'content-block'}>
                    <div className={'dx-card dashboardTab'}>
                        <Tabs
                            width={'100%'}
                            dataSource={tabTitles}
                            selectedIndex={selectedIndex}
                            onOptionChanged={onTabsSelectionChanged}
                        />
                        {(selectedIndex === 0 && datePeriod != undefined) &&
                            <Overview
                                initDataSiteList={initDataSiteList}
                                initDataTesCodeValues={initDataTesCodeValues}
                                generalSettings={generalSetting}
                                datePeriod={datePeriod}
                            />
                        }
                        {(selectedIndex === 1 && datePeriod != undefined) &&
                            <EmphasisArea
                                initDataSiteList={initDataSiteList}
                                initDataTesCodeValues={initDataTesCodeValues}
                                generalSettings={generalSetting}
                                datePeriod={datePeriod}
                                initDataCollisionGeneralSetting={initDataCollisionGeneralSetting}
                            />
                        }
                        {(selectedIndex === 2 && datePeriod != undefined) &&
                            <Comparative
                                initDataSiteList={initDataSiteList}
                                initDataTesCodeValues={initDataTesCodeValues}
                                generalSettings={generalSetting}
                                datePeriod={datePeriod}
                                initDataCollisionGeneralSetting={initDataCollisionGeneralSetting}
                            />
                        }
                        {(selectedIndex === 3 && datePeriod != undefined) &&
                            <HeatMap
                                initDataSiteList={initDataSiteList}
                                initDataTesCodeValues={initDataTesCodeValues}
                                generalSettings={generalSetting}
                                datePeriod={datePeriod}
                            />
                        }
                    </div>
                </div>
            </React.Fragment></Permission>

    );
}
export default CollisionDashboard;