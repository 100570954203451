import React, { useEffect, useRef, useState } from "react";
import Permission from "../../../components/permission/permision";
import { TMSPermissions } from "../../../constants/Permissions";
import { Button, DataGrid, Form, Popup, TextBox } from "devextreme-react";
import { OnExporting } from "../../../utils/dataGridTools";
import { Column, Export, FilterBuilderPopup, FilterPanel, FilterRow, GroupPanel, Grouping, HeaderFilter, Pager, Paging, Search, SearchPanel, Button as DevExpressButton, ColumnChooser} from "devextreme-react/data-grid";
import { t } from "i18next";
import { EditKeyDto, GetKetDtos } from "../../../types/translation/dtos/translationDtos";
import notify from "devextreme/ui/notify";
import { TranslationApiUrl } from "../../../environment/routeSettings";
import { RequestErrorHandling, TesDelete, TesGet, TesPut } from "../../../utils/rest";
import { ResponseCode } from "../../../types/general/enums/generalEnums";
import { SimpleItem } from "devextreme-react/form";
import { custom } from "devextreme/ui/dialog";

const Keys = () => {

    const dataGridRef = useRef<DataGrid<any, any>>(null);
    const [keyList, setKeyList] = useState<GetKetDtos[]>([])
    const [selectedKey, setSelectedKey] = useState<EditKeyDto>(new EditKeyDto)
    const [showModal, setShowModal] = useState<boolean>(false);

    useEffect(() => {
        async function fetchMyAPI() {
          try {
            await getKeys();
            // setLstLanguageType(Enum2Array(LanguageType))
          } catch (ex) {
            notify(t("someErrorOccurred") + ex, "error", 5000);
          }
        }
        fetchMyAPI();
        // eslint-disable-next-line react-hooks/exhaustive-deps
      }, []);
    
      async function getKeys() {
        try {
          const res = await TesGet(
            TranslationApiUrl() + "/api/Keys/GetKeys",
            true
          );
          if (res.responseCode === ResponseCode.OK) {
            setKeyList(res.results);
          } else {
            await RequestErrorHandling(res);;
          }
        } catch (error) {
          console.error("Error fetching initial data:", error);
        }
      }

      function onEdit(e: any) {
        setShowModal(true)
        setSelectedKey({...selectedKey, keyId:e.row.data.keyId, newKey: e.row.data.key})
      }

      async function onDelete(d: any) {
        let myDialog = custom({
          title: t("warning"),
          messageHtml: t("deleteWarningMessage"),
          buttons: [
            {
              text: t("yes"),
              onClick: async (e) => {
                try {
                  await TesDelete(TranslationApiUrl() + "/api/Keys/RemoveKey/" + `${d.row.data.keyId}`, true);
                  await getKeys();
                  dataGridRef.current?.instance.refresh()
                  notify(t("dataSuccessfullyDeleted"), "success", 5000);
                } catch {
                  notify(t("someErrorOccurred"), "error", 5000);
                }
                return { buttonText: e.component.option("text") };
              },
            },
            {
              text: t("no"),
              onClick: (e) => {
                return { buttonText: e.component.option("text") };
              },
            },
          ],
        });
        myDialog.show();
      }

      function onCloseModal() {
        setShowModal(false)
        setSelectedKey(new EditKeyDto);
      }

      async function onPopupUpdate() {
        try {
          const res = (await (TesPut(TranslationApiUrl() + "/api/Keys/EditKey", selectedKey, true)))
          if (res.responseCode === ResponseCode.OK) {
            notify(t("dataSuccessfullyUpdated"), "success", 5000);
            onCloseModal()
            getKeys()
            dataGridRef.current?.instance.refresh()
          } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
            notify(t("customerNotPermitted"), "error", 5000);
          }
          await RequestErrorHandling(res);;
        } catch (ex) {
          notify(t("someErrorOccurred" + ex), "error", 5000);
        }
      }

    return (
            <Permission
            allowed={[TMSPermissions.TMS_Admin]}
              hasFeedBackElement={true}
            >
              <React.Fragment>
              <h2 className={"content-block"}>{t("Keys")}</h2>
                <div className={"content-block"}>
                  <div className={"dx-card responsive-paddings"}>
                    <DataGrid
                      id="gridContainer"
                      ref={dataGridRef}
                      dataSource={keyList}
                      rowAlternationEnabled={true}
                      showBorders={true}
                      hoverStateEnabled={true}
                      remoteOperations={true}
                      allowColumnReordering={true}
                      allowColumnResizing={true}
                      columnAutoWidth={true}
                      onExporting={OnExporting}
                    >
                      <Export enabled={true} allowExportSelectedData={true} />
                      <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                      <GroupPanel visible={true} />
                      <FilterPanel visible={true} />
                      <FilterBuilderPopup position={"top"} />
                      <Paging enabled={true} defaultPageSize={100} />
                      <Pager
                        showPageSizeSelector={true}
                        allowedPageSizes={[100, 200, 300, 400, 500]}
                        showNavigationButtons={true}
                        showInfo={true}
                        visible={true}
                      />
                      <FilterRow visible={true} applyFilter="auto" />
                      <HeaderFilter visible={true} />
                      <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                      <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                        <Search enabled />
                      </ColumnChooser>
                      <Column
                        dataField="keyId"
                        allowSorting={false}
                        caption={t("KeyId")}
                        visible={false}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      <Column
                        dataField="key"
                        allowSorting={false}
                        caption={t("key")}
                      >
                        <HeaderFilter>
                          <Search enabled />
                        </HeaderFilter>
                      </Column>
                      <Column
                      type="buttons"
                      caption={t("actions")}
                      width={90}
                      fixed={true}
                      fixedPosition="right"
                      >
                        <DevExpressButton
                          hint={t("edit")}
                          onClick={e => onEdit(e)}
                          icon="fa-solid fa-pencil"
                        />
                        <DevExpressButton
                          hint={t("edit")}
                          onClick={e => onDelete(e)}
                          icon="fa-solid fa-trash"
                        />
                      </Column>
                    </DataGrid>
                  </div>
                </div>

                <Popup
                width={"60%"}
                height="auto"
                visible={showModal}
                resizeEnabled={true}
                showTitle={true}
                title={selectedKey.newKey}
                hideOnOutsideClick={false}
                showCloseButton={true}
                onHiding={() => onCloseModal()}
            >
                <div>
                    <Form colCount={2}>
                      <SimpleItem colSpan={1}>
                        <TextBox
                          placeholder=""
                          label={t('newKey')}
                          labelMode='static'
                          value={selectedKey.newKey}
                          onValueChange={(e) => setSelectedKey({...selectedKey, newKey:e})}
                          showClearButton={true}
                        />
                      </SimpleItem>
                    </Form>
                </div>
                <div className='rightColumn' style={{margin:"2rem -0.5rem 0 0"}}>
                    <Button
                        className='tes-modal-btn-cancel'
                        style={{ marginRight: 20 }}
                        onClick={() => onCloseModal()}
                        text={t('cancel')}
                    />
                    <Button
                      className='tes-modal-btn-add'
                      style={{ marginRight: 20 }}
                      onClick={() => onPopupUpdate()}
                      text={t('update')}
                    />
                </div>
            </Popup>
              </React.Fragment>
            </Permission>
          )
}

export default Keys;