import React from "react";
import { CustomerCollision } from "../../../../../types/collision/collisionTypes";
import { Form } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import { ValidationGroup } from "devextreme-react/validation-group";
import { VMMapLocation } from "../../../../../types/collision/dto/collisionDtos";
import InfrastructureLocationPicker from "../../../../../components/infrastructureLocationPicker/infrastructureLocationPicker";
import { TesMapModule } from "../../../../../types/general/enums/generalEnums";
import { LocationPickerMode } from "../../../../../types/infrastructure/enums/infrastructureEnums";
import { VMLocation } from "../../../../../types/infrastructure/dto/locationdDto";

// props
interface IPros {
  didMount: boolean;
  initDataCollision: CustomerCollision;
  tempMapLocation: VMMapLocation;
  validationRef: React.RefObject<ValidationGroup>;
  mapLocation: VMMapLocation;
  setInitDataCollision: React.Dispatch<React.SetStateAction<any>>;
  setMapLocation: React.Dispatch<React.SetStateAction<any>>;
  setTempMapLocation: React.Dispatch<React.SetStateAction<VMMapLocation>>;
  isLocked: boolean;
}

const Location = (props: IPros) => {
  //#region consts



  function handleLocationChange(loc: VMMapLocation) {
    props.setMapLocation(loc)
  }


  function setInfrastructureData(
    inData: VMLocation) {
    props.setInitDataCollision({
      ...props.initDataCollision,
      locationId: inData.id,
      locationDescription: inData.description,
      locationType: inData.locationType,
      geoId: inData.geoId,
      jurisdictionId: inData.jurisdictionId,
      municipalityId: inData.municipalityId,
      divisionId: inData.divisionId
    });
  }


  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 10, padding: 10 }}>
        <ValidationGroup ref={props.validationRef}>
          <Form colCount={2}>
            <SimpleItem colSpan={2}>
              <InfrastructureLocationPicker
                renderMap={true}
                didMount={props.didMount}
                setInfrastructureData={setInfrastructureData}
                initLocationData={{
                  customerId: props.initDataCollision.customerId,
                  id: props.initDataCollision.locationId,
                }}
                tesModule={TesMapModule.CollisionDetails}
                handleLocationChange={handleLocationChange}
                tempMapLocation={props.tempMapLocation}
                componentMode={LocationPickerMode.TextBox}
                selectedMapLocation={props.mapLocation}
                renderCoordinate={true}
                isLocked={props.isLocked}
                showIcons={true}
              />
            </SimpleItem>
          </Form>
        </ValidationGroup>
      </div>
    </React.Fragment>
  );
};
export default Location;
