//imports
import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Selection,
  Pager,
  Paging,
  TotalItem,
  Summary,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  GroupItem,
  Button as DevExpressButton,
  Export,
  Search,
} from "devextreme-react/data-grid";
import { Popup } from "devextreme-react/popup";
import { Button } from "devextreme-react/button";
import { TesPut, TesGet, TesPost, RequestErrorHandling } from "../../../utils/rest";
import "./patrolArea.scss";
import { useTranslation } from "react-i18next";
import notify from "devextreme/ui/notify";
import { custom } from "devextreme/ui/dialog";
import { useAuth } from "../../../contexts/auth";
import CustomStore from "devextreme/data/custom_store";
import { Location } from "../../../types/infrastructure/infrastructureTypes";
import CollapsibleCard from "../../../components/collapsibleCard/collapsibleCard";
import { VMWebSign } from "../../../types/sign/dto/signDto";
import { VMWebSupport } from "../../../types/sign/dto/supportDto";
import { useClientSetting } from "../../../contexts/clientSetting";
import Permission from "../../../components/permission/permision";
import { SignPermissions } from "../../../constants/Permissions";
import { InfrastructureApiUrl, SignApiUrl } from "../../../environment/routeSettings";
import GeneralGisMapManager from "../../../components/gisMap/generalGisMapManager";
import { ResponseCode, TesMapModule } from "../../../types/general/enums/generalEnums";
import { VMLocation } from "../../../types/infrastructure/dto/locationdDto";
import { SignSupportList } from "../../../types/sign/supportTypes";
import { OnExporting } from "../../../utils/dataGridTools";
import { ContextMenu, Lookup } from "devextreme-react";
import { RightClickMenu } from "../../../utils/rightClickMenu";
import { RequestResponseResult } from "../../../types/general/generalTypes";
import { SortObjectByPropName } from "../../../utils/arrayTools";

// props
interface IProps {
  areaId: string;
  updateIntersection: (lstIntersections: Location[] | undefined) => void;
  updateRoadSegment: (lstRoadSegments: Location[] | undefined) => void;
  intersections: Location[];
  roadSegments: Location[];
}
const PatrolArea = (props: IProps) => {
  const dataGridSignRef = useRef<DataGrid<any, any>>(null);
  const dataGridSupportRef = useRef<DataGrid<any, any>>(null);
  const dataGridAddSignRef = useRef<DataGrid<any, any>>(null);
  const dataGridAddSupportRef = useRef<DataGrid<any, any>>(null);
  const [showModalSign, setShowModalSign] = useState(false);
  const [showModalSupport, setShowModalSupport] = useState(false);
  const [showAddFromMap, setShowAddFromMap] = useState(false);
  const [initDataSign, setInitDataSign] = useState<VMWebSign[]>([new VMWebSign()]);
  const [initDataSupport, setinitDataSupport] = useState<VMWebSupport[]>([new VMWebSupport()]);
  const { activeLoading } = useAuth();
  const { generalSetting } = useClientSetting();
  const { t } = useTranslation();
  const [selectedRowData, setSelectedRowData] = useState<any>(null);
  const items = [
    // { text: t('openInNewTab'), icon:  "fa-solid fa-up-right-from-square"},
    { text: t('openLocation'), icon: "fa-solid fa-location-dot" }
  ];
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  const { customerDivisions } = useClientSetting();

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitDataPatrolArea();
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //GET InitData
  async function getInitDataPatrolArea() {
    const res = await TesGet(
      SignApiUrl() +
      "/api/Patrols/GetUserPatrols/" +
      localStorage.getItem("selectedCustomerId"),
      true
    );
    console.log(JSON.stringify(res))
    setInitDataSign(res.signs);
    setinitDataSupport(res.supports);
  }

  const dataSourceSupport = new CustomStore({
    load: async (loadOption) => {
      return await TesPut(
        `${SignApiUrl()}/api/Supports/LazyLoading/${localStorage.getItem(
          "selectedCustomerId"
        )}`,
        JSON.stringify(loadOption),
        true
      );
    },
  });

  const dataSourceSign = new CustomStore({
    load: async (loadOption) => {
      return await TesPut(
        `${SignApiUrl()}/api/TesSigns/LazyLoading/${localStorage.getItem(
          "selectedCustomerId"
        )}/false`,
        JSON.stringify(loadOption),
        true
      );
    },
  });

  function onCloseModalSign() {
    setShowModalSign(false);
  }

  function onSaveChangesSign() {


    var selectedSigns = dataGridAddSignRef.current?.instance
      .getSelectedRowsData()
      .filter(
        (x: any) => !initDataSign?.map((a) => a.id).includes(x.id)
      ) as VMWebSign[];

    setInitDataSign([...initDataSign, ...selectedSigns]);
    onCloseModalSign();
    dataGridAddSignRef.current?.instance.deselectAll();
  }
  function onSaveChangesSupport() {
    var selectedSupports = dataGridAddSupportRef.current?.instance
      .getSelectedRowsData()
      .filter(
        (x: any) => !initDataSupport?.map((a) => a.id).includes(x.id)
      ) as VMWebSupport[];
    setinitDataSupport([...initDataSupport, ...selectedSupports]);
    onCloseModalSupport();
    dataGridAddSupportRef.current?.instance.deselectAll();
  }
  function onCloseModalSupport() {
    setShowModalSupport(false);
  }

  //update function
  async function updatePatrolArea() {
    if (activeLoading) activeLoading(true);
    try {
      const postObj = {
        signIds: initDataSign?.map((x: VMWebSign) => x.id),
        supportIds: initDataSupport?.map((x: VMWebSupport) => x.id),
        customerId: localStorage.getItem("selectedCustomerId"),
      };
      await TesPost(
        SignApiUrl() + "/api/Patrols/UpdatePatrol",
        postObj,
        true
      );
      notify(t("dataSuccessfullyUpdated"), "success", 5000);
      if (activeLoading) activeLoading(false);
    } catch {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred"), "error", 5000);
    }
  }

  //delete functions
  async function onDeleteSign(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              setInitDataSign(initDataSign.filter((x) => x.id !== d.row.data.id))
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  async function onDeleteSupport(d: any) {
    let myDialog = custom({
      title: t("deleteData"),
      messageHtml: t("areYouSureWantToDelete?"),
      buttons: [
        {
          text: t("yes"),
          onClick: async (e) => {
            try {
              setinitDataSupport(initDataSupport.filter((x) => x.id !== d.row.data.id))
              notify(t("dataSuccessfullyDeleted"), "success", 5000);
            } catch {
              notify(t("someErrorOccurred"), "error", 5000);
            }

            return { buttonText: e.component.option("text") };
          },
        },
        {
          text: t("no"),
          onClick: (e) => {
            return { buttonText: e.component.option("text") };
          },
        },
      ],
    });
    myDialog.show();
  }

  async function addLocationFromMap(lstData: VMLocation[]) {
    var res = await TesPut(`${InfrastructureApiUrl()}/api/Locations/GetLocationsByGeoIds/
    ${localStorage.getItem("selectedCustomerId")}`,
      lstData, true) as RequestResponseResult<VMLocation[]>;
    if (res.responseCode === ResponseCode.OK) {
      var supportsOfLocations = await TesPost(`${SignApiUrl()}/api/Supports/GetSupportsofLocations`, res.results.map(x => x.id), true) as SignSupportList;
      setInitDataSign([...initDataSign, ...supportsOfLocations.signs]);
      setinitDataSupport([...initDataSupport, ...supportsOfLocations.supports]);
    } else {
      await RequestErrorHandling(res);
    }
  }

  return (
    <Permission
      allowed={[
        SignPermissions.Sign_D,
        SignPermissions.Sign_R,
        SignPermissions.Sign_V,
        SignPermissions.Sign_E,
      ]}
      hasFeedBackElement={true}
    >
      <div className={`patrolArea ${compactViewModel ? "compactStyle" : ""}`}>

        <React.Fragment>
          <ContextMenu
            dataSource={items}
            width={100}
            target=".dx-data-row"
            onItemClick={e => RightClickMenu(e, selectedRowData.row.key.id, "", selectedRowData.row.data.locationType, selectedRowData.row.data.locationId)}
          />
          <h2 className={"content-block"}>{t("patrolArea")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>
              <div className="row" style={{ marginTop: "-2rem" }} id="buttonGroup">
                <div className="rightColumn">
                  <Button
                    style={{ margin: 5 }}
                    onClick={() => { setinitDataSupport([]); setInitDataSign([]) }}
                    icon="fa-solid fa-circle-minus"
                    text={t("clear")}
                  />
                  <Button
                    style={{ margin: 5 }}
                    onClick={() => setShowModalSign(true)}
                    icon="fa-solid fa-circle-plus"
                    text={t("sign")}
                  />
                  <Button
                    style={{ marginRight: 5 }}
                    onClick={() => setShowModalSupport(true)}
                    icon="fa-solid fa-circle-plus"
                    text={t("support")}
                  />
                  <Button
                    onClick={() => setShowAddFromMap(true)}
                    icon="fa-solid fa-circle-plus"
                    text={t("fromMap")}
                  />
                  <Button
                    style={{ marginLeft: "0.5rem" }}
                    onClick={updatePatrolArea}
                    icon="fa-solid fa-floppy-disk"
                    hint={t("save")}
                  />
                </div>
                <div className="leftColumn">
                  <Button icon="fa-solid fa-map-location-dot" hint={t("gisMap")} />
                </div>
              </div>
              {/* sign dataGrid */}
              <CollapsibleCard title={t("signs")}>

                <DataGrid
                  id="gridContainer"
                  ref={dataGridSignRef}
                  dataSource={initDataSign}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onExporting={OnExporting}
                  onContextMenuPreparing={e => { setSelectedRowData(e) }}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <Summary>
                    <TotalItem column="GeoId" summaryType="count" />
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <Column dataField="geoId" caption={t("geoId")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column dataField="signLocationTypeTitle" caption={t("locationType")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column alignment="left" dataField="signId" caption={t("signId")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="supportTitle"
                    caption={t("supportId")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column alignment="left" dataField="signCodeTitle" caption={t("code")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="signDimensionTitle"
                    caption={t("dimension")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column alignment="left" dataField="height" caption={t("height")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="signFacingDirectionTitle"
                    caption={t("facingDirection")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="signFaceMaterialTitle"
                    caption={t("faceMaterial")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="signReflectiveCoatingTitle"
                    caption={t("reflectiveCoating")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="signReflectiveRatingTitle"
                    caption={t("reflectiveRating")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="signConditionTitle"
                    caption={t("condition")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="dateInstalled"
                    dataType="date"
                    format={generalSetting?.dateFormat}
                    caption={t("installationDate")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="dateExpired"
                    dataType="date"
                    format={generalSetting?.dateFormat}
                    caption={t("expirationDate")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="materialCost"
                    caption={t("materialCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="labourCost"
                    caption={t("labourCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="installationCost"
                    caption={t("installationCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="latitude"
                    dataType="number"
                    caption={t("latitude")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="longitude"
                    dataType="number"
                    caption={t("longitude")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="xCoord"
                    dataType="number"
                    caption={t("xCoord")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="yCoord"
                    dataType="number"
                    caption={t("yCoord")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                    <DevExpressButton
                      hint={t("delete")}
                      onClick={onDeleteSign}
                      icon="fa-solid fa-trash-can"
                    ></DevExpressButton>
                  </Column>
                </DataGrid>


              </CollapsibleCard>
              {/* support dataGrid */}
              <CollapsibleCard title={t("supports")}>
                <DataGrid
                  id="gridContainer"
                  ref={dataGridSupportRef}
                  dataSource={initDataSupport}
                  rowAlternationEnabled={true}
                  showBorders={true}
                  hoverStateEnabled={true}
                  remoteOperations={true}
                  allowColumnReordering={true}
                  allowColumnResizing={true}
                  columnAutoWidth={true}
                  onExporting={OnExporting}
                  onContextMenuPreparing={e => { setSelectedRowData(e) }}
                >
                  <Export enabled={true} allowExportSelectedData={true} />
                  <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                  <GroupPanel visible={true} /> {/* or "auto" */}
                  <FilterPanel visible={true} />
                  <FilterBuilderPopup position={"top"} />
                  <Summary>
                    <TotalItem column="GeoId" summaryType="count" />
                    <GroupItem
                      summaryType="count"
                      alignByColumn
                      name="Total Count"
                    />
                  </Summary>
                  <Paging enabled={true} defaultPageSize={100} />
                  <Pager
                    showPageSizeSelector={true}
                    allowedPageSizes={[100, 200, 300, 400, 500]}
                    showNavigationButtons={true}
                    showInfo={true}
                    visible={true}
                  />
                  <FilterRow visible={true} applyFilter="auto" />
                  <HeaderFilter visible={true} />
                  <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                  <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                    <Search enabled />
                  </ColumnChooser>
                  <Column dataField="geoId" caption={t("geoId")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    dataField="supportLocationTypeTitle"
                    caption={t("locationType")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="xCoord"
                    caption={t("xCoord")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="yCoord"
                    caption={t("yCoord")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="supportId"
                    caption={t("supportId")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="supportCodeTitle"
                    caption={t("code")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="supportDescriptionTitle"
                    caption={t("description")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="supportMaterialTitle"
                    caption={t("material")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="supportTypeTitle"
                    caption={t("type")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column alignment="left" dataField="height" caption={t("height")}>
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="supportPositionTitle"
                    caption={t("position")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="supportConditionTitle"
                    caption={t("condition")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="dateInstalled"
                    dataType="date"
                    format={generalSetting?.dateFormat}
                    caption={t("installationDate")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="dateExpired"
                    dataType="date"
                    format={generalSetting?.dateFormat}
                    caption={t("expirationDate")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="materialCost"
                    caption={t("materialCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="labourCost"
                    caption={t("labourCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column
                    alignment="left"
                    dataField="installationCost"
                    caption={("installationCost")}
                  >
                    <HeaderFilter>
                      <Search enabled />
                    </HeaderFilter>
                  </Column>
                  <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                    <DevExpressButton
                      hint={t("delete")}
                      onClick={onDeleteSupport}
                      icon="fa-solid fa-trash-can"
                    ></DevExpressButton>
                  </Column>
                </DataGrid>
              </CollapsibleCard>
            </div>
          </div>
          {/* sign modal */}
          <Popup
            width={"65%"}
            visible={showModalSign}
            resizeEnabled={true}
            showTitle={true}
            title={t("addSign")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModalSign()}
          >
            <div style={{ height: "90%" }}>
              <DataGrid
                id="gridContainer"
                ref={dataGridAddSignRef}
                dataSource={dataSourceSign}
                rowAlternationEnabled={true}
                showBorders={true}
                style={{ height: "100%" }}
                keyExpr="id"
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={OnExporting}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridAddSignRef?.current?.instance.deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="geoId" caption={t("geoId")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column dataField="signLocationTypeTitle" caption={t("locationType")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column alignment="left" dataField="signId" caption={t("signId")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="supportTitle"
                  caption={t("supportId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column alignment="left" dataField="signCodeTitle" caption={t("code")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signDimensionTitle"
                  visible={false}
                  caption={t("dimension")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="height"
                  visible={false}
                  caption={t("height")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signFacingDirectionTitle"
                  visible={false}
                  caption={t("facingDirection")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signFaceMaterialTitle"
                  visible={false}
                  caption={t("faceMaterial")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signReflectiveCoatingTitle"
                  visible={false}
                  caption={t("reflectiveCoating")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signReflectiveRatingTitle"
                  visible={false}
                  caption={t("reflectiveRating")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="signConditionTitle"
                  visible={false}
                  caption={t("condition")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="dateInstalled"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  visible={false}
                  caption={t("installationDate")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="dateExpired"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  visible={false}
                  caption={t("expirationDate")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="materialCost"
                  visible={false}
                  caption={t("materialCost")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="labourCost"
                  visible={false}
                  caption={t("labourCost")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="installationCost"
                  visible={false}
                  caption={t("installationCost")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="latitude"
                  dataType="number"
                  visible={false}
                  caption={t("latitude")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="longitude"
                  dataType="number"
                  visible={false}
                  caption={t("longitude")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="xCoord"
                  dataType="number"
                  visible={false}
                  caption={t("xCoord")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="yCoord"
                  dataType="number"
                  visible={false}
                  caption={t("yCoord")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
              </DataGrid>
            </div>

            <div style={{ marginTop: 20 }}>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModalSign()}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={onSaveChangesSign}
                  text={t("add")}
                />
              </div>
            </div>
          </Popup>
          {/* support Modal */}
          <Popup
            width={"65%"}
            visible={showModalSupport}
            resizeEnabled={true}
            showTitle={true}
            title={t("add")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => onCloseModalSupport()}
          >
            <div style={{ height: "90%" }}>
              <DataGrid
                id="gridContainer"
                ref={dataGridAddSupportRef}
                dataSource={dataSourceSupport}
                rowAlternationEnabled={true}
                showBorders={true}
                style={{ height: "100%" }}
                keyExpr="id"
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={OnExporting}
              //onContentReady={e => {e.component.deselectAll()}}
              //onFilterValueChange={e => {dataGridAddSupportRef?.current?.instance.deselectAll()}}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />
                <Selection
                  mode="multiple"
                  selectAllMode={"allPages"}
                  showCheckBoxesMode={"always"}
                />
                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>
                <Column dataField="geoId" caption={t("geoId")}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="supportLocationTypeTitle"
                  caption={t("locationType")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="xCoord"
                  caption={t("xCoord")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="yCoord"
                  caption={t("yCoord")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="supportId"
                  caption={t("supportId")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="supportCodeTitle"
                  caption={t("code")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="supportDescriptionTitle"
                  visible={false}
                  caption={t("description")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="supportMaterialTitle"
                  visible={false}
                  caption={t("material")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="supportTypeTitle"
                  visible={false}
                  caption={t("type")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="height"
                  visible={false}
                  caption={t("height")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="supportPositionTitle"
                  visible={false}
                  caption={t("position")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="supportConditionTitle"
                  visible={false}
                  caption={t("condition")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="dateInstalled"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  visible={false}
                  caption={t("installationDate")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="dateExpired"
                  dataType="date"
                  format={generalSetting?.dateFormat}
                  visible={false}
                  caption={t("expirationDate")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="materialCost"
                  visible={false}
                  caption={t("materialCost")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="labourCost"
                  visible={false}
                  caption={t("labourCost")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  alignment="left"
                  dataField="installationCost"
                  visible={false}
                  caption={t("installationCost")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="DivisionId"
                  allowSorting={true}
                  visible={false}
                  caption={t("division")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                  <Lookup
                    dataSource={SortObjectByPropName(customerDivisions!, "name")}
                    valueExpr="id"
                    displayExpr="name"
                  />
                </Column>
              </DataGrid>
            </div>


            <div style={{ marginTop: 20 }}>
              <div className="rightColumn">
                <Button
                  className="tes-modal-btn-cancel"
                  style={{ marginRight: 20 }}
                  onClick={() => onCloseModalSupport()}
                  text={t("cancel")}
                />
                <Button
                  className="tes-modal-btn-add"
                  onClick={onSaveChangesSupport}
                  text={t("add")}
                />
              </div>
            </div>
          </Popup>


          {/*Add From Map Popup */}
          <Popup
            width={"50%"}
            height={"auto"}
            // maxHeight={"90%"}
            visible={showAddFromMap}
            resizeEnabled={true}
            showTitle={true}
            title={t("map")}
            hideOnOutsideClick={false}
            showCloseButton={true}
            onHiding={() => setShowAddFromMap(false)}
          >
            <GeneralGisMapManager
              tesModule={TesMapModule.PatrolArea}
              showGis={showAddFromMap}
              setShowMap={setShowAddFromMap}
              locId="LocationId"
              addLocationFromMap={addLocationFromMap}
            />
          </Popup>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default PatrolArea;
