import { Button, Form, TextBox } from "devextreme-react";
import { SimpleItem } from "devextreme-react/form";
import notify from "devextreme/ui/notify";
import { t } from "i18next";
import React from "react";
import { useAuth } from "../../../../contexts/auth";
import { AuthApiUrl } from "../../../../environment/routeSettings";
import { ResponseCode } from "../../../../types/general/enums/generalEnums";
import { RequestResponseResult } from "../../../../types/general/generalTypes";
import { VMUserProfileDetails } from "../../../../types/identity/dto/identityDTO";
import { RequestErrorHandling, TesPost } from "../../../../utils/rest";
import { GroupItem as GroupItemForm } from 'devextreme-react/form';


// props
interface IPros {
  initData: VMUserProfileDetails,
  setInitData: React.Dispatch<React.SetStateAction<any>>;
}

const MyProfile = (props: IPros) => {

  const { activeLoading } = useAuth();

  function onValueChanged(name: string, value: any) {
    props.setInitData({ ...props.initData, [name]: value })
  }

  async function onSave() {
    try {
      if (activeLoading) activeLoading(true);
      const res = (await (TesPost(AuthApiUrl() + "/api/User/UpdateUserGeneralInformation", props.initData, true))) as RequestResponseResult<null>
      if (res.responseCode === ResponseCode.OK) {
        if (activeLoading) activeLoading(false);
        notify(t("dataSuccessfullyUpdated"), "success", 5000);
      } else if (res.responseCode === ResponseCode.CustomerNotPermitted) {
        if (activeLoading) activeLoading(false);
        notify(t("customerNotPermitted"), "error", 5000);
      }
      if (activeLoading) activeLoading(false);
      await RequestErrorHandling(res);;
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred" + ex), "error", 5000);
    }
  }

  return (
    <React.Fragment>
      <div className="row" style={{ marginTop: 20, padding: 10 }}>
        <Form colCount={1}>
          <GroupItemForm caption={t("myProfile")}
            name={t("myProfile")}
            visible={true}
            colSpan={1}
          >
            <Form colCount={3}>
              <SimpleItem colSpan={1} >
                <TextBox
                  label={t('email')}
                  labelMode='static'
                  value={props.initData?.email!}
                  onValueChange={e => onValueChanged("email", e)}
                  className="modalInput"
                />
              </SimpleItem>
              <SimpleItem colSpan={1} >
                <TextBox
                  label={t('firstName')}
                  labelMode='static'
                  value={props.initData?.firstName!}
                  onValueChange={e => onValueChanged("firstName", e)}
                  className="modalInput"
                />
              </SimpleItem>
              <SimpleItem colSpan={1} >
                <TextBox
                  label={t('lastName')}
                  labelMode='static'
                  value={props.initData?.lastName!}
                  onValueChange={e => onValueChanged("lastName", e)}
                  className="modalInput"
                />
              </SimpleItem>
            </Form>
          </GroupItemForm>
        </Form>
        <Button
          className='tes-modal-btn-add rightColumn'
          onClick={onSave}
          text={t('save')}
        />
      </div>
    </React.Fragment>
  );
};
export default MyProfile;