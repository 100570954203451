//imports
import React, { useState, useEffect, useCallback } from 'react';
import design from './heatMap.module.scss';
import CollapsibleCard from "../../../../../components/collapsibleCard/collapsibleCard";
import CheckBox from 'devextreme-react/check-box';
import notify from 'devextreme/ui/notify';
import { RequestErrorHandling, TesGet, TesPost } from '../../../../../utils/rest';
import { IDashboardResult } from '../../../../../types/collision/collisionTypes';
import { INameId, RequestResponseResult } from '../../../../../types/general/generalTypes';
import DateBox from 'devextreme-react/date-box';
import TagBox from 'devextreme-react/tag-box';
import { GeneralSetting } from '../../../../../types/setting/gridSettingTypes';
import { CollisionApiUrl, InfrastructureApiUrl } from '../../../../../environment/routeSettings';
import { DashboardReportType } from '../../../../../types/collision/enums/collisionEnums';
import { HeatMapSetting } from '../../../../../types/arcGis/arcGisTypes';
import { IInfrastructureSetting, TesCodeValue } from '../../../../../types/infrastructure/infrastructureTypes';
import { useAuth } from '../../../../../contexts/auth';
import { useTranslation } from 'react-i18next';
import CollisionDashboardHeatMap from '../../../../../components/collisionDashboardHeatMap/collisionDashboardHeatMap';
import { Form, NumberBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { SimpleItem } from 'devextreme-react/form';
import { useScreenSize } from '../../../../../utils/media-query';
import { CreateDateAsUTC } from '../../../../../utils/dateTimeTools';
import { GetCollisionDatePeriodRequestDTO } from '../../../../../types/collision/dto/collisionDtos';
import { SortObjectByPropName } from '../../../../../utils/arrayTools';
import { ResponseCode } from '../../../../../types/general/enums/generalEnums';
import { IsNullOrEmpty } from '../../../../../utils/stringTools';
const FiveYearIcon = "/images/5YIcon.svg";
const OneYearIcon = "/images/1YIcon.svg";

//props
interface IPros {
  initDataSiteList: INameId[] | undefined;
  initDataTesCodeValues: TesCodeValue;
  generalSettings: GeneralSetting | null;
  datePeriod: GetCollisionDatePeriodRequestDTO | undefined
}

const HeatMap = (props: IPros) => {
  //states
  const [severity, setSeverity] = useState<number[]>([1, 2, 3]);
  const [type, setType] = useState<number[]>([1, 2, 3, 4]);
  const [initData, setInitData] = useState<IDashboardResult | undefined>(undefined);
  const [reportDate, setReportDate] = useState({
    beginDateFrom: new Date(new Date(props.datePeriod?.endDT!).getFullYear() - 4, 0, 1),
    beginDateTo: new Date(new Date(props.datePeriod?.endDT!).getFullYear(), 11, 31)
  });
  const [locationIds, setLocationIds] = useState<string[]>([]);
  const [municipalityIds, setMunicipalityIds] = useState<string[]>([]);
  const [jurisdictionIds, setJurisdictionIds] = useState<string[]>([]);
  const [heatMapSetting, setHeatMapSetting] = useState<HeatMapSetting>(new HeatMapSetting());
  const [tempHeatMapSetting, setTempHeatMapSetting] = useState<HeatMapSetting>(new HeatMapSetting());
  const [initDataGis, setInitDataGis] = useState<IInfrastructureSetting | null>(null);
  const { activeLoading } = useAuth();
  const { t } = useTranslation();
  const { isXLarge } = useScreenSize();

  //#region functions
  const applyFilter = useCallback(async () => {
    try {
      if (activeLoading) activeLoading(true);
      const postObj = {
        customerId: localStorage.getItem('selectedCustomerId'),
        divisionId: localStorage.getItem("selectedDivisionId")!,
        reportType: DashboardReportType.HeatMap,
        severityList: severity,
        peopleInvolvements: type,
        beginDateFrom: CreateDateAsUTC(reportDate.beginDateFrom),
        beginDateTo: CreateDateAsUTC(reportDate.beginDateTo),
        locationIds: locationIds,
        jurisdictionIds: jurisdictionIds,
        municipalityIds: municipalityIds
      }
      setInitData(await (TesPost(CollisionApiUrl() + "/api/Reports/GetMainDashboard", postObj, true)))
      if (activeLoading) activeLoading(false);
    } catch (ex) {
      if (activeLoading) activeLoading(false);
      notify(t("someErrorOccurred") + ex, "error", 5000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [severity, reportDate, locationIds, type, municipalityIds, jurisdictionIds]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        await applyFilter();
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applyFilter]);

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        setTempHeatMapSetting({ maxPixelIntensity: 400, minPixelIntensity: 100 });
        setHeatMapSetting({ maxPixelIntensity: 400, minPixelIntensity: 100 });
        await getInitDataGisMap();
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }
    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitDataGisMap() {
    const res = await TesGet(InfrastructureApiUrl() + "/api/Settings/GetInfrastructureSettings/" + localStorage.getItem('selectedCustomerId'), true) as RequestResponseResult<IInfrastructureSetting>
    if (res.responseCode === ResponseCode.OK) {
      setInitDataGis(res.results);
    } else {
      await RequestErrorHandling(res);
    }
  }

  const onChangeSeverity = (value: number) => {
    var index = severity.indexOf(value)
    if (index !== -1) {
      var lstNewSeverity = severity.filter(x => x !== value)
      if (lstNewSeverity.length === 0) {
        notify(t('oneItemShouldBeSelected'), 'warning', 5000);
        return;
      }
      setSeverity(lstNewSeverity);
    } else {
      setSeverity([...severity, value]);
    }
  }
  function onChangeTypeAll() {
    if (type.includes(1)) {
      setType(type.filter(x => x !== 1));
    } else {
      setType([1, 2, 3, 4]);
    }
  }
  function onChangeType(value: number) {
    var index = type.indexOf(value)
    if (index !== -1) {
      var lstNewType = type.filter(x => x !== value);
      if (lstNewType.length === 0) {
        notify(t('oneItemShouldBeSelected'), 'warning', 5000);
        return;
      }
      setType(lstNewType);
    } else {
      setType([...type, value]);
    }
  }
  function onChangeDate(name: string, value: Date) {
    if (name === "beginDateFrom") {
      if (reportDate.beginDateTo < value) {
        notify('Select valid date range.', "error", 5000);
        return;
      }
      setReportDate({
        ...reportDate,
        beginDateFrom: value
      })
    }
    if (name === "beginDateTo") {
      if (reportDate.beginDateFrom > value) {
        notify('Select valid date range.', "error", 5000);
        return;
      }
      setReportDate({
        ...reportDate,
        beginDateTo: value
      })

    }
  }

  async function onAreaValueChanged(e: any) {
    const areas = e.value;
    const locations = await (TesPost(InfrastructureApiUrl() + "/api/areas/GetGeoIds", areas, true)) as RequestResponseResult<INameId[]>
    if (locations.responseCode === ResponseCode.OK) {
      if (locations.results !== null) {
        setLocationIds(locations.results.map(a => a.id))
      }
    } else {
      await RequestErrorHandling(locations);
    }
  }

  async function onTesCodeValueChange(e: any) {
    const codes = e.value;
    setJurisdictionIds(codes);
  }
  async function onMunicipalityChange(e: any) {
    const munis = e.value;
    setMunicipalityIds(munis);
  }

  function onApply() {
    setHeatMapSetting(tempHeatMapSetting);
  }
  function onHeatMapSettingChange(name: string, value: any) {
    setTempHeatMapSetting({ ...tempHeatMapSetting, [name]: value })
  }
  function getLastNYear(decYear: number) {
    var currentYear = new Date().getFullYear();
    setReportDate({
      beginDateFrom: new Date(currentYear - decYear, 0, 1),
      beginDateTo: new Date(currentYear - 1, 11, 31)
    })
  }

  //#endregion

  return (
    <React.Fragment>
      <div className={design.row}>
        <div className={design.column1}>
          <CollapsibleCard compact='dashboard' title={t("severity")}>
            <div className={design.collapsibleContentHorizontal}>
              <CheckBox text={t("fatal")} value={severity.includes(3)} onValueChanged={() => onChangeSeverity(3)}></CheckBox>
              <CheckBox text={t("injury")} value={severity.includes(2)} onValueChanged={() => onChangeSeverity(2)}></CheckBox>
              <CheckBox text={t("PDO")} value={severity.includes(1)} onValueChanged={() => onChangeSeverity(1)}></CheckBox>
            </div>
          </CollapsibleCard>
          <CollapsibleCard compact='dashboard' title={t("type")}>
            <div className={design.collapsibleContentHorizontal}>
              <CheckBox text={t("all")} value={type.includes(1)} onValueChanged={() => onChangeTypeAll()}></CheckBox>
              <CheckBox text={t("pedestrians")} disabled={type.includes(1) ? true : false} value={type.includes(2)} onValueChanged={() => onChangeType(2)}></CheckBox>
              <CheckBox text={t("cyclists")} disabled={type.includes(1) ? true : false} value={type.includes(3)} onValueChanged={() => onChangeType(3)}></CheckBox>
            </div>
            <div className={design.collapsibleContentHorizontal}>
              <CheckBox text={t("motorcyclists")} disabled={type.includes(1) ? true : false} value={type.includes(4)} onValueChanged={() => onChangeType(4)}></CheckBox>
            </div>
          </CollapsibleCard>
          <CollapsibleCard compact='dashboard' title={t("filter")}>
            <div className={`${design.collapsibleContentVertical}`}>
              <div style={{ display: "flex" }}>
                <span style={{ width: "20%", display: "inline-block" }}>{t("from")}:</span>
                <DateBox
                  // label={t("from")}
                  defaultValue={reportDate.beginDateFrom}
                  value={reportDate.beginDateFrom}
                  type="date"
                  onValueChange={e => onChangeDate('beginDateFrom', e)}
                  pickerType="calendar"
                  placeholder={props.generalSettings?.dateFormat}
                  displayFormat={props.generalSettings?.dateFormat}
                  useMaskBehavior={true}
                  openOnFieldClick={true}
                  showClearButton={true}
                  style={{ height: "2rem", marginBottom: "0.5rem", marginTop: "-0.5rem", width: "80%" }}
                />
              </div>
              <div style={{ display: "flex" }}>
                <span style={{ width: "20%", display: "inline-block" }}>{t("to")}:</span>
                <DateBox
                  // label={t("to")}
                  defaultValue={reportDate.beginDateTo}
                  value={reportDate.beginDateTo}
                  type="date"
                  onValueChange={e => onChangeDate('beginDateTo', e)}
                  pickerType="calendar"
                  placeholder={props.generalSettings?.dateFormat}
                  displayFormat={props.generalSettings?.dateFormat}
                  useMaskBehavior={true}
                  openOnFieldClick={true}
                  showClearButton={true}
                  style={{ height: "2rem", marginBottom: "0.5rem", width: "80%" }}
                />
              </div>
              <div style={{ display: "flex", flexDirection: isXLarge ? "row" : "column", justifyContent: isXLarge ? "space-around" : "center", alignItems: "center" }}>
                <Button
                  icon={OneYearIcon}
                  text={t("lastYear")}
                  className="dateOptions"
                  style={{ marginBottom: "0.5rem", width: isXLarge ? "auto" : "100%" }}
                  onClick={() => {
                    getLastNYear(1)
                  }
                  }
                />
                <Button
                  icon={FiveYearIcon}
                  text={t("lastFiveYears")}
                  className="dateOptions"
                  style={{ marginBottom: "0.5rem", width: isXLarge ? "auto" : "100%" }}
                  onClick={() => {
                    getLastNYear(5)
                  }
                  }
                />
              </div>
            </div>
            <div className={`${design.collapsibleContentVertical}`}>
              <div className={`${design.dashboardHeaderTitle}`} style={{ marginTop: "0", marginBottom: "0" }}>{t("siteList")}</div>
              <TagBox
                dataSource={props.initDataSiteList}
                valueExpr="id"
                displayExpr="name"
                onValueChanged={onAreaValueChanged}
                searchEnabled={true}
                style={{ height: "2.75rem", marginBottom: "-0.5rem" }}
              />
              <div className={`${design.dashboardHeaderTitle}`} style={{ marginTop: "1rem", marginBottom: "0" }}>{t("jurisdiction")}</div>
              {IsNullOrEmpty(localStorage.getItem("selectedDivisionId")) && props.initDataTesCodeValues.jurisdictions?.length !== 0 && props.initDataTesCodeValues.jurisdictions !== undefined &&
                <TagBox
                  dataSource={SortObjectByPropName(props.initDataTesCodeValues.jurisdictions, "name")}
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={onTesCodeValueChange}
                  searchEnabled={true}
                  style={{ height: "2.75rem", marginBottom: "-0.5rem" }}
                />
              }
              <div className={`${design.dashboardHeaderTitle}`} style={{ marginTop: "1rem", marginBottom: "0" }}>{t("municipalities")}</div>
              {IsNullOrEmpty(localStorage.getItem("selectedDivisionId")) && props.initDataTesCodeValues.municipalities?.length !== 0 && props.initDataTesCodeValues.municipalities !== undefined &&
                <TagBox
                  dataSource={SortObjectByPropName(props.initDataTesCodeValues.municipalities, "name")}
                  valueExpr="id"
                  displayExpr="name"
                  onValueChanged={onMunicipalityChange}
                  searchEnabled={true}
                  style={{ height: "2.75rem" }}
                />
              }
            </div>
          </CollapsibleCard>
        </div>

        <div className="column2">

          <CollapsibleCard title={t("HeatMapSettings")}>

            <Form
              colCount={9}>

              <SimpleItem colSpan={4} >
                <NumberBox
                  step={0}
                  label={t('minPixelIntensity')}
                  labelMode='static'
                  value={tempHeatMapSetting.minPixelIntensity}
                  onValueChange={e => onHeatMapSettingChange("minPixelIntensity", e)}
                  className="modalInput"
                >

                </NumberBox>
              </SimpleItem>
              <SimpleItem colSpan={4} >
                <NumberBox
                  step={0}
                  label={t('maxPixelIntensity')}
                  labelMode='static'
                  value={tempHeatMapSetting.maxPixelIntensity}
                  onValueChange={e => onHeatMapSettingChange("maxPixelIntensity", e)}
                  className="modalInput"
                >

                </NumberBox>
              </SimpleItem>
              <SimpleItem colSpan={1} >
                <Button
                  className='tes-modal-btn-add'
                  onClick={onApply}
                  text={t('apply')}
                  style={{ marginTop: "0.5rem" }}
                />
              </SimpleItem>
            </Form>

          </CollapsibleCard>
          {(initData && initData.heatMap) &&
            <CollapsibleCard title={t("heatMap")}>
              <div>
                <CollisionDashboardHeatMap
                  heatMapSetting={heatMapSetting}
                  initDataGis={initDataGis}
                  initData={initData}
                />
              </div>
            </CollapsibleCard>
          }

        </div>
      </div>
    </React.Fragment>
  );
}
export default HeatMap;