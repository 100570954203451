//#region imports
import React, { useEffect, useRef, useState } from "react";
import DataGrid, {
  Column,
  FilterRow,
  HeaderFilter,
  SearchPanel,
  ColumnChooser,
  Pager,
  Paging,
  Summary,
  Grouping,
  GroupPanel,
  FilterBuilderPopup,
  FilterPanel,
  GroupItem,
  Button as DevExpressButton,
  Export,
  Lookup,
  Search
} from "devextreme-react/data-grid";
import { Button } from "devextreme-react/button";
import {
  TesGet,
} from "../../../utils/rest";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../../contexts/auth";
import { ScrollView } from "devextreme-react";
import notify from "devextreme/ui/notify";
import Permission from "../../../components/permission/permision";
import { Popup } from "devextreme-react/popup";
import { TrafficStudyPermissions } from "../../../constants/Permissions";
import "./trafficCounters.scss";
import { ImportRequest, ImportResponse } from "../../../types/trafficStudy/dtos/importDto";
import { NameValue } from "../../../types/general/generalTypes";
import { StudyType } from "../../../types/trafficStudy/enums/trafficStudyEnums";
import { Enum2Array } from "../../../utils/enumTools";
import { ImportStatus } from "../../../types/trafficStudy/enums/importEnums";
import { TrafficApiUrl } from "../../../environment/routeSettings";
import { useScreenSize } from "../../../utils/media-query";
import { OnExporting } from "../../../utils/dataGridTools";
import { SortObjectByPropName } from "../../../utils/arrayTools";
import getTitle from "../../../components/title/getTitle";

//#endregion imports
const ImportLogs = () => {
  //#region consts
  const [initData, setInitData] = useState<ImportRequest[]>([]);
  const [importResponses, setImportResponses] = useState<ImportResponse[]>([]);
  const [showLog, setShowLog] = useState<boolean>(false);
  const [lstStudyTypes, setLstStudyTypes] = useState<NameValue[]>([]);
  const [lstStatuses, setLstStatuses] = useState<NameValue[]>([]);
  const dataGridRef = useRef<DataGrid<any, any>>(null);
  const dataGridViewLogsRef = useRef<DataGrid<any, any>>(null);
  const { t } = useTranslation();
  const { activeLoading } = useAuth();
  const { isXLarge, is2xLarge } = useScreenSize();
  const [gridHeight, setGridHeight] = useState<number>(420)
  const [popupHeight, setPopupHeight] = useState<string>("85%")
  const compactViewModel: boolean = (JSON.parse(localStorage.getItem("GeneralUISetting") || '{"viewMode":"normal"}') || {}).viewMode === "compact";
  //#endregion

  //#region functions
  const title = getTitle('/trafficStudy/import/importLogs', '');
  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    if (isXLarge) { setPopupHeight("auto"); setGridHeight(600) }
    else if (is2xLarge) { setPopupHeight("auto"); setGridHeight(900) }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    async function fetchMyAPI() {
      try {
        if (activeLoading) activeLoading(true);
        await getInitData();
        setLstStudyTypes(Enum2Array(StudyType));
        setLstStatuses(Enum2Array(ImportStatus));
        if (activeLoading) activeLoading(false);
      } catch (ex) {
        if (activeLoading) activeLoading(false);
        notify(t("someErrorOccurred") + ex, "error", 5000);
      }

    }
    fetchMyAPI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getInitData() {
    setInitData(await TesGet(TrafficApiUrl() + "/api/import/getCustomerImportLogs/" + localStorage.getItem("selectedCustomerId"), true));
  }

  function onView(d: any) {
    setImportResponses(d.row.data.importResponses)
    setShowLog(true);
  }

  function onClosePopup() {
    setShowLog(false);
  }

  //#endregion functions


  return (
    <Permission
      allowed={[TrafficStudyPermissions.TrafficStudy_D_Setups]}
      hasFeedBackElement={true}
    >
      <div className={compactViewModel ? "compactStyle" : ""}>
        <React.Fragment>
          <h2 className={"content-block"}>{t("importLogs")}</h2>
          <div className={"content-block"}>
            <div className={"dx-card responsive-paddings"}>

              <DataGrid
                id="gridContainer"
                ref={dataGridRef}
                dataSource={initData}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Summary>
                  <GroupItem summaryType="count" alignByColumn name="Total Count" />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />

                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>

                <Column dataField="id" caption={t("Id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="customerId"
                  caption={t("customerId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="userId"
                  caption={t("userId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="divisionId"
                  caption={t("divisionId")}
                  visible={false}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>
                <Column
                  dataField="checkRequestDT"
                  caption={t("checkRequest")}
                  dataType="datetime"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="checkResponseDT"
                  caption={t("checkResponse")}
                  dataType="datetime"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="startImportDT"
                  caption={t("startImport")}
                  dataType="datetime"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="endImportDT"
                  caption={t("endImport")}
                  dataType="datetime"
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>


                <Column type="buttons" caption={t("actions")} width={110} fixed={true} fixedPosition="right">
                  <DevExpressButton
                    hint={t("viewLogs")}
                    onClick={onView}
                    icon="fa-solid fa-file-circle-exclamation"
                  ></DevExpressButton>

                </Column>
              </DataGrid>

            </div>
            <Popup
              width={"50%"}
              height={popupHeight}
              visible={showLog}
              resizeEnabled={true}
              showTitle={true}
              title={t("viewLogs")}
              hideOnOutsideClick={false}
              showCloseButton={true}
              onHiding={() => setShowLog(false)}
            >
              {/* <ScrollView width="100%" height="100%"> */}
              <DataGrid
                id="gridContainer"
                ref={dataGridViewLogsRef}
                dataSource={importResponses}
                rowAlternationEnabled={true}
                showBorders={true}
                hoverStateEnabled={true}
                remoteOperations={true}
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                height={gridHeight}
                onExporting={OnExporting}
              >
                <Export enabled={true} allowExportSelectedData={true} />
                <Grouping contextMenuEnabled={true} autoExpandAll={false} />
                <GroupPanel visible={true} /> {/* or "auto" */}
                <FilterPanel visible={true} />
                <FilterBuilderPopup position={"top"} />
                <Summary>
                  <GroupItem summaryType="count" alignByColumn name="Total Count" />
                </Summary>
                <Paging enabled={true} defaultPageSize={100} />
                <Pager
                  showPageSizeSelector={true}
                  allowedPageSizes={[100, 200, 300, 400, 500]}
                  showNavigationButtons={true}
                  showInfo={true}
                  visible={true}
                />

                <FilterRow visible={true} applyFilter="auto" />
                <HeaderFilter visible={true} />
                <SearchPanel visible={true} width={285} placeholder={t("search...")} />
                <ColumnChooser width={350} height={400} enabled={true} mode="select" sortOrder="asc">
                  <Search enabled />
                </ColumnChooser>

                <Column dataField="id" caption={t("Id")} visible={false}>
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="fileKey"
                  caption={t("fileKey")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="studyType"
                  caption={t("studyType")}
                >
                  <Lookup dataSource={SortObjectByPropName(lstStudyTypes, "name")} valueExpr="value" displayExpr="name" />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="status"
                  caption={t("status")}
                >
                  <Lookup dataSource={SortObjectByPropName(lstStatuses, "name")} valueExpr="value" displayExpr="name" />
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

                <Column
                  dataField="note"
                  caption={t("note")}
                >
                  <HeaderFilter>
                    <Search enabled />
                  </HeaderFilter>
                </Column>

              </DataGrid>

              <div>
                <div className="rightColumn" style={{ marginTop: "0rem" }}>
                  <Button
                    className="tes-modal-btn-cancel"
                    onClick={onClosePopup}
                    text={t("close")}
                  />
                </div>
              </div>
              {/* </ScrollView> */}
            </Popup>
          </div>
        </React.Fragment>
      </div>
    </Permission>
  );
};
export default ImportLogs;
